import React, { useEffect, useState } from "react";
import { PlusCircle, Edit } from "react-feather";
import { Link } from "react-router-dom"; 

const RoalaccPermitionlist = () => {

  const [userData, setUserData] = useState([]);
 

  useEffect(() => {

    const fetchUserRoles = async () => {
      try {
        const response = await fetch("http://localhost:8000/api/accsessroalrights/one", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        if (Array.isArray(data.data)) {
          setUserData(data.data);
        } else {
          console.error("Data is not an array");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchUserRoles();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Access Roles</h4>
              <h6>Manage your Access Roles</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/roalaccPermitions" className="btn btn-added">
              <PlusCircle className="me-2" />
              Set Access Roles
            </Link>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Role ID</th>
                    <th>Role Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.map((user) => (
                    <tr key={user._id}>
                      <td>{user.rid}</td>
                      <td>{user.role}</td>
                      <td>
                        <Link to={`/editPermition/${user.rid}`}>
                          <Edit className="feather" /> Access Role Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoalaccPermitionlist;
