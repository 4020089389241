import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
//import { Sliders } from "react-feather";
import { Select, Table, Tag } from "antd";
import Swal from "sweetalert2"; 
import { updateApprovalStatus, getEmployeeLeaveWithEmployee,getSearchResults } from "../../service/leavesEmployeeApiService";
import okilogo from "../../assets/OKI-logo.png"
import jsPDF from 'jspdf';
import dayjs from "dayjs";
import ExcelJS from 'exceljs';
import { DatePicker, Space } from 'antd';
import BulkUploadModal from "../../feature-module/hrm/leavesAdminBulkUpload"

const { RangePicker } = DatePicker;

const LeavesAdmin = () => {
  const { Option } = Select;

  const [dataSource, setDataSource] = useState([]);
  const [tempStatus, setTempStatus] = useState({}); 
  const [epf_number, setEpf] = useState("")
  const [leaveType, setLeaveType] = useState("")
  const [name, setName] = useState("")
  const [branch, setBranch] = useState(null)
  const [startDate,setStartDate]=useState(null)
  const [endDate,setEndDate]=useState(null)
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const [loading,setLoading]=useState(false)
  const branchOptions=[
    {value: "kaduwela", label: "Kaduwela"},
    {value: "wattala", label: "Wattala"},
    {value: "negombo", label: "Negombo"},
    {value: "kiribathgoda", label: "Kiribathgoda"},
    {value: "kandana", label: "Kandana"}]
  

  const onChange = (dates, dateStrings) => {
    setStartDate(dateStrings[0])
    setEndDate(dateStrings[1])
  };

  useEffect(() => {
    const fetchLeaves = async () => {
      try {
        setLoading(true)
        const response = await getEmployeeLeaveWithEmployee();
        const leaveData = response.data;
        setLoading(false)
  
        if (leaveData) {
          const formattedData = leaveData.map((item) => {
            if (item.employee) {
              return {
                id: item._id,
                empname: `${item.employee.first_name} ${item.employee.last_name}`,
                empid: item.employee.employee_id,
                epf: item.employee.epf_number,
                type: item.leavetype.name,
                fromdate: new Date(item.startDate).toLocaleDateString(),
                todate: new Date(item.endDate).toLocaleDateString(),
                days_hours: item.duration,
                appliedon: new Date(item.date).toLocaleDateString(),
                status: item.approval,
                branch:item.employee.branch,
              };
            } else {
              return {
                id: item._id,
                empname: "N/A", 
                empid: "N/A", 
                type: item.leavetype.name,
                fromdate: new Date(item.startDate).toLocaleDateString(),
                todate: new Date(item.endDate).toLocaleDateString(),
                days_hours: item.duration,
                appliedon: new Date(item.date).toLocaleDateString(),
                status: item.approval,
                branch:item.employee.branch,
                epf: item.employee.epf_number,
              };
            }
          });
  
          // Sort by appliedon date in descending order
          const sortedData = formattedData.sort((a, b) => new Date(b.appliedon) - new Date(a.appliedon));
  
          setDataSource(sortedData); // Set sorted data to state
        }
      } catch (error) {
        console.error("Error fetching leaves:", error);
      }
    };
  
    fetchLeaves();
  }, []);

 const getSeachData = async () => {
  try {
    setLoading(true)
    const response = await getSearchResults({
          setLoading,
          epf_number,
          name,
          leaveType,
          branch,
          startDate,
          endDate
      });
    const leaveData = response;
    setLoading(false)

    if (leaveData) {
      const formattedData = leaveData.map((item) => {
        if (item.employee) {
          return {
            id: item._id,
            empname: `${item.employee.first_name} ${item.employee.last_name}`,
            empid: item.employee.employee_id,
            type: item.leavetype.name,
            fromdate: new Date(item.startDate).toLocaleDateString(),
            todate: new Date(item.endDate).toLocaleDateString(),
            days_hours: item.duration,
            appliedon: new Date(item.date).toLocaleDateString(),
            status: item.approval,
            branch:item.employee.branch,
            epf: item.employee.epf_number,
          };
        } else {
          return {
            id: item._id,
            empname: "N/A", 
            empid: "N/A", 
            type: item.leavetype.name,
            fromdate: new Date(item.startDate).toLocaleDateString(),
            todate: new Date(item.endDate).toLocaleDateString(),
            days_hours: item.duration,
            appliedon: new Date(item.date).toLocaleDateString(),
            status: item.approval,
            branch:item.employee.branch,
            epf: item.employee.epf_number,
          };
        }
      });

      // Sort by appliedon date in descending order
      const sortedData = formattedData.sort((a, b) => new Date(b.appliedon) - new Date(a.appliedon));

      setDataSource(sortedData); // Set sorted data to state
    }
  } catch (error) {
    console.error("Error fetching leaves:", error);
  }
};
  const handleStatusChange = (value, recordId) => {
    setDataSource((prevData) =>
      prevData.map((record) =>
        record.id === recordId ? { ...record, status: value } : record
      )
    );
  };

  const handleSelectChange = (value, record) => {
    setTempStatus({ ...tempStatus, [record.id]: value });

    let statusText;
    if (value === null) {
      statusText = "Pending"; 
    } else {
      statusText = value ? "Approved" : "Rejected"; 
    }

    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to mark this leave request as ${statusText}.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, proceed!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        handleStatusChange(value, record.id); 
        updateApprovalStatus(record.id, value); 
        
        Swal.fire(
          'Updated!',
          `Leave status has been updated to ${statusText}.`,
          'success'
        );
      } else {
        
        setTempStatus({ ...tempStatus, [record.id]: record.status });
      }
    });
  };

  // const handleSearchChange = (event) => {
   
  //  setSearchText(event.target.value); 
   
  // };

  // const filteredData = dataSource.filter((item) => {
  //   const fullName = item.empname.toLowerCase(); 
  //   const type = item.type.toLowerCase();
  //   const empId = item.empid.toLowerCase();
  //   return (
  //     fullName.includes(searchText.toLowerCase()) ||
  //     empId.includes(searchText.toLowerCase())||
  //     type.includes(searchText.toLowerCase())
  //   );
    
  // });

  ////PDF Controller
  
  const handlePdfExport = () => {
    const date= dayjs().format('YYYY-MM-DD');
    const img=okilogo
    // Filter out unwanted columns
    const filteredData = dataSource.map(emp => ({
        'name': `${emp.empname}`,
        'number': emp.empid,
        'applydate': new Date(emp.appliedon).toLocaleDateString(),
        'type':emp.type,
        'from': new Date(emp.fromdate).toLocaleDateString(),
        'to': emp.todate,
        'status': emp.status === true 
                    ? 'Approved' 
                    : emp.status === false 
                    ? 'Rejected' 
                    : 'Pending'
    }));
    
    const doc = new jsPDF();
   
    doc.setTextColor(0,0,0); // Blue color
    doc.setFontSize(16);
    doc.text('OKI International ', 15, 13);
  
   
    // Larger font size
    doc.setFontSize(13);
    doc.setTextColor(138, 132, 125);
    doc.text(`Employees Leaves`, 15, 23);
   doc.text(`Date: ${date}`, 15, 30);
    doc.addImage(img, 'PNG', 170, 8, 22, 25);
    doc.autoTable({
        head: [['Employee Name', 'Employee Number', 'Applied On', 'Leave Type','From', 'To', 'Status']],
        body: filteredData.map(data => [
            data['name'],
            data['number'],
            data['applydate'],
            data['type'],
            data['from'],
            data['to'],
            data['status']
        ]),
        startY: 35,
        theme: 'striped',
        headStyles: {
            fillColor: [138, 132, 125],
        }
    });
  
    // Save the PDF
    doc.save('employees_leaves.pdf');
  };

  ///Excel export

  const handleExcelExport = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Employees Leaves Report');
  
    // Define cell styles with borders
    const borderStyle = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };
  
    // Define columns and add header
    worksheet.columns = [
        { header: 'Emp Name', key: 'name', width: 20 },
        { header: 'Emp Id', key: 'number', width: 20 },
        { header: 'Applied On', key: 'applydate', width: 15 },
        { header: 'Leave Type', key: 'type', width: 30 },
        { header: 'From', key: 'from', width: 20 },
        { header: 'To', key: 'to', width: 25 },
        { header: 'Status', key: 'status', width: 25 }
    ];
  
    // Add data rows
    dataSource.forEach(emp => {
        worksheet.addRow({
          'name': `${emp.empname}`,
          'number': emp.empid,
          'applydate': new Date(emp.appliedon).toLocaleDateString(),
          'type':emp.type,
          'from': new Date(emp.fromdate).toLocaleDateString(),
          'to': emp.todate,
          'status': emp.status === true 
                      ? 'Approved' 
                      : emp.status === false 
                      ? 'Rejected' 
                      : 'Pending'
        });
    });
  
    // Apply border styles to all cells
    worksheet.eachRow({ includeEmpty: true }, (row) => {
        row.eachCell({ includeEmpty: true }, (cell) => {
            cell.border = borderStyle;
        });
    });
  
    // Save the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Employees_Leaves_Report.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
    });
  };

  const columns = [
    {
      title: "Emp Name",
      dataIndex: "empname",
    },
    {
      title: "EPF No",
      dataIndex: "epf",
    },
    {
      title: "Branch",
      dataIndex: "branch",
    },
    {
      title: "Leave Type",
      dataIndex: "type",
    },
    {
      title: "From Date",
      dataIndex: "fromdate",
    },
    {
      title: "To Date",
      dataIndex: "todate",
    },
    {
      title: "Duration",
      dataIndex: "days_hours",
    },
    {
      title: "Applied On",
      dataIndex: "appliedon",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        return (
          <Select
            value={tempStatus[record.id] !== undefined ? tempStatus[record.id] : status}
            onChange={(value) => handleSelectChange(value, record)}
            dropdownStyle={{
              maxHeight: 200,
              overflowY: 'auto',
              zIndex: 1050,
            }}
            getPopupContainer={(trigger) => trigger.parentNode}
            style={{ width: 120, height:37}}
            placeholder={status === null ? "Select" : undefined}
          >
            <Option value={null}>
              <Tag className="badges" style={{ backgroundColor: '#f0ad4e', color: '#fff' }} >
                Pending
              </Tag>
            </Option>
            <Option value={true}>
              <Tag className="badges status-badge">Approved</Tag>
            </Option>
            <Option value={false}>
              <Tag className="badges unstatus-badge">Rejected</Tag>
            </Option>
          </Select>
        );
      }
    }
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Leaves</h4>
                <h6>Manage employees leaves</h6>
              </div>
            </div>
            <div className="wordset">
                    <ul>
                      <li>
                        <a  title="pdf"
                        onClick={handlePdfExport}
                        >
                          <img src="assets/img/icons/pdf.svg" alt="pdf" />
                        </a>
                      </li>
                      <li>
                        <a  title="excel"
                        onClick={handleExcelExport}
                        >
                          <img src="assets/img/icons/excel.svg" alt="excel" />
                        </a>
                      </li>
                      {/* <li>
                        <a href="#" title="print">
                          <img src="assets/img/icons/printer.svg" alt="print" />
                        </a>
                      </li> */}
                    </ul>
                  </div>

            <div className="page-btn">
              {

                <a className="btn btn-added" onClick={() => setIsBulkUploadOpen(true)}>
                  {/* <PlusCircle className="me-2" /> */}
                  Bulk upload
                </a>
              }


            </div>
            <div className="page-btn">
              <Link to="/allocate-leaves" className="btn btn-added">
                Allocated Leaves
              </Link>
            </div>
            
          </div>
          <div className="card table-list-card">
            <div className="card-body" style={{ maxWidth: "100%", marginRight: "20px" }}>
              <div className="container mt-3 " style={{ maxWidth: "100%", marginLeft: "20px", marginRight: "40px" }}> {/* Added p-0 and maxWidth */}
                <div className="row d-flex flex-wrap align-items-start justify-content-between"> {/* Added justify-content-start */}
                  <div className="col-12 col-md-3  text-start">
                    <label className="form-label">Employee Name</label>
                    <input
                      type='text'
                      value={name}
                      className="form-control"
                      onChange={(e) => {
                          setName(e.target.value);
                      }}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-12 col-md-2 mb-3 text-start">
                    <label className="form-label">EPF No</label>
                    <input
                      className="form-control"
                      type='text'
                      value={epf_number}
                      onChange={(e) => setEpf(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </div>

                  <div className="col-12 col-md-2 mb-3 text-start">
                    <label className="form-label">Leave Type</label>
                    <input
                      className="form-control"
                      type='text'
                      value={leaveType}
                      onChange={(e) => setLeaveType(e.target.value)}
                      style={{ width: "100%" ,height: "38px"}}
                    />

                  </div>
                  <div className="col-12 col-md-2 mb-3 text-start">
                    <label className="form-label">branch</label>

                    <Select
                      value={branch}
                      options={branchOptions}
                      className="select"
                      placeholder="Select Branch"
                       onChange={setBranch}
                      allowClear
                      style={{ width: "100%", height: "38px" }}
                    />

                  </div>
                  <div className="col-12 col-md-3 mb-3 text-start">
                    <label className="form-label">Date</label>
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        format="YYYY-MM-DD"
                        onChange={onChange}
                        //disabledDate={disabledDate}
                        style={{ width: "100%" }}
                      />
                    </Space>

                  </div>



                </div>
              </div>

              <div className="container mt-0" style={{ maxWidth: "100%", marginLeft: "20px", marginRight: "40px" }}>
                <div className="row d-flex align-items-center justify-content-start">

                  
                  <div className="col-12 col-md-2 mb-3 text-start">

                    <button
                      className="btn btn-submit"
                      onClick={getSeachData}
                      style={{ height: "38px", width: "55%" }}
                    >
                      Submit
                    </button>

                  </div>

                </div>
              </div>



                    </div>
                </div>
          <div className="card table-list-card">
            <div className="card-body pb-0">
              <div className="table-top">
                <div className="input-blocks search-set mb-0">
                 
                 
                </div>
                
              </div>
            </div>
            <div className="table-responsive">
              <Table
                loading={loading}
                columns={columns}
                dataSource={dataSource} 
                pagination={{ pageSize: 10 }} 
                rowKey={(record) => record.id} 
                className="custom-ant-table" 
              />
            </div>
          </div>
        </div>
      </div>
      <BulkUploadModal
        handleCancel={() => setIsBulkUploadOpen(false)}
        isVisible={isBulkUploadOpen}
        onUploadData={getSeachData}
      />
    </div>
  );
};

export default LeavesAdmin; 
