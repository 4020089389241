import React, { useState } from 'react'
// import { Link } from 'react-router-dom'
import { createTax } from '../../../service/taxApiServices';
import PropTypes from "prop-types";

const AddTaxRates = ({onTaxAdded}) => {
    const [name, setTaxName] = useState('');
    const [taxRate, setTaxRate] = useState('');
    const [status, setStatus] = useState(true);

    const handleStatusCheckboxChange = (event) => {
        setStatus(event.target.checked);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        const isValidNumber = /^[0-9]*\.?[0-9]*$/.test(value);
        
        if (isValidNumber) {
            setTaxRate(value);
        }
    };

    const handleSubmit = async () => {
        // e.preventDefault();

        const tax = {
            name,
            taxRate,
            status
        };

        try {
            const response = await createTax(tax);
            onTaxAdded();
            console.log("Tax added successfully:", response.data);
            setTaxName(""); 
            setTaxRate(""); 
            setStatus(true);
        } catch (error) {
          console.error("Error adding Tax:", error);
        }
    };
    return (
        <div>
            {/* Add Tax Rates */}
            <div className="modal fade" id="add-tax">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Add Tax Rates</h4>
                                    </div>
                                    <div className="status-toggle modal-status d-flex justify-content-between align-items-center ms-auto me-2">
                                        <input
                                            type="checkbox"
                                            id="user1"
                                            className="check"
                                            defaultChecked="true"
                                            name="status"
                                            checked={status}
                                            onChange={handleStatusCheckboxChange}
                                        />
                                        <label htmlFor="user1" className="checktoggle">
                                            {" "}
                                        </label>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label className="form-label">
                                                        Name <span> *</span>
                                                    </label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        name="taxName"
                                                        value={name} 
                                                        onChange={(e) => setTaxName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-0">
                                                    <label className="form-label">
                                                        Tax Rate % <span> *</span>
                                                    </label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        name="taxPercentage"
                                                        value={taxRate} 
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                data-bs-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <button to="#" className="btn btn-submit">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Tax Rates */}
        </div>
    )
}

AddTaxRates.propTypes = {
    onTaxAdded: PropTypes.func.isRequired
}

export default AddTaxRates
