import axios from "axios";
import Swal from "sweetalert2";

const showWarning = (str) =>{
    return Swal.fire({
      title: "Error",
      text: str,
      icon: "error",
    });
  }

export const createDeductionType = async (deductioType) => {
    try {
        const response = await axios.post(
            `/deduction-type`, deductioType
        );

        return response.data;
    } catch (error) {
        throw new Error(
            error.response.data.message || "Failed to create"
        );
    }
}    


export const fetchDeductioTypeDetails = async () => {
    try {
        const response = await axios.get(`/deduction-type`);
        return response.data;
    } catch (error) {
        console.error('Error fetching job details:', error);
        throw error;
    }
};


export const editDeductioType = async (id, type) => {
    try {
      const response = await axios.put(`/deduction-type/${id}`, type);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Failed to edit deduction-type");
    }
  };


  export const deleteDeductioType = async ({id}) => {
    try {
        console.log(id)
      await axios.delete(`/deduction-type/${id}`).then(() => {
        // setIsDelete(true);
        // setTimeout(() => setIsDelete(false), 1000);
      }).catch((err) => {
        if (err.response.status === 404) {
          showWarning(err.response.data.message);
          return;
        }
        showWarning("Error occurred while deleting deduction-type");
      });
    } catch (error) {
      showWarning("Error occurred while deleting deduction-type");
    }
  };

