import React, { useState, useEffect } from "react";
import { PlusCircle } from "react-feather";

const AddUserarole = () => {
  const [roleid, setRoleId] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const generateRoleId = () => {
    const newRoleId = Math.floor(1000 + Math.random() * 9000);
    setRoleId(newRoleId);
  };

  useEffect(() => {
    generateRoleId();
  }, []);

  const validateForm = () => {
    if (!role) {
      setError("Role field is required.");
      return false;
    }
    setError("");
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return; 
    }

    const newUserRole = {
      roleid,
      role,
    };

    try {
      const response = await fetch("http://localhost:8000/api/useracrights/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newUserRole),
      });

      if (response.ok) {
        setSuccessMessage("User role added successfully!");
        setRole(""); 
        generateRoleId(); 
      } else {
        const result = await response.json();
        setError(result.message || "Failed to add user role");
      }
    } catch (error) {
      console.error("Error adding user role:", error);
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Add User Role</h4>
              <h6>Fill in the details to add a new role</h6>
            </div>
          </div>
          <div className="page-btn">
            <a to="#" className="btn btn-added">
              <PlusCircle className="me-2" />
              Add New Role
            </a>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            {error && <div className="alert alert-danger">{error}</div>}
            {successMessage && (
              <div className="alert alert-success">{successMessage}</div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Role ID</label>
                <input
                  type="text"
                  className="form-control"
                  value={roleid}
                  readOnly 
                />
              </div>
              <div className="form-group">
                <label>Role</label>
                <input
                  type="text"
                  className="form-control"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  placeholder="Enter role"
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Save Role
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserarole;
