import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../core/breadcrumbs";


import CustomerModal from "../../core/modals/peoples/customerModal";
import CustomerTable from "./customerTable";

import {searchCustomer} from "../../service/ApiServices";
import { API_BASE_URL } from "../../../src/environment";

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const [selectedCustomer, setSelectedCustomer] = useState([]);
  useEffect(() => {
    // fetchCustomers();
    searchCustomer(searchQuery,page).then(value => {setCustomers(value.data.docs)

    setTotal(value.data.totalDocs)})
  }, [searchQuery,page]);

  const changePage = async (e)=>{
    setPage(e.taget.value)
    let axiosResponse = await searchCustomer(searchQuery, page);
    setCustomers(axiosResponse.data.docs);
    setTotal(axiosResponse.data.totalDocs);
  }

  const storedUserData = localStorage.getItem("user");
  const userData = JSON.parse(storedUserData);
  const id = userData?.data?.uid; //
  const [userRightssub, setUserRightssub] = useState([]);
  useEffect(() => {
    const fetchUserRightssub = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/customers/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user rights");
        }
        const data = await response.json();
        console.log("Fetched User Sub Data:", data.data);
        setUserRightssub(data.data || []); // Ensure it's always an array
      } catch (error) {
        console.error("Error fetching data:", error);
        setUserRightssub([]); // Set to empty array in case of error
      }
    };
  
    fetchUserRightssub(); // Fetch the data on component mount
  }, [id]);
  const hasJobRightWithAdd = userRightssub.some(right => right.right === 'Customers' && right.adds === 1);
   
    


  // const fetchCustomers = async () => {
  //   try {
  //     const response = await fetch(`http://${BASE_URL}:8000/api/customers`);
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch customers");
  //     }
  //     const data = await response.json();
  //     console.log("Fetched data:", data);
  //     setCustomers(data.data.customers);
  //   } catch (error) {
  //     console.error("Error fetching customers:", error);
  //   }
  // };


  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="page-wrapper">
      <div className="content">
      {hasJobRightWithAdd && (
         <Breadcrumbs
         maintitle="Customer List"
         subtitle="Manage Your Customers"
         addButton="Add New Customer"
       />
       )}





        

        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                <input 
                    type="search" 
                    className="form-control form-control-sm formsearch" 
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                </div>
              </div>

            </div>

            <div className="table-responsive">
              <CustomerTable customers={customers} loadCustomer={searchCustomer} setSelectedCustomer={setSelectedCustomer} changePage={changePage} total={total} page={page}/>
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      <CustomerModal loadCustomers={searchCustomer} selectedCustomer={selectedCustomer}/>
    </div>
  );
};

export default Customers;
