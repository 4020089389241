
import CreateBodyTypeModal from '../../core/modals/inventory/customizeStyle';
import React, { useEffect, useState } from 'react';
import { Modal, Table, Tag } from 'antd';
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import { getAllBodyTypeMethod } from '../../service/styleService';

const BodyType = () => {
    const [data, setData] = useState([]);
    const [isJobTypeAdd, setIsJobTypeAdd] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBodyType, setSelectedBodyType] = useState(null);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const handler = setTimeout(() => {
            const fetchData = async () => {
                setLoading(true);
                let data = await getAllBodyTypeMethod({ search, setLoading });
                setData(data);
            };
            fetchData();
            setIsJobTypeAdd(false);
            setSelectedBodyType(null);
        }, 600);

        return () => {
            clearTimeout(handler);
        };
    }, [search, isJobTypeAdd]);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setSelectedBodyType(null)
        setIsModalOpen(false);
    };

    const handleJobTypeAdding = () => {
        setIsJobTypeAdd(true);
    };

    const columns = [
        {
            title: 'Style',
            dataIndex: 'styleName',
            key: 'styleName',
        },
        {
            title: 'Values',
            dataIndex: 'values',
            key: 'values',
            render: (types) => {
                return (
                    <ul style={{ listStyleType: 'disc', paddingLeft: '0px',listStyle:"none", lineHeight:"2"}}>
                        {types.map((type, i) => (
                            <li key={i} > <Tag style={{background:"#f2f8fc"}}>{type}</Tag> </li>
                        ))}
                    </ul>
                );
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <td className="action-table-data">
                    <Link
                        className="me-2 p-2"
                        to="#"
                        onClick={() => {
                            setSelectedBodyType(record);
                            setIsModalOpen(true);
                        }}
                    >
                        <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                </td>
            ),
        },
    ];


    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Styles</h4>
                            <h6>Personalize Your Style</h6>
                        </div>
                    </div>
                    <div className="page-btn">
                        <a className="btn btn-added" onClick={() => setIsModalOpen(true)}>
                            <PlusCircle className="me-2" />
                            Add New Style
                        </a>
                    </div>
                </div>

                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <Link className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </Link>
                                </div>
                            </div>

                        </div>
                        <div className="table-responsive">
                            <Table loading={loading} columns={columns} dataSource={data} rowKey="value" pagination={false} />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                zIndex={200000}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                closable={false}
            >
                <CreateBodyTypeModal handleJobTypeAdding={handleJobTypeAdding} handleCancel={handleCancel} selectedBodyType={selectedBodyType} />
            </Modal>
        </div>
    );
};

export default BodyType;



// import React, { useEffect, useState } from 'react';
// import { Modal, Table } from 'antd';
// import { PlusCircle } from 'react-feather';
// import { Link } from 'react-router-dom';// import { getAllStyles } from '../../service/styleService';

// //C:\Users\sdils\Desktop\POS new\pos-ui\src\service\styleService.js
// const Style = () => {
    
//     const [isJobTypeAdd, setIsJobTypeAdd] = useState(false);
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [selectedBodyType, setSelectedBodyType] = useState(null);
//     const [search, setSearch] = useState('');
//     const [data, setData] = useState([]);
//     //const [loading, setLoading] = useState(false);
//     useEffect(() => {
//         const handler = setTimeout(() => {
//             const fetchData = async () => {
//                 //setLoading(true);
//                 //let data = await getAllBodyTypeMethod({ search, setLoading });
//                // setData(data);
//             };
//             fetchData();
//             setIsJobTypeAdd(false);
//             setSelectedBodyType(null);
//         }, 600);

//         return () => {
//             clearTimeout(handler);
//         };
//     }, [search, isJobTypeAdd]);

//     const handleOk = () => {
//         setIsModalOpen(false);
//     };

//     const handleCancel = () => {
//         setSelectedBodyType(null)
//         setIsModalOpen(false);
//     };

//     const handleJobTypeAdding = () => {
//         setIsJobTypeAdd(true);
//     };

//     // useEffect(() => {
//     //     const fetchData = async () => {
//     //         const result = await getAllStyles();
//     //         if (result) {
//     //             console.log("BACKEND DATA", result);
    
//     //             // Transform the data to group styles into a single row
//     //             const tableData = result.map((item) => ({
//     //                 key: item.serviceName,
//     //                 serviceName: item.serviceName,
//     //                 styleNames: item.styles.map(style => style.styleName).join('\n'), // Join style names with newline
//     //                 values: item.styles.map(style => style.value.join('\n')).join('\n'), // Join values with newline
//     //             }));
    
//     //             setData(tableData);
//     //         }
//     //     };
    
//     //     fetchData();
//     // }, []);


//     // const columns = [
//     //     {
//     //         title: 'Service Name',
//     //         dataIndex: 'serviceName',
//     //         key: 'serviceName',
//     //         render: text => <div style={{  }}>{text}</div>,
//     //     },
//     //     {
//     //         title: 'Style Names',
//     //         dataIndex: 'styleNames',
//     //         key: 'styleNames',
//     //         render: text => (
//     //             <div style={{ display: 'flex', flexDirection: 'column' }}>
//     //                 {text.split('\n').map((line, index) => (
//     //                     <div
//     //                         key={index}
//     //                         style={{ 
//     //                             lineHeight: '3',
//     //                             //marginBottom:"25px"
//     //                         }}
//     //                     >
//     //                         {line}
//     //                     </div>
//     //                 ))}
//     //             </div>
//     //         ),
//     //     },

//     //     {
//     //         title: 'Values',
//     //         dataIndex: 'values',
//     //         key: 'values',
//     //         render: text => <div style={{ whiteSpace: 'pre-line', lineHeight: '2' }}>{text}</div>, // Display multiline text
//     //     },
//     //     {
//     //         title: 'Action',
//     //         key: 'action',
//     //         render: (_, record) => (
//     //             <td className="action-table-data">
//     //                 <Link
//     //                     className="me-2 p-2"
//     //                     to="#"
//     //                     onClick={() => {
//     //                         setSelectedBodyType(record);
//     //                         setIsModalOpen(true);
//     //                     }}
//     //                 >
//     //                     <i data-feather="edit" className="feather-edit"></i>
//     //                 </Link>
//     //             </td>
//     //         ),
//     //     },
//     // ];
//     /////////////////////////////////////////////////
//     ///////////
//     useEffect(() => {
//         const fetchData = async () => {
//             const result = await getAllStyles();
//             if (result) {
//                 console.log("BACKEND DATA", result);
    
//                 // Transform the data to group styles into a single row
//                 const tableData = result.map((item) => ({
//                     key: item.serviceName,
//                     serviceName: item.serviceName,
//                     styleNames: item.styles.map((style, index) => ({
//                         name: style.styleName,
//                         color: getColorForIndex(index),
//                     })),
//                     values: item.styles.map((style, index) => ({
//                         value: style.value.join('\n'),
//                         color: getColorForIndex(index),
//                     })),
//                 }));
    
//                 setData(tableData);
//             }
//         };
    
//         fetchData();
//     }, []);
    
//     // Function to determine color based on index
//     const getColorForIndex = (index) => {
//         const colors = ['orange', 'black']; // Define your colors here
//         return colors[index % colors.length];
//     };
//     const columns = [
//     {
//         title: 'Service Name',
//         dataIndex: 'serviceName',
//         key: 'serviceName',
//     },
//     {
//         title: 'Style Names',
//         dataIndex: 'styleNames',
//         key: 'styleNames',
//         render: styles => (
//             <div style={{ display: 'flex', flexDirection: 'column' }}>
//                 {styles.map((style, index) => (
//                     <div
//                         key={index}
//                         style={{
//                             color: style.color, // Apply the color based on the index
//                             lineHeight: '',
//                         }}
//                     >
//                         {style.name}
//                     </div>
//                 ))}
//             </div>
//         ),
//     },
//     {
//         title: 'Values',
//         dataIndex: 'values',
//         key: 'values',
//         render: values => (
//             <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '2' }}>
//                 {values.map((val, index) => (
//                     <div
//                         key={index}
//                         style={{
//                             color: val.color,
//                         }}
//                     >
//                         {val.value}
//                     </div>
//                 ))}
//             </div>
//         ),
//     },
//     {
//         title: 'Action',
//         key: 'action',
//         render: (_, record) => (
//             <td className="action-table-data">
//                 <Link
//                     className="me-2 p-2"
//                     to="#"
//                     onClick={() => {
//                         setSelectedBodyType(record);
//                         setIsModalOpen(true);
//                     }}
//                 >
//                     <i data-feather="edit" className="feather-edit"></i>
//                 </Link>
//             </td>
//         ),
//     },
// ];

//     // const styleColors = ['#f0f0f0', '#d9eaf7']; // Define alternating colors

//     // const columns = [
//     //     {
//     //         title: 'Service Name',
//     //         dataIndex: 'serviceName',
//     //         key: 'serviceName',
//     //         render: text => <div>{text}</div>,
//     //     },
//     //     {
//     //         title: 'Style Names',
//     //         dataIndex: 'styleNames',
//     //         key: 'styleNames',
//     //         render: (text) => (
//     //             <div style={{ display: 'flex', flexDirection: 'column' }}>
//     //                 {text.split('\n').map((line, index) => (
//     //                     <div
//     //                         key={index}
//     //                         style={{
//     //                             backgroundColor: styleColors[index % styleColors.length], // Alternate background colors
//     //                             padding: '8px', // Add padding for better appearance
//     //                             lineHeight: '2',
//     //                         }}
//     //                     >
//     //                         {line}
//     //                     </div>
//     //                 ))}
//     //             </div>
//     //         ),
//     //     },
//     //     {
//     //         title: 'Values',
//     //         dataIndex: 'values',
//     //         key: 'values',
//     //         render: (text) => (
//     //             <div style={{ display: 'flex', flexDirection: 'column' }}>
//     //                 {text.split('\n').map((line, index) => (
//     //                     <div
//     //                         key={index}
//     //                         style={{
//     //                             backgroundColor: styleColors[index % styleColors.length], // Match the background color of styleNames
//     //                             padding: '8px',
//     //                             lineHeight: '2',
//     //                         }}
//     //                     >
//     //                         {line}
//     //                     </div>
//     //                 ))}
//     //             </div>
//     //         ),
//     //     },
//     //     {
//     //         title: 'Action',
//     //         key: 'action',
//     //         render: (_, record) => (
//     //             <td className="action-table-data">
//     //                 <Link
//     //                     className="me-2 p-2"
//     //                     to="#"
//     //                     onClick={() => {
//     //                         setSelectedBodyType(record);
//     //                         setIsModalOpen(true);
//     //                     }}
//     //                 >
//     //                     <i data-feather="edit" className="feather-edit"></i>
//     //                 </Link>
//     //             </td>
//     //         ),
//     //     },
//     // ];
    

//     return (
//         <div className="page-wrapper">
//             <div className="content">
//                 <div className="page-header">
//                     <div className="add-item d-flex">
//                         <div className="page-title">
//                             <h4>Styles</h4>
//                             <h6>Customize Your Styles</h6>
//                         </div>
//                     </div>
//                     <div className="page-btn">
//                         <a className="btn btn-added" onClick={() => setIsModalOpen(true)}>
//                             <PlusCircle className="me-2" />
//                             Customize Style
//                         </a>
//                     </div>
//                 </div>

//                 <div className="card table-list-card">
//                     <div className="card-body">
//                         <div className="table-top">
//                             <div className="search-set">
//                                 <div className="search-input">
//                                     <input
//                                         type="search"
//                                         className="form-control"
//                                         placeholder="Search"
//                                         value={search}
//                                         onChange={(e) => setSearch(e.target.value)}
//                                     />
//                                     <Link className="btn btn-searchset">
//                                         <i data-feather="search" className="feather-search" />
//                                     </Link>
//                                 </div>
//                             </div>

//                         </div>
//                         <div className="table-responsive">
//                             <Table columns={columns}
//                              dataSource={data}
//                              rowKey="value" pagination={false} />
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <Modal
//                 zIndex={200000}
//                 open={isModalOpen}
//                 onOk={handleOk}
//                 onCancel={handleCancel}
//                 footer={null}
//                 maskClosable={false}
//                 closable={false}
//             >
//                 <CreateBodyTypeModal handleJobTypeAdding={handleJobTypeAdding} handleCancel={handleCancel} selectedBodyType={selectedBodyType} />
//             </Modal>
//         </div>
//     );
// };

// export default Style;
