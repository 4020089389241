import { DatePicker } from "antd";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";
import Select from "react-select";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  fetchSuppliers,
  fetchProducts,
  fetchProductDetails,
  createPurchase,
  generateBatchNumber,
} from "../../../service/purchasesApiServices";

const AddPurchases = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [batchNumber, setBatchNumber] = useState("");

  const [suppliers, setSuppliers] = useState([]);
  const [products, setProducts] = useState([]);

  const [productDetails, setProductDetails] = useState([]);
  const [productDataList, setProductDataList] = useState([]);
  const [disabledProducts, setDisabledProducts] = useState([]); // Track disabled products

  const [orderTax, setOrderTax] = useState("");
  const [discount, setDiscount] = useState("");
  const [shipping, setShipping] = useState("");
  const [status, setStatus] = useState(null);
  const [notes, setNotes] = useState("");
  const [quantity, setQuantity] = useState("");

  const statusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Completed", label: "Completed" },
  ];

  useEffect(() => {
    const fetchBatchNumber = async () => {
      try {
        const response = await generateBatchNumber();
        setBatchNumber(response);
      } catch (error) {
        console.error("Error generating batch number:", error);
      }
    };

    fetchBatchNumber();
  }, []);
  useEffect(() => {
    const getSuppliers = async () => {
      try {
        const supplierData = await fetchSuppliers();
        setSuppliers([
          {
            id: "Choose",
            label: "Choose",
          },
          ...supplierData.data.suppliers.map((supplier) => ({
            id: supplier._id,
            label: supplier.supplierName,
          })),
        ]);
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };
    //  Display list of product names for dropdown
    const getProducts = async () => {
      try {
        const productData = await fetchProducts();
        setProducts(
          productData.data.data.products.map((product) => ({
            id: product._id,
            label: product.productName,
          }))
        );
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    getSuppliers();
    getProducts();
  }, []);

  const handleProductChange = async (selectedOption) => {
    setSelectedProduct(selectedOption);
    if (selectedOption) {
      try {
        const details = await fetchProductDetails(selectedOption.id);

        setProductDetails(details.data.product);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    } else {
      setProductDetails([]);
    }
  };

  const InsertProductRecords = async () => {
    try {
      if (productDetails) {
        // Append the new product record
        const newProductDetails = { ...productDetails, quantity };
        setProductDataList((prevDataList) => [
          ...prevDataList,
          newProductDetails,
        ]);

        // Disable the selected product in the dropdown
        setDisabledProducts((prevDisabled) => [
          ...prevDisabled,
          productDetails._id,
        ]);

        setSelectedProduct(null);
        setQuantity("");
        console.log("Updated productDataList:", productDataList);
      } else {
        console.error("No product details available to insert");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const DeleteProductRecord = (index) => {
    try {
      const productToDelete = productDataList[index];

      setProductDataList((prevDataList) =>
        prevDataList.filter((_, i) => i !== index)
      );
      // Enable the deleted product in the dropdown
      setDisabledProducts((prevDisabled) =>
        prevDisabled.filter((id) => id !== productToDelete._id)
      );
    } catch (error) {
      console.log("Did not delete index", error);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Structure the purchase data
    const purchaseData = {
      batchNumber: batchNumber,
      purchaseDate: selectedDate || null,
      supplier: selectedSupplier?.id || null,
      supplierName: selectedSupplier?.label || null,
      orderTax: orderTax || null,
      discount: discount || null,
      shipping: shipping || null,
      status: status?.value || null,
      notes: notes || null,
      products: productDataList.map((product) => ({
        product: product._id || null,
        productName: product.productName || null,
        quantity: product.quantity || null,
      })),
    };

    console.log("Payload to send:", purchaseData);

    try {
      const response = await createPurchase(purchaseData);
      console.log("Response received:", response);
      console.log("Selected Supplier:", selectedSupplier);
      console.log("Selected Status:", status);
      console.log("Payload to send:", purchaseData);

      if (response) {
        console.log("Triggering success alert...");
        Swal.fire({
          icon: "success",
          title: "Purchase Created",
          text: "Purchase details have been added successfully!",
          confirmButtonText: "OK",
        }).then(() => {
          // Clear the productDataList and navigate
          document.getElementById("closeModalButton").click();
          setProductDataList([]);
          setOrderTax("");
          setDiscount("");
          setStatus("");
          setShipping("");
          setSelectedSupplier(null);
          setSelectedProduct(null);
          setNotes("");
        });
      }
    } catch (error) {
      console.error("Error creating purchase:", error.message);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred while creating the purchase.",
        confirmButtonText: "Try Again",
      });
    }
  };
  const handleResetButton = () => {
    setOrderTax("");
    setDiscount("");
    setStatus("");
    setShipping("");
    setSelectedSupplier(null);
    setSelectedProduct(null);
    setNotes("");
    setBatchNumber("");
    setProductDataList([]);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleQuantityChange = (index, value) => {
    setProductDataList((prevDataList) =>
      prevDataList.map((item, i) =>
        i === index ? { ...item, quantity: parseInt(value) } : item
      )
    );
  };

  const datePickerStyle = {
    zIndex: 2000,
  };
  const filteredProducts = products.filter(
    (product) => !disabledProducts.includes(product.id)
  );
  return (
    <div>
      {/* Add Purchase */}
      {console.log(productDetails)}

      <div className="modal fade" id="add-units">
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Purchase</h4>
                  </div>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="input-blocks">
                          <label>Supplier Name</label>
                          <Select
                            options={suppliers}
                            className="select"
                            placeholder="Choose"
                            onChange={(selectedOption) => {
                              console.log("Selected Supplier:", selectedOption);
                              setSelectedSupplier(selectedOption);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-lg-1 col-md-1 col-sm-2 d-flex mt-3 align-items-center">
                        <div className="add-icon tab  d-flex align-items-center">
                          <Link to="/suppliers">
                            <PlusCircleOutlined className="feather-plus-circles" />
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-4 col-sm-8">
                        <div className="input-blocks">
                          <label>Purchase Date</label>
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            type="date"
                            className="filterdatepicker"
                            dateFormat="dd-MM-yyyy"
                            placeholder="Choose Date"
                            popupStyle={datePickerStyle}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="input-blocks">
                          <label>Product Name</label>
                          <Select
                            selected={selectedProduct}
                            options={filteredProducts}
                            className="select"
                            placeholder="Choose"
                            onChange={handleProductChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-2 d-flex mt-3 align-items-center">
                        <div className="add-icon tab  d-flex align-items-center">
                          <Link>
                            <PlusCircleOutlined
                              className="feather-plus-circles"
                              onClick={() =>
                                InsertProductRecords(productDetails)
                              }
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="input-blocks">
                          <label>Reference Number</label>
                          <input
                            type="text"
                            value={batchNumber}
                            disabled="true"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Qty</th>
                                <th>Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {/* Add product details rows dynamically here */}

                              {productDataList.map((purchase, index) => (
                                <tr key={index}>
                                  <td>{purchase.productName}</td>
                                  <td>
                                    <input
                                      type="number"
                                      value={purchase.quantity || 0}
                                      className="form-control  w-50"
                                      onChange={(e) =>
                                        handleQuantityChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="action-table-data">
                                    <div
                                      className="edit-delete-action"
                                      onClick={() => DeleteProductRecord(index)}
                                    >
                                      <Link className="confirm-text p-2" to="#">
                                        <i
                                          data-feather="trash-2"
                                          className="feather-trash-2"
                                        ></i>
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Order Tax</label>
                          <input
                            type="text"
                            className="form-control"
                            value={orderTax}
                            onChange={(e) => setOrderTax(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Discount</label>
                          <input
                            type="text"
                            className="form-control"
                            value={discount}
                            onChange={(e) => setDiscount(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Shipping</label>
                          <input
                            type="text"
                            className="form-control"
                            value={shipping}
                            onChange={(e) => setShipping(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Status</label>
                          <Select
                            options={statusOptions}
                            className="select"
                            placeholder="Choose"
                            onChange={(selectedOption) => {
                              console.log("Selected Status:", selectedOption);
                              setStatus(selectedOption);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-blocks">
                          <label>Notes</label>

                          <ReactQuill
                            className="form-control"
                            value={notes}
                            onChange={(content) => setNotes(content)}
                            theme="snow"
                            placeholder="Write your notes here..."
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                      <button
                        type="button"
                        id="closeModalButton"
                        className="btn btn-cancel"
                        data-bs-dismiss="modal"
                        style={{ display: "none" }}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-cancel"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <div>
                        <button
                          type="reset"
                          className="btn btn-reset me-2"
                          onClick={handleResetButton}
                        >
                          Reset
                        </button>
                        <button type="submit" className="btn btn-submit ">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Purchase */}
    </div>
  );
};

export default AddPurchases;
