import React, { useState, useEffect } from "react";
import Select from "react-select";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
import dayjs from "dayjs";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { updateLeaveEmployee } from "../../../service/leavesEmployeeApiService";
import { getAllLeaveTypes } from "../../../service/leaveApiServices/leaveTypesApi";

const EditLeaveEmployee = ({ handleCancel, selectedEmployeeLeave }) => {
  const leaveDayOptions = [
    { value: "Choose Status", label: "Choose Status" },
    { value: "Full Day", label: "Full Day", dayValue: 1 },
    { value: "Half Day", label: "Half Day", dayValue: 0.5 },
  ];

  const [selectedDate, setSelectedDate] = useState([null, null]);
  const [dateRange, setDateRange] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState({});
  const [reason, setReason] = useState("");
  const [leaveDays, setLeaveDays] = useState([]);
  const [leaveType, setLeaveType] = useState([]);

  useEffect(() => {
    if (selectedEmployeeLeave) {
      if (!selectedDate[0] && !selectedDate[1]) {
        const formattedLeaveDays = selectedEmployeeLeave.leaveDays.map(
          (leave, index) => ({
            date: dayjs(leave.date).toDate(),
            dayValue: leave.Day,
            key: `Day ${index + 1}`,
          })
        );

        setLeaveDays(formattedLeaveDays);
        setSelectedLeaveType({
          id: selectedEmployeeLeave.leavetype._id,
          label: selectedEmployeeLeave.leavetype.name,
        });
        setReason(selectedEmployeeLeave.reason);

        // Set the date range based on leave days
        if (formattedLeaveDays.length > 0) {
          const dates = formattedLeaveDays.map((leave) => leave.date);
          setSelectedDate([dates[0], dates[dates.length - 1]]);
          setDateRange(formattedLeaveDays);
        }
      }
    }
  }, [selectedEmployeeLeave]);

  useEffect(() => {
    const fetchLeaveTypes = async () => {
      try {
        const leaveTypeRecord = await getAllLeaveTypes({
          search: "",
          setLoading: () => {}, // Optional loading state
        });

        if (leaveTypeRecord) {
          const options = leaveTypeRecord.map((leave) => ({
            id: leave._id,
            label: leave.name,
            leaveCount: leave.minimumLeaveCount,
          }));

          setLeaveType([{ value: "Choose", label: "Choose" }, ...options]);
        }
      } catch (error) {
        console.error("Error fetching leave types:", error);
      }
    };

    fetchLeaveTypes();
  }, []);

  // Handle Date Change
  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setSelectedDate([dates[0], dates[1]]);

      const newDateRange = [];
      let day = dates[0];
      let index = 0;

      while (day.isBefore(dates[1]) || day.isSame(dates[1], "day")) {
        newDateRange.push({
          date: day,
          dayValue: leaveDays[index]?.dayValue || "Full Day",
          key: `Day ${index + 1}`,
        });
        day = day.add(1, "day");
        index++;
      }

      setDateRange(newDateRange);
      setLeaveDays(newDateRange);
    } else {
      setSelectedDate([null, null]);
      setDateRange([]);
      setLeaveDays([]);
    }
  };

  // Handle Leave Type Change for individual days
  const handleLeaveTypeChange = (index, selectedOption) => {
    const updatedLeaveDays = [...leaveDays];
    updatedLeaveDays[index].dayValue = selectedOption.value;
    setLeaveDays(updatedLeaveDays);
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const leaveDetails = {
      leavetype: selectedLeaveType.id,
      leaveDays: leaveDays.map((day) => ({
        date: dayjs(day.date).format("YYYY-MM-DD"),
        Day: day.dayValue,
      })),
      reason: reason || null,
      startDate: dayjs(selectedDate[0]).format("YYYY-MM-DD"),
      endDate: dayjs(selectedDate[1]).format("YYYY-MM-DD"),
      approval: false,
    };

    if (!selectedEmployeeLeave._id) {
      console.error("No ID available for the leave.");
      return;
    }

    try {
      const response = await updateLeaveEmployee(
        selectedEmployeeLeave._id,
        leaveDetails
      );
      console.log("Employee Leave Updated successfully:", response);

      if (response.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Leave Submitted",
          text: "Leave has been submitted successfully!",
        }).then(() => {
          handleCancel(); // Close the modal or reset the form
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: response.message || "Failed to submit leave. Please try again.",
        });
      }
    } catch (error) {
      console.error("Failed to update Employee Leave:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update Employee Leave. Please try again.",
      });
    }
  };

  const selectedLeaveTypeName = selectedLeaveType
    ? selectedLeaveType.label
    : "";
  console.log("name:", selectedLeaveTypeName);
  return (
    <div>
      <div className="modal-content">
        <div className="page-wrapper-new p-0">
          <div className="content">
            <div className="modal-header border-0 custom-modal-header">
              <div className="page-title">
                <h4>Edit Leave</h4>
              </div>
            </div>
            <div className="modal-body custom-modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-blocks">
                      <label>Date Range</label>
                      <div className="input-groupicon calender-input">
                        <RangePicker
                          onChange={handleDateChange}
                          value={selectedDate.map((date) => dayjs(date))}
                          className="filterdatepicker"
                          format="YYYY-MM-DD"
                          placeholder={["Start Date", "End Date"]}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {dateRange.map((item, index) => (
                    <div key={index} className="col-lg-12">
                      <div className="apply-leave">
                        <div className="leave-apply">
                          <div className="leave-date">
                            <span>{item.key}</span>
                            <p>{dayjs(item.date).format("YYYY-MM-DD")}</p>
                          </div>
                          <div className="leave-time">
                            <div className="input-blocks mb-0">
                              <Select
                                className="select"
                                options={leaveDayOptions}
                                value={leaveDayOptions.find(
                                  (option) => option.value === item.dayValue
                                )}
                                onChange={(selectedOption) =>
                                  handleLeaveTypeChange(index, selectedOption)
                                }
                                placeholder="Select Leave Day"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label className="form-label">Select Leave Type</label>
                      <Select
                        className="select"
                        options={leaveType}
                        placeholder={
                          selectedLeaveTypeName
                            ? selectedLeaveTypeName
                            : "Select Leave Type"
                        }
                        onChange={setSelectedLeaveType}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="mb-3 summer-description-box mb-0">
                      <label className="form-label">Reason</label>
                      <input
                        type="text"
                        className="form-control"
                        value={reason}
                        onChange={(event) => setReason(event.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer-btn">
                  <button
                    type="button"
                    className="btn btn-cancel me-2"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button className="btn btn-submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EditLeaveEmployee.propTypes = {
  selectedEmployeeLeave: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default EditLeaveEmployee;
