import React, { useEffect, useState } from "react";
// import Select from "react-select";
// import ImageWithBasePath from "../../img/imagewithbasebath";
import PropTypes from "prop-types";
import { createSupplier, getSupplier, updateSupplier } from "../../../service/ApiServices";

const SupplierModal = ({onSupplierAdded, supplierId}) => {
  const [formData, setFormData] = useState({
    supplierName: "",
    supplierEmail: "",
    supplierPhone: "",
    supplierAddress: "",
    supplierCity: "",
    supplierCountry: "",
    supplierDescription: "",
    supplierImage: null,

  });

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setFormData({
          ...formData,
          customerImage: file, 
        });
      };
      reader.readAsDataURL(file);
    }
  };

  // console.log(CustomerModal)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const setSelectedSupplier = async (supplierId) => {
    try {
      const response = await getSupplier(supplierId);
      if (response.data) {
        const { supplierName, supplierEmail, supplierPhone, supplierAddress, supplierCity, supplierCountry, supplierDescription, supplierImage } = response.data.data.supplier;
        setFormData({
          ...formData,
          supplierName,
          supplierEmail,
          supplierPhone,
          supplierAddress,
          supplierCity,
          supplierCountry,
          supplierDescription,
          supplierImage,
        });  
      } else {
        console.error("Failed to fetch customer data");
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  useEffect(() => {
    if (supplierId) {
      setSelectedSupplier(supplierId);
    }
  }, [supplierId]);

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateSupplier(supplierId, formData); 
      onSupplierAdded();
      console.log("Supplier updated successfully:", response);
      setFormData({
        // Clear form data if needed
        supplierName: "",
        supplierEmail: "",
        supplierPhone: "",
        supplierAddress: "",
        supplierCity: "",
        supplierCountry: "",
        supplierDescription: "",
        supplierImage: null,
      });
    } catch (error) {
      console.error("Error updating supplier:", error);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await createSupplier(formData);
        onSupplierAdded();
        console.log("Supplier added successfully:", response.data);
      setFormData({
        supplierName: "",
        supplierEmail: "",
        supplierPhone: "",
        supplierAddress: "",
        supplierCity: "",
        supplierCountry: "",
        supplierDescription: "",
        supplierImage: null,
      });
    } catch (error) {
      console.error("Error adding supplier:", error);
    }
  };

  // const options3 = [{ value: "Varrel", label: "Varrel" }];

  // const options4 = [
  //   { value: "Germany", label: "Germany" },
  //   { value: "France", label: "France" },
  //   { value: "Mexico", label: "Mexico" },
  // ];
  return (
    <div>
      {/* Add Supplier */}
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Supplier</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="new-employee-field">
                          <span>Avatar</span>
                          {/* <div className="profile-pic-upload mb-2">
                            <div className="profile-pic">
                              <span>
                                <i
                                  data-feather="plus-circle"
                                  className="plus-down-add"
                                />{" "}
                                Profile Photo
                              </span>
                            </div>
                            <div className="input-blocks mb-0">
                              <div className="image-upload mb-0">
                                <input type="file" />
                                <div className="image-uploads">
                                  <h4>Change Image</h4>
                                </div>
                              </div>
                            </div>
                          </div> */}
                            <div className="profile-pic-upload">
                              <div className="profile-pic" style={{ backgroundImage: `url(${selectedImage})`, backgroundSize: '150%', backgroundPosition: 'center' }}>
                                  {!selectedImage && (
                                  <span>
                                    <i data-feather="plus-circle" className="plus-down-add" /> Add
                                    Image
                                  </span>
                                  )}
                              </div>
                            <div className="mb-3">
                              <div className="image-upload mb-0">
                                <input type="file" onChange={handleImageChange} />
                                <div className="image-uploads">
                                  <h4>Change Image</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-blocks">
                          <label>Supplier Name</label>
                          <input type="text"
                            className="form-control"
                            name="supplierName"
                            value={formData.supplierName}
                            onChange={handleChange} 
                            required
                            />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-blocks">
                          <label>Email</label>
                          <input type="text"
                            className="form-control"
                            name="supplierEmail"
                            value={formData.supplierEmail}
                            onChange={handleChange} 
                            required
                            />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-blocks">
                          <label>Phone</label>
                          <input name="supplierPhone"
                            type="text"
                            value={formData.customerPhone}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Address</label>
                          <input type="text"
                            className="form-control"
                            name="supplierAddress"
                            value={formData.supplierAddress}
                            onChange={handleChange} 
                            required
                            />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>City</label>
                          {/* <Select className="select" options={options1} /> */}
                          <input
                            type="text"
                            className="form-control"
                            name="supplierCity"
                            value={formData.supplierCity}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>Country</label>
                          {/* <Select className="select" options={options2} /> */}
                          <input
                            type="text"
                            className="form-control"
                            name="supplierCountry"
                            value={formData.supplierCountry}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-0 input-blocks">
                          <label className="form-label">Descriptions</label>
                          {/* <textarea
                            className="form-control mb-1"
                            defaultValue={""}
                          /> */}
                          <textarea
                            className="form-control mb-1"
                            name="supplierDescription"
                            value={formData.supplierDescription}
                            onChange={handleChange}
                            required
                          />
                          <p>Maximum 600 Characters</p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit"
                        className="btn btn-submit"
                        data-bs-dismiss="modal">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Supplier */}
      {/* Edit Supplier */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Supplier</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleUpdateSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="new-employee-field">
                          <span>Avatar</span>
                          <div className="profile-pic-upload edit-pic">
                            {/* <div className="profile-pic">
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/supplier/edit-supplier.jpg"
                                  alt
                                />
                              </span>
                              <div className="close-img">
                                <i data-feather="x" className="info-img" />
                              </div>
                            </div> */}
                            <div className="profile-pic" style={{ backgroundImage: `url(${selectedImage})`, backgroundSize: '150%', backgroundPosition: 'center' }}>
                              {!selectedImage && (
                                <span>
                                  <i data-feather="plus-circle" className="plus-down-add" /> Add
                                    Image
                                </span>
                              )}
                            </div>
                            {/* <div className="input-blocks mb-0">
                              <div className="image-upload mb-0">
                                <input type="file" />
                                <div className="image-uploads">
                                  <h4>Change Image</h4>
                                </div>
                              </div>
                            </div> */}
                            <div className="mb-3">
                              <div className="image-upload mb-0">
                                <input type="file" onChange={handleImageChange} />
                                <div className="image-uploads">
                                  <h4>Change Image</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-blocks">
                          <label>Supplier Name</label>
                          <input type="text"
                            className="form-control"
                            name="supplierName"
                            value={formData.supplierName}
                            onChange={handleChange}
                            required
                            />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-blocks">
                          <label>Email</label>
                          <input type="text"
                            className="form-control"
                            name="supplierEmail"
                            value={formData.supplierEmail}
                            onChange={handleChange}
                            required
                            />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-blocks">
                          <label>Phone</label>
                          <input name="supplierPhone"
                            type="text"
                            value={formData.supplierPhone}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Address</label>
                          <input type="text"
                            className="form-control"
                            name="supplierAddress"
                            value={formData.supplierAddress}
                            onChange={handleChange}
                            required
                            />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>City</label>
                          {/* <Select className="select" options={options3} /> */}
                          <input
                            type="text"
                            className="form-control"
                            name="supplierCity"
                            value={formData.supplierCity}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>Country</label>
                          {/* <Select className="select" options={options4} /> */}
                          <input
                            type="text"
                            className="form-control"
                            name="supplierCountry"
                            value={formData.supplierCountry}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="mb-0 input-blocks">
                        <label className="form-label">Descriptions</label>
                        {/* <textarea
                          className="form-control mb-1"
                          defaultValue={""}
                        /> */}
                        <textarea
                            className="form-control mb-1"
                            name="supplierDescription"
                            value={formData.supplierDescription}
                            onChange={handleChange}
                            required
                          />
                        <p>Maximum 600 Characters</p>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit"
                        className="btn btn-submit"
                        data-bs-dismiss="modal">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Supplier */}
    </div>
  );
};
SupplierModal.propTypes = {
  onSupplierAdded: PropTypes.func.isRequired,
  supplierId: PropTypes.string.isRequired,
};

export default SupplierModal;
