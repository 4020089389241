import React, { useEffect, useState } from "react";
// import Select from "react-select";
// import ImageWithBasePath from "../../img/imagewithbasebath";
import PropTypes from "prop-types";
import { createStore, getStore, updateStore } from "../../../service/ApiServices";

const StoreModal = ({onStoreAdded, storeId}) => {
  const [formData, setFormData] = useState({
    storeName: "",
    storeEmail: "",
    storePhone: "",
    storeAddress: "",
    storeCity: "",
    storeCountry: "",
    storeDescription: "",
    storeImage: null,

  });

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setFormData({
          ...formData,
          storeImage: file, 
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const setSelectedStore = async (storeId) => {
    try {
      const response = await getStore(storeId);
      if (response.data) {
        const { storeName, storeEmail, storePhone, storeAddress, storeCity, storeCountry, storeDescription, storeImage } = response.data.data.store;
        setFormData({
          ...formData,
          storeName,
          storeEmail,
          storePhone,
          storeAddress,
          storeCity,
          storeCountry,
          storeDescription,
          storeImage,
        });  
      } else {
        console.error("Failed to fetch store data");
      }
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };

  useEffect(() => {
    if (storeId) {
      setSelectedStore(storeId);
    }
  }, [storeId]);

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateStore(storeId, formData); 
      onStoreAdded();
      console.log("Store updated successfully:", response);
      setFormData({
        // Clear form data if needed
        storeName: "",
        storeEmail: "",
        storePhone: "",
        storeAddress: "",
        storeCity: "",
        storeCountry: "",
        storeDescription: "",
        storeImage: null,
      });
    } catch (error) {
      console.error("Error updating store:", error);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await createStore(formData);
        onStoreAdded();
        console.log("Store added successfully:", response.data);
      setFormData({
        storeName: "",
        storeEmail: "",
        storePhone: "",
        storeAddress: "",
        storeCity: "",
        storeCountry: "",
        storeDescription: "",
        storeImage: null,
      });
    } catch (error) {
      console.error("Error adding store:", error);
    }
  };

  return (
    <div>
      {/* Add Supplier */}
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Store</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="new-employee-field">
                          <span>Avatar</span>
                          {/* <div className="profile-pic-upload mb-2">
                            <div className="profile-pic">
                              <span>
                                <i
                                  data-feather="plus-circle"
                                  className="plus-down-add"
                                />{" "}
                                Profile Photo
                              </span>
                            </div>
                            <div className="input-blocks mb-0">
                              <div className="image-upload mb-0">
                                <input type="file" />
                                <div className="image-uploads">
                                  <h4>Change Image</h4>
                                </div>
                              </div>
                            </div>
                          </div> */}
                            <div className="profile-pic-upload">
                              <div className="profile-pic" style={{ backgroundImage: `url(${selectedImage})`, backgroundSize: '150%', backgroundPosition: 'center' }}>
                                  {!selectedImage && (
                                  <span>
                                    <i data-feather="plus-circle" className="plus-down-add" /> Add
                                    Image
                                  </span>
                                  )}
                              </div>
                            <div className="mb-3">
                              <div className="image-upload mb-0">
                                <input type="file" onChange={handleImageChange} />
                                <div className="image-uploads">
                                  <h4>Change Image</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-blocks">
                          <label>Store Name</label>
                          <input type="text"
                            className="form-control"
                            name="storeName"
                            value={formData.storeName}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-blocks">
                          <label>Email</label>
                          <input type="text"
                            className="form-control"
                            name="storeEmail"
                            value={formData.storeEmail}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-blocks">
                          <label>Phone</label>
                          <input name="storePhone"
                            type="text"
                            value={formData.storePhone}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Address</label>
                          <input type="text"
                            className="form-control"
                            name="storeAddress"
                            value={formData.storeAddress}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>City</label>
                          {/* <Select className="select" options={options1} /> */}
                          <input
                            type="text"
                            className="form-control"
                            name="storeCity"
                            value={formData.storeCity}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>Country</label>
                          {/* <Select className="select" options={options2} /> */}
                          <input
                            type="text"
                            className="form-control"
                            name="storeCountry"
                            value={formData.storeCountry}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-0 input-blocks">
                          <label className="form-label">Descriptions</label>
                          {/* <textarea
                            className="form-control mb-1"
                            defaultValue={""}
                          /> */}
                          <textarea
                            className="form-control mb-1"
                            name="storeDescription"
                            value={formData.storeDescription}
                            onChange={handleChange}
                          />
                          <p>Maximum 600 Characters</p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit"
                        className="btn btn-submit"
                        data-bs-dismiss="modal">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Supplier */}
      {/* Edit Supplier */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Store</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleUpdateSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="new-employee-field">
                          <span>Avatar</span>
                          <div className="profile-pic-upload edit-pic">
                            <div className="profile-pic" style={{ backgroundImage: `url(${selectedImage})`, backgroundSize: '150%', backgroundPosition: 'center' }}>
                              {!selectedImage && (
                                <span>
                                  <i data-feather="plus-circle" className="plus-down-add" /> Add
                                    Image
                                </span>
                              )}
                            </div>
                            <div className="mb-3">
                              <div className="image-upload mb-0">
                                <input type="file" onChange={handleImageChange} />
                                <div className="image-uploads">
                                  <h4>Change Image</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-blocks">
                          <label>Store Name</label>
                          <input type="text"
                            className="form-control"
                            name="storeName"
                            value={formData.storeName}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-blocks">
                          <label>Email</label>
                          <input type="text"
                            className="form-control"
                            name="storeEmail"
                            value={formData.storeEmail}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-blocks">
                          <label>Phone</label>
                          <input name="storePhone"
                            type="text"
                            value={formData.storePhone}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Address</label>
                          <input type="text"
                            className="form-control"
                            name="storeAddress"
                            value={formData.storeAddress}
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>City</label>
                          {/* <Select className="select" options={options3} /> */}
                          <input
                            type="text"
                            className="form-control"
                            name="storeCity"
                            value={formData.storeCity}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10 col-10">
                        <div className="input-blocks">
                          <label>Country</label>
                          {/* <Select className="select" options={options4} /> */}
                          <input
                            type="text"
                            className="form-control"
                            name="storeCountry"
                            value={formData.storeCountry}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="mb-0 input-blocks">
                        <label className="form-label">Descriptions</label>
                        {/* <textarea
                          className="form-control mb-1"
                          defaultValue={""}
                        /> */}
                        <textarea
                            className="form-control mb-1"
                            name="storeDescription"
                            value={formData.storeDescription}
                            onChange={handleChange}
                          />
                        <p>Maximum 600 Characters</p>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit"
                        className="btn btn-submit"
                        data-bs-dismiss="modal">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Supplier */}
    </div>
  );
};
StoreModal.propTypes = {
  onStoreAdded: PropTypes.func.isRequired,
  storeId: PropTypes.string.isRequired,
};

export default StoreModal;
