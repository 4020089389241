import React, { useState } from 'react'
import { editVariant } from '../../../service/ApiServices';
import PropTypes from 'prop-types';

const EditVarient = ({VariantId}) => {

//edit variant
const [name, setName] = useState('');
const [values, setValues] = useState('');
const [status, setStatus] = useState(true);



const handleVariantEdit = async (e) => {
    e.preventDefault();

    try {
        const updatedVariantData = {
            name: name,
            values: values,
            status: status
        };

        const updatedVariant = await editVariant(VariantId, updatedVariantData);
        console.log('Variant updated:', updatedVariant);
       
    } catch (error) {
        console.error('Error updating variant:', error.message);
       
    }
};



//edit variant








    return (
        <>
            {/* Edit Unit */}
            <div className="modal fade" id="edit-units">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Edit Attributes</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label className="form-label">Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-blocks">
                                            <label className="form-label">Variant</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={values}
                                                onChange={(e) => setValues(e.target.value)}
                                            />
                                            <span className="tag-text">
                                                Enter value separated by comma
                                            </span>
                                        </div>
                                        <div className="mb-0">
                                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                <span className="status-label">Status</span>
                                                <input
                                                    type="checkbox"
                                                    className="check"
                                                    checked={status}
                                                    onChange={(e) => setStatus(e.target.checked)}
                                                />
                                                <label htmlFor="user3" className="checktoggle" />
                                            </div>
                                        </div>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                data-bs-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" 
                                            className="btn btn-submit"
                                            data-bs-dismiss="modal"
                                            onClick={handleVariantEdit}
                                            >
                                                Save Changes
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Edit Unit */}
        </>

    )
}


EditVarient.propTypes = {
    VariantId: PropTypes.string.isRequired,
};

export default EditVarient
