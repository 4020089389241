import axios from "axios";
import Swal from "sweetalert2";

export const getAllActiveEmployees = async () => {
    try {
        const response = await axios.get("/employees-active");
        return response.data
    } catch (error) {
        console.log(error)
        showWarning('Failed to fetch allowance');
    }
}

const showWarning = (str) => {
    return Swal.fire({
        title: "Error",
        text: str,
        icon: "error",
    });
}


export const getSearchEmployees = async ({name,epf_number,center,branch,status,setLoading,startDate,endDate,departmentName,employee_type}) => {
    try {setLoading(true)
        const response = await axios.get(`/employees-search`, {
            params: {
                name:name,
                epf_number:epf_number,
                center:center,
                branch:branch,
                status,
                startDate,
                endDate,
                departmentName,
                employee_type
            }, headers: {
                'Cache-Control': 'no-cache'
            }
        });

        setLoading(false) 
        console.log(response)
        return response.data;
        
    } catch (error) {
        setLoading(false)
       console.log(error)
    }
};