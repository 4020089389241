import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../core/img/imagewithbasebath';
import { ChevronUp, Filter, RotateCcw, Sliders, Edit, Trash2} from 'feather-icons-react/build/IconComponents';
import { setToogleHeader } from '../../core/redux/action';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Box } from 'react-feather';
import { DatePicker } from 'antd';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Table from '../../core/pagination/datatable'
import { BASE_URL } from '../../environment';

const ExpiredProduct = () => {

    const dispatch = useDispatch();
    const data = useSelector((state) => state.toggle_header);
    // const dataSource = useSelector((state) => state.expiredproduct_data);

    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const toggleFilterVisibility = () => {
        setIsFilterVisible((prevVisibility) => !prevVisibility);
    };
    const [selectedDate, setSelectedDate] = useState(new Date());
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const oldandlatestvalue = [
        { value: 'date', label: 'Sort by Date' },
        { value: 'newest', label: 'Newest' },
        { value: 'oldest', label: 'Oldest' },
    ];

    const brands = [
        { value: 'chooseType', label: 'Choose Type' },
        { value: 'lenovo3rdGen', label: 'Lenovo 3rd Generation' },
        { value: 'nikeJordan', label: 'Nike Jordan' },
    ];

    const renderTooltip = (props) => (
        <Tooltip id="pdf-tooltip" {...props}>
            Pdf
        </Tooltip>
    );
    const renderExcelTooltip = (props) => (
        <Tooltip id="excel-tooltip" {...props}>
            Excel
        </Tooltip>
    );
    const renderPrinterTooltip = (props) => (
        <Tooltip id="printer-tooltip" {...props}>
            Printer
        </Tooltip>
    );
    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );
    const renderCollapseTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Collapse
        </Tooltip>
    );

    const columns = [
        {
            title: "Product",
            dataIndex: "product",
            // render: (text, record) => (
            //     <span className="productimgname">
            //         <Link to="#" className="product-img stock-img">
            //             <ImageWithBasePath alt="" src={record.img} />
            //         </Link>
            //         {text}
            //     </span>
            // ),
            sorter: (a, b) => a.product.length - b.product.length,
            width: "5%"
        },
        {
            title: "SKU",
            dataIndex: "sku",
            sorter: (a, b) => a.sku.length - b.sku.length,
        },
        {
            title: "Manufactured Date",
            dataIndex: "manufactureddate",
            sorter: (a, b) => a.manufactureddate.length - b.manufactureddate.length,
        },
        {
            title: "Expired Date",
            dataIndex: "expireddate",
            sorter: (a, b) => a.expireddate.length - b.expireddate.length,
        },
       
        {
            title: 'Actions',
            dataIndex: 'actions',
            // key: 'actions',
            // render: () => (
            //     <td className="action-table-data">
            //         <div className="edit-delete-action">
            //             <Link className="me-2 p-2" to="#">
            //                 <i data-feather="edit" className="feather-edit"></i>
            //             </Link>
            //             <Link className="confirm-text p-2" to="#"  >
            //                 <i data-feather="trash-2" className="feather-trash-2" onClick={showConfirmationAlert}></i>
            //             </Link>
            //         </div>
            //     </td>
            // )
        },
    ]

    const [products, setProductss] = useState([]);
    // const [productId, setProductId] = useState("");
    // const navigate = useNavigate();
  
    const [shouldReloadData, setShouldReloadData] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchInputChange = (event) => {
      setSearchQuery(event.target.value);
    };
  
    const filteredProducts = products.filter(product => {
      const searchLower = searchQuery.toLowerCase();
      return (
        product.productName.toLowerCase().includes(searchLower)
      );
  });
  
    useEffect(() => {
      fetchProducts();
    }, [shouldReloadData]);
  
    const fetchProducts = async () => {
      try {
        const response = await fetch(`http://${BASE_URL}:8000/api/exp/products`);
        if (!response.ok) {
          throw new Error("Failed to fetch products");
        }
        const data = await response.json();
        console.log("Fetched data:", data);
        setProductss(data.data.expiredProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
  
    const handleReloadData = () => {
      setShouldReloadData((prev) => !prev);
    };

    const MySwal = withReactContent(Swal);

    const showConfirmationAlert = (productId) => {
      MySwal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#00ff00",
        confirmButtonText: "Yes, delete it!",
        cancelButtonColor: "#ff0000",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteProduct(productId);
          MySwal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            className: "btn btn-success",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else {
          MySwal.close();
        }
      });
    };

    const deleteProduct = async (productId) => {
        try {
          const response = await fetch(`http://${BASE_URL}:8000/api/products/${productId}`, {
            method: "DELETE",
          });
          if (response.ok) {
            handleReloadData();
            console.log("product deleted successfully");
          } else {
            console.error("Failed to delete product");
          }
        } catch (error) {
          console.error("Error deleting product:", error);
        }
      };
    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Expired Products</h4>
                                <h6>Manage your expired products</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                    <Link>
                                        <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <ImageWithBasePath src="assets/img/icons/excel.svg" alt="img" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>

                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <i data-feather="printer" className="feather-printer" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>

                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>

                                    <Link
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        id="collapse-header"
                                        className={data ? "active" : ""}
                                        onClick={() => { dispatch(setToogleHeader(!data)) }}
                                    >
                                        <ChevronUp />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                        </ul>
                    </div>
                    {/* /product list */}
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                    <input 
                                            type="search" 
                                            className="form-control form-control-sm formsearch" 
                                            value={searchQuery}
                                            onChange={handleSearchInputChange}
                                        />
                                        <Link to className="btn btn-searchset">
                                            <i data-feather="search" className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="search-path">
                                    <div className="d-flex align-items-center">
                                        <Link className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`} id="filter_search">
                                            <Filter
                                                className="filter-icon"
                                                onClick={toggleFilterVisibility}
                                            />
                                            <span onClick={toggleFilterVisibility}>
                                                <ImageWithBasePath src="assets/img/icons/closes.svg" alt="img" />
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="form-sort">
                                    <Sliders className="info-img" />
                                    <Select
                                        className="select"
                                        options={oldandlatestvalue}
                                        placeholder="Newest"
                                    />
                                </div>
                            </div>
                            {/* /Filter */}
                            <div
                                className={`card${isFilterVisible ? " visible" : ""}`}
                                id="filter_inputs"
                                style={{ display: isFilterVisible ? "block" : "none" }}
                            >
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <Box className="info-img" />

                                                <Select options={brands} className="select" placeholder="Choose Type" />

                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <div className="input-groupicon">
                                                    <DatePicker
                                                        selected={selectedDate}
                                                        onChange={handleDateChange}
                                                        type="date"
                                                        className="filterdatepicker"
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholder='Choose Date'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                                            <div className="input-blocks">
                                                <Link className="btn btn-filters ms-auto">
                                                    {" "}
                                                    <i data-feather="search" className="feather-search" />{" "}
                                                    Search{" "}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Filter */}
                            <div className="table-responsive">
                            {/* <Table columns={columns} dataSource={dataSource} /> */}
                            <Table
                                className="table datanew"
                                columns={columns}
                                dataSource={filteredProducts.map((product) => ({
                                key: product._id,
                                product: (
                                    <span className="productimgname">
                                    <Link to="#" className="product-img stock-img">
                                        <ImageWithBasePath alt="" src={product.productImages[0]} />
                                    </Link>
                                    <Link to="#">{product.productName}</Link>
                                    </span>
                                ),
                                sku: product.productSKU,
                                manufactureddate: product.productManufacturedDate,
                                expireddate: product.productExpiryDate,
                                actions: (
                                    <td className="action-table-data">
                                    <div className="edit-delete-action">
                                      <div className="input-block add-lists"></div>
                                      <Link
                                        
                                        className="me-2 p-2"
                                        to="/edit-product" state={{ productId: `${product._id}` }}
                                      >
                                        <Edit className="feather-edit" />
                                      </Link>
                
                                      <Link
                                        className="confirm-text p-2"
                                        to="#"
                                        onClick={() => showConfirmationAlert(product._id)}
                                      >
                                        <Trash2 className="feather-trash-2" />
                                      </Link>
                                    </div>
                                  </td>
                                        ),
                                        }))}
                                        pagination={false}
                                    />

                            </div>
                        </div>
                    </div>
                    {/* /product list */}
                </div>
            </div>

        </div>
    )
}

export default ExpiredProduct
