
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PlusCircle,  Sliders } from 'feather-icons-react/build/IconComponents';
//import Select from 'react-select';
//import { Tooltip } from 'antd';
import{Table,Tag,}  from 'antd'
import AddShift from '../../core/modals/hrm/addshift.jsx';
import EditShift from '../../core/modals/hrm/editshift.jsx';
import withReactContent from 'sweetalert2-react-content';
import { API_BASE_URL } from "../../../src/environment";
import { getAllShiftsNew,deleteShift } from '../../service/shiftService.js';
import Swal from 'sweetalert2';
const Shift = () => {
    const [shiftData, setShifts] = useState([]);
    const [selectedShiftId,setSelectedShiftId] = useState(null);
    //const [shouldReloadData, setShouldReloadData] = useState(false);
    const [search, setSearch] = useState('');
    const [loading,setLoading]=useState(false)
    

    const fetchShifts = async () => {
        setLoading(true);  
        try {
          const response = await getAllShiftsNew();  
          setShifts(response); 
         // console.log(response.data,"Response DATATA") 
         setSelectedShiftId(null)
          setLoading(false)
        } catch (error) {
          console.error("Error fetching shifts:", error.message);
        }
      };

      useEffect(() => {
        fetchShifts(); 

      }, []); 

      const handleDelete = async (shiftId) => {
        try {
         await deleteShift(shiftId);
         fetchShifts();

        } catch (error) {
            console.error('Error deleting shift:', error.response ? error.response.data : error.message);
            alert('Error deleting shift!');
        }
    };

    const storedUserData = localStorage.getItem("user");
    const userData = JSON.parse(storedUserData);
    const id = userData?.data?.uid; //
    const [userRightssub, setUserRightssub] = useState([]);
    useEffect(() => {
      const fetchUserRightssub = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/userright/${id}`);
          if (!response.ok) {
            throw new Error("Failed to fetch user rights");
          }
          const data = await response.json();
          console.log("Fetched User Sub Data:", data.data);
          setUserRightssub(data.data || []); // Ensure it's always an array
        } catch (error) {
          console.error("Error fetching data:", error);
          setUserRightssub([]); // Set to empty array in case of error
        }
      };
    
      fetchUserRightssub(); // Fetch the data on component mount
    }, [id]);
    const hasJobRightWithAdd = userRightssub.some(right => right.right === 'Shifts' && right.adds === 1);
 
    // const handleReloadData = () => {
    //     setShouldReloadData(!shouldReloadData);
    // };

    const columns = [
        {
            title: "Shift Name",
            dataIndex: "shiftName",
            key:"shiftName"
        },
        {
           // title: "Late Grace Period (minutes)",
           title: (
            <>
                Late Grace Period <span style={{ fontSize: "12px", color: "gray", textAlign:"center" }}>(minutes)</span>
            </>
        ),
            dataIndex: "lateGracePeriod",
            
        },
            {
              title:  <div style={{ textAlign: 'center' }}>Week Days Definition</div>,
              dataIndex: 'weekdaysDefinition', 
              key: 'weekdaysDefinition',
              render: (weekdaysDefinition) => (
                <div >
                <Table
                  dataSource={weekdaysDefinition}
                  columns={[
                    {
                      title: <Tag color="orange" style={{ color: 'black' }}>Day</Tag>,
                      dataIndex: ['day'], 
                      key: 'day',
                      align: 'left',
                    },
                    {
                      title: <Tag color="orange" style={{ color: 'black' }}>Checked</Tag>,
                      dataIndex: 'checked',
                      key: 'checked',
                      align: 'left',
                      render: (checked) => checked ==true ?
                      <span className="badge bg-success">Yes</span> : <span className="badge bg-primary">No</span>
                    },
                    {
                        title: <Tag color="orange" style={{ color: 'black' }}>From</Tag>,
                        dataIndex: 'from',
                        key: 'from',
                        align: 'from',
                      },
                      {
                        title: <Tag color="orange" style={{ color: 'black' }}>To</Tag>,
                        dataIndex: 'to',
                        key: 'to',
                        align: 'left',
                      },
                  ]}
                  pagination={false} 
                  rowKey="_id" 
                
                />
                </div>
              ),
            },
         {
            title: "Status",
            dataIndex: "status",
            render: (status) => (
                <span className={`badge ${status ? 'badge-linesuccess' : 'badge-linedanger'}`} style={{ width:80, padding:6}}>
                    <Link to="#" style={{ cursor: 'default', fontSize:14}}>{status ? 'Active' : 'Inactive'}</Link>
                </span>
            ),
        },
        {
            title: "Created On",
            dataIndex: "createdAt",
            render: (text) => {
                const date = new Date(text);
                
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
                const day = date.getDate().toString().padStart(2, '0');  
                
                return `${year}-${month}-${day}`;
              }
        },
       
        {
            title: "Actions",
            dataIndex: "actions",
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_,record) => (
                <td className="action-table-data">
                   

                        <Link
                            className="me-2 p-2"
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#edit-units"
                           onClick={() => setSelectedShiftId(record._id)}
                          
                        >
                            <i data-feather="edit" className="feather-edit"></i>
                        </Link>
                    
                    
                        <Link className="confirm-text p-2" to="#">
                        <i
                            data-feather="trash-2"
                            className="feather-trash-2"
                            onClick={()=>showConfirmationAlert(record._id)}
                        ></i>
                      </Link>
                    
                          
                    

                        
                        
                    
                </td>
            ),
        },
    ];
    const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (shiftId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(shiftId);
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

    const filteredData = shiftData.filter((item) => {
      const name = item.shiftName ? item.shiftName.toLowerCase() : ''; // Fallback to an empty string
      return name.includes(search.toLowerCase());
    });

    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Shift</h4>
                                <h6>Manage your employees shift</h6>
                            </div>
                        </div>
                      
                        <div className="page-btn">
                        {!hasJobRightWithAdd && (
                               <Link
                               to="#"
                               className="btn btn-added"
                               data-bs-toggle="modal"
                               data-bs-target="#add-units"
                                 >
                               <PlusCircle className="me-2" />
                               Add New Shift
                                 </Link>
                             )} 
                           
                        </div>
                        
                    </div>
                    {/* /product list */}
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                            <div className="search-set">
                            <div className="search-input">
                             
                             {/*                  search              */}
                              <input
                                // type="text"
                                type="search"
                                placeholder="Search"
                                className="form-control form-control-sm formsearch"
                                value={search}
                                //onChange={handleSearchChange}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                              
                              <Link to className="btn btn-searchset">
                                <i data-feather="search" className="feather-search" />
                              </Link>
                            </div>
                            <div className='wordset'>
                            {/* <ul>
                                        <li>
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="pdf">
                                               <img src="assets/img/icons/pdf.svg" alt="pdf" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="excel">
                                               <img src="assets/img/icons/excel.svg" alt="excel" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="print">
                                                <img src="assets/img/icons/printer.svg" alt="print" />
                                            </a>
                                        </li>
                                    </ul> */}
                            </div>
                          </div>
                               
                          <div className="search-set">
                                <a href="#" className="btn btn-filter" id="filter_search">
                                    <Sliders className="me-2" />Filter
                                </a>
                            </div>
                            </div>
                        
                            <div className="table-responsive">
                            <Table loading={loading} columns={columns} dataSource={filteredData} rowKey="value" pagination={false} />
                            </div>
                        </div>
                    </div>
                    {/* /product list */}
                </div>
            </div>
            <AddShift onShiftAdded={fetchShifts}/>
            <EditShift onShiftAdded={fetchShifts} shiftId={selectedShiftId}/>
        </div>
    )
}

export default Shift
