import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const UserRights = () => {
  const [userData, setUserData] = useState([]);
  const [userRights, setUserRights] = useState([]);
  useEffect(() => {
    // Fetch user data from your backend API
    const fetchUserData = async () => {
      try {
        const response = await fetch("http://localhost:8000/api/user/allusers", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        console.log('user data:', data);
        if (Array.isArray(data.data)) {
          const transformedData = data.data.map((user) => ({
            id: user._id,
            uid: user.uid,
            name: user.name,
            email: user.email,
            role: user.role ? user.role.name : "No data",
            phone: user.phone || "No data",
            createdon: user.createdon || "No data",
            status: user.status || "No data",
          }));
          setUserData(transformedData);
        } else {
          console.error("Data is not an array");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    // Fetch user rights data
    const fetchUserRights = async () => {
      try {
        const response = await fetch("http://localhost:8000/api/userright", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        console.log('user rights data:', data);
        if (Array.isArray(data.data)) {
          setUserRights(data.data);
        } else {
          console.error("Data is not an array");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchUserData();
    fetchUserRights();
  }, []);
  const isAddRightDisabled = (uid) => {
   return userRights.some(right => right.userid=== uid);
    
  };
   return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>User Access Rights</h4>
            <h6>The level of permissions granted to a user account within a system</h6>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Actions</th>
                    <th>Additional Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.map((user) => (
                    <tr key={user.id}>
                      <td>
                        <Link to={`/edit-user/${user.uid}`}>{user.name}</Link>
                      </td>
                      <td>{user.phone}</td>
                      <td>{user.email}</td>
                      <td>
                        <span
                          className={`badge ${
                            user.status === "Active"
                              ? "badge-success"
                              : "badge-danger"
                          }`}
                        >
                          {user.status}
                        </span>
                      </td>
                      <td>
                        <Link to={`/userrightlist/${user.uid}`} className="btn btn-edit">
                          Edit Right
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/adduserRight/${user.uid}`}
                          className={`btn btn-add ${isAddRightDisabled(user.uid) ? "disabled" : ""}`}
                          style={{ pointerEvents: isAddRightDisabled(user.uid) ? "none" : "auto" }}
                        >
                          Add Right
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRights;
