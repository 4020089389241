import axios from 'axios';
import Swal from "sweetalert2";
const showWarning = (str) => {
    return Swal.fire({
        title: "Error",
        text: str,
        icon: "error",
    });
}

export const getEmployeeRecordsByDate = async (employeeId, date) => {
    try {
        const response = await axios.get(`/adminAtendances/employee-date`, {
            params: {
              epf_number: employeeId,
                date: date,
            }, headers: {
              'Cache-Control': 'no-cache', // Prevents caching
              'Pragma': 'no-cache',
              'Expires': '0',
          },
        });

        console.log('Employee Records:', response.data);
        return response.data; // Return the fetched data
    } catch (error) {
        console.error('Error fetching employee records:', error);
        throw error; // Optionally rethrow the error for further handling
    }
};

export const updateAttendanceTime = async (attendanceId, recordTime,setLoading) => {
    try {
        setLoading(true)
      const response = await axios.post('/adminAtendances/updateTime', { 
        id: attendanceId,
        time: recordTime
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
        if (response.data) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Time Updated Successfully",
            })
        }else{
            showWarning("Error updating attendance time")
        }
      
      setLoading(false)
      return response.data; // Return the updated record if needed
    } catch (error) {
      console.error('Error updating attendance:', error.response ? error.response.data : error.message);
      throw error; // Re-throw error to handle it further if needed
    }
  };



  export const getLateRecords = async ({name,epf_number,startDate,endDate,center,branch}) => {
    try {
      const response = await axios.get('/late-attendance', {
          params: {
              dateFrom:startDate,
              dateTo:endDate,
              search:name,
              branch:branch,
              center:center,
              epf_number:epf_number
          }
      });
     
      return response.data;
      
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};


export const getOtWorkedData = async ({name,epf_number,startDate,endDate,center,branch}) => {
  try {
    const response = await axios.get('/ot-report-data', {
        params: {
            dateFrom:startDate,
            dateTo:endDate,
            search:name,
            branch:branch,
            center:center,
            epf_number:epf_number
        }
    });
   
    return response.data;
    
} catch (error) {
    console.error('Error fetching data:', error);
}
};

export const getLeaveEmployees = async ({name,epf_number,startDate,endDate,center,branch}) => {
  try {
    const response = await axios.get('/leave-report-data', {
        params: {
            dateFrom:startDate,
            dateTo:endDate,
            search:name,
            branch:branch,
            center:center,
            epf_number:epf_number
        }
    });
   
    return response.data;
    
} catch (error) {
    console.error('Error fetching data:', error);
}
};