import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
//import { Sliders } from "react-feather";
import { Select, Table } from "antd";
//import Swal from "sweetalert2"; 

import { getLateRecords } from "../../service/adminAttendanceService";
import okilogo from "../../assets/OKI-logo.png"
import jsPDF from 'jspdf';
import dayjs from "dayjs";
import ExcelJS from 'exceljs';
import { DatePicker, } from 'antd';

const { RangePicker } = DatePicker;

const Report = () => {
  const startOfMonth = dayjs().startOf("month");
  const today = dayjs();
  const [dataSource,setDataSource] = useState([]);
  
  const [epf_number, setEpf] = useState("")
  const [name, setName] = useState("")
  const [branch, setBranch] = useState(null)
  const [center, setCenter] = useState(null)
  const [startDate,setStartDate]=useState(startOfMonth.format('YYYY-MM-DD'))
  const [endDate,setEndDate]=useState(today.format('YYYY-MM-DD'))

  const [loading,setLoading]=useState(false)
  const branchOptions=[
    {value: "kaduwela", label: "Kaduwela"},
    {value: "wattala", label: "Wattala"},
    {value: "negombo", label: "Negombo"},
    {value: "kiribathgoda", label: "Kiribathgoda"},
    {value: "kandana", label: "Kandana"}]
  
    const centerOptions = [
      { value: "OKI", label: "OKI" },
      { value: "OKIDS", label: "OKIDS" },
      { value: "OKIT", label: "OKIT" }
    ]
  const onChange = (dates, dateStrings) => {
    setStartDate(dateStrings[0])
    setEndDate(dateStrings[1])
    console.log(startDate,endDate)
  };



  const lateRecords = async () => {
    try {setLoading(true)
      const response = await getLateRecords({
        epf_number,
        name,
        center,
        branch,
        startDate,
        endDate
        });
        setDataSource(response.data)
        setLoading(false)
      console.log(response.data,"resopons")
    } catch (error) {
      console.error("Error fetching leaves:", error);
    }
  };
  useEffect(() => {
   lateRecords()
   
  }, []);


  const handlePdfExport = async () => {

    // const startdate = startDate.format('YYYY-MM-DD');
    // const enddate = endDate.format('YYYY-MM-DD');
    const img = okilogo

    // Filter out unwanted columns

    const doc = new jsPDF();
    doc.setTextColor(0, 0, 0); // Blue color
    doc.setFontSize(16);
    doc.text('OKI International', 15, 13);


    // Larger font size

    doc.setFontSize(14);
    doc.setTextColor(138, 132, 125);
    doc.text(`Late Attendance Report`, 15, 23);
    // doc.text(`Date : ${startDate} - ${endDate}`, 15, 30);
    doc.addImage(img, 'PNG', 170, 8, 22, 25);
    doc.autoTable({
        head: [['Emp Name', 'EPF No', 'Branch','Center','Total Late Time (HH:MM)']],

        body: dataSource.map(({employee,totalLatetime}) => [
            [`${employee.first_name} ${employee.last_name}`],
            [employee.employee_id],
            [employee.branch],
            [employee.center],
            [totalLatetime],
           

        ]),
        startY: 35,
        theme: 'striped',
        headStyles: {
            fillColor: [138, 132, 125],
        }
    });


        doc.save('late_attendance_report.pdf');
   
   
};



  ///Excel export


  const handleExcelExport = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Late Attendance Report');

    // Define cell styles with borders
    const borderStyle = {
        top: {style: 'thin'},
        left: {style: 'thin'},
        bottom: {style: 'thin'},
        right: {style: 'thin'}
    };

    // Define columns and add header
    worksheet.columns = [
        {header: 'Emp Name', key: 'empName', width: 25},
        {header: 'EPF No', key: 'empId', width: 15},
        {header: 'Branch', key: 'branch', width: 15},
        {header: 'Center', key: 'center', width: 15},
        {header: 'Late Time (HH:MM)', key: 'lateTime', width: 20},
       
    ];

    // Add data rows
    dataSource.forEach(({employee,totalLatetime}) => {
        worksheet.addRow({
            empName: `${employee.first_name} ${employee.last_name}`,
            empId: employee.epf_number,
            branch:employee.branch,
            center:employee.center,
            lateTime: totalLatetime,
            
        });
    });

    // Apply border styles to all cells
    worksheet.eachRow({includeEmpty: true}, (row) => {
        row.eachCell({includeEmpty: true}, (cell) => {
            cell.border = borderStyle;
        });
    });

    // Save the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'late_attendance_report.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
    });
};

  const columns = [
    {
      title: "Emp Name",
      dataIndex: "employee",
      render: (employee) => `${employee?.first_name || ""} ${employee?.last_name || ""}`.trim() || "-",
    },
    {
      title: "EPF No",
      dataIndex: "employee",
      render: (employee) => employee?.epf_number || "-",
    },
    {
      title: "Branch",
      dataIndex: "employee",
      render: (employee) => employee?.branch || "-",
    },
    {
      title: "Center",
      dataIndex: "employee",
      render: (employee) => employee?.center || "-",
    },
 
    {
      title: "Total Late Time (HH:MM)",
      dataIndex: "totalLatetime",
    },
    
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Late Attendance</h4>
                {/* <h6>Manage employees </h6> */}
              </div>
            </div>
            <div className="wordset">
                    <ul>
                      <li>
                        <a  title="pdf"
                        onClick={handlePdfExport}
                        >
                          <img src="assets/img/icons/pdf.svg" alt="pdf" />
                        </a>
                      </li>
                      <li>
                        <a  title="excel"
                        onClick={handleExcelExport}
                        >
                          <img src="assets/img/icons/excel.svg" alt="excel" />
                        </a>
                      </li>
                      {/* <li>
                        <a href="#" title="print">
                          <img src="assets/img/icons/printer.svg" alt="print" />
                        </a>
                      </li> */}
                    </ul>
                  </div>
            {/* <div className="page-btn">
              <Link to="/allocate-leaves" className="btn btn-added">
                Allocated Leaves
              </Link>
            </div>
            <div className="page-btn">
              <Link to="/leave-types" className="btn btn-added">
                Leave types
              </Link>
            </div> */}
            
          </div>
          <div className="card table-list-card">
            <div className="card-body" style={{ maxWidth: "100%", marginRight: "20px" }}>
              <div className="container mt-3 " style={{ maxWidth: "100%", marginLeft: "20px", marginRight: "40px" }}> {/* Added p-0 and maxWidth */}
                <div className="row d-flex flex-wrap align-items-start justify-content-between"> {/* Added justify-content-start */}
                  <div className="col-12 col-md-3  text-start">
                    <label className="form-label">Employee Name</label>
                    <input
                      type='text'
                      value={name}
                      className="form-control"
                      onChange={(e) => {
                          setName(e.target.value);
                      }}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-12 col-md-2 mb-3 text-start">
                    <label className="form-label">EPF No</label>
                    <input
                      className="form-control"
                      type='text'
                      value={epf_number}
                      onChange={(e) => setEpf(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </div>

                  <div className="col-12 col-md-2 mb-3 text-start">
                    <label className="form-label">Branch</label>
                  
                    <Select
                      value={branch}
                      options={branchOptions}
                      className="select"
                      placeholder="Select Branch"
                       onChange={setBranch}
                      allowClear
                      style={{ width: "100%", height: "38px" }}
                    />

                  </div>
                  <div className="col-12 col-md-2 mb-3 text-start">
                    <label className="form-label">Center</label>

                    <Select
                      value={center}
                      options={centerOptions}
                      className="select"
                      placeholder="Select Center"
                       onChange={setCenter}
                      allowClear
                      style={{ width: "100%", height: "38px" }}
                    />

                  </div>
                  <div className="col-12 col-md-3 mb-3 text-start">
                    <label className="form-label">Date</label>

                      <RangePicker
                        format="YYYY-MM-DD"
                        onChange={onChange}
                        //disabledDate={disabledDate}
                        defaultValue={[startOfMonth, today]}
                        style={{ width: "100%" }}
                      />


                  </div>



                </div>
              </div>

              <div className="container mt-0" style={{ maxWidth: "100%", marginLeft: "20px", marginRight: "40px" }}>
                <div className="row d-flex align-items-center justify-content-start">

                  
                  <div className="col-12 col-md-2 mb-3 text-start">

                    <button
                      className="btn btn-submit"
                      onClick={lateRecords}
                      style={{ height: "38px", width: "55%" }}
                    >
                      Submit
                    </button>

                  </div>

                </div>
              </div>



                    </div>
                </div>
          <div className="card table-list-card">
            <div className="card-body pb-0">
              <div className="table-top">
                <div className="input-blocks search-set mb-0">
                 
                 
                </div>
                
              </div>
            </div>
            <div className="table-responsive">
              <Table
                loading={loading}
                columns={columns}
                dataSource={dataSource} 
                pagination={{ pageSize: 10 }} 
                rowKey={(record) => record.id} 
                className="custom-ant-table" 
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report; 
