import React, { useEffect, useState } from "react";
import { PlusCircle, Edit, Trash } from "react-feather";
import { Link } from "react-router-dom"; 

const Accsessroal = () => {
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    const fetchUserRoles = async () => {
      try {
        const response = await fetch("http://localhost:8000/api/accsessrights/all", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        if (Array.isArray(data.data)) {
          setUserData(data.data);
        } else {
          console.error("Data is not an array");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchUserRoles();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this role?")) {
      try {
        const response = await fetch(`http://localhost:8000/api/accsessrights/delete/${id}`, {
          method: "DELETE",
        });

        if (response.ok) {
          setUserData(userData.filter((user) => user._id !== id));
        } else {
          console.error("Failed to delete role");
        }
      } catch (error) {
        console.error("Error deleting role:", error);
      }
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Create New Access Roles</h4>
              <h6>Manage your access roles</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/addaccsesroals" className="btn btn-added">
              <PlusCircle className="me-2" />
              Add New Role
            </Link>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Role ID</th>
                    <th>Role Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.length > 0 ? (
                    userData.map((user) => (
                      <tr key={user._id}>
                        <td>{user.rid}</td>
                        <td>{user.right}</td>

                        <td>

                          <Link
                            to={`/editaccsesroals/${user._id}`}
                            className="btn btn-sm btn-primary me-2"
                          >
                            <Edit className="feather" /> Edit
                          </Link>

                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => handleDelete(user._id)} 
                          >
                            <Trash className="feather" /> Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center">No access roles available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accsessroal;
