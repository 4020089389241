import axios from "axios";
import {BASE_URL} from "../environment";

//create new tax
export const createTax = async (tax) => {
    try {
        const response = await axios.post(`http://${BASE_URL}:8000/api/taxes`, tax);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message || 'Failed to create tax');
    }
};

//Get tax detail using ID
export const getTaxById = async (taxId) => {
    const response = await fetch(`http://${BASE_URL}:8000/api/taxes/${taxId}`);
    if (!response.ok) {
        throw new Error('Failed to fetch tax details');
    }
    return response.json();
};

//Update tax detail using ID
export const editTax = async (taxId, taxData) => {
    try {
        const response = await axios.put(`http://${BASE_URL}:8000/api/taxes/${taxId}`, taxData);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Failed to edit tax');
    }
};
  