import React, { createContext, useState, useEffect } from 'react';
import { getUserById } from "../service/userService";
import PropTypes from 'prop-types';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    
    const storedUserData = localStorage.getItem("user");
    const userData = JSON.parse(storedUserData);
    const userObj = userData?.data;
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
    });

    useEffect(() => {
        console.log("Stored Data",storedUserData)
        if(storedUserData !== null){

            const fetchUserData = async () => {
                try {
                    const data = await getUserById(userObj._id);
                    const fullName = data.name.split(' '); // Split the name by space
                    const firstName = fullName[0];
                    const lastName = fullName.slice(1).join(' ');
                    setUser({
                        firstName: firstName,
                        lastName: lastName

                    });
                } catch (err) {
                    console.log(err);
                }
            };
            fetchUserData();
        }



    }, []);

    //   console.log(user,"User Context Console")

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};
UserProvider.propTypes = {
    children: PropTypes.node.isRequired,
};