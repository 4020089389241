import React, { useEffect, useState } from "react";
import Select from "react-select";
import ImageWithBasePath from "../../img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
const baseURL = "http://localhost:8000";
import { Edit, Trash2 } from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { createStockTransfer, getProduct, getStockTransfer, updateProduct, updateStockTransfer } from "../../../service/ApiServices";
import { BASE_URL } from "../../../environment";

const StockTransferModal = ({onStockTransferAdded, stockTransferId }) => {

  const optionsStore1Store2 = [
    { value: "choose", label: "Choose" },
    { value: "store1", label: "Store 1" },
    { value: "store2", label: "Store 2" },
  ];

  const optionsSentPending = [
    { value: "choose", label: "Choose" },
    { value: "sent", label: "Sent" },
    { value: "pending", label: "Pending" },
  ];

  const [SKU, setSKU] = useState([]);
  const [categoryName, setCategoryName] = useState([]);
  const [productQuantity, setProductQuantity] = useState([]);
  const [wareHouses, setWareHouses] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  // handle Product
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  // const [reloadProducts, setReloadProducts] = useState(false);
  const [productFormData, setProductFormData] = useState([]);

  const initialFormData = {
    fromWareHouse: "",
    toWareHouse: "",
    product: [],
    stockTransferResponsiblePerson: "",
    stockNorts: "",
    stockTransferRefernceNo:"",
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    fetchProduct();
  }, []);

  const fetchProduct = async () => {
    try {
      const response = await fetch("http://localhost:8000/api/products");
      if (!response.ok) {
        throw new Error("Failed to fetch ware Houses");
      }
      const data = await response.json();
      const options = data.data.products.map((product) => ({
        label: product.productName,
        value: product._id,
      }));
      
      setProducts(options);
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  // const handleSelectProduct = (product) => {
  //   setSearchTerm(product.label);
  //   // getProductById(product.value);
  //   setFilteredProducts([]);
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     product: product,
  //   }));
  // };
  const handleSelectProduct = async (product) => {
    setSearchTerm(product.label);
    setFilteredProducts([]);
  
    // Update form data with the new product
    setFormData((prevData) => ({
      ...prevData,
      product: [...prevData.product, product],
    }));
      const productDetails = await getProductById(product.value);
      if (productDetails.data) {
        const product = productDetails.data.data.product;
        setSKU(prevState => [...prevState, product.productSKU]);
        setCategoryName(prevState => [...prevState, product.category.category]);
        setProductQuantity(prevState => [...prevState, product.productQuantity]);
        const firstImage = product.productImages.length > 0 ? `${baseURL}${product.productImages[0]}` : null;
        setSelectedImages(prevState => [...prevState, firstImage]);
      } else {
        console.error("Failed to fetch product data");
      }

  
    // Update SKU, categoryName, productQuantity, and selectedImages
    // setSKU((prevSKU) => [...prevSKU, product.productSKU]);
    // setCategoryName((prevCategoryName) => [...prevCategoryName, product.category]);
    // setProductQuantity((prevQuantity) => [...prevQuantity, product.productQuantity]);
    // setSelectedImages((prevImages) => [...prevImages, product.productImages[0]]);
  };

  
 const handleSearch = (event) => {
  const value = event.target.value;
  setSearchTerm(value);
    if (value) {
      const filtered = products.filter((product) =>
        product.label.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  };

  const removeProduct = (index, e) => {
    e.preventDefault();
    setFormData((prevData) => {
      const updatedProducts = [...prevData.product];
      updatedProducts.splice(index, 1);
      return { ...prevData, product: updatedProducts };
    });
  
    setSKU((prevSKU) => {
      const updatedSKU = [...prevSKU];
      updatedSKU.splice(index, 1);
      return updatedSKU;
    });
  
    setCategoryName((prevCategoryName) => {
      const updatedCategory = [...prevCategoryName];
      updatedCategory.splice(index, 1);
      return updatedCategory;
    });
  
    setProductQuantity((prevQuantity) => {
      const updatedQuantity = [...prevQuantity];
      updatedQuantity.splice(index, 1);
      return updatedQuantity;
    });
  
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };


  // const getProductById = async (productId) => {
  //   try {
  //     const response = await getProduct(productId);
  //     if (response.data) {
  //       const product = response.data.data.product;
  //       setProductFormData(product)
  //       setSKU(product.productSKU); 
  //       setCategoryName(product.category.category);
  //       setProductQuantity(product.productQuantity);
  //       const productImages = product.productImages.map(image => ({
  //         url: `${baseURL}${image}`, 
  //         file: null 
  //       }));
                
  //       setSelectedImages(productImages.map(image => image.url));

  //     } else {
  //       console.error("Failed to fetch stock data");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching stockAdjustments:", error);
  //   }
  // };

  // handle wareHouse
  useEffect(() => {
    fetchWareHouses();
  }, []);

  const fetchWareHouses = async () => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/wareHouses`);
      if (!response.ok) {
        throw new Error("Failed to fetch ware Houses");
      }
      const data = await response.json();
      const options = data.data.wareHouses.map((wareHouse) => ({
        label: wareHouse.wareHouseName,
        value: wareHouse._id,
      }));
      
      setWareHouses(options);
    } catch (error) {
      console.error("Error fetching WareHouse:", error);
    }
  };

  const options1 = [
    { value: "choose", label: "Choose" },
    { value: "stevenUassets/img/users/user-08.jpg", label: "Steven" },
    { value: "gravelyUassets/img/users/user-09.jpg", label: "Gravely" },
  ];



  const setSelectedStockAdjustment = async (stockTransferId) => {
    try {
      console.log(stockTransferId)
      const response = await getStockTransfer(stockTransferId);
      
      if (response.data) {
        const stockTransfer = response.data.data.stockTransfer;
        console.log(stockTransfer)
        const fromwareHouseOption = wareHouses.find(w => w.value === stockTransfer.fromWareHouse._id);
        const towareHouseOption = wareHouses.find(w => w.value === stockTransfer.toWareHouse._id);
        // const productOption = products.find(c => c.value === stockTransfer.product[0]._id);
              // Map the products from stockTransfer to the corresponding product options
      const productOptions = stockTransfer.product.map(product => {
        return products.find(c => c.value === product._id);
      }).filter(Boolean); // Filter out any undefined values
        const contackPersonOpption = options1.find(u => u.value === stockTransfer.stockTransferResponsiblePerson);
        // getProductById(stockTransfer.product[0]._id)

        
        setFormData({
          ...formData,
          fromWareHouse: fromwareHouseOption,
          toWareHouse: towareHouseOption,
          product: productOptions,
          stockTransferResponsiblePerson: contackPersonOpption,
          stockTransferRefernceNo: stockTransfer.stockTransferRefernceNo,
          stockNorts: stockTransfer.stockNorts 
        });
        
        // Fetch details for each product
      await Promise.all(stockTransfer.product.map(async (product) => {
        const productId = product._id;
        const productDetails = await getProductById(productId);
        if (productDetails.data) {
          const product = productDetails.data.data.product;
          setProductFormData(prevState => [...prevState, product]);
          setSKU(prevState => [...prevState, product.productSKU]);
          setCategoryName(prevState => [...prevState, product.category.category]);
          setProductQuantity(prevState => [...prevState, product.productQuantity]);
          const firstImage = product.productImages.length > 0 ? `${baseURL}${product.productImages[0]}` : null;
          setSelectedImages(prevState => [...prevState, firstImage]);
        } else {
          console.error("Failed to fetch product data");
        }
      }));

      } else {
        console.error("Failed to fetch stockTransfer data");
      }
    } catch (error) {
      console.error("Error fetching stockTransfer data:", error);
    }
  };

  useEffect(() => {
    if (stockTransferId) {
      setSelectedStockAdjustment(stockTransferId);
    }
  }, [stockTransferId, wareHouses, products]);

  const getProductById = async (productId) => {
    try {
      const response = await getProduct(productId);
      // const product = response.data.data.product;
      // setProductFormData(product)
      return response;
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(formData)
      const updatedFormData = { ...formData, fromWareHouse: formData.fromWareHouse.value, 
        product: formData.product.map(p => p.value), toWareHouse: formData.toWareHouse.value,
        stockTransferResponsiblePerson: formData.stockTransferResponsiblePerson.value,
      };
      console.log(updatedFormData)
      const response = await createStockTransfer(updatedFormData);

          // Update each product individually
    for (const product of productFormData) {
      const responseProduct = await updateProduct(product._id, product);
      console.log("stock updated product:", responseProduct);
    }

      onStockTransferAdded();
      console.log("Product added successfully:", response.data);

      resetFormData();
      setSearchTerm('');
    } catch (error) {
      console.error("Error adding stock:", error);
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedFormData = { ...formData, fromWareHouse: formData.fromWareHouse.value, 
        product: formData.product.map(p => p.value), toWareHouse: formData.toWareHouse.value,
        stockTransferResponsiblePerson: formData.stockTransferResponsiblePerson.value,
       };
      const response = await updateStockTransfer(stockTransferId, updatedFormData);
      console.log(productFormData)

      for (const product of productFormData) {
        const responseProduct = await updateProduct(product._id, product);
        console.log("stock updated product:", responseProduct);
      }

      // const responseProduct = await updateProduct(productFormData._id, productFormData);
      onStockTransferAdded();
      console.log("stock updated successfully:", response);
      resetFormData();
      setSearchTerm('');
    } catch (error) {
      console.error("Error updating stock:", error);
    }
  };

  const resetFormData = () => {
    setFormData(initialFormData);
    setSKU('');
    setCategoryName('');
    setProductQuantity([]);
    setSelectedImages([]);
  };

  const deleteStockTransfer = async(stockId) => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/stockTransfers/${stockId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        onStockTransferAdded();
        console.log("stock deleted successfully");
      } else {
        console.error("Failed to delete stock");
      }
    } catch (error) {
      console.error("Error deleting stock:", error);
    }
  }

  // const deleteStock = async(stockId) => {
  //   try {
  //     const response = await fetch(`http://localhost:8000/api/stocks/${stockId}`, {
  //       method: "DELETE",
  //     });
  //     if (response.ok) {
  //       onStockAdded();
  //       console.log("stock deleted successfully");
  //     } else {
  //       console.error("Failed to delete stock");
  //     }
  //   } catch (error) {
  //     console.error("Error deleting stock:", error);
  //   }
  // }

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (productId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProduct(productId);
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  const deleteProduct = (productId) => {
      if (formData.product.length === 1) {
        resetFormData();
        setSKU([]);
        setCategoryName([]);
        setProductQuantity([]);
        setSelectedImages([]);
        // setReloadProducts(true)
        setProductFormData([])
        deleteStockTransfer(stockTransferId);

      } else {
                // Remove the product details from the state
                setSKU((prevSKU) => prevSKU.filter((sku, index) => formData.product[index].value !== productId));
                setCategoryName((prevCategoryName) => prevCategoryName.filter((name, index) => formData.product[index].value !== productId));
                setProductQuantity((prevProductQuantity) => prevProductQuantity.filter((quantity, index) => formData.product[index].value !== productId));
                setSelectedImages((prevSelectedImages) => prevSelectedImages.filter((image, index) => formData.product[index].value !== productId));
                setProductFormData((prevProductFormData) => prevProductFormData.filter((_, index) => formData.product[index].value !== productId));
        
                // Remove the product from formData
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  product: prevFormData.product.filter(productOption => productOption.value !== productId)
                }));
          
                // setReloadProducts(true);
                console.log("Product deleted successfully");
      }
  };

  // const deleteProduct = async (productId) => {
  //   try {
  //     const response = await fetch(`http://${BASE_URL}:8000/api/products/${productId}`, {
  //       method: "DELETE",
  //     });
  //     if (response.ok) {
  //       deleteStockTransfer(stockTransferId);
  
  //       // Remove the product details from the state
  //       setSKU((prevSKU) => prevSKU.filter((sku, index) => formData.product[index].value !== productId));
  //       setCategoryName((prevCategoryName) => prevCategoryName.filter((name, index) => formData.product[index].value !== productId));
  //       setProductQuantity((prevProductQuantity) => prevProductQuantity.filter((quantity, index) => formData.product[index].value !== productId));
  //       setSelectedImages((prevSelectedImages) => prevSelectedImages.filter((image, index) => formData.product[index].value !== productId));
  //       setProductFormData((prevProductFormData) => prevProductFormData.filter((_, index) => formData.product[index].value !== productId));

  //       // Remove the product from formData
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         product: prevFormData.product.filter(productOption => productOption.value !== productId)
  //       }));
  
  //       setReloadProducts(true);
  
  //       console.log("Product deleted successfully");
  
  //       // If all products are deleted, reset the form
  //       if (formData.product.length === 1) {
  //         resetFormData();
  //         setSKU([]);
  //         setCategoryName([]);
  //         setProductQuantity([]);
  //         setSelectedImages([]);
  //         setReloadProducts(true)
  //         setProductFormData([])
  //       }
  //     } else {
  //       console.error("Failed to delete product");
  //     }
  //   } catch (error) {
  //     console.error("Error deleting product:", error);
  //   }
  // };

  // const deleteProduct = async (productId) => {
  //   try {
  //     const response = await fetch(`http://localhost:8000/api/products/${productId}`, {
  //       method: "DELETE",
  //     });
  //     if (response.ok) {
  //       deleteStockTransfer(stockTransferId)
  //       // deleteStock(stockId);
  //       setSKU('');
  //       setCategoryName('');
  //       setProductQuantity('');
  //       setSelectedImages([]);
  //       resetFormData();
  //       setReloadProducts(true)
  //       console.log("product deleted successfully");
  //     } else {
  //       console.error("Failed to delete product");
  //     }
  //   } catch (error) {
  //     console.error("Error deleting product:", error);
  //   }
  // };

  const navigate = useNavigate();

  const handleLinkClick = (e) => {
    e.preventDefault();
    setTimeout(() => {
      navigate("/edit-product", { state: { productId: formData.product.value } });
    }, 300);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleProductQtyChange = (e) => {
  //   const value  = e.target.value;
  //   console.log(value)
  //   setProductFormData((prevData) => ({
  //     ...prevData,
  //     productQuantity :value
  //   }));
  //   setProductQuantity(value)
  // };
  const handleProductQtyChange = (e) => {
    const value = e.target.value;
    console.log(value);
    
    // Find the index of the product to update
    const productIndex = formData.product.findIndex(p => p.value === e.target.name);
    
    if (productIndex !== -1) {
      // Update the product quantity in the productFormData array
      setProductFormData((prevData) => {
        const updatedProducts = [...prevData];
        updatedProducts[productIndex] = {
          ...updatedProducts[productIndex],
          productQuantity: value
        };
        return updatedProducts;
      });
  
      // Update the product quantity in the productQuantity array
      setProductQuantity((prevQuantity) => {
        const updatedQuantity = [...prevQuantity];
        updatedQuantity[productIndex] = value;
        return updatedQuantity;
      });
    }
  };

  const cancelClick = () => {
    resetFormData();
  }
  return (
    <div>
      {/* Add Stock */}
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Transfer</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Warehouse From</label>
                          <Select
                            options={wareHouses}
                            value={formData.fromWareHouse}
                            placeholder="Choose wareHouse"
                            onChange={(selectedOption) => setFormData((prevData) => ({
                              ...prevData,
                              fromWareHouse: selectedOption,
                            }))}
                            required
                            // placeholder="Choose"
                          />
                          {/* <Select className="select" options={optionsChoose} /> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Warehouse To</label>
                          <Select
                            options={wareHouses}
                            value={formData.toWareHouse}
                            placeholder="Choose wareHouse"
                            onChange={(selectedOption) => setFormData((prevData) => ({
                              ...prevData,
                              toWareHouse: selectedOption,
                            }))}
                            required
                            // placeholder="Choose"
                          />
                          {/* <Select
                            className="select"
                            options={optionsSelosyLogerro}
                          /> */}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Responsible Person</label>
                          <Select
                            options={options1}
                            value={formData.stockTransferResponsiblePerson}
                            placeholder="Choose Person"
                            onChange={(selectedOption) => setFormData((prevData) => ({
                              ...prevData,
                              stockTransferResponsiblePerson: selectedOption,
                            }))}
                            required
                          />
                          {/* <input type="text" className="form-control" /> */}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks search-form mb-3">
                          <label>Product</label>
                          <input
                          type="text"
                          className="form-control"
                          placeholder="Select Product"
                          value={searchTerm}
                          onChange={handleSearch}
                          required
                        />
                        <i data-feather="search" className="feather-search custom-search" />
                        {filteredProducts.length > 0 && (
                          <ul className="dropdown">
                            {filteredProducts.map((product) => (
                              <li key={product.value} onClick={() => handleSelectProduct(product)} className="dropdown-item" style={{ cursor: 'pointer' }}>
                                {product.label}
                              </li>
                            ))}
                          </ul>
                        )}
                          {/* <input
                            type="text"
                            className="form-control"
                            placeholder="Select Product"
                          />
                          <i
                            data-feather="search"
                            className="feather-search custom-search"
                          /> */}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        {/* <div className="modal-body-table"> */}
                          <div className="table-responsive no-pagination">
                          <table className="table datanew">
                      <thead>
                        <tr>
                          <th>Selected Product</th>
                          <th>SKU</th>
                          <th>Category</th>
                          <th>Quantity</th>
                          <th className="no-sort">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formData.product.map((product, index) => (
                          <tr key={product.value}>
                            <td>
                              <div className="productimgname">
                                <Link to="#" className="product-img stock-img">
                                  <img src={selectedImages[index]} alt={product.label} />
                                </Link>
                                <Link to="#">{product.label}</Link>
                              </div>
                            </td>
                            <td>{SKU[index]}</td>
                            <td>{categoryName[index]}</td>
                            <td>
                              <div className="product-quantity">
                                <span className="quantity-btn">
                                  <i data-feather="minus-circle" className="feather-search" />
                                </span>
                                <input
                                  type="text"
                                  className="quntity-input"
                                  value={productQuantity[index]}
                                  onChange={(e) => handleProductQtyChange(e, index)}
                                />
                                <span className="quantity-btn">
                                  <i data-feather="plus-circle" className="feather-search" />
                                </span>
                              </div>
                            </td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                {/* <button className="confirm-text p-2" 
                                type="button" 
                                onClick={() => removeProduct(index)}
                                onClick={(e) => e.preventDefault()}>
                                  <Trash2 className="feather-trash-2" />
                                </button> */}
                                <Link className="confirm-text p-2" 
                                to="#" 
                                onClick={(e) => removeProduct(index, e)}>
                                  <Trash2 className="feather-trash-2" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                          {/* <table className="table  datanew">
                              <thead>
                                <tr>
                                  <th>Selected Product</th>
                                  <th>SKU</th>
                                  <th className="no-sort">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="productimgname">
                                      <Link
                                        to="#"
                                        className="product-img stock-img"
                                      >
                                        <img src={selectedImages[0]}/>
                                      </Link>
                                      <Link to="#">
                                      {formData.product.label}
                                      
                                      </Link>
                                    </div>
                                  </td>
                                  <td>{SKU}</td>
                                  <td>{categoryName}</td>
                                  <td>
                                    <div className="product-quantity">
                                      <span className="quantity-btn">
                                        <i
                                          data-feather="minus-circle"
                                          className="feather-search"
                                        />
                                      </span>
                                      <input
                                        type="text"
                                        className="quntity-input"
                                        value={productQuantity}
                                        onChange={handleProductQtyChange}
                                      />
                                      <span className="quantity-btn">
                                        +
                                        <i
                                          data-feather="plus-circle"
                                          className="plus-circle"
                                        />
                                      </span>
                                    </div>
                                  </td>
                                  <td className="action-table-data">
                                    <div className="edit-delete-action">
                                      <div className="input-block add-lists"></div>
                                      <Link
                                        
                                        className="me-2 p-2"
                                        // to="/edit-product" state={{ productId: `${formData.product.value}` }}
                                        onClick={handleLinkClick}
                                        data-bs-dismiss="modal"
                                      >
                                        <Edit className="feather-edit" />
                                      </Link>

                                      <Link
                                        className="confirm-text p-2"
                                        to="#"
                                        onClick={() => showConfirmationAlert(formData.product.value)}
                                      >
                                        <Trash2 className="feather-trash-2" />
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table> */}
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks search-form mb-0">
                          <label>Notes</label>
                          <textarea
                          type="text"
                          className="form-control"
                          name="stockNorts"
                          defaultValue={""}
                          value={formData.stockNorts}
                          onChange={handleChange}
                          required
                        />
                          {/* <textarea
                            className="form-control"
                            defaultValue={""}
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" 
                          className="btn btn-submit"
                          data-bs-dismiss="modal">
                        Create
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Stock */}
      {/* Edit Stock */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Transfer</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleUpdateSubmit}>
                    <div className="input-blocks search-form">
                      <label>Product</label>
                      <input
                          type="text"
                          className="form-control"
                          placeholder="Select Product"
                          value={searchTerm}
                          onChange={handleSearch}
                          required
                        />
                        <i data-feather="search" className="feather-search custom-search" />
                        {filteredProducts.length > 0 && (
                          <ul className="dropdown">
                            {filteredProducts.map((product) => (
                              <li key={product.value} onClick={() => handleSelectProduct(product)} className="dropdown-item" style={{ cursor: 'pointer' }}>
                                {product.label}
                              </li>
                            ))}
                          </ul>
                        )}
                      {/* <input
                        type="text"
                        className="form-control"
                        defaultValue="Nike Jordan"
                      />
                      <i
                        data-feather="search"
                        className="feather-search custom-search"
                      /> */}
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Warehouse From</label>
                          <Select
                            options={wareHouses}
                            value={formData.fromWareHouse}
                            placeholder="Choose wareHouse"
                            onChange={(selectedOption) => setFormData((prevData) => ({
                              ...prevData,
                              fromWareHouse: selectedOption,
                            }))}
                            required
                            // placeholder="Choose"
                          />
                          {/* <Select className="select" options={optionsChoose} /> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Warehouse To</label>
                          <Select
                            options={wareHouses}
                            value={formData.toWareHouse}
                            placeholder="Choose wareHouse"
                            onChange={(selectedOption) => setFormData((prevData) => ({
                              ...prevData,
                              toWareHouse: selectedOption,
                            }))}
                            required
                            // placeholder="Choose"
                          />
                          {/* <Select
                            className="select"
                            options={optionsSelosyLogerro}
                          /> */}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Reference No</label>
                          <input
                            type="text"
                            className="form-control"
                            name="stockReferenceNumber"
                            value={formData.stockTransferRefernceNo}
                            onChange={handleChange}
                            required
                          />
                          {/* <input
                            type="text"
                            className="form-control"
                            defaultValue={32434545}
                          /> */}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks search-form mb-3">
                          <label>Product</label>
                          <input
                          type="text"
                          className="form-control"
                          placeholder="Select Product"
                          // value={formData.product.label}
                          value={searchTerm}
                          onChange={handleSearch}
                          required
                        />
                        <i data-feather="search" className="feather-search custom-search" />
                        {filteredProducts.length > 0 && (
                          <ul className="dropdown">
                            {filteredProducts.map((product) => (
                              <li key={product.value} onClick={() => handleSelectProduct(product)} className="dropdown-item" style={{ cursor: 'pointer' }}>
                                {product.label}
                              </li>
                            ))}
                          </ul>
                        )}
                          {/* <input
                            type="text"
                            className="form-control"
                            placeholder="Select Product"
                            defaultValue="Nike Jordan"
                          />
                          <i
                            data-feather="search"
                            className="feather-search custom-search"
                          /> */}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="modal-body-table">
                          <div className="table-responsive">
                          <table className="table  datanew">
                              <thead>
                                <tr>
                                  <th>Product</th>
                                  <th>SKU</th>
                                  <th>Category</th>
                                  <th>Qty</th>
                                  <th className="no-sort">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                              {formData.product.map((productOption, index) => (
                                <tr key={productOption.value}>
                                  <td>
                                    <div className="productimgname">
                                      <Link to="#" className="product-img stock-img">
                                        <img src={selectedImages[index]} alt="Product" />
                                      </Link>
                                      <Link to="#">
                                        {productOption.label}
                                      </Link>
                                    </div>
                                  </td>
                                  <td>{SKU[index]}</td>
                                  <td>{categoryName[index]}</td>
                                  <td>
                                    <div className="product-quantity">
                                      <span className="quantity-btn">
                                        <i data-feather="minus-circle" className="feather-search" />
                                      </span>
                                      <input
                                        type="text"
                                        className="quntity-input"
                                        name={productOption.value}
                                        value={productQuantity[index]}
                                        onChange={(e) => handleProductQtyChange(e, index)}
                                      />
                                      <span className="quantity-btn">
                                        <i data-feather="plus-circle" className="plus-circle" />
                                      </span>
                                    </div>
                                  </td>
                                  <td className="action-table-data">
                                    <div className="edit-delete-action">
                                      <div className="input-block add-lists"></div>
                                      <Link
                                        className="me-2 p-2"
                                        onClick={() => handleLinkClick(productOption.value)}
                                        data-bs-dismiss="modal"
                                      >
                                        <Edit className="feather-edit" />
                                      </Link>
                                      <Link
                                        className="confirm-text p-2"
                                        to="#"
                                        onClick={() => showConfirmationAlert(productOption.value)}
                                      >
                                        <Trash2 className="feather-trash-2" />
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                              {/* <tbody>
                                <tr>
                                  <td>
                                    <div className="productimgname">
                                      <Link
                                        to="#"
                                        className="product-img stock-img"
                                      >
                                        <img src={selectedImages[0]}/>
                                      </Link>
                                      <Link to="#">
                                      {formData.product.label}
                                      
                                      </Link>
                                    </div>
                                  </td>
                                  <td>{SKU}</td>
                                  <td>{categoryName}</td>
                                  <td>
                                    <div className="product-quantity">
                                      <span className="quantity-btn">
                                        <i
                                          data-feather="minus-circle"
                                          className="feather-search"
                                        />
                                      </span>
                                      <input
                                        type="text"
                                        className="quntity-input"
                                        value={productQuantity}
                                        onChange={handleProductQtyChange}
                                      />
                                      <span className="quantity-btn">
                                        +
                                        <i
                                          data-feather="plus-circle"
                                          className="plus-circle"
                                        />
                                      </span>
                                    </div>
                                  </td>
                                  <td className="action-table-data">
                                    <div className="edit-delete-action">
                                      <div className="input-block add-lists"></div>
                                      <Link
                                        
                                        className="me-2 p-2"
                                        // to="/edit-product" state={{ productId: `${formData.product.value}` }}
                                        onClick={handleLinkClick}
                                        data-bs-dismiss="modal"
                                      >
                                        <Edit className="feather-edit" />
                                      </Link>

                                      <Link
                                        className="confirm-text p-2"
                                        to="#"
                                        onClick={() => showConfirmationAlert(formData.product.value)}
                                      >
                                        <Trash2 className="feather-trash-2" />
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              </tbody> */}
                            </table>
                            {/* <table className="table  datanew">
                              <thead>
                                <tr>
                                  <th>Product</th>
                                  <th>SKU</th>
                                  <th>Category</th>
                                  <th>Qty</th>
                                  <th className="no-sort">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="productimgname">
                                      <Link
                                        to="#"
                                        className="product-img stock-img"
                                      >
                                        <ImageWithBasePath
                                          src="assets/img/products/stock-img-02.png"
                                          alt="product"
                                        />
                                      </Link>
                                      <Link to="#">Nike Jordan</Link>
                                    </div>
                                  </td>
                                  <td>PT002</td>
                                  <td>Nike</td>
                                  <td>
                                    <div className="product-quantity">
                                      <span className="quantity-btn">
                                        <i
                                          data-feather="minus-circle"
                                          className="feather-search"
                                        />
                                      </span>
                                      <input
                                        type="text"
                                        className="quntity-input"
                                        defaultValue={2}
                                      />
                                      <span className="quantity-btn">
                                        +
                                        <i
                                          data-feather="plus-circle"
                                          className="plus-circle"
                                        />
                                      </span>
                                    </div>
                                  </td>
                                  <td className="action-table-data">
                                    <div className="edit-delete-action">
                                      <Link
                                        className="me-2 p-2"
                                        to="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#edit-units"
                                      >
                                        <i
                                          data-feather="edit"
                                          className="feather-edit"
                                        />
                                      </Link>
                                      <Link className="confirm-text p-2" to="#">
                                        <i
                                          data-feather="trash-2"
                                          className="feather-trash-2"
                                        />
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks search-form mb-0">
                          <label>Notes</label>
                          <textarea
                          type="text"
                          className="form-control"
                          name="stockNorts"
                          defaultValue={""}
                          value={formData.stockNorts}
                          onChange={handleChange}
                          required
                        />
                          {/* <textarea
                            className="form-control"
                            defaultValue={
                              "The Jordan brand is owned by Nike (owned by the Knight family), as, at the time, the company was building its strategy to work with athletes to launch shows that could inspire consumers.Although Jordan preferred Converse and Adidas, they simply could not match the offer Nike made. "
                            }
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        onClick={cancelClick}
                      >
                        Cancel
                      </button>
                      <button type="submit" 
                          className="btn btn-submit"
                          data-bs-dismiss="modal">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Stock */}
      {/* Import Transfer */}
      <div className="modal fade" id="view-notes">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Import Transfer</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              </div>
              <div className="modal-body custom-modal-body">
                <form>
                  <div className="row">
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>From</label>
                        <Select
                          className="select"
                          options={optionsStore1Store2}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>To</label>
                        <Select
                          className="select"
                          options={optionsStore1Store2}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>Satus</label>
                        <Select
                          className="select"
                          options={optionsSentPending}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-6 col-12">
                      <div className="row">
                        <div>
                          <div className="modal-footer-btn download-file">
                            <Link to="#" className="btn btn-submit">
                              Download Sample File
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-blocks image-upload-down">
                        <label> Upload CSV File</label>
                        <div className="image-upload download">
                          <input type="file" />
                          <div className="image-uploads">
                            <ImageWithBasePath
                              src="assets/img/download-img.png"
                              alt="img"
                            />
                            <h4>
                              Drag and drop a <span>file to upload</span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-6 col-12">
                      <div className="mb-3">
                        <label className="form-label">Shipping</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3 summer-description-box transfer">
                      <label className="form-label">Description</label>
                      <div id="summernote3"></div>
                      <p>Maximum 60 Characters</p>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        onClick={cancelClick}
                      >
                        Cancel
                      </button>
                      <button type="submit" 
                          className="btn btn-submit"
                          data-bs-dismiss="modal">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Import Transfer */}
    </div>
  );
};

StockTransferModal.propTypes = {
  onStockTransferAdded: PropTypes.func.isRequired,
  stockTransferId: PropTypes.string.isRequired,
};

export default StockTransferModal;
