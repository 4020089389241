import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createProduct, getAllBrands, getCategory, getUnits, updateProduct } from '../../service/ApiServices';
import { PlusOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { message, Spin, Upload, Image } from 'antd';
import ImgCrop from 'antd-img-crop';
import Select from 'react-select';
import { API_BASE_URL } from "../../../src/environment";



const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const CreateCategoryModal = ({ handleCancel, selectedProduct }) => {
  const formDataToSend = new FormData();
  const [name, setName] = useState('');
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectCategory, setSelectCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [selectUnit, setSelectUnit] = useState("");
  const [brands, setBrands] = useState([]);
  const [selectBrand, setSelectBrand] = useState("");
  const [uploadedPic, setUploadedPic] = useState("")
  const [subcategories, setSubcategories] = useState([]);
  const [selectSubcategory, setSelectSubcategory] = useState("");
  const [unchangeCategory, setUnchangeCategory] = useState([]);
  const [description, setDescription] = useState("");
  const queryClient = useQueryClient();

  const { data: categoryData, isLoading: isCategoryLoading } = useQuery('categories', getCategory, {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const { data: unitData, isLoading: isUnitLoading } = useQuery('units', getUnits, {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const { data: brandData, isLoading: isBrandLoading } = useQuery('brands', getAllBrands, {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
  });


  const storedUserData = localStorage.getItem("user");
  const userData = JSON.parse(storedUserData);
  const id = userData?.data?.uid; //
  const [userRightssub, setUserRightssub] = useState([]);
  useEffect(() => {
    const fetchUserRightssub = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/userright/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user rights");
        }
        const data = await response.json();
        console.log("Fetched User Sub Data:", data.data);
        setUserRightssub(data.data || []); // Ensure it's always an array
      } catch (error) {
        console.error("Error fetching data:", error);
        setUserRightssub([]); // Set to empty array in case of error
      }
    };
    fetchUserRightssub(); // Fetch the data on component mount
  }, [id]);
  const hasJobRightWithAdd = userRightssub.some(right => right.right === 'add-product' && right.adds === 1);
  //const hasJobRightWithedit = userRightssub.some(right => right.right === 'product-list' && right.updates === 1);




  useEffect(() => {
    if (categoryData && categoryData.data && categoryData.data.data && categoryData.data.data.category) {
      const categoryOptions = [
        {
          value: '',
          label: 'Choose',
        },
        ...categoryData.data.data.category
          .filter((category) => !category.parentId)
          .map((category) => ({
            value: category._id,
            label: category.title,
            parentId: category.parentId,
          }))
      ];
      setUnchangeCategory(categoryData.data.data.category);
      setCategories(categoryOptions);
    }
    if (unitData && unitData.data && unitData.data.data) {
      const categoryOptions = [
        {
          value: '',
          label: 'Choose',
        },
        ...unitData.data.data
          .map((unit) => ({
            value: unit._id,
            label: unit.name + " (" + unit.variation + ")",
          }))
      ];
      setUnits(categoryOptions);
    }
    if (brandData && brandData.data) {
      const brandOptions = [
        {
          value: '',
          label: 'Choose',
        },
        ...brandData.data
          .map((brand) => ({
            value: brand._id,
            label: brand.name,
          }))
      ];
      setBrands(brandOptions);
    }
  }, [categoryData, unitData, brandData]);
  useEffect(() => {
    if (selectCategory) {
      const selectedCategoryObjArray = unchangeCategory.filter((category) => category.parentId && category.parentId._id === selectCategory);
      if (selectedCategoryObjArray.length > 0) {
        const subcategoryOptions = [
          {
            value: '',
            label: 'Choose',
          },
          ...selectedCategoryObjArray
            .map((category) => ({
              value: category._id,
              label: category.title,
            }))
        ];
        setSubcategories(subcategoryOptions);
      } else {
        setSubcategories([]);
        setSelectSubcategory('');
      }

    } else {
      setSubcategories([]);
      setSelectSubcategory('');
    }
  }, [selectCategory, selectedProduct, unchangeCategory]);
  useEffect(() => {

  }, [selectSubcategory])
  useEffect(() => {
    if (selectedProduct) {
      setName(selectedProduct.productName);
      setSelectCategory(selectedProduct.category.parentId ? selectedProduct.category.parentId : selectedProduct.category._id);
      setSelectSubcategory(selectedProduct.category.parentId ? selectedProduct.category._id : '');
      setSelectUnit(selectedProduct.unit?._id || '');
      setSelectBrand(selectedProduct.brand?._id || '');
      setDescription(selectedProduct.description);
      setUploadedPic(selectedProduct.image);
    } else {
      handleReset();
    }
  }, [selectedProduct, unchangeCategory]);

  const mutation = useMutation(
    selectedProduct ? (data) => updateProduct( selectedProduct._id,data) : createProduct,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('products');
        Swal.fire({
          title: "Success",
          text: selectedProduct ? "Product updated successfully!" : "Product created successfully!",
          icon: "success",
        });
        handleCancel();
        handleReset();
      },
      onError: (error) => {
        let errorMessage = "Failed to process request.";
        if (error.response) {
          const { status, data } = error.response;
          if (status === 400 && data.message) {
            errorMessage = data.message;
          } else if (status === 500) {
            errorMessage = "Server error. Please try again later.";
          }
        }
        Swal.fire({
          title: "Error",
          text: errorMessage,
          icon: "error",
        });
      },
    }
  );

  const handleSave = () => {
    if (name.trim() === '' ||
      selectUnit.trim() === '' ||
      selectBrand.trim() === '' ||
      description.trim() === ''
    ) {
      Swal.fire({
        title: "Warning",
        text: "Please fill in the required fields",
        icon: "warning",
      });
      return;
    }
    if (!selectedProduct && fileList.length === 0) {
      Swal.fire({
        title: "Warning",
        text: "Please upload a product picture",
        icon: "warning",
      });
      return
    }
    if (subcategories.length > 0 && !selectSubcategory) {
      Swal.fire({
        title: "Warning",
        text: "Please select a Subcategory",
        icon: "warning",
      });
      return;
    }
    formDataToSend.append('productName', name);
    if (fileList.length) {
      formDataToSend.append('file', fileList[0].originFileObj);
    }
    formDataToSend.append("unit", selectUnit)
    formDataToSend.append("brand", selectBrand)
    formDataToSend.append("description", description)
    if (selectSubcategory) {
      formDataToSend.append("category", selectSubcategory)
    } else {
      formDataToSend.append("category", selectCategory)
    }
    mutation.mutate(formDataToSend);
  };
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const handleCustomRequest = async ({ file, onSuccess, onError }) => {
    setUploading(true);
    formDataToSend.append('profilePicture', file);
    try {
      onSuccess(true);
      message.success(`${file.name} uploaded successfully`);
    } catch (error) {
      onError(error);
      message.error(`${file.name} upload failed`);
    } finally {
      setUploading(false);
    }
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const handleReset = () => {
    setName('');
    setFileList([]);
    setSelectCategory('');
    setSelectUnit('');
    setSelectBrand('');
    setDescription('');
  }
  return (
    <div className="modal-content">
      <div className="modal-header border-0 custom-modal-header">
        <div className="page-title">
          <h4>{selectedProduct ? "Edit Product" : "Create Product"}</h4>
          <p className="mb-0">Fill in the required fields to {selectedProduct ? "edit" : "create"} a product</p>
        </div>
      </div>
      {
        isBrandLoading || isCategoryLoading || isUnitLoading ?
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <Spin size="large" />
          </div>
          : <div className="modal-body custom-modal-body">
            <div style={{ marginBottom: "30px", display: "flex", justifyContent: "center" }}>
              <div style={{ textAlign: "center" }}>
                {selectedProduct && <label className="form-label" style={{ display: "block", marginBottom: "10px" }}>
                  Uploaded Product Picture
                </label>}
                {selectedProduct && (
                  <Image
                    src={selectedProduct.image}
                    alt="Profile Picture"
                    width={120}
                    height={120}
                    style={{
                      objectFit: "cover",
                      borderRadius: "50%",
                      border: "2px solid #ddd",
                      padding: "5px",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)"
                    }}
                    preview={{
                      zIndex: 10000,
                      visible: previewOpen,
                      src: previewImage,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                    }}
                    onClick={() => {
                      setPreviewImage(uploadedPic);
                      setPreviewOpen(true);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="profile-pic-upload">
              <div className="input-blocks mb-0">
                <ImgCrop>
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    customRequest={handleCustomRequest}
                    disabled={uploading}
                  >
                    {fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                </ImgCrop>
                {previewImage && (
                  <Image
                    wrapperStyle={{ display: "none" }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) => !visible && setPreviewImage(""),
                    }}
                    src={previewImage}
                  />
                )}
                <label className="form-label">Product Picture</label>
              </div>
            </div>
            <form>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Product Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Select Category</label>
                    <Select
                      name="category"
                      value={categories.find((category) => category.value === selectCategory) || ''}
                      className="select"
                      options={categories}
                      onChange={(selectedOption) => { setSelectCategory(selectedOption.value) }}
                      placeholder="Choose"
                    />
                  </div>
                </div>
                {subcategories.length > 0 && (
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Select Subcategory</label>
                      <Select
                        name="subcategory"
                        value={subcategories.find((subcategory) => subcategory.value === selectSubcategory) || ''}
                        className="select"
                        options={subcategories}
                        onChange={(selectedOption) => setSelectSubcategory(selectedOption.value)}
                        placeholder="Choose"
                      />
                    </div>
                  </div>
                )}

                <div className="col-lg-6 col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Select Unit</label>
                    <Select
                      name="unit"
                      value={units.find((unit) => unit.value === selectUnit) || ''}
                      className="select"
                      options={units}
                      onChange={(selectedOption) => { setSelectUnit(selectedOption.value) }}
                      placeholder="Choose"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Select Brand</label>
                    <Select
                      name="brand"
                      value={brands.find((brand) => brand.value === selectBrand) || ''}
                      className="select"
                      options={brands}
                      onChange={(selectedOption) => { setSelectBrand(selectedOption.value) }}
                      placeholder="Choose"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <textarea
                      className="form-control"
                      placeholder="Enter Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </form>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
              <button
                type="button"
                className="btn btn-cancel"
                onClick={() => {
                  handleCancel();
                  if (!selectedProduct) {
                    handleReset();
                  }

                }}
              >
                Close
              </button>
              <div>
                <button
                  type="button"
                  className="btn btn-reset me-2"
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Reset
                </button>
                {hasJobRightWithAdd && (
                          <button
                          type="button"
                          className="btn btn-submit ml-0"
                          onClick={handleSave}
                          disabled={mutation.isLoading}
                        >
                          {mutation.isLoading ? <Spin size='small' /> : 'Save'}
                        </button>
                 )}   

                


              </div>
            </div>
          </div>
      }
    </div>
  );
};

CreateCategoryModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object,
};

export default CreateCategoryModal;
