import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../core/breadcrumbs";
import { Link } from "react-router-dom";
import { Filter, Sliders, User, Globe, Edit, Eye, Trash2 } from "react-feather";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import Select from "react-select";
import { Table } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SupplierModal from "../../core/modals/peoples/supplierModal";
import { BASE_URL } from "../../environment";
import { API_BASE_URL } from "../../../src/environment";
const Suppliers = () => {

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const [suppliers, setSuppliers] = useState([]);

  const [shouldReloadData, setShouldReloadData] = useState(false);

  const storedUserData = localStorage.getItem("user");
    const userData = JSON.parse(storedUserData);
    const id = userData?.data?.uid; //
    const [userRightssub, setUserRightssub] = useState([]);
    useEffect(() => {
      const fetchUserRightssub = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/userright/${id}`);
          if (!response.ok) {
            throw new Error("Failed to fetch user rights");
          }
          const data = await response.json();
          console.log("Fetched User Sub Data:", data.data);
          setUserRightssub(data.data || []); // Ensure it's always an array
        } catch (error) {
          console.error("Error fetching data:", error);
          setUserRightssub([]); // Set to empty array in case of error
        }
      };
    
      fetchUserRightssub(); // Fetch the data on component mount
    }, [id]);
    const hasJobRightWithAdd = userRightssub.some(right => right.right === 'Suppliers' && right.adds === 1);
    const hasJobRightWithedit = userRightssub.some(right => right.right === 'Suppliers' && right.updates === 1);
    const hasJobRightWithview = userRightssub.some(right => right.right === 'Suppliers' && right.viewes === 1);  
    const hasJobRightWithedel = userRightssub.some(right => right.right === 'Suppliers' && right.deletes=== 1); 

  useEffect(() => {
    fetchSuppliers();
  }, [shouldReloadData]);

  const fetchSuppliers = async () => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/suppliers`);
      if (!response.ok) {
        throw new Error("Failed to fetch suppliers");
      }
      const data = await response.json();
      console.log("Fetched data:", data);
      setSuppliers(data.data.suppliers);
    } catch (error) {
      console.error("Error fetching suppliers:", error);
    }
  };

  const handleReloadData = () => {
    setShouldReloadData(!shouldReloadData);
  };

  const options = [
    { value: "sortByDate", label: "Sort by Date" },
    { value: "140923", label: "14 09 23" },
    { value: "110923", label: "11 09 23" },
  ];
  const optionsTwo = [
    { label: "Choose Customer Name", value: "" },
    { label: "Benjamin", value: "Benjamin" },
    { label: "Ellen", value: "Ellen" },
    { label: "Freda", value: "Freda" },
    { label: "Kaitlin", value: "Kaitlin" },
  ];

  const countries = [
    { label: "Choose Country", value: "" },
    { label: "India", value: "India" },
    { label: "USA", value: "USA" },
  ];

  const columns = [
    {
      render: () => (
        <label className="checkboxs">
          <input type="checkbox" />
          <span className="checkmarks" />
        </label>
      ),
    },

    {
      title: "Supplier Name",
      dataIndex: "SupplierName",
      sorter: (a, b) => a.supplierName.length - b.supplierName.length,
    },
    {
      title: "Code",
      dataIndex: "Code",
      sorter: (a, b) => a.code.length - b.code.length,
    },

    {
      title: "Email",
      dataIndex: "Email",
      sorter: (a, b) => a.email.length - b.email.length,
    },

    {
      title: "Phone",
      dataIndex: "Phone",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },

    {
      title: "Country",
      dataIndex: "Country",
      sorter: (a, b) => a.country.length - b.country.length,
    },

    {
      title: "Action",
      dataIndex: "action",
      sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (supplierId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSupplier(supplierId);
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  const deleteSupplier = async (supplierId) => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/suppliers/${supplierId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        handleReloadData();
        console.log("Customer deleted successfully");
      } else {
        console.error("Failed to delete customer");
      }
    } catch (error) {
      console.error("Error deleting customer:", error);
    }
  };
  
  const handleLinkClick = async (supplierId) => {
    setSelectedSupplierId(supplierId);
  };

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredSuppliers = suppliers.filter(suplier => {
    const searchLower = searchQuery.toLowerCase();
    return (
      suplier.supplierName.toLowerCase().includes(searchLower)
    );
});

  return (
    <div className="page-wrapper">
      <div className="content">
      {hasJobRightWithAdd && (
        <Breadcrumbs
        maintitle="Supplier List "
        subtitle="Manage Your Supplier"
        addButton="Add New Supplier"
        />
      )}
        
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="search" 
                    className="form-control form-control-sm formsearch" 
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              <div className="search-path">
                <Link
                  className={`btn btn-filter ${
                    isFilterVisible ? "setclose" : ""
                  }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div>
              <div className="form-sort stylewidth">
                <Sliders className="info-img" />

                <Select
                  className="select "
                  options={options}
                  placeholder="Sort by Date"
                />
              </div>
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <User className="info-img" />
                      <Select
                        options={optionsTwo}
                        placeholder="Choose Customer Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Globe className="info-img" />
                      <Select
                        options={countries}
                        placeholder="Choose Country"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <a className="btn btn-filters ms-auto">
                        {" "}
                        <i
                          data-feather="search"
                          className="feather-search"
                        />{" "}
                        Search{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              {/* <Table
                className="table datanew"
                columns={columns}
                dataSource={data}
                rowKey={(record) => record.id}
                // pagination={true}
              /> */}
              <Table
                className="table datanew"
                columns={columns}
                dataSource={filteredSuppliers.map((supplier) => ({
                  key: supplier._id,
                  SupplierName: (
                    <span className="productimgname">
                      <Link to="#" className="product-img stock-img">
                        <ImageWithBasePath alt="" src={supplier.image} />
                      </Link>
                      <Link to="#">{supplier.supplierName}</Link>
                    </span>
                  ),
                  Code: `#${supplier._id.slice(-3)}`,
                  Email: supplier.supplierEmail,
                  Phone: supplier.supplierPhone,
                  Country: supplier.supplierCountry,
                  action: (
                    <td className="action-table-data">
                      <div className="edit-delete-action">
                        <div className="input-block add-lists"></div>

                        {hasJobRightWithview && (
                          <Link className="me-2 p-2" to="#">
                          <Eye className="feather-view" />
                          </Link>
                        )}

                        {hasJobRightWithedit && (
                          <Link
                          className="me-2 p-2"
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-units"
                          onClick={() => handleLinkClick(supplier._id)}
                          >
                          <Edit className="feather-edit" />
                          </Link>
                        )}
                        {hasJobRightWithedel  && (
                          <Link
                          className="confirm-text p-2"
                          to="#"
                          onClick={() => showConfirmationAlert(supplier._id)}
                          >
                          <Trash2 className="feather-trash-2" />
                          </Link>
                        )}


                        
                      </div>
                    </td>
                  ),
                }))}
                pagination={false}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>

      <SupplierModal onSupplierAdded={handleReloadData} supplierId={selectedSupplierId}/>
    </div>
  );
};

export default Suppliers;
