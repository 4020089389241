import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/dist';
import AddPurchases from '../../core/modals/purchases/addpurchases';
import EditPurchases from '../../core/modals/purchases/editpurchases';
import Swal from 'sweetalert2';
import Table from '../../core/pagination/datatable'
import Select from 'react-select';
import Sliders from 'feather-icons-react/build/IconComponents/Sliders';
import { ChevronUp, Filter, RotateCcw, StopCircle, Zap } from 'feather-icons-react/build/IconComponents';
import { DatePicker } from 'antd';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ImageWithBasePath from '../../core/img/imagewithbasebath';
import { setToogleHeader } from '../../core/redux/action';
import withReactContent from 'sweetalert2-react-content';
import { getPurchases, deletePurchase } from '../../../src/service/purchasesApiServices';
import { API_BASE_URL } from "../../../src/environment";
const PurchaseReturnList = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.toggle_header);

    // purchase work start
    const [purchases, setPurchases] = useState([]);
    const [updatePurchase, setUpdatePurchase] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    const deletePurchases = async (record) => {
        try {
            await deletePurchase(record._id);
            const updatedPurchases = purchases.filter(purchase => purchase?._id !== record?._id);
            setUpdatePurchase(updatedPurchases);
        } catch (error) {
            console.error('Error deleting purchase:', error);
        }
    };


    const storedUserData = localStorage.getItem("user");
    const userData = JSON.parse(storedUserData);
    const id = userData?.data?.uid; //
    const [userRightssub, setUserRightssub] = useState([]);
    useEffect(() => {
      const fetchUserRightssub = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/userright/${id}`);
          if (!response.ok) {
            throw new Error("Failed to fetch user rights");
          }
          const data = await response.json();
          console.log("Fetched User Sub Data:", data.data);
          setUserRightssub(data.data || []); // Ensure it's always an array
        } catch (error) {
          console.error("Error fetching data:", error);
          setUserRightssub([]); // Set to empty array in case of error
        }
      };
    
      fetchUserRightssub(); // Fetch the data on component mount
    }, [id]);
   // const hasJobRightWithAdd = userRightssub.some(right => right.right === 'Purchases' && right.adds === 1);
   // const hasJobRightWithedit = userRightssub.some(right => right.right === 'Purchases' && right.updates === 1);
   // const hasJobRightWithview = userRightssub.some(right => right.right === 'Purchases' && right.viewes === 1);  
    const hasJobRightWithedel = userRightssub.some(right => right.right === 'Purchases' && right.deletes=== 1); 

    console.log(updatePurchase, "==Purchase ID==");
    console.log(purchases, "== PURCHASES USE STATE==");

    const fetchPurchases = async () => {
        try {
            const purchasesData = await getPurchases();
            setPurchases(purchasesData);
        } catch (error) {
            console.error('Error fetching purchases:', error);
        }
    };

    useEffect(() => {
        fetchPurchases();
    }, []);

    

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredPurchases = purchases.filter(purchase => 
        purchase.batchNumber.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // purchase work end

    const oldandlatestvalue = [
        { value: 'date', label: 'Sort by Date' },
        { value: 'newest', label: 'Newest' },
        { value: 'oldest', label: 'Oldest' },
    ];
    const brandOptions = [
        { value: 'choose', label: 'Choose Brand' },
        { value: 'lenevo', label: 'Lenevo' },
        { value: 'boat', label: 'Boat' },
        { value: 'nike', label: 'Nike' },
    ];
    const status = [
        { value: 'choose Status', label: 'Choose Status' },
        { value: 'Active', label: 'Active' },
        { value: 'InActive', label: 'InActive' },
    ];
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const toggleFilterVisibility = () => {
        setIsFilterVisible((prevVisibility) => !prevVisibility);
    };
    const [selectedDate, setSelectedDate] = useState(new Date());
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const renderTooltip = (props) => (
        <Tooltip id="pdf-tooltip" {...props}>
            Pdf
        </Tooltip>
    );
    const renderExcelTooltip = (props) => (
        <Tooltip id="excel-tooltip" {...props}>
            Excel
        </Tooltip>
    );
    const renderPrinterTooltip = (props) => (
        <Tooltip id="printer-tooltip" {...props}>
            Printer
        </Tooltip>
    );
    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );
    const renderCollapseTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Collapse
        </Tooltip>
    );

    const columns = [
        {
            title: "Supplier Name",
            dataIndex: ['supplier', 'supplierName'],
            sorter: (a, b) => a.supplier.supplierName.localeCompare(b.supplier.supplierName),
        },
        {
            title: "Batch",
            dataIndex: "batchNumber",
            sorter: (a, b) => a.batchNumber.length - b.batchNumber.length,
        },
        {
            title: "Date",
            dataIndex: "purchaseDate",
            sorter: (a, b) => new Date(a.purchaseDate) - new Date(b.purchaseDate),
        },
        {
            title: "Purchase Price",
            dataIndex: "purchasePrice",
            sorter: (a, b) => a.purchasePrice - b.purchasePrice,
        },
        {
            title: "Selling Price",
            dataIndex: "sellingPrice",
            sorter: (a, b) => a.sellingPrice - b.sellingPrice,
        },
        {
            title: "Qty",
            dataIndex: "quantity",
            sorter: (a, b) => a.quantity - b.quantity,
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text) => (
                <span className="badge badge-linesuccess">
                    <Link to="#"> {text}</Link>
                </span>
            ),
            sorter: (a, b) => a.status.localeCompare(b.status),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <td className="action-table-data">
                    <div className="edit-delete-action">
                    {hasJobRightWithedel && (
                       <Link className="confirm-text p-2" to="#"  >
                       <i data-feather="trash-2"
                           className="feather-trash-2"
                           onClick={() => showConfirmationAlert(record)}
                       >
                       </i>
                         </Link>
                    )}
                    </div>
                </td>
            )
        },
    ];

    const MySwal = withReactContent(Swal);

    const showConfirmationAlert = (record) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            showCancelButton: true,
            confirmButtonColor: '#00ff00',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonColor: '#ff0000',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                MySwal.fire({
                    title: 'Deleted!',
                    text: 'Your file has been deleted.',
                    className: "btn btn-success",
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                });
                deletePurchases(record);
            } else {
                MySwal.close();
            }
        });
    };

    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Purchase Return List</h4>
                                <h6>Manage your purchase returns</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                    <Link>
                                        <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <ImageWithBasePath src="assets/img/icons/excel.svg" alt="img" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <i data-feather="printer" className="feather-printer" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                                    <Link
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        id="collapse-header"
                                        className={data ? "active" : ""}
                                        onClick={() => { dispatch(setToogleHeader(!data)) }}
                                    >
                                        <ChevronUp />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                        </ul>
                        <div className="page-btn">
                            
                        </div>
                    </div>
                    {/* /product list */}
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="form-control form-control-sm formsearch"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                        <Link to className="btn btn-searchset">
                                            <i data-feather="search" className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="search-path">
                                    <Link className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`} id="filter_search">
                                        <Filter
                                            className="filter-icon"
                                            onClick={toggleFilterVisibility}
                                        />
                                        <span onClick={toggleFilterVisibility}>
                                            <ImageWithBasePath src="assets/img/icons/closes.svg" alt="img" />
                                        </span>
                                    </Link>
                                </div>
                                <div className="form-sort">
                                    <Sliders className="info-img" />
                                    <Select
                                        className="select"
                                        options={oldandlatestvalue}
                                        placeholder="Newest"
                                    />
                                </div>
                            </div>
                            {/* /Filter */}

                            <div
                                className={`card${isFilterVisible ? ' visible' : ''}`}
                                id="filter_inputs"
                                style={{ display: isFilterVisible ? 'block' : 'none' }}
                            >
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <Zap className="info-img" />
                                                <Select
                                                    className="select"
                                                    options={brandOptions}
                                                    placeholder="Choose Brand"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <div className="input-groupicon">
                                                    <DatePicker
                                                        selected={selectedDate}
                                                        onChange={handleDateChange}
                                                        type="date"
                                                        className="filterdatepicker"
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholder='Choose Date'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <i data-feather="stop-circle" className="info-img" />
                                                <StopCircle className="info-img" />
                                                <Select
                                                    className="select"
                                                    options={status}
                                                    placeholder="Choose Status"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                                            <div className="input-blocks">
                                                <Link className="btn btn-filters ms-auto">
                                                    {" "}
                                                    <i data-feather="search" className="feather-search" />{" "}
                                                    Search{" "}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* /Filter */}
                            <div className="table-responsive">
                                <Table columns={columns} dataSource={filteredPurchases} />

                            </div>
                        </div>
                        {/* /product list */}
                    </div>
                </div>
            </div>
            
            <AddPurchases />
            <EditPurchases purchaseId={updatePurchase?._id} />
        </div>
    )
}

export default PurchaseReturnList;
