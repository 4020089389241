import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const Editaccsessroal = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    lable: "",
    right: "",
    rid: "",
    status: "1",
    link: "",
    icon: "",
    menu: "1",
    menuid: "",
    submenu: "0",
    submenuid: "",
    active: "0",
    adds: "0",
    viewes: "0",
    updates: "0",
    deletes: "0",
    download: "0",
    upload: "0",
    approve: "0",
    pdf: "0",
    excel: "0",
    print: "0",
    email: "0",
    filters: "0",
    searchbar: "0"
  });
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchAccessRight = async () => {
      try {
        const response = await fetch(`http://localhost:8000/api/accsessrights/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setFormData({
            right: data.data.right || "",
            rid: data.data.rid || "",
            status: data.data.status || "",
            link: data.data.link || "",
            icon: data.data.icon || "",
            menu: data.data.menu || "",
            menuid: data.data.menuid || "",
            submenu: data.data.submenu || "",
            submenuid: data.data.submenuid || "",
            active: data.data.active || "",
            adds: data.data.adds || "",
            viewes: data.data.viewes || "",
            updates: data.data.updates || "",
            deletes: data.data.deletes || "",
            download: data.data.download || "",
            upload: data.data.upload || "",
            approve: data.data.approve || "",
            pdf: data.data.pdf || "",
            excel: data.data.excel || "",
            print: data.data.print || "",
            email: data.data.email || "",
            filters: data.data.filters || "",
            searchbar: data.data.searchbar || "",
          });
        } else {
          const errorData = await response.json();
          setMessage(`Error fetching access right: ${errorData.message}`);
        }
      } catch (error) {
        setMessage(`Error: ${error.message}`);
      }
    };
    fetchAccessRight();
  }, [id]);


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

   const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`http://localhost:8000/api/accsessrights/update/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setMessage("Access right updated successfully!");
  
      } else {
        const errorData = await response.json();
        setMessage(`Error updating access right: ${errorData.message}`);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <h4>Edit Access Right</h4>
          <p>Update the details of the access right</p>
        </div>

 
        {message && <p>{message}</p>}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Right</label>
            <input
              type="text"
              name="right"
              className="form-control"
              value={formData.right}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Role ID</label>
            <input
              type="number"
              name="rid"
              className="form-control"
              value={formData.rid}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-sm-1">
                <label>Status</label>
                <select
                  name="status"
                  className="form-control"
                  value={formData.status}
                  onChange={handleChange}
                >
                  <option value={formData.status}>
                    {formData.status === "1" ? "Currently Active" : "Currently Inactive"}
                  </option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              {["adds", "viewes", "updates", "deletes", "download", "upload", "approve", "pdf", "excel", "print", "email", "filters", "searchbar"].map((field, index) => (
                <div className="col-sm-1" key={index}>
                  <label>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                  <select
                    name={field}
                    className="form-control"
                    value={formData[field]}
                    onChange={handleChange}
                    required
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
              ))}
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-sm-2">
                <label>URL Link</label>
                <input
                  type="text"
                  name="link"
                  className="form-control"
                  value={formData.link}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-sm-2">
                <label>Icon</label>
                <input
                  type="text"
                  name="icon"
                  className="form-control"
                  value={formData.icon}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-sm-1">
                <label>Is Menu</label>
                <input
                  type="text"
                  name="menu"
                  className="form-control"
                  value={formData.menu}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-1">
                <label>Menu ID</label>
                <input
                  type="text"
                  name="menuid"
                  className="form-control"
                  value={formData.menuid}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-1">
                <label>Is Submenu</label>
                <input
                  type="text"
                  name="submenu"
                  className="form-control"
                  value={formData.submenu}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-1">
                <label>Sub Menu ID</label>
                <input
                  type="text"
                  name="submenuid"
                  className="form-control"
                  value={formData.submenuid}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-1">
                <label>Is Active</label>
                <select
                  name="active"
                  className="form-control"
                  value={formData.active}
                  onChange={handleChange}
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
            </div>
          </div>

          <button type="submit" className="btn btn-primary">
            Update Access Right
          </button>
        </form>
      </div>
    </div>
  );
};

export default Editaccsessroal;
