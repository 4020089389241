import axios from "axios";



export const getPendingJobs = async () => {
  try {
    const response = await axios.get("/dashboard/pending-jobs");
    console.log("Pending Jobs", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching jobs:", error.message);
    throw error;
  }
};

export const getAllJobs = async () => {
  try {
    const response = await axios.get("/dashboard/all-jobs");
    console.log(response.data, "All Jobs");
    return response.data;
  } catch (error) {
    console.error("Error fetching jobs:", error.message);
    throw error;
  }
};

export const getPendingPayments = async () => {
  try {
    const response = await axios.get("/dashboard/pending-payments");
    console.log(response.data, "total outstanding");
    return response.data;
  } catch (error) {
    console.error("Error fetching jobs:", error.message);
    throw error;
  }
};
export const getPaidPayments = async () => {
  try {
    const response = await axios.get("/dashboard/paid");
    console.log(response.data, "JOB JOB DATA API");
    return response.data;
  } catch (error) {
    console.error("Error fetching jobs:", error.message);
    throw error;
  }
};

export const getAllCounts = async () => {
  try {
    const response = await axios.get(
      `/dashboard/get-counts`
    );
    //console.log("All Counts:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching counts:", error.message);
    throw error;
  }
};

export const getMonthlyCounts = async (year) => {
  try {
    const response = await axios.get(
      `/dashboard/sales-purchase-counts?year=${year}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching montly purchase sales counts:",
      error.message
    );
    throw error;
  }
};

export const findEmployeeByUserId = async () => {

  const storedUserData = localStorage.getItem('user');
  const userID = JSON.parse(storedUserData).data._id
  try {
    const response = await axios.get(
      `/findEmployeeByUserId/${userID}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching montly purchase sales counts:",
      error.message
    );
    throw error;
  }
};

export const getNoOfEmployee = async (branchname) => {
  try {

    const response = await axios.get('/dashboard/all-employee-count', {
      params: {
          
          branch:branchname    
      }
  });
  console.log(response.data)
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching montly purchase sales counts:",
      error.message
    );
    throw error;
  }
};

// export const countLateAttendence = async (date) => {
//   try {
//     const response = await axios.get(
//       `http://${BASE_URL}:8000/api/countLateAttendence/${date}`
//     );
//     return response;
//   } catch (error) {
//     console.error(
//       "Error fetching montly purchase sales counts:",
//       error.message
//     );
//     throw error;
//   }
// };

// http://${BASE_URL}:8000/api/
export const countLateAttendence_and_Leaves = async (startDate, endDate, branchname) => {
  try {
    console.log(startDate,"START",endDate,"END")
    const response = await axios.get('/dashboard/count-employee-details', {
      params: {

        branch: branchname,
        dateFrom:startDate,
        dateTo:endDate

      }
    });
    
    return response;
  } catch (error) {
    console.error(
      "Error fetching montly purchase sales counts:",
      error.message
    );
    throw error;
  }
};


export const getLateAttendence = async (startDate, endDate, branchname) => {
  try {
    console.log(startDate,"START",endDate,"END")
    const response = await axios.get('/dashboard/late-attendance-count', {
      params: {

        branch: branchname,
        dateFrom:startDate,
        dateTo:endDate

      }
    });
    
    return response;
  } catch (error) {
    console.error(
      "Error fetching late attendance counts:",
      error.message
    );
    throw error;
  }
};
