import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from 'react-query';
import { createUnit,updateUnit } from '../../service/ApiServices';
import Swal from 'sweetalert2';
import { Spin } from 'antd';

const CreateUnitModal = ({ handleCancel, selectedUnit }) => {
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const queryClient = useQueryClient();

    useEffect(() => {
        if (selectedUnit) {
            setName(selectedUnit.name);
            setShortName(selectedUnit.variation);
        } else {
            setName('');
            setShortName('');
        }
    }, [selectedUnit]);

    const mutation = useMutation(
        selectedUnit ? (data) => updateUnit(selectedUnit._id, data) : createUnit,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('units');
                Swal.fire({
                    title: "Success",
                    text: selectedUnit ? "Unit updated successfully!" : "Unit created successfully!",
                    icon: "success",
                });
                handleCancel();
                setName('');
                setShortName('')
            },
            onError: (error) => {
                let errorMessage = "Failed to process request.";
                if (error.response) {
                    const { status, data } = error.response;
                    if (status === 400 && data.message) {
                        errorMessage = data.message;
                    } else if (status === 500) {
                        errorMessage = "Server error. Please try again later.";
                    }
                }
                Swal.fire({
                    title: "Error",
                    text: errorMessage,
                    icon: "error",
                });
            },
        }
    );

    const handleSave = () => {
        if (name.trim() === '' || shortName.trim() === '') {
            Swal.fire({
                title: "Warning",
                text: "Please fill in all the required fields.",
                icon: "warning",
            });
            return;
        }
        mutation.mutate({ name,variation:shortName });
    };

    return (
        <div className="modal-content">
            <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                    <h4>{selectedUnit ? "Edit Unit" : "Create Unit"}</h4>
                    <p className="mb-0">Fill in the required fields to {selectedUnit ? "edit" : "create"} a unit</p>
                </div>
            </div>
            <div className="modal-body custom-modal-body">
                <form>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="mb-3">
                                <label className="form-label">Unit Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Unit Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="mb-3">
                                <label className="form-label">Variation</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Short Name"
                                    value={shortName}
                                    onChange={(e) => setShortName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={() => {
                            handleCancel();
                            if (!selectedUnit) {
                                setName('');
                                setShortName('');
                            }

                        }}
                    >
                        Close
                    </button>
                    <div>
                        <button
                            type="button"
                            className="btn btn-reset me-2"
                            onClick={() =>{
                                setName('');
                                setShortName('');
                            }}
                        >
                            Reset
                        </button>
                        <button
                            type="button"
                            className="btn btn-submit ml-0"
                            onClick={handleSave}
                            disabled={mutation.isLoading}
                        >
                            {mutation.isLoading ? <Spin size='small' /> : 'Save'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

CreateUnitModal.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    selectedUnit: PropTypes.object,
};

export default CreateUnitModal;
