import React, { useState, useEffect } from 'react'
import { Popconfirm, Table ,Spin} from 'antd';
import { createDeductionType, editDeductioType, fetchDeductioTypeDetails, deleteDeductioType } from '../../service/deductionTypeService'
import { Link } from 'react-router-dom';

const DeductionType = () => {
    const [type, setType] = useState('');
    const [edit, isEdit] = useState(Boolean);
    const [dataSource, setDataSource] = useState();
    const [record, setRecord] = useState({});
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const fetchData = async () => {
        let data = await fetchDeductioTypeDetails({});
        setDataSource(data.data.deduction);
    };

    useEffect(() => {
        setLoading2(true)
        const handler = setTimeout(() => {
            fetchData();
            setLoading2(false)
        }, 400);
        return () => {
            clearTimeout(handler);
        };
    }, []);


    const editData = async (record) => {
        // console.log(record);
        setType(record.deducitionType);
        isEdit(true)
        setRecord(record);
    };

    const deleteData = async (record) => {
      
       await deleteDeductioType({id:record._id})
        setLoading2(true); 
        setTimeout(() => {
            fetchData();
            setLoading2(false);
        }, 300);

    };




    const columns = [
        {
            title: 'Deduction Type',
            dataIndex: 'deducitionType',
            key: 'deducitionType',
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (_, record) => (
                <div>
                    {record.is_active ? "Active" : "Disabled"}
                </div>
            )
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <td className="action-table-data">
                    <Link className="me-2 p-2" to="#"
                        onClick={() => {
                            editData(record)

                        }}
                    >
                        <i data-feather="download" className="feather-edit"></i>
                    </Link>
                    <Popconfirm
                        title="Delete the Deduction-Type"
                        description="Are you sure to delete this Deduction-Type?"
                        okText="Yes"
                        cancelText="No"

                        onConfirm={() => {
                            deleteData(record)
                        }}
                        onCancel={() => {
                            console.log("c")
                        }}

                    >
                        <Link className="confirm-text p-2" to="#"
                        >
                            <i data-feather="trash-2"
                                className="feather-trash-2"
                            ></i>
                        </Link>
                    </Popconfirm>
                </td>
            ),
        },
    ];


    const saveDeductionType =async () => {
        if (edit) {
            setLoading(true)
            await editDeductioType(record._id, { deducitionType: type })
            handleReset();
            setLoading(false)
            fetchData();
        } else {

            if (type === "") {
                alert("Please fill all fields")
                return
            }
            setLoading(true)
            await createDeductionType({ deducitionType: type })
            handleReset();
            setLoading(false)
            setLoading2(true);

            setTimeout(() => {
                fetchData();
                setLoading2(false);
            }, 400);
        }


    };
    const handleReset = () => {
        setType("")
    };

    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Deduction Type</h4>
                                <h6>Manage Deduction type</h6>
                            </div>
                        </div>

                    </div>

                    <div className="card table-list-card">

                        <div className="card-body">
                            <div className="row">

                                <div className="col-4">

                                    <div className="table-top">
                                        <div className="search-set">
                                            <div className="search-input">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Deduction Type"
                                                    value={type}
                                                    onChange={(e) => setType(e.target.value)}
                                                />
                                            </div>
                                            <div className="wordset">
                                                <button type="button" className="btn btn-primary me-2" onClick={() => { saveDeductionType() }}>
                                                {loading ? <Spin size='small' /> : "Save"}
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="table-responsive">
                                        <Table loading={loading2} columns={columns} rowKey="value" dataSource={dataSource} pagination={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeductionType
