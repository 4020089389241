import React, {useEffect, useState} from 'react'
//import Select from 'react-select'
import PropTypes from 'prop-types'
import {getCategory, updatesubCategory} from "../../service/ApiServices";


const EditSubcategories = ({record}) => {

    const [selectedCategory, setSelectedCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [status, setStatus] = useState(true);
    const [description, setDescription] = useState("");
    const [subcategoryName, setSubCategoryName] = useState(record);
    const [subcategoryList, setSubCategoryList] = useState([]);

    useEffect(() => {
        setSubCategory(record)

        const fetchData = async () => {
            try {
                const response = await getCategory();
                setSubCategoryList(response.category);
                console.log("________________________-", response);
            } catch (err) {
                console.error("Error", err);
            }
        };
        fetchData();
    }, [record])

console.log("UPDATING RECORD",record)
    const handleSubmit = async (e) => {
        e.preventDefault();
        const req ={
            subCategory : subcategoryName,
            category : selectedCategory,
            status : status,
            description : description
        }
        const response = updatesubCategory(subCategory._id, req);
        setSubCategory(response)

    }


    return (
        <div>
            {/* Edit Category */}
            <div className="modal fade" id="edit-category">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Edit Sub Category</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label className="form-label">Parent Category</label>
                                            <select defaultValue={subCategory?.category?._id} className="form-control" onChange={(e)=>setSelectedCategory(e.target.value)}>
                                                <option value="">Select Category</option>
                                                {subcategoryList.map((category) => (
                                                    <option key={category._id} value={category._id}>
                                                        {category.category}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Sub Category Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="subCategory"
                                                defaultValue={subCategory?.subCategory}
                                                // name="category"  
                                                onChange={(e) => setSubCategoryName(e.target.value)}
                                            />
                                        </div>

                                        <div className="mb-3 input-blocks">
                                            <label className="form-label">Description</label>
                                            <textarea
                                                className="form-control"
                                                name="description"
                                                value={subCategory?.description}
                                                // name="description"
                                                onChange={(e) => setDescription(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-0">
                                            <div
                                                className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                <span className="status-label">Status</span>
                                                <input
                                                    type="checkbox"
                                                    id="user3"
                                                    className="check"
                                                    defaultChecked="true"
                                                    onChange={(e)=>{setStatus(e.target.checked)}}
                                                />
                                                <label htmlFor="user3" className="checktoggle"/>
                                            </div>
                                        </div>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                data-bs-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <button className="btn btn-submit" onClick={handleSubmit}>   {" "} Save
                                                Changes
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Edit Category */}
        </div>
    )
}
EditSubcategories.propTypes = {
    record: PropTypes.object.isRequired,
};
export default EditSubcategories