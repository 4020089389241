import React, {useEffect, useState} from "react";
import {getCategory, addsubCategory} from "../../../service/ApiServices";
import Swal from "sweetalert2";
// import Select from 'react-select'
// import axios from 'axios'

const AddSubcategory = () => {
    const [category, setCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [status, setStatus] = useState(true);
    const [description, setDescription] = useState("");
    //const [image, setImage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getCategory();
                setCategory(response.category);
                console.log("________________________-", response);
            } catch (err) {
                console.error("Error", err);
            }
        };
        fetchData();
    }, []);

    const handleChange = (e) => {
        console.log(e.target.value);
        const c = category?.find((category) => category._id === e.target.value);
        console.log("Cat", c);
        setSelectedCategory(c);
        console.log("Selected Category", selectedCategory);
    };

    const handelSubmit = async (e) => {
        e.preventDefault();
        const body = {
            subCategory: subCategory,
            description: description,
            category: selectedCategory._id,
            status: status
        }
        try {
            let axiosResponse = await addsubCategory(body);
            console.log("axiosResponse", await axiosResponse)
            setSubCategory('');
            setStatus('');
            setDescription('');
            setSelectedCategory('');
            Swal.fire({
                title: "Good job!",
                text: "Sub Category Successfully Saved!",
                icon: "success"
            });
        } catch (error) {
            console.log("Error", error);
        }
    };

    return (
        <div>
            {/* Add Category */}
            <div className="modal fade" id="add-sub-category">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Create Sub Category</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form onSubmit={handelSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Parent Category</label>
                                            <select value={category} className="form-control" onChange={handleChange}>
                                                <option value="">Select Category</option>
                                                {category.map((category) => (
                                                    <option key={category._id} value={category._id}>
                                                        {category.category}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Category Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={subCategory}
                                                onChange={(e) => {
                                                    setSubCategory(e.target.value);
                                                }}
                                            />
                                        </div>

                                        <div className="mb-3 input-blocks">
                                            <label className="form-label">Description</label>
                                            <textarea
                                                className="form-control"
                                                value={description}
                                                onChange={(e) => {
                                                    setDescription(e.target.value);
                                                }}
                                            />
                                        </div>
                                        {/* <div className="image-upload mb-0">
                                                <input type="file" />
                                                <input
                                                    type="file"
                                                    onChange={(e) => setImage(e.target.files[0])}
                                                />
                                                <div className="image-uploads">
                                                    <h4>Change Image</h4>
                                                </div>
                                            </div> */}

                                        <div className="mb-0">
                                            <div
                                                className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                <span className="status-label">Status</span>
                                                <input
                                                    type="checkbox"
                                                    id="user2"
                                                    className="check"
                                                    defaultChecked="true"
                                                    onChange={(e) => setStatus(e.target.checked)}
                                                />
                                                <label htmlFor="user2" className="checktoggle"/>
                                            </div>
                                        </div>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                data-bs-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            {/* <Link to="#" className="btn btn-submit">
                                                Create Subcategory
                                            </Link> */}
                                            <button className="btn btn-submit" type="submit">
                                                Create Subcategory
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Category */}
        </div>
    );
};

export default AddSubcategory;
