import axios from "axios";

const API_URL = "http://localhost:8000/api/purchases";

const generateBatchNumber = async () => {
  try {
    const response = await axios.get(`${API_URL}/batch-number`);
    console.log("Batch number:", response.data.batchNumber);
    return response.data.batchNumber;
  } catch (error) {
    console.error("Error fetching purchases:", error);
    throw error;
  }
};
const getPurchases = async () => {
  try {
    const response = await axios.get(API_URL);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching purchases:", error);
    throw error;
  }
};

const getPurchaseById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/${id}`);

    return response.data;
  } catch (error) {
    console.error(`Error fetching purchase with id ${id}:`, error);
    throw error;
  }
};

const createPurchase = async (purchaseData) => {
  try {
    const response = await axios.post(API_URL, purchaseData);
    return response.data;
  } catch (error) {
    console.error("Error creating purchase:", error);
    throw error;
  }
};

const updatePurchase = async (id, purchaseData) => {
  try {
    const response = await axios.put(`${API_URL}/${id}`, purchaseData);
    return response.data;
  } catch (error) {
    console.error(`Error updating purchase with id ${id}:`, error);
    throw error;
  }
};

const deletePurchase = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting purchase with id ${id}:`, error);
    throw error;
  }
};
const fetchSuppliers = async () => {
  try {
    const response = await axios.get("http://localhost:8000/api/suppliers");
    return response.data;
  } catch (error) {
    console.error("Error fetching suppliers:", error);
    throw error;
  }
};

const fetchProducts = async () => {
  try {
    const response = await axios.get("http://localhost:8000/api/products");
    //console.log(response);
    return response;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

const fetchProductDetails = async (id) => {
  try {
    const response = await axios.get(
      `http://localhost:8000/api/products/${id}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching product details:", error);
    return {};
  }
};

const getStocks = async () => {
  try {
    const response = await axios.get("http://localhost:8000/api/stocks");
    return response.data;
  } catch (error) {
    console.error("Error fetching stocks data:", error);
    throw error;
  }
};

export {
  getPurchases,
  getPurchaseById,
  createPurchase,
  updatePurchase,
  deletePurchase,
  fetchSuppliers,
  fetchProducts,
  fetchProductDetails,
  getStocks,
  generateBatchNumber,
};
