import React, { useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../img/imagewithbasebath";
import Select from "react-select";
import { DatePicker } from "antd";

const AddSalesReturns = () => {
  const status = [
    { value: "Pending", label: "Pending" },
    { value: "Received", label: "Received" },
  ];

  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  return (
    <div>
      {/* add popup */}
      <div className="modal fade" id="add-sales-new">
        <div className="modal-dialog add-centered">
          <div className="modal-content">
            <div className="page-wrapper p-0 m-0">
              <div className="content p-0">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4> Add Sales Return</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="col-lg-12 col-sm-6 col-12">
                        <div className="input-blocks">
                          <label>Select Sale</label>
                          <div className="input-groupicon select-code">
                            <Select
                              type="text"
                              placeholder="Please select a sale to return"
                            />
                            <div className="addonset">
                              <ImageWithBasePath
                                src="assets/img/icons/qrcode-scan.svg"
                                alt="img"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Customer Name</label>
                            <div className="row">
                              <div className="col-lg-10 col-sm-10 col-10">
                                <input
                                  type="text"
                                  placeholder="Sale Should be selected"
                                  className="form-control"
                                  disabled="true"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Date</label>
                            <div className="input-groupicon calender-input">
                              <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                type="date"
                                className="filterdatepicker"
                                dateFormat="dd-MM-yyyy"
                                placeholder="Choose Date"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Reference No.</label>
                            <input 
                              type="text" 
                              className="form-control" 
                            />
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive no-pagination">
                        <table className="table  datanew">
                          <thead>
                            <tr>
                              <th>Select</th>
                              <th>Product</th>
                              <th>Batch No</th>
                              <th>Qty</th>
                              <th>Sold Price(LKR)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td> 
                                <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                </label>
                              </td>
                              <td> </td>
                              <td> </td>
                              <td> </td>
                              <td> </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="row mt-4">
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Status</label>
                            <Select
                              className="select"
                              options={status}
                              placeholder="Choose status"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12 text-end pt-4">
                          <div className="total-order w-100 max-widthauto m-auto mb-4">
                            <ul>
                              <li>
                                <h5>Return Amount Total :</h5>
                                <h5>LKR 0.00</h5>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="input-blocks">
                            <label>Remarks <span style={{ color: "red" }}>*</span></label>
                            <textarea 
                              className="form-control"
                              placeholder="Enter the reason for returning the product"    
                            />
                          </div>
                        </div>
                      </div>
                      
                      <div className="row">
                        <div className="col-lg-12 text-end">
                          <button
                            type="button"
                            className="btn btn-cancel add-cancel me-3"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <Link to="#" className="btn btn-submit add-sale">
                            Submit
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /add popup */}
    </div>
  );
};

export default AddSalesReturns;
