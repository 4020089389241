import axios from 'axios';

export const getAllDepartments = async ({ search }) => {
  try {
    const response = await axios.get('/departments', {
      params: { // Include user if available
        search: search || '' // Include search term if available
      }
    });
    return response.data.data; // Return the departments data
  } catch (error) {
    console.error('Error fetching departments:', error);
    throw error; // Re-throw the error to handle it where the function is called
  }
};