import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PlusCircle } from 'feather-icons-react/build/IconComponents';
import { Modal, Spin, Table, Input, Button, Popconfirm, Select, Divider, Space } from 'antd';
import axios from 'axios';
import {
    createInvoice,
    createPaymentMethod,
    deleteInvoiceMethod,
    getAllCustomers,
    getAllInvoiceMethod,
    getAllJobTypeMethod,
    getInvoiceNumMethod
} from '../../service/ApiServices';
import Swal from 'sweetalert2';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;
import dayjs from 'dayjs';
import CustomerModal from "../../core/modals/peoples/customerModal";
const SalesList = () => {
    const startOfCurrentMonth = dayjs().startOf('month');
    const endOfCurrentMonth = dayjs().endOf('month');

    const [selectedRange, setSelectedRange] = useState([
        startOfCurrentMonth,
        endOfCurrentMonth
    ]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [jobDate, setJobDate] = useState("");
    const [firstFitOn, setFirstFitOn] = useState("");
    const [customer, setCustomer] = useState("");
    const [customers, setCustomers] = useState([]);
    const [jobType, setJobType] = useState("");
    const [jobTypeOptions, setJobTypeOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [loading5, setLoading5] = useState(false);
    const [printModal, setPrintModal] = useState(false);
    const [qty, setQty] = useState(1);
    const [tableItems, setTableItems] = useState([]);
    const [discount, setDiscount] = useState(0);
    const [discountType, setDiscountType] = useState("fixed");
    const [invoiceNum, setInvoiceNum] = useState(0);
    const [isInvoiceCreated, setIsInvoiceCreated] = useState(false);
    const [search, setSearch] = useState("");
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [selectedInvoiceForEdit, setSelectedInvoiceForEdit] = useState(null);
    const [createPaymentData, setCreatePaymentData] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [payAmount, setPayAmount] = useState(1);
    const [paymentType, setPaymentType] = useState("");


    const [invoiceData, setInvoiceData] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0
    });

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedInvoice(null);
        setSelectedInvoiceForEdit(null);
        handleReset();
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk2 = () => {
        setIsModalOpen2(false);
    };

    const showModal2 = () => {
        setIsModalOpen2(true);
    };

    const handleCancel2 = () => {
        setIsModalOpen2(false);
        setCreatePaymentData(null);
        setPayAmount(1);
        setPaymentType("");
    };

    const deleteItem = (itemId) => {
        setTableItems(tableItems.filter(item => item.itemId !== itemId));
    };

    const getRateInvoiceNum = async () => {
        const data3 = await getInvoiceNumMethod();
        setInvoiceNum(data3.invoiceNumber);
    };

    const changeUnitPrice = (e, record) => {
        console.log("Price", e.target.value);
        console.log("Record", record);
        console.log("Table Items", tableItems)

        const newPrice = parseFloat(e.target.value) || 0; // Convert input value to a number
        const updatedDataSource = tableItems.map(item => {
            if (item.itemId === record.itemId) {
                // Update the price and recalculate the total price
                return { ...item, price: newPrice, totalPrice: newPrice * item.qty };
            }
            return item;
        });
        setTableItems(updatedDataSource); // Update the state with the new data

    };

//**PDF LINK URL ***//
    
    const handleGeneratePDF = async (invoiceData) => {
        
        try {
            const dueAmount = invoiceData?.payments?.length === 0 ? invoiceData.total_after_discount : calculateDueAmount(invoiceData.total_after_discount, invoiceData.payments.reduce((acc, item) => acc + item.amount, 0));
            const response = await axios.post('/generate-pdf', { invoiceData ,dueAmount});
            const pdfUrl = response.data.url;
    
            
            console.log(`PDF generated : ${pdfUrl}`);
            
    
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };
    
    const columns = [
        {
            title: 'Item Name',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Unit Price (Rs.)',
            dataIndex: 'price',
            key: 'price',
            align: "right",
            render: (text, record) => <Input defaultValue={text} onChange={(e) => changeUnitPrice(e, record)} />
        },
        {
            title: 'Total Price (Rs.)',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            align: "right",
            render: (text, record) => record.qty * record.price,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button type="link" className="text-danger text-right" disabled={selectedInvoice} onClick={() => deleteItem(record.itemId)}>
                    <i data-feather="trash-2" className="feather-delete"></i>
                </Button>
            ),
        },
    ];

    const invoiceColumns = [
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
        },
        {
            title: 'Customer Name',
            dataIndex: ['customer', 'customerName'],
            key: 'customer.customerName',
        },
        {
            title: 'Invoice Date',
            dataIndex: ['createdAt'],
            key: 'createdAt',
            render: (createdAt) => new Date(createdAt).toLocaleDateString()
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            render: (due_date) => new Date(due_date).toLocaleDateString()
        },
        {
            title: 'Total Amount Rs.',
            dataIndex: 'total',
            key: 'total_after_discount',
            align: 'right',
            render: (text) => text?.toFixed(2),
        },
        {
            title: 'Discount Amount Rs.',
            dataIndex: ['discount', 'amount'],
            key: 'total_after_discount',
            align: 'right',
            render: (text) => text?.toFixed(2),
        },
        {
            title: 'Total Payable Rs.',
            dataIndex: 'total_after_discount',
            key: 'total_after_discount',
            align: 'right',
            render: (text) => text?.toFixed(2),
        },
        {
            title: 'Paid Amount Rs.',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
            align: 'right',
            render: (text) => text?.toFixed(2),
        },
        {
            title: 'Balance Amount Rs.',
            dataIndex: 'balance',
            key: 'total_after_discount1',
            align: 'right',
            render: (text) => text?.toFixed(2),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                if (status == "paid") {
                    return <span className="badge bg-success">paid</span>
                } else if (status == "not-paid") {
                    return <span className="badge bg-danger">unpaid</span>
                } else {
                    return <span className="badge bg-warning">partialy paid</span>

                }
            }

        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <td className="action-table-data">
                      <Link className="me-2 p-2" to="#"
                        onClick={() => {
                        handleGeneratePDF(record)
                        }}
                    >
                        <i data-feather="show" className="feather-mail"></i>
                    </Link>
                    <Link className="me-2 p-2" to="#"
                        onClick={() => {
                            createPayment(record)
                        }}
                    >
                        <i data-feather="show" className="feather-dollar-sign"></i>
                    </Link>
                    <Link className="me-2 p-2" to="#"
                        onClick={() => {
                            downloadPDF(record);
                            console.log(record,"ReCORDDD")
                        }}
                    >
                        <i data-feather="show" className="feather-download"></i>
                    </Link>
                    <Link className="me-2 p-2" to="#"
                        onClick={() => {
                            setSelectedInvoice(record);
                            showModal();
                        }}
                    >
                        <i data-feather="download" className="feather-eye"></i>
                    </Link>
                    {
                        record.status === "not-paid" &&
                        <Link className="me-2 p-2" to="#"
                            onClick={() => {
                                setSelectedInvoiceForEdit(record);
                                showModal();
                            }}>
                            <i data-feather="download" className="feather-edit"></i>
                        </Link>
                    }
                    <Popconfirm
                        title="Delete the Invoice"
                        description="Are you sure to delete this Invoice?"
                        onConfirm={() => {

                            deleteInvoiceMethod({
                                invoiceId: record._id,
                                showSuccess,
                                setIsItemDeleted: setIsInvoiceCreated,
                            })
                        }}
                        onCancel={() => {
                            console.log("c")
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Link className="confirm-text p-2" to="#">
                            <i data-feather="trash-2"
                                className="feather-trash-2"

                            ></i>
                        </Link>
                    </Popconfirm>

                </td>
                // <Dropdown overlay={
                //     <Menu>
                //         <Menu.Item key="1" onClick={() => createPayment(record)}>
                //             <FaMoneyCheckAlt style={{marginRight: 8}}/>
                //             {record.status === "paid" ? "View" : "Create"} Payment
                //         </Menu.Item>
                //         <Menu.Item key="2" onClick={() => downloadPDF(record)}>
                //             <FaFilePdf style={{marginRight: 8}}/>
                //             Download PDF
                //         </Menu.Item>
                //         <Menu.Item key="3" onClick={() => {
                //             setSelectedInvoice(record);
                //             showModal();
                //         }}>
                //             <FaEye style={{marginRight: 8}}/>
                //             View Invoice
                //         </Menu.Item>
                //         {
                //             record.status === "not-pai" && <Menu.Item key="4" onClick={() => {
                //                 setSelectedInvoiceForEdit(record);
                //                 showModal();
                //             }}>
                //                 <FaEdit style={{marginRight: 8}}/>
                //                 Edit Invoice {record.status}
                //             </Menu.Item>
                //         }
                //         <Menu.Item key="5">
                //             <Popconfirm
                //                 title="Delete the task"
                //                 description="Are you sure to delete this task?"
                //                 okText="Yes"
                //                         cancelText="No"
                //                         onConfirm={() => {
                //                             deleteInvoiceMethod({
                //                                 invoiceId: record._id,
                //                                 showSuccess,
                //                                 setIsItemDeleted: setIsInvoiceCreated,
                //                             })
                //                         }}
                //                     >
                //                         <FaTrash style={{ marginRight: 8 }} />
                //                         Delete Invoice
                //                     </Popconfirm>
                //                 </Menu.Item>
                //             </Menu>
                //         } trigger={['click']}>
                //             <Button type="text" icon={<FaEllipsisV />} />
                //         </Dropdown>
            ),
        }
    ];

    const paymentColumns = [
        {
            title: 'Payment Date',
            dataIndex: 'payment_date',
            key: 'payment_date',
            render: (payment_date) => new Date(payment_date).toLocaleDateString()
        },
        {
            title: 'Payment Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Payment Type',
            dataIndex: 'payment_method',
            key: 'payment_method',
        },
    ]

    const calculateSubtotal = () => {
        return tableItems.reduce((acc, item) => acc + (item.price * item.qty), 0);
    };

    const calculateTotalAfterDiscount = (subtotal) => {
        if (discountType === "fixed") {
            return subtotal - discount;
        } else if (discountType === "percentage") {
            return subtotal - (subtotal * (discount / 100));
        }
        return subtotal;
    };

    const handleReset = () => {
        setTableItems([]);
        setJobDate("");
        setFirstFitOn("");

        setCustomer("");
        setJobType("");
        setQty("");
        setDiscount(0);
        setDiscountType("fixed");
    };

    const createPayment = (data) => {
        showModal2()
        setCreatePaymentData(data);
    };

    const getBase64FromUrl = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    function formatNumber(number) {
        return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    const downloadPDF = async (invoiceData) => {

        const doc = new jsPDF('p', 'mm', 'a4');
        const logoUrl = 'assets/img/logo/logo.jpeg';
        const dueAmount = invoiceData?.payments?.length === 0 ? invoiceData.total_after_discount : calculateDueAmount(invoiceData.total_after_discount, invoiceData.payments.reduce((acc, item) => acc + item.amount, 0));
        console.log("data==========", dueAmount)
        const pageWidth = doc.internal.pageSize.getWidth();
        const logoBase64 = await getBase64FromUrl(logoUrl);
        doc.addImage(logoBase64, 'PNG', 14, 10, 50, 50);

        // Company details x,y
        doc.setFontSize(20);
        doc.text("INVOICE", 155, 14);
        doc.setFontSize(16);
        doc.text("The Suit", 155, 20);
        doc.setFontSize(10);
        doc.text("335, Colombo Road", 155, 26);
        doc.text("Negombo, Gampaha 11500", 155, 32);
        doc.text("Sri Lanka", 155, 38);
        doc.text("Phone: 0314355515", 155, 44);
        doc.text("Mobile: 0774055515", 155, 50);

        doc.setDrawColor(182, 182, 181);
        doc.line(0, 65, pageWidth, 65);
        // // Invoice details
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(9);
        doc.text(`Invoice Number: ${invoiceData.invoice_number}`, 155, 70);
        doc.text(`Invoice Date: ${new Date(invoiceData.createdAt).toLocaleDateString()}`, 155, 76);
        doc.text(`First Fit On: ${new Date(invoiceData.fit_on_date).toLocaleDateString()}`, 155, 82);
        doc.text(`Due Date: ${new Date(invoiceData.due_date).toLocaleDateString()}`, 155, 88);
        doc.text(`Amount Due (LKR): ${dueAmount.toFixed(2)}`, 155, 94);

        // Customer details
        doc.setTextColor(182, 182, 181);
        doc.setFontSize(12);
        doc.text("BILL TO", 14, 70);
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(9);
        doc.text(invoiceData.customer.customerName, 14, 76);
        doc.text(invoiceData.customer.customerPhone, 14, 82);

        // Invoice items table
        const items = invoiceData.items.map(item => [
            item.itemDetails.type,
            item.quantity,
            "Rs " + formatNumber(item.itemDetails.price),
            "Rs " + formatNumber((item.quantity * item.itemDetails.price)),
        ]);

        doc.autoTable({
            startY: 100,
            head: [['Items', 'Quantity', 'Price', 'Amount']],
            body: items,
            theme: 'grid',
            styles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
            headStyles: { fillColor: [74, 74, 73], textColor: [255, 255, 255] },
            footStyles: { fillColor: [74, 74, 73], textColor: [255, 255, 255] },
            columnStyles: {
                0: { cellWidth: 70 },
                1: { cellWidth: 30 },
                2: { cellWidth: 40 },
                3: { cellWidth: 40 },
            },
        });

        // Total section
        doc.setFont('helvetica', 'normal');
        const finalY = doc.previousAutoTable.finalY + 10;
        doc.text("Subtotal: ", 130, finalY);
        doc.text(`Rs ${formatNumber(invoiceData.total)}`, 170, finalY);
        doc.setDrawColor(182, 182, 181);
        doc.line(100, finalY + 2, 190, finalY + 2);
        doc.text("Total: ", 130, finalY + 10);
        doc.text(`Rs ${formatNumber(invoiceData.total)}`, 170, finalY + 10);
        doc.text("Discount: ", 130, finalY + 20);
        doc.text(`${invoiceData.discount.amount} (${invoiceData.discount.type === "fixed" ? "LKR" : "%"})`, 170, finalY + 20);
        doc.text("Total After Discount: ", 130, finalY + 30);
        doc.text(`Rs ${formatNumber(invoiceData.total_after_discount)}`, 170, finalY + 30);

        // Payment section
        invoiceData.payments.forEach((payment, index) => {
            const label = (invoiceData.payments.length >= 2 && index === 0) ? " (Advance)" : "";
            doc.text(`Payment${label} on ${new Date(payment.payment_date).toLocaleDateString()} using ${payment.payment_method} : Rs ${formatNumber(payment.amount)}`, 14, finalY + 40 + index * 6);
        });

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(12);
        doc.text("Amount Due: ", 130, finalY + 40 + invoiceData.payments.length * 6);
        doc.text(`Rs ${formatNumber(dueAmount)}`, 170, finalY + 40 + invoiceData.payments.length * 6);

        doc.setDrawColor(182, 182, 181);
        doc.line(0, finalY + 50 + invoiceData.payments.length * 6, pageWidth, finalY + 50 + invoiceData.payments.length * 6);
        // Notes / Terms
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8)
        const notes = [
            "* 50% of the total bill should be paid as advance to commence the production.",
            "* The balance payment should be paid on the second fit-on date.",
            "* Any changes in the appointment dates should be notified a few days prior for rescheduling.",
            "* Calling-in to book the time of visit is much preferred.",
            "* All items are non refundable under any circumstances.",
            "* The Suit will not be accountable for any uncollected orders that exceed 60 days from the mentioned delivery date.",
        ];

        doc.text("Notes / Terms", 14, finalY + 70);
        notes.forEach((note, index) => {
            doc.text(note, 14, finalY + 76 + index * 6);
        });

        // Save the PDF
        // doc.autoPrint(`invoice_${invoiceData.invoice_number}.pdf`);
        const pdfBlob = doc.output('blob');

        // Create a URL for the blob
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl)
        setPrintModal(true)

        // Set the URL in the state to render the preview
        // setPdfUrl(pdfUrl);
        // const iframe = document.createElement('iframe');
        // iframe.style.position = 'absolute';
        // iframe.style.width = '0px';
        // iframe.style.height = '0px';
        // iframe.src = pdfUrl;
        // document.body.appendChild(iframe);
        // iframe.contentWindow.focus();
        // iframe.contentWindow.print();
        // document.body.removeChild(iframe);
    };
  
 

    const handlePrintDirectly = () => {
        if (!pdfUrl) return;

        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.width = '0px';
        iframe.style.height = '0px';
        iframe.style.border = 'none';
        iframe.src = pdfUrl;
        document.body.appendChild(iframe);
        iframe.onload = () => {
            iframe.contentWindow.focus();
            iframe.contentWindow.print();
            document.body.removeChild(iframe);
        };
    };

    const handleSubmit = () => {
        if (!jobDate) {
            showWarning("Please select a date");
            return;
        }
        if (!customer) {
            showWarning("Please select a customer");
            return;
        }
        if (tableItems.length === 0) {
            showWarning("Please add items to the table");
            return;
        }
        const submitData = {
            id: selectedInvoiceForEdit ? selectedInvoiceForEdit._id : null,
            jobDate,
            customer,
            items: tableItems,
            discount,
            discountType,
            invoiceNum,
            firstFitOn: firstFitOn,
            subtotal: calculateSubtotal(),
            totalAfterDiscount: calculateTotalAfterDiscount(calculateSubtotal())
        };
        createInvoice({ data: submitData, setLoading: setLoading3, handleCancel, setIsInvoiceCreated, showSuccess });
    };

    const showSuccess = (msg) => {
        return Swal.fire({
            title: "Good job!",
            text: msg,
            icon: "success"
        });
    };

    const showWarning = (str) => {
        return Swal.fire({
            title: "Error",
            text: str,
            icon: "error",
        });
    }

    const handleTableChange = (pagination) => {
        getAllInvoiceMethod({
            setInvoiceData,
            setLoading: setLoading4,
            params: { page: pagination.current, limit: pagination.pageSize },
            search,
            startDate: selectedRange[0].format("YYYY-MM-DD"),
            endDate: selectedRange[1].format("YYYY-MM-DD")
        });
    };

    const setData = (data) => {
        setInvoiceNum(data.invoice_number)
        setJobDate(new Date(data.due_date).toLocaleDateString('en-CA'));
        setCustomer(data.customer._id);
        setTableItems(data.items.map(item => ({
            itemId: item.itemDetails._id,
            label: item.itemDetails.type,
            qty: item.quantity,
            price: item.itemDetails.price
        }))
        );
        setFirstFitOn(new Date(data.fit_on_date).toLocaleDateString('en-CA'))
        setDiscount(data.discount.amount);
        setDiscountType(data.discount.type);
    }

    const calculateDueAmount = (total, paid) => {
        return total - paid - parseFloat(createPaymentData?.payments.reduce((acc, item) => acc + item.amount, 0) || 0);
    };

    const onChange = (dates) => {
        if (dates === null) {
            setSelectedRange([startOfCurrentMonth, endOfCurrentMonth])
            return
        }
        setSelectedRange(dates);
    };

    useEffect(() => {
        const initialCalls = async () => {
            const data = await getAllCustomers({ setLoading: setLoading2 });
            const data2 = await getAllJobTypeMethod({ search: "", setLoading: setLoading });
            setJobTypeOptions(data2);
            setCustomers(data.data.data.customers);
        };
        initialCalls();
    }, []);

    useEffect(() => {
        const handler = setTimeout(() => {
            getAllInvoiceMethod({
                setInvoiceData,
                setLoading: setLoading4,
                params: { page: invoiceData.page, limit: invoiceData.limit },
                search,
                startDate: selectedRange[0].format("YYYY-MM-DD"),
                endDate: selectedRange[1].format("YYYY-MM-DD")
            });
        }, 600);

        return () => {
            clearTimeout(handler);
        };
    }, [search, selectedRange]);

    useEffect(() => {
        if (isInvoiceCreated) {
            getAllInvoiceMethod({
                setInvoiceData,
                setLoading: setLoading4,
                params: { page: invoiceData.page, limit: invoiceData.limit },
                search,
                startDate: selectedRange[0].format("YYYY-MM-DD"),
                endDate: selectedRange[1].format("YYYY-MM-DD")
            });
        }
    }, [isInvoiceCreated]);

    useEffect(() => {
        console.log("tttitem", tableItems)
        if (tableItems.length === 0) {
            setDiscount(0);
            setDiscountType("fixed");
        }
    }, [tableItems])

    useEffect(() => {
        if (selectedInvoice) {
            setData(selectedInvoice);
        }
        if (selectedInvoiceForEdit) {
            setData(selectedInvoiceForEdit);
        }
    }, [selectedInvoice, selectedInvoiceForEdit]);

    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Invoice List</h4>
                                <h6>Manage Your Invoices</h6>
                            </div>
                        </div>
                        <div className="page-btn">
                            <Link className="btn btn-added" onClick={() => {
                                showModal();
                                getRateInvoiceNum();
                            }}>
                                <PlusCircle className="me-2" />
                                Add New Invoice
                            </Link>
                        </div>
                    </div>
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="search"
                                            className="form-control"
                                            placeholder="Search"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                        <Link className="btn btn-searchset">
                                            <i data-feather="search" className="feather-search" />
                                        </Link>
                                    </div>
                                    <RangePicker onChange={onChange} value={selectedRange} format="YYYY-MM-DD" />

                                </div>

                            </div>
                            <div className="table-responsive">
                                <Table
                                    columns={invoiceColumns}
                                    dataSource={invoiceData.data}
                                    rowKey="_id"
                                    pagination={{
                                        current: invoiceData.page,
                                        pageSize: invoiceData.limit,
                                        total: invoiceData.total
                                    }}
                                    loading={loading4}
                                    onChange={handleTableChange}

                                />
                            </div>
                            <Modal
                                title="PDF Preview"
                                open={printModal}
                                zIndex={100000}
                                okText="Print"
                                onOk={handlePrintDirectly}
                                onCancel={() => setPrintModal(false)}
                            >
                                {pdfUrl && (
                                    <>
                                        <iframe src={pdfUrl} width="100%" height="500px" title="PDF Preview"></iframe>

                                    </>
                                )}
                            </Modal>
                        </div>
                    </div>

                </div>
            </div>
            {/* Modal for view,edit and create invoice */}
            <Modal
                zIndex={1001}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                closable={false}
            >
                <div className="modal-dialog add-centered">
                    <div className="modal-content">
                        <div className="page-wrapper p-0 m-0">
                            <div className="content p-0">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4> {selectedInvoice ? "View" : selectedInvoiceForEdit ? "Edit" : "Add"} Invoice</h4>
                                        <label>Fill all fields to create an Invoice</label>
                                    </div>
                                </div>

                                <div className="row pl-4 pr-4 pt-2">
                                    <div className="col-lg-4 col-sm-6 col-12">
                                        <div className="input-blocks">
                                            <label>Invoice No</label>
                                            <input
                                                type="text"
                                                disabled
                                                className="form-control"
                                                value={invoiceNum}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 col-12">
                                        <div className="input-blocks">
                                            <label>First Fit On</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={firstFitOn}
                                                onChange={(e) => setFirstFitOn(e.target.value)}
                                                disabled={selectedInvoice}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 col-12">
                                        <div className="input-blocks">
                                            <label>Delivery Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={jobDate}
                                                onChange={(e) => setJobDate(e.target.value)}
                                                disabled={selectedInvoice}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-sm-6 col-12">
                                        {
                                            loading2 ? <Spin size='small' /> : <div className="input-blocks">
                                                <div className="add-newplus">
                                                    <label className="form-label" >Customer</label>
                                                    <Link
                                                        to="#"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#add-units"
                                                    >
                                                        <PlusCircle className="plus-down-add" />
                                                        <span>Add New</span>
                                                    </Link>
                                                </div>

                                                <Select
                                                    showSearch
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    disabled={selectedInvoice}
                                                    size={"large"}
                                                    onChange={(e) => setCustomer(e)}
                                                    placeholder="Search to Select"
                                                    optionFilterProp="label"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    value={customer}
                                                    options={(customers || []).map((d) => ({
                                                        value: d._id,
                                                        label: d.customerName,
                                                    }))}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <Divider />
                                <div className="row pl-4 pr-4" >
                                    <div className="col-lg-6 col-sm-6 col-12">
                                        {
                                            loading ? <Spin size='small' /> : <div className="input-blocks">
                                                <label>Item</label>
                                                <select
                                                    value={jobType}
                                                    onChange={(e) => setJobType(e.target.value)}
                                                    className="form-control"
                                                    disabled={selectedInvoice}
                                                >
                                                    <option value={""}>Select a JobType</option>
                                                    {jobTypeOptions && jobTypeOptions.map((type, index) => (
                                                        <option key={index} value={type.value}>{type.label}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-lg-6 col-sm-6 col-12">
                                        <div className="input-blocks">
                                            <label>Qty</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={qty}
                                                disabled={selectedInvoice}
                                                onChange={(e) => {

                                                    if (e.target.value < 0 || e.target.value == 0) {
                                                        showWarning("Qty cannot be negative or zero");
                                                        return;
                                                    }
                                                    setQty(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <Button className="btn btn-primary pl-4 pr-4"
                                        disabled={
                                            !jobType || !qty
                                        }
                                        onClick={() => {
                                            const isTableItemExist = tableItems.find(item => item.itemId === jobType);
                                            if (isTableItemExist) {
                                                showWarning("Item already exists in the table");
                                                setQty("");
                                                setJobType("");
                                                return;
                                            }
                                            setTableItems([...tableItems, {
                                                itemId: jobType,
                                                label: jobTypeOptions.find(type => type.value === jobType).label,
                                                qty: qty,
                                                price: jobTypeOptions.find(type => type.value === jobType).price
                                            }]);
                                            setQty("");
                                            setJobType("");
                                        }}
                                    >Add To Table</Button>
                                </div>
                                <div className="table-responsive">
                                    <Table columns={columns} dataSource={tableItems} rowKey="itemId" />
                                </div>
                                <div style={{ marginTop: '20px' }}>
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <label>Discount Type</label>
                                                <select

                                                    value={discountType}
                                                    onChange={(e) => {
                                                        if (e.target.value == "percentage" && discount > 100) {
                                                            showWarning("Discount cannot be greater than 100%");
                                                            return;
                                                        }
                                                        setDiscountType(e.target.value);
                                                    }}
                                                    className="form-control"
                                                    disabled={tableItems.length === 0 || selectedInvoice}
                                                >
                                                    <option value={"fixed"}>Fixed</option>
                                                    <option value={"percentage"}>Percentage</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <label>Discount</label>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    value={discount}
                                                    disabled={tableItems.length === 0 || selectedInvoice}
                                                    onChange={(e) => {
                                                        if (discountType === "percentage" && e.target.value > 100) {
                                                            showWarning("Discount cannot be greater than 100%");
                                                            return;
                                                        } else if (discountType === "percentage" && e.target.value < 0) {
                                                            showWarning("Discount cannot be negative");
                                                            return;
                                                        } else if (discountType === "fixed" && e.target.value < 0) {
                                                            showWarning("Discount cannot be negative");
                                                            return;
                                                        } else if (discountType === "fixed" && e.target.value > calculateSubtotal()) {
                                                            showWarning("Discount cannot be greater than Subtotal");
                                                            return;
                                                        } else if (e.target.value == "") {
                                                            setDiscount(0);
                                                        } else {
                                                            setDiscount(e.target.value)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <label>Sub Total (Rs.)</label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    value={calculateSubtotal()}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <label>Total Payable (Rs.)</label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    value={calculateTotalAfterDiscount(calculateSubtotal())}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        selectedInvoice && <div className="row mt-3">
                                            <div className="col-lg-6 col-sm-6 col-12">
                                                <div className="input-blocks">
                                                    <label>Paid Amount</label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        value={selectedInvoice?.paidAmount ? selectedInvoice?.paidAmount : 0}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 col-12">
                                                <div className="input-blocks">
                                                    <label>Balance</label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        value={selectedInvoice?.balance}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}>
                                    <div>

                                        <button
                                            type="button"
                                            className="btn btn-cancel"
                                            onClick={() => {
                                                handleCancel();
                                                setSelectedInvoice(null);
                                                setSelectedInvoiceForEdit(null);
                                            }}
                                        >
                                            Close
                                        </button>
                                    </div>
                                    <Space>
                                        {
                                            !selectedInvoice && <>
                                                <button
                                                    type="button"
                                                    className="btn btn-reset me-2"
                                                    onClick={() => {
                                                        handleReset()
                                                    }}
                                                >
                                                    Reset
                                                </button>
                                                <button className="btn btn-submit" onClick={() => {
                                                    handleSubmit();
                                                }}>
                                                    {loading3 ? <Spin size='small' /> : "Save"}
                                                </button>
                                            </>
                                        }

                                    </Space>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* Modal for payment */}
            <Modal
                zIndex={200000}
                open={isModalOpen2}
                onOk={handleOk2}
                onCancel={handleCancel2}
                footer={null}
                width={"50vw"}
                maskClosable={false}
                closable={false}
            >
                <div className="modal-dialog add-centered">
                    <div className="modal-content">
                        <div className="page-wrapper p-0 m-0">
                            <div className="content p-0">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>{createPaymentData?.status !== "paid" ? "Create" : "View"} Payment</h4>
                                        <label>Fill all fields to create a payment</label>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div>
                                            <Table columns={paymentColumns} dataSource={createPaymentData?.payments} rowKey="_id" />
                                        </div>
                                        {
                                            createPaymentData?.status !== "paid" ? <div style={{
                                                border: "1px solid black",
                                                padding: "10px", margin: "10px 0px",
                                                borderRadius: "10px"
                                            }}>
                                                <div className="row" >
                                                    <div className="col-lg-4 col-sm-6 col-12">
                                                        <div className="input-blocks">
                                                            <label>Invoice No</label>
                                                            <input
                                                                type="text"
                                                                disabled
                                                                className="form-control"
                                                                value={createPaymentData ? createPaymentData.invoice_number : ""}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-6 col-12">
                                                        <div className="input-blocks">
                                                            <label>Due Date</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                value={createPaymentData ? createPaymentData.due_date.split("T")[0] : ""}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-6 col-12">
                                                        {
                                                            loading2 ? <Spin size='small' /> : <div className="input-blocks">
                                                                <label>Customer</label>
                                                                <select
                                                                    value={createPaymentData ? createPaymentData.customer._id : ""}
                                                                    className="form-control"
                                                                    disabled
                                                                >
                                                                    <option value={""}>Select a Customer</option>
                                                                    {customers && customers.map((customer, index) => (
                                                                        <option key={index} value={customer._id}>{customer.customerName}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row" >
                                                    <div className="col-lg-6 col-sm-6 col-12">
                                                        <div className="input-blocks">
                                                            <label>Payment Amount</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={payAmount}
                                                                onChange={(e) => {
                                                                    if (e.target.value < 0 || e.target.value == 0) {
                                                                        showWarning("Payment Amount cannot be negative or zero");
                                                                        return;
                                                                    } else if (e.target.value === "") {
                                                                        setPayAmount(1);
                                                                    } else if (createPaymentData.total_after_discount - parseFloat(createPaymentData?.payments.reduce((acc, item) => acc + item.amount, 0) || 0) < e.target.value) {
                                                                        showWarning("Payment Amount cannot be greater than Due Amount");
                                                                        return;
                                                                    }
                                                                    setPayAmount(e.target.value);
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6 col-12">
                                                        <div className="input-blocks">
                                                            <label>Payment Type</label>
                                                            <select
                                                                value={paymentType}
                                                                className="form-control"
                                                                onChange={(e) => setPaymentType(e.target.value)}
                                                            >
                                                                <option value={""}>Select a Payment Type</option>
                                                                <option value={"cash"}>Cash</option>
                                                                <option value={"credit"}>Credit</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" >
                                                    <div className="col-lg-4 col-sm-4 col-12">
                                                        <div className="input-blocks">
                                                            <label>SubTotal Amount</label>
                                                            <input
                                                                disabled
                                                                type="number"
                                                                className="form-control"
                                                                value={createPaymentData ? createPaymentData.total_after_discount : 0}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-12">
                                                        <div className="input-blocks">
                                                            <label>Due Amount</label>
                                                            <input
                                                                disabled
                                                                type="number"
                                                                className="form-control"
                                                                value={calculateDueAmount(createPaymentData?.total_after_discount || 0, payAmount)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-12">
                                                        <div className="input-blocks">
                                                            <label>Paid Amount</label>
                                                            <input
                                                                disabled
                                                                type="number"
                                                                className="form-control"
                                                                value={createPaymentData ?
                                                                    createPaymentData.payments.reduce((acc, item) => acc + item.amount, 0)
                                                                    : 0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ""
                                        }
                                    </div>

                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }} >
                                    {
                                        createPaymentData?.status !== "paid" ?
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-cancel me-2"
                                                    onClick={() => {
                                                        setPayAmount(0);
                                                        setPaymentType("");
                                                    }}
                                                >
                                                    Reset
                                                </button>
                                                <button className="btn btn-submit"
                                                    onClick={() => {
                                                        if (!payAmount) {
                                                            showWarning("Please enter payment amount");
                                                            return;
                                                        }
                                                        if (!paymentType) {
                                                            showWarning("Please select payment type");
                                                            return;
                                                        }
                                                        const submitData = {
                                                            invoiceId: createPaymentData._id,
                                                            paymentAmount: payAmount,
                                                            paymentType,
                                                            dueAmount: calculateDueAmount(createPaymentData?.total_after_discount || 0, payAmount),
                                                        };
                                                        createPaymentMethod({
                                                            data: submitData,
                                                            setLoading: setLoading5,
                                                            handleCancel: handleCancel2,
                                                            showSuccess,
                                                            setIsPaymentCreated: setIsInvoiceCreated,
                                                        });
                                                    }}
                                                >
                                                    {loading5 ? <Spin size='small' /> : "Save"}
                                                </button>
                                            </div> : ""
                                    }
                                    <button
                                        type="button"
                                        className="btn btn-cancel"
                                        onClick={handleCancel2}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <CustomerModal loadCustomers={getAllCustomers} />
        </div>
    );
};

export default SalesList;
