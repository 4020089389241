import React, { useEffect, useState } from "react";
//import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import withReactContent from "sweetalert2-react-content";
import { API_BASE_URL } from "../../../src/environment";
import {
  getPurchases,
  deletePurchase,
} from "../../../src/service/purchasesApiServices";
import AddPurchases from "../../core/modals/purchases/addpurchases";
import EditPurchases from "../../core/modals/purchases/editpurchases";

import { PlusCircle } from "feather-icons-react"; // Ensure correct import for PlusCircle icon

const PurchaseList = () => {
  const [purchases, setPurchases] = useState([]);
  const [updatePurchase, setUpdatePurchase] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const deletePurchases = async (record) => {
    try {
      await deletePurchase(record._id);
      const updatedPurchases = purchases.filter(
        (purchase) => purchase?._id !== record?._id
      );
      setPurchases(updatedPurchases);
    } catch (error) {
      console.error("Error deleting purchase:", error);
    }
  };

  const storedUserData = localStorage.getItem("user");
  const userData = JSON.parse(storedUserData);
  const id = userData?.data?.uid; //
  const [userRightssub, setUserRightssub] = useState([]);
  useEffect(() => {
    const fetchUserRightssub = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/userright/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user rights");
        }
        const data = await response.json();
        console.log("Fetched User Sub Data:", data.data);
        setUserRightssub(data.data || []); // Ensure it's always an array
      } catch (error) {
        console.error("Error fetching data:", error);
        setUserRightssub([]); // Set to empty array in case of error
      }
    };
  
    fetchUserRightssub(); // Fetch the data on component mount
  }, [id]);
  const hasJobRightWithAdd = userRightssub.some(right => right.right === 'Purchases' && right.adds === 1);
  const hasJobRightWithedit = userRightssub.some(right => right.right === 'Purchases' && right.updates === 1);
 // const hasJobRightWithview = userRightssub.some(right => right.right === 'Purchases' && right.viewes === 1);  
  const hasJobRightWithedel = userRightssub.some(right => right.right === 'Purchases' && right.deletes=== 1); 
    











  const fetchPurchases = async () => {
    try {
      const purchasesData = await getPurchases();
      const formattedData = purchasesData.map((purchase) => ({
        ...purchase,
        supplierName: purchase.supplier?.supplierName, // Update this based on how supplier name is stored
        purchaseDate: new Date(purchase.purchaseDate).toLocaleDateString(),
        products: purchase.products.map((product) => ({
          productName: product.productName || "N/A",
          purchasePrice: product.purchasePrice || "N/A",
          sellingPrice: product.sellingPrice || "N/A",
          quantity: product.quantity || "N/A",
          totalCost: product.totalCost || "N/A",
        })),
      }));
      //console.log(updatePurchase);
      setPurchases(formattedData);
    } catch (error) {
      console.error("Error fetching purchases:", error);
    }
  };

  useEffect(() => {
    fetchPurchases();
  }, []);

  const handleUpdatePurchase = (record) => {
    console.log("Selected Purchase Record:", record);
    setUpdatePurchase(record);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredPurchases = purchases.filter((purchase) =>
    purchase.supplierName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      sorter: (a, b) => a.supplierName.localeCompare(b.supplierName),
    },
    {
      title: "Date",
      dataIndex: "purchaseDate",
      sorter: (a, b) => new Date(a.purchaseDate) - new Date(b.purchaseDate),
    },
    {
      title: "Order Tax",
      dataIndex: "orderTax",
      sorter: (a, b) => a.orderTax - b.orderTax,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      sorter: (a, b) => a.discount - b.discount,
    },
    {
      title: "Shipping",
      dataIndex: "shipping",
      sorter: (a, b) => parseFloat(a.shipping) - parseFloat(b.shipping),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <span className="badge badge-linesuccess">
          <Link to="#"> {text}</Link>
        </span>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Products",
      dataIndex: "products",
      render: (products) => (
        <div>
          {products.map((prod, idx) => (
            <option key={idx} value={prod.productName}>
              {prod.productName}
            </option>
          ))}

          <hr />
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
          {hasJobRightWithedit && (
                <Link
                href="#"
                className="me-2 p-2"
                data-bs-toggle="modal"
                data-bs-target="#edit-units"
                onClick={() => handleUpdatePurchase(record)}
              >
                <i data-feather="edit" className="feather-edit"></i>
              </Link>
          )}
          {hasJobRightWithedel && (
              <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => showConfirmationAlert(record)}
               >
              <i data-feather="trash-2" className="feather-trash-2"></i>
               </Link>
          )}
          </div>
        </td>
      ),
    },
  ];

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (record) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
        deletePurchases(record);
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Purchase List</h4>
                <h6>Manage your purchases</h6>
              </div>
            </div>
            <div className="page-btn">
            {hasJobRightWithAdd && (
                <Link
                  to="#"
                  className="btn btn-added"
                  data-bs-toggle="modal"
                  data-bs-target="#add-units"
                >
                  <PlusCircle className="me-2" />
                  Add New Purchase
                </Link>
            )}

             
            </div>
          </div>
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table columns={columns} dataSource={filteredPurchases} />
              </div>
            </div>
          </div>
        </div>
        <AddPurchases />
        {updatePurchase && <EditPurchases purchaseId={updatePurchase._id} />}
      </div>
    </div>
  );
};

export default PurchaseList;
