import React, {useState, useEffect} from 'react'

import {Select, Popconfirm, Table, Input, DatePicker, Spin} from 'antd';

import {fetchDeductioTypeDetails} from '../../service/deductionTypeService'
import {createDeduction, fetchDeductioDetails, editDeduction, deleteDeduction,fetchSearchDeduction} from '../../service/deductionService'
import BulkUploadModal from "../../feature-module/hrm/deductionBulkUpload"
import dayjs from 'dayjs';
import {Link} from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import {getAllActiveEmployees} from "../../service/employeeService";
import moment from "moment";
import Swal from "sweetalert2";

const Deduction = () => {

    const [deductionTypeList, setDeductionTypeList] = useState();
    const [deductionList, setDeductionList] = useState([]);
    const [selectedEmp, setSelectedEmp] = useState();
    const [empList, setEmpList] = useState([]);
    const [type, setType] = useState();
    const [amount, setAmount] = useState();
    const [description, setDescription] = useState();
    const [paymentTyep, setPaymentType] = useState("oneTime");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [paymentPeriod, setPaymentPeriod] = useState();
    const [installement, setInstallement] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [record, setRecord] = useState(false);
    const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
    const [epf_number, setEpf] = useState("")
    const [deductionType, setdeductionType] = useState("")
    const [searchDate, setSearchDate] = useState(null);
    const [name, setName] = useState("")

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const handleSearchDateChange = (date, dateString) => {
        setSearchDate(dateString);
        if(searchDate){
            console.log(searchDate)
        }
    };

    const fetchTypeData = async () => {
        let data = await fetchDeductioTypeDetails({});
        setDeductionTypeList(data.data.deduction);
    };

    const fetchData = async () => {
        let data = await fetchDeductioDetails({});
        setDeductionList(data.data.deduction);
        let empList = await getAllActiveEmployees();
        setEmpList(empList.data)
    };

    useEffect(() => {
        setLoading2(true)
        const handler = setTimeout(() => {
            fetchTypeData();
            fetchData();
            setLoading2(false)
        }, 400);
        return () => {
            clearTimeout(handler);
        };
    }, []);

    const editData = (record) => {
        setIsEdit(true);
        // console.log("edit",record)
        setType(record.parentId._id);
        setAmount(record.amount);
        setDescription(record.description);
        setPaymentType(record.paymentType);
        setStartDate(dayjs(record.startDate));
        setEndDate(dayjs(record.endDate));
        setPaymentPeriod(record.paymentPeriod);
        setInstallement(record.installement);
        setRecord(record);

        setSelectedEmp(record.employee._id)

    }

    const deleteData = async (record) => {
        await deleteDeduction({id: record._id})
        setLoading2(true);

        setTimeout(() => {
            fetchData();
            setLoading2(false);
        }, 400);
    };
    const getSeachDeductions =async ( ) => {
        const records = await fetchSearchDeduction({
            setLoading2,
            epf_number,
            name,
            deductionType,
            date:searchDate
        });
        console.log(records,"FETCHED DATA")
        if (records) {
           setDeductionList(records)
           
        } else {
           setDeductionList([])  // Ensure fallback to an empty array
        }
        //console.log(deductionList,"this is record")
    }


    const columns = [

        {
            title: 'Employee',
            dataIndex: 'employee',
            key: 'employee',
            render: (employee) =>
                employee
                    ? `${employee.employee_id} - ${employee.first_name} ${employee.last_name}`
                    : 'No Employee Info',
        },
        {
            title: 'Deduction',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) =>
                amount !== null && amount !== undefined
                    ? `Rs. ${amount.toFixed(2)}`
                    : 'Rs. 0.00', // Default if the amount is null or undefined
        },
        {
            title: 'Deduction Type',
            dataIndex: 'paymentType',
            key: 'paymentType',
            render: (paymentTyep) => paymentTyep === 'oneTime' ?
                <span className="badge bg-primary">One Time</span> : <span className="badge bg-success">Recurring</span>
        },
        {
            title: 'Reason',
            dataIndex: 'parentId',
            key: 'parentId',
            render: (parentId) =>
                parentId
                    ? `${parentId.deducitionType}`
                    : 'No Deduction Info',
        },
        {
            title: 'Effective Date',
            dataIndex: 'deduction_date',
            key: 'deduction_date',
        },

        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (startDate) => moment(startDate).format("YYYY-MM-DD")
        },

        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (endDate) => moment(endDate).format("YYYY-MM-DD")
        },

        {
            title: 'Payment Terms',
            dataIndex: 'paymentPeriod',
            key: 'paymentPeriod',
        },

        {
            title: 'Installment',
            dataIndex: 'installement',
            key: 'installement',
            render: (installement) =>
                installement !== null && installement !== undefined
                    ? `Rs. ${installement.toFixed(2)}`
                    : 'Rs. 0.00', // Default if the amount is null or undefined
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <td className="action-table-data">
                    <Link className="me-2 p-2" to="#"
                          onClick={() => {
                              editData(record)

                          }}
                    >
                        <i data-feather="download" className="feather-edit"></i>
                    </Link>
                    <Popconfirm
                        title="Delete the Department"
                        description="Are you sure to delete this Department?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => {
                            deleteData(record)
                        }}
                        onCancel={() => {
                            console.log("c")
                        }}
                    >
                        <Link className="confirm-text p-2" to="#">
                            <i data-feather="trash-2"
                               className="feather-trash-2"
                            ></i>
                        </Link>
                    </Popconfirm>
                </td>
            ),
        },
    ];

    const handleDateStart = (date) => {
        setStartDate(date)
    };

    const handleDateEnd = (date) => {

        setEndDate(date)
    };

    const handleSave = async () => {
        if (!selectedEmp || !type || !amount) {
            Swal.fire("Error", "Please fill in all fields", "error");
            return;
        }
        if (isEdit) {
            let obj = {
                employee: selectedEmp,
                parentId: type,
                amount: amount,
                description: description,
                paymentPeriod: paymentPeriod,
                installement: installement,
                paymentType: paymentTyep,
                startDate: startDate,
                endDate: endDate
            }
            setLoading(true)
            await editDeduction(record._id, obj);
            setLoading(false)
            handleReset();
        } else {

            let obj = {
                employee: selectedEmp,
                parentId: type,
                amount: amount,
                description: description,
                paymentPeriod: paymentPeriod,
                installement: installement,
                paymentType: paymentTyep,
                startDate: startDate,
                endDate: endDate
            }
            setLoading(true)
            await createDeduction(obj);
            setLoading(false)
            handleReset();
        }
        setLoading2(true);

        setTimeout(() => {
            fetchData();
            setLoading2(false);
        }, 500);

    }
    const handleReset = () => {
        setType(null);
        setAmount();
        setDescription();
        setPaymentType("oneTime");
        setStartDate();
        setEndDate();
        setPaymentPeriod();
        setInstallement();
        setIsEdit(false)

    };


    const setTypeAsTyep = (value) => {

        setType(value);
    }


    // const optionsWithDisabled = [
    //     { label: 'Reoccurring', value: 'Reoccurring' },
    //     { label: 'One Time', value: 'One Time' },
    // ];
    //
    const onchangePaymentType = (e) => {
        //console.log("Selected Value",e.target.value)
        setPaymentType(e.target.value);
    };

    //console.log("EMP LIST",empList)
    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Deduction</h4>
                                <h6>Manage Deduction</h6>
                            </div>
                        </div>
                        <div className="page-btn">
                            {

                                <a className="btn btn-added" onClick={() => setIsBulkUploadOpen(true)}>
                                    {/* <PlusCircle className="me-2" /> */}
                                    Bulk upload
                                </a>
                            }


                        </div>
                    </div>
                    <div className="card table-list-card">
                    <div className="card-body" style={{ maxWidth: "100%", marginRight: "20px" }}>
                        <div className="container mt-3 " style={{ maxWidth: "100%", marginLeft: "20px", marginRight: "40px" }}> {/* Added p-0 and maxWidth */}
                            <div className="row d-flex flex-wrap align-items-start justify-content-between"> {/* Added justify-content-start */}
                                <div className="col-12 col-md-3  text-start">
                                    <label className="form-label">Employee Name</label>
                                    <input
                                        type='text'
                                        value={name}
                                        className="form-control"
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div className="col-12 col-md-3 mb-3 text-start">
                                    <label className="form-label">EPF No</label>
                                    <input
                                        className="form-control"
                                        type='text'
                                        value={epf_number}
                                        onChange={(e) => setEpf(e.target.value)}
                                        style={{ width: "100%" }}
                                    />
                                </div>

                                <div className="col-12 col-md-3 mb-3 text-start">
                                    <label className="form-label">Deduction Type</label>
                                    <input
                                        className="form-control"
                                        type='text'
                                        value={deductionType}
                                        onChange={(e) => setdeductionType(e.target.value)}
                                        style={{ width: "100%" }}
                                    />
                                   
                                    </div>
                                <div className="col-12 col-md-3 mb-3 text-start">
                                    <label className="form-label">Date</label>
                                        <DatePicker
                                            picker="month"
                                            //defaultValue={dayjs()}
                                            format="YYYY-MM" // Display the year and month in the desired format
                                            style={{ width: "100%", height: "38px" }}
                                            onChange={handleSearchDateChange}
                                            placeholder="Select Date"
                                        />
                                    
                                </div>



                            </div>
                        </div>

                        <div className="container mt-0" style={{ maxWidth: "100%", marginLeft: "20px", marginRight: "40px" }}>
                            <div className="row d-flex align-items-center justify-content-start">

                                {/* Center Select */}
                                {/* <div className="col-12 col-md-2 mb-3 text-start">
                                    <label className="form-label">Year</label>
                                    <div className="d-flex align-items-center">
                                    <DatePicker
                                        picker="year"
                                        onChange={handleSearchYearChange}
                                        //defaultValue={dayjs(`${currentYear}`, 'YYYY')}
                                        placeholder="Select Year"
                                        size="large"
                                        style={{ width: "100%", borderRadius: 8 }}
                                        allowClear
                                    />
                                    </div>
                                </div> */}
                                

                                {/* Search Button */}
                                {/* <div className="col-12 col-md-2 mb-3 text-start">
                                    <label className="form-label" style={{ visibility: "hidden" }}>Hidden Label</label>
                                    <div className="d-flex align-items-center">
                                        <button
                                            className="btn btn-submit"
                                            onClick={handleSearch}
                                            style={{ height: "38px", width: "55%" }}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div> */}
                                 <div className="col-12 col-md-2 mb-3 text-start">
                                   
                                    <button
                                            className="btn btn-submit"
                                             onClick={getSeachDeductions}
                                            style={{ height: "38px", width: "55%" }}
                                        >
                                            Submit
                                        </button>
                                    
                                </div>

                            </div>
                        </div>



                    </div>
                </div>


                    <div className="row">

                        <div className="col-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="input-blocks">
                                        <label htmlFor="jobTypeName">Employee</label>
                                        <Select
                                            showSearch
                                            style={{
                                                width: '100%',
                                            }}
                                            size={"large"}
                                            onChange={(e) => setSelectedEmp(e)}
                                            placeholder="Search to Select"
                                            optionFilterProp="label"
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            value={selectedEmp}
                                            options={(empList || []).map((d) => ({
                                                value: d._id,
                                                label: d.first_name + " " + d.last_name,
                                            }))}

                                        />
                                    </div>
                                    <div className="input-blocks">
                                        <label htmlFor="jobTypeName">Deduction Type</label>
                                        <Select
                                            size="large"
                                            name="type"
                                            className="select"
                                            options={deductionTypeList?.map((val) =>
                                                ({

                                                    value: val._id,
                                                    label: val.deducitionType
                                                })
                                            )}
                                            value={type}
                                            onChange={(e) => setTypeAsTyep(e)}
                                            placeholder="Choose"
                                            style={{width: '100%'}}
                                        />
                                    </div>
                                    <div className="input-blocks">
                                        <label className="form-label">Amount (Rs.)</label>
                                        <Input
                                            size="large"
                                            placeholder="00.00"
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                            style={{width: '100%'}}
                                        />
                                    </div>
                                    <div className="input-blocks">
                                        <label className="form-label">Description (Optional)</label>
                                        <TextArea
                                            size="large"
                                            placeholder="Desription"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            style={{width: '100%'}}

                                        />
                                    </div>
                                    <div className="input-blocks">
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="inlineRadioOptions"
                                                id="inlineRadio1"
                                                defaultValue="oneTime"
                                                defaultChecked
                                                onChange={(e) => onchangePaymentType(e)}
                                            />

                                            <label
                                                className="form-check-label"
                                                htmlFor="inlineRadio1"
                                            >
                                                One Time
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="inlineRadioOptions"
                                                id="inlineRadio1"
                                                defaultValue="recurring"
                                                onChange={onchangePaymentType}
                                            />

                                            <label
                                                className="form-check-label"
                                                htmlFor="inlineRadio1"
                                            >
                                                Recurring
                                            </label>
                                        </div>
                                    </div>
                                    {paymentTyep === "recurring" &&
                                        <>
                                            <div className="input-blocks">
                                                <label className="form-label">Start Date</label>
                                                <DatePicker
                                                    size="large"
                                                    value={startDate}
                                                    placeholder="Start Date"
                                                    onChange={handleDateStart}
                                                    style={{width: '100%'}}
                                                />
                                            </div>
                                            <div className="input-blocks">
                                                <label className="form-label">End Date</label>
                                                <DatePicker
                                                    size="large"
                                                    placeholder="End Date"
                                                    value={endDate}
                                                    onChange={handleDateEnd}
                                                    style={{width: '100%'}}
                                                />
                                            </div>
                                            <div className="input-blocks">
                                                <label className="form-label">Payment Period</label>
                                                <Input
                                                    size="large"
                                                    placeholder="Payment period"
                                                    value={paymentPeriod}
                                                    onChange={(e) => setPaymentPeriod(e.target.value)}
                                                    style={{width: '100%'}}
                                                />
                                            </div>
                                            <div className="input-blocks">
                                                <label className="form-label">Installment Amount (Rs.)</label>
                                                <Input
                                                    size="large"
                                                    placeholder="Installment"
                                                    onChange={(e) => setInstallement(e.target.value)}
                                                    value={installement}
                                                    style={{width: '100%'}}
                                                />
                                            </div>
                                        </>

                                    }


                                </div>
                                <button type="button" className="btn btn-primary me-22" onClick={handleSave}>
                                    {loading ? <Spin size='small'/> : "Save"}
                                </button>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="table-responsive">
                                <Table loading={loading2} columns={columns} rowKey="value" dataSource={deductionList}
                                />
                            </div>
                        </div>
                    </div>


                </div>
            </div>
           
                <BulkUploadModal
                    handleCancel={() => setIsBulkUploadOpen(false)}
                    isVisible={isBulkUploadOpen}
                    onUploadData={fetchData}
                />
            
        </div>
    );
};

export default Deduction
