import axios from "axios";
import { BASE_URL } from "../environment";

//Get all Invoices

export const GetInvoices = async () => {
  try {
    const response = await axios.get(
      `http://${BASE_URL}:8000/api/salesReport/AllInvoices`
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
  return GetInvoices;
};

//Create report
export const CreateReport = async (custId, invoiceId, Status) => {
  const response = await axios.post(
    `http://${BASE_URL}:8000/api/salesReport/CreateReport`,
    { custId, invoiceId, Status }
  );
  return response.data;
};
//Get all sales Reports
export const GetSalesReports = async () => {
  try {
    const response = await axios.get(
      `http://localhost:8000/api/salesReport/AllReports`
    );
    return response.data.data || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};
