import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../core/img/imagewithbasebath'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ChevronUp, Layout, RotateCcw,  StopCircle } from 'feather-icons-react/build/IconComponents';
import Select from 'react-select';
import Filter from 'feather-icons-react/build/IconComponents/Filter';
import { DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../core/pagination/datatable'
import { setToogleHeader } from '../../core/redux/action';
import Swal from "sweetalert2";
//import { parse, differenceInMinutes } from 'date-fns';

import { getEmployeeAttendance,CreateEmployeeAttendance, updateEmployeeAttendance, getEmployeeAttendanceCounts } from "../../service/employeeAllocationApiService"

const AttendanceEmployee = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [searchQuery, setSearchQuery] = useState('');  // Search attendance
    const [attendanceData,setAttendanceData] = useState([]);    //Fetch attendance data
    const [currentTime, setCurrentTime] = useState('');
    const [todayAttendance, setTodayAttendance] = useState(null);

    const [attendanceCounts, setAttendanceCounts] = useState({
        totalWorkingDays: 0,
        absentDays: 0,
        leaveDays: 0,
      });

      const [isClockInDisabled, setIsClockInDisabled] = useState(false);  
      const [isClockOutDisabled, setIsClockOutDisabled] = useState(true);
      
    const dispatch = useDispatch();
    const data = useSelector((state) => state.toggle_header);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const toggleFilterVisibility = () => {
        setIsFilterVisible((prevVisibility) => !prevVisibility);
    };

    
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value); // Update the searchQuery state
    };


    const [isLayoutVisible, setIsLayoutVisible] = useState(false);
    const handleLayoutClick = () => {
        setIsLayoutVisible(!isLayoutVisible);
    };


    useEffect(() => {
            fetchEmployeeAttendance();
            fetchAttendanceCounts();
            updateCurrentTime();
        const timer = setInterval(updateCurrentTime, 1000); // Update time every second

        return () => clearInterval(timer); // Clear timer on component unmount
        }, []);
      
        const fetchEmployeeAttendance = async () => {
            try {
                // Get userId 
                const storedUserData = localStorage.getItem('user');
                const user = JSON.parse(storedUserData);
                if (!user || !user.data || !user.data._id) {
                    console.error("User data not found in local storage");
                    return;
                }
                const userId = user.data._id;
        
                // Pass userId as a parameter to getEmployeeAttendance function
                const employeeAttendance = await getEmployeeAttendance(userId);
                setAttendanceData(employeeAttendance.data); 
        
                // Find today's attendance record
                const today = new Date().toLocaleDateString();
                const todaysAttendance = employeeAttendance.data.find(attendance =>
                    new Date(attendance.clockIn).toLocaleDateString() === today
                );
        
                // Set today's attendance
                setTodayAttendance(todaysAttendance);
                setAttendanceCounts(attendanceCounts);

                console.log(todaysAttendance);
            } catch (error) {
                console.log("Error fetching employee attendance:", error.message);
            }
        };
        const updateCurrentTime = () => {
            const current = new Date();
            const hours = current.getHours().toString().padStart(2, '0');
            const minutes = current.getMinutes().toString().padStart(2, '0');
            const seconds = current.getSeconds().toString().padStart(2, '0');
            setCurrentTime(`${hours}:${minutes}:${seconds}`);
        };     

          // Helper function to format total hours into "x hours x minutes x seconds"
    const formatTotalHours = (totalHours) => {
        if (!totalHours) return "-";

        const [hours, minutes, seconds] = totalHours.split(':').map(Number);
        const hourLabel = hours === 1 ? "hour" : "hours";
        const minuteLabel = minutes === 1 ? "minute" : "minutes";
        const secondLabel = seconds === 1 ? "second" : "seconds";

        return `${hours} ${hourLabel} ${minutes} ${minuteLabel} ${seconds} ${secondLabel}`;
    };

    const fetchAttendanceCounts = async () => {
        try {
          const storedUserData = localStorage.getItem('user');
          if (!storedUserData) {
            console.error("User data not found in local storage.");
            return;
          }
          const user = JSON.parse(storedUserData);
      
          // Get current month and year
          const currentDate = new Date();
          const month = currentDate.getMonth() + 1; // getMonth() returns 0-based index, so +1 for actual month
          const year = currentDate.getFullYear();
      
          // Fetch the attendance counts from the API
          const response = await getEmployeeAttendanceCounts(user.data._id, month, year);
      
          // Check if the response is successful and contains data
          if (response.status === 'success' && response.data) {
            // Update state with the fetched data
            setAttendanceCounts({
              totalWorkingDays: 31, // Assuming it's constant for now, you can fetch dynamically if needed
              absentDays: response.data.absentDays || 0, // Default to 0 if not available
              leaveDays: response.data.leaveDays || 0,   // Default to 0 if not available
            });
          }
        } catch (error) {
          console.log("Error fetching Attendance counts:", error.message);
        }
      };
      
    // Define the columns for the Table component
    const columns = [
        
        {
            title: 'Date',
            key: 'date',
            render: (attendance) => <span>{new Date(attendance.clockIn).toLocaleDateString()}</span>,
        },
        {
            title: 'Clock In',
            dataIndex: 'clockIn',
            key: 'clockIn',
            render: (clockIn) => <span>{new Date(clockIn).toLocaleTimeString()}</span>,
        },
        {
            title: 'Clock Out',
            dataIndex: 'clockOut',
            key: 'clockOut', 
            render: (clockOut) => (clockOut ? <span>{new Date(clockOut).toLocaleTimeString()}</span> : '-'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <span className={`badge ${status === 'active' ? 'badge-success' : 'badge-danger'}`}>
                    {status === 'active' ? 'Active' : 'Inactive'}
                </span>
            ),
        },
        {
            title: 'Total Hours',
            dataIndex: 'totalHours',
            key: 'totalHours',
            render: (totalHours) => (totalHours? <span>{formatTotalHours(totalHours)}</span> : "-"),
        },
    ];

    // Filter by user name
    const filteredData = Array.isArray(attendanceData) 
  ? attendanceData.filter((attendance) =>
      attendance.user?.name?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  : [];

    const dataSource = filteredData.map((attendance) => ({
        key: attendance._id,
        user: attendance.user,
        clockIn: attendance.clockIn,
        clockOut: attendance.clockOut,
        status: attendance.status,
        totalHours:attendance.totalHours,
        currentDate: attendance.currentDate,
    }));

    const renderTooltip = (props) => (
        <Tooltip id="pdf-tooltip" {...props}>
            Pdf
        </Tooltip>
    );
    const renderExcelTooltip = (props) => (
        <Tooltip id="excel-tooltip" {...props}>
            Excel
        </Tooltip>
    );
    const renderPrinterTooltip = (props) => (
        <Tooltip id="printer-tooltip" {...props}>
            Printer
        </Tooltip>
    );


    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );
    const renderCollapseTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Collapse
        </Tooltip>
    );
    

  //POST request  
  const handleSubmit = async (e)=>{
    e.preventDefault();
     // Get user data from localStorage
   // Get user data from localStorage
   const storedUserData = localStorage.getItem('user');
   if (!storedUserData) {
       Swal.fire({
           icon: "error",
           title: "Error",
           text: "User data not found in local storage.",
       });
       return;
   }

   const user = JSON.parse(storedUserData);
   
   // Check if the user data contains the required userId
   if (!user?.data?._id || !user?.data?.name) {
       Swal.fire({
           icon: "error",
           title: "Error",
           text: "User ID and name are required!",
       });
       return;
   }
    const attendanceData = {
        clockIn:new Date(),
        status:"active",
        user: {
            _id: user.data._id,  // Send user ID
            name: user.data.name, // Send user name
        },
    };
    try {
        const response =await CreateEmployeeAttendance(attendanceData);

        if(response.status ==="success"){
            Swal.fire({
                icon: "success",
                title: "ClockIn data submitted",
                text: "ClockIn data has been submitted successfully!",
              })
              setIsClockInDisabled(true); // Disable Clock In 
              setIsClockOutDisabled(false); // Enable Clock Out
              fetchEmployeeAttendance();
        }else{
          
            Swal.fire({
            icon: "error",
            title: "Error",
            text:"Failed to submit ClockIn data:" || response.message,
          });
        }
        
    } catch (error) {
        console.error("Failed to ClockIn data:", error);
        Swal.fire({
            icon: "error",
            title: "Error",
            text: error.response.data.message,
          });
    }
  }
 
  //PUT request
  const handleUpdate = async () => {
    if (!todayAttendance || !todayAttendance._id) {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "No attendance record found for today.",
        });
        return;
    }

    try {
        const storedUserData = localStorage.getItem('user');
        const user = JSON.parse(storedUserData);
        const userId = user.data._id;
        const date = new Date().toISOString().split('T')[0]; // Use today's date
        const clockOutTime = new Date(); // Current time for clockOut
        const response = await updateEmployeeAttendance(userId, date, { clockOut: clockOutTime });

        if (response.status === "success") {
            Swal.fire({
                icon: "success",
                title: "ClockOut data updated",
                text: "ClockOut data has been updated successfully!",
            });
            setIsClockOutDisabled(true); // Disable Clock Out
            fetchEmployeeAttendance(); // Refresh attendance data
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to submit ClockOut data:" || response.message,
            });
        }
    } catch (error) {
        console.error("Failed to update ClockOut data:", error);
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to update ClockOut data. Please try again.",
        });
    }
};



    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="attendance-header">
                        <div className="attendance-content">
                            <ImageWithBasePath
                                src="./assets/img/icons/hand01.svg"
                                className="hand-img"
                                alt="img"
                            />
                            <h3>
                                Good Morning, <span>User</span>
                            </h3>
                        </div>
                        <div>
                            <ul className="table-top-head employe">
                                <li>
                                    <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>

                                        <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                            <RotateCcw />
                                        </Link>
                                    </OverlayTrigger>
                                </li>
                                <li>
                                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>

                                    <Link
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        id="collapse-header"
                                        className={data ? "active" : ""}
                                        onClick={() => {dispatch(setToogleHeader(!data)) }}
                                    >
                                        <ChevronUp />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            </ul>
                        </div>
                    </div>
                    <div className="attendance-widget">
                        <div className="row">
                            <div className="col-xl-4 col-lg-12 col-md-4 d-flex">
                                <div className="card w-100">
                                    <div className="card-body">
                                        <h5>
                                            Attendance<span>{new Date().toLocaleDateString()}</span>
                                        </h5>
                                        <div className="card attendance">
                                            <div>
                                                <ImageWithBasePath src="./assets/img/icons/time-big.svg" alt="time-img" />
                                            </div>
                                            <div>
                                                <h2>{currentTime}</h2>
                                                <p>Current Time</p>
                                            </div>
                                        </div>
                                        <div className="modal-attendance-btn flex-column flex-lg-row">
                                        <Link to="#" className={`btn btn-submit w-100 ${isClockInDisabled ? 'disabled' : ''}`} 
                                        onClick={handleSubmit} disabled={isClockInDisabled} 
                                        style={{ backgroundColor: isClockInDisabled ? 'grey' : '', cursor: isClockInDisabled ? 'not-allowed' : 'pointer' }}>
    
                                                Clock In
                                            </Link>
                                            <Link to="#" className={`btn btn-cancel me-2 w-100 ${isClockOutDisabled ? 'disabled' : ''}`} 
                                            onClick={handleUpdate} disabled={isClockOutDisabled} 
                                            style={{ backgroundColor: isClockOutDisabled ? 'grey' : '', cursor: isClockOutDisabled ? 'not-allowed' : 'pointer' }}>
                                                Clock Out
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-12 col-md-8 d-flex">
                                <div className="card w-100">
                                    <div className="card-body">
                                        <h5>Days Overview This Month</h5>
                                        <ul className="widget-attend">
                                            <li className="box-attend">
                                                <div className="warming-card">
                                                    <h4>31</h4>
                                                    <h6>Total Working Days</h6>
                                                </div>
                                            </li>
                                            <li className="box-attend">
                                                <div className="danger-card">
                                                    <h4>{attendanceCounts.absentDays}</h4>
                                                    <h6>Abesent Days</h6>
                                                </div>
                                            </li>
                                            <li className="box-attend">
                                                <div className="light-card">
                                                    <h4>{attendanceCounts.leaveDays}</h4>
                                                    <h6>Leave Days</h6>
                                                </div>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Attendance</h4>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                    <Link>
                                        <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <ImageWithBasePath src="assets/img/icons/excel.svg" alt="img" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>

                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <i data-feather="printer" className="feather-printer" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                        </ul>
                    </div>
                    {/* /product list */}
                    <div className="card table-list-card">
                        <div className="card-body pb-0">
                            <div className="table-top">
                                <div className="input-blocks search-set mb-0">
                                    <div className="search-set">
                                        <div className="search-input">
                                        <input 
                                            type="search" 
                                            className="form-control form-control-sm formsearch" 
                                            value={searchQuery}
                                            onChange={handleSearchInputChange}
                                        />
                                            <Link to className="btn btn-searchset">
                                                <i data-feather="search" className="feather-search" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="search-path">
                                    <div className="d-flex align-items-center">
                                        <Link className="btn btn-filter" id="filter_search">
                                            <Filter className="filter-icon" onClick={toggleFilterVisibility}
                                            />
                                            <span>
                                                <ImageWithBasePath src="assets/img/icons/closes.svg" alt="img" />
                                            </span>
                                        </Link>
                                        <div className={`layout-hide-box ${isLayoutVisible ? 'layout-show-box' : 'layout-hide-box'}`}>
                                            <Link to="#" className="me-3 layout-box" onClick={handleLayoutClick}>
                                                <Layout />
                                            </Link>
                                            
                                        </div>
                                    </div>
                                </div>

                                
                            </div>
                            {/* /Filter */}
                            <div
                                className={`card${isFilterVisible ? " visible" : ""}`}
                                id="filter_inputs"
                                style={{ display: isFilterVisible ? "block" : "none" }}
                            >
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-lg-4 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <i data-feather="calendar" className="info-img" />
                                                <div className="input-groupicon">
                                                    <DatePicker
                                                        selected={selectedDate}
                                                        onChange={handleDateChange}
                                                        type="date"
                                                        className="filterdatepicker"
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholder='Choose Date'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <i data-feather="stop-circle" className="info-img" />
                                                <StopCircle className="info-img" />

                                                <Select
                                                    className="select"
                                                    options={status}
                                                    placeholder="Newest"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-6 col-12 ms-auto">
                                            <div className="input-blocks">
                                                <Link className="btn btn-filters ms-auto">
                                                    {" "}
                                                    <i data-feather="search" className="feather-search" />{" "}
                                                    Search{" "}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                             {/* Attendance Table */}
                             <div className="table-responsive">
                                <Table className="table datanew" columns={columns} dataSource={dataSource} pagination={false} />
                            </div>
                                {/* <Table columns={columns} dataSource={dataSource} /> */}
                            </div>
                        </div>
                    </div>
                    {/* /product list */}
                </div>
            </div>

   
    )
}

export default AttendanceEmployee
