import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createExpenseCategory, getExpenseCategory, updateExpenseCategory } from "../../../service/ApiServices";

const ExpenseCategoryModal = ({onExpenseCategoryAdded, expenseCategoryId}) => {
  const [formData, setFormData] = useState({
    expenseCategoryName: "",
    expenseCategoryDescription: "",

  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const setSelectedExpenseCategory = async (expenseCategoryId) => {
    try {
      const response = await getExpenseCategory(expenseCategoryId);
      if (response.data) {
        const { expenseCategoryName, expenseCategoryDescription, } = response.data.data.expenseCategory;
        setFormData({
          ...formData,
          expenseCategoryName,
          expenseCategoryDescription,
        });  
      } else {
        console.error("Failed to fetch expenseCategory data");
      }
    } catch (error) {
      console.error("Error fetching expenseCategory data:", error);
    }
  };

  useEffect(() => {
    if (expenseCategoryId) {
        setSelectedExpenseCategory(expenseCategoryId);
    }
  }, [expenseCategoryId]);

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateExpenseCategory(expenseCategoryId, formData); 
      onExpenseCategoryAdded();
      console.log("expenseCategory updated successfully:", response);
      setFormData({
        // Clear form data if needed
        expenseCategoryName: "",
        expenseCategoryDescription: "",
      });
    } catch (error) {
      console.error("Error updating expenseCategory:", error);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await createExpenseCategory(formData);
        onExpenseCategoryAdded();
        console.log("expenseCategory added successfully:", response.data);
      setFormData({
        expenseCategoryName: "",
        expenseCategoryDescription: "",
      });
    } catch (error) {
      console.error("Error adding expenseCategory:", error);
    }
  };

  return (
    <div>
    {/* Add Expense Category*/}
    <div className="modal fade" id="add-units">
    <div className="modal-dialog modal-dialog-centered custom-modal-two">
      <div className="modal-content">
        <div className="page-wrapper-new p-0">
          <div className="content">
            <div className="modal-header border-0 custom-modal-header">
              <div className="page-title">
                <h4>Add Expense Category</h4>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body custom-modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label className="form-label">Expense Name</label>
                      {/* <input type="text" className="form-control" /> */}
                      <input
                        type="text"
                        className="form-control"
                        name="expenseCategoryName"
                        value={formData.expenseCategoryName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  {/* Editor */}
                  <div className="col-md-12">
                    <div className="edit-add card">
                      <div className="edit-add">
                        <label className="form-label">Description</label>
                      </div>
                      <div className="card-body-list input-blocks mb-0">
                      <textarea
                        className="form-control mb-1"
                        name="expenseCategoryDescription"
                        value={formData.expenseCategoryDescription}
                        onChange={handleChange}
                        required
                      />
                      </div>
                      <p>Maximum 600 Characters</p>
                    </div>
                  </div>
                  {/* /Editor */}
                </div>
                <div className="modal-footer-btn">
                  <button
                    type="button"
                    className="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit"
                        className="btn btn-submit"
                        data-bs-dismiss="modal">
                        Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* /Add Expense Category*/}

  {/* Edit Expense Category*/}
  <div className="modal fade" id="edit-units">
    <div className="modal-dialog modal-dialog-centered custom-modal-two">
      <div className="modal-content">
        <div className="page-wrapper-new p-0">
          <div className="content">
            <div className="modal-header border-0 custom-modal-header">
              <div className="page-title">
                <h4>Edit Expense Category</h4>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body custom-modal-body">
            <form onSubmit={handleUpdateSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label className="form-label">Expense Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="expenseCategoryName"
                        value={formData.expenseCategoryName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/* Editor */}
                  <div className="col-md-12">
                    <div className="edit-add card">
                      <div className="edit-add">
                        <label className="form-label">Description</label>
                      </div>
                      <div className="card-body-list input-blocks mb-0">
                      <textarea
                        className="form-control mb-1"
                        name="expenseCategoryDescription"
                        value={formData.expenseCategoryDescription}
                        onChange={handleChange}
                      />
                      </div>
                      <p>Maximum 600 Characters</p>
                    </div>
                  </div>
                  {/* /Editor */}
                </div>
                <div className="modal-footer-btn">
                  <button
                    type="button"
                    className="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit"
                        className="btn btn-submit"
                        data-bs-dismiss="modal">
                        Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* /Edit Expense */}
  </div>
  );
};
ExpenseCategoryModal.propTypes = {
  onExpenseCategoryAdded: PropTypes.func.isRequired,
  expenseCategoryId: PropTypes.string.isRequired,
};

export default ExpenseCategoryModal;
