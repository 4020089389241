import axios from "axios";
import { BASE_URL } from "../environment";

export const getPaymentsReport = async () => {
  try {
    const response = await axios.get(
      `http://${BASE_URL}:8000/api/payment/getAll`
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
  return getPaymentsReport;
};
