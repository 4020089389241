import axios from "axios";
import { BASE_URL } from "../environment";

export const CreateEmployeeAttendance = async (attendanceData) => {
  try {
    const response = await axios.post(
      `http://${BASE_URL}:8000/api/employee-attendance`,
      {
        user: attendanceData.user,
        clockIn: attendanceData.clockIn,
        status: attendanceData.status,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to create employee attendance:", error.message);
    throw error;
  }
};

export const getEmployeeAttendance = async (userId) => {
  try {
    const response = await axios.get(
      `http://${BASE_URL}:8000/api/employee-attendance/${userId}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed fetching employee attendance:", error.message);
    throw error;
  }
};

export const updateEmployeeAttendance = async (
  userId,
  date,
  attendanceData
) => {
  try {
    const response = await axios.put(
      `http://${BASE_URL}:8000/api/employee-attendance/${userId}/${date}`,
      {
        clockOut: attendanceData.clockOut,
        totalHours: attendanceData.totalHours,
        status: attendanceData.status,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed updating employee attendance:", error.message);
    throw error;
  }
};

export const getEmployeeAttendanceCounts = async (userId, month, year) => {
  try {
    const response = await axios.get(
      `http://${BASE_URL}:8000/api/employee-attendance/attendance-counts/${userId}/${month}/${year}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch attendance counts:", error.message);
    throw error;
  }
};
