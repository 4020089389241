import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PlusCircle, Sliders } from "feather-icons-react/build/IconComponents";
import {
  Modal,
  Spin,
  Table,
  Input,
  Button,
  Dropdown,
  Menu,
  Popconfirm,
} from "antd";
import {
  createQuotation,
  createPaymentMethod,
  deleteQuotationMethod,
  getAllCustomers,
  getAllQuotationMethod,
  getAllJobTypeMethod,
  getQuotationNumMethod,
} from "../../service/ApiServices";
import Swal from "sweetalert2";
import {
  FaFilePdf,
  FaTrash,
  FaEdit,
  FaEye,
  FaMoneyCheckAlt,
  FaEllipsisV,
} from "react-icons/fa";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
import dayjs from "dayjs";
import { API_BASE_URL } from "../../../src/environment";
const QuotationList = () => {
  const startOfCurrentMonth = dayjs().startOf("month");
  const endOfCurrentMonth = dayjs().endOf("month");

  const [selectedRange, setSelectedRange] = useState([
    startOfCurrentMonth,
    endOfCurrentMonth,
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [jobDate, setJobDate] = useState("");
  const [customer, setCustomer] = useState("");
  const [customers, setCustomers] = useState([]);
  const [jobType, setJobType] = useState("");
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [qty, setQty] = useState(1);
  const [tableItems, setTableItems] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState("fixed");
  const [quotationNum, setQuotationNum] = useState(0);
  const [isQuotationCreated, setIsQuotationCreated] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [selectedQuotationForEdit, setSelectedQuotationForEdit] =
    useState(null);
  const [createPaymentData, setCreatePaymentData] = useState(null);
  const [payAmount, setPayAmount] = useState(1);
  const [paymentType, setPaymentType] = useState("");
  const [quotationData, setQuotationData] = useState({
    data: [],
    page: 1,
    limit: 10,
    total: 0,
  });

  const storedUserData = localStorage.getItem("user");
  const userData = JSON.parse(storedUserData);
  const id = userData?.data?.uid; //
  const [userRightssub, setUserRightssub] = useState([]);
  useEffect(() => {
    const fetchUserRightssub = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/userright/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user rights");
        }
        const data = await response.json();
        console.log("Fetched User Sub Data:", data.data);
        setUserRightssub(data.data || []); // Ensure it's always an array
      } catch (error) {
        console.error("Error fetching data:", error);
        setUserRightssub([]); // Set to empty array in case of error
      }
    };
  
    fetchUserRightssub(); // Fetch the data on component mount
  }, [id]);
  const hasJobRightWithAdd = userRightssub.some(right => right.right === 'Quotation' && right.adds === 1);
 // const hasJobRightWithedit = userRightssub.some(right => right.right === 'category-list' && right.updates === 1);
  //const hasJobRightWithedown = userRightssub.some(right => right.right === 'category-list' && right.fdownload === 1);  
 // const hasJobRightWithedel = userRightssub.some(right => right.right === 'category-list' && right.deletes=== 1); 
    

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedQuotation(null);
    setSelectedQuotationForEdit(null);
    handleReset();
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };

  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
    setCreatePaymentData(null);
    setPayAmount(1);
    setPaymentType("");
  };

  const deleteItem = (itemId) => {
    setTableItems(tableItems.filter((item) => item.itemId !== itemId));
  };

  const getRateQuotationNum = async () => {
    const data3 = await getQuotationNumMethod();
    setQuotationNum(data3.quotationNumber);
  };

  const columns = [
    {
      title: "Item Name",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "Price (qty*1)",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Total Price (qty*price)",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (text, record) => record.qty * record.price,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
       
        <Button
          type="link"
          className="text-danger text-right"
          disabled={selectedQuotation}
          onClick={() => deleteItem(record.itemId)}
        >
          <i data-feather="trash-2" className="feather-delete"></i>
        </Button>
      ),
    },
  ];

  const quotationColumns = [
    {
      title: "Quotation Number",
      dataIndex: "quotation_number",
      key: "quotation_number",
    },
    {
      title: "Customer Name",
      dataIndex: ["customer", "customerName"],
      key: "customer.customerName",
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (due_date) => new Date(due_date).toLocaleDateString(),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Total After Discount",
      dataIndex: "total_after_discount",
      key: "total_after_discount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        if (status == "paid") {
          return <span className="badge bg-success">paid</span>;
        } else if (status == "not-paid") {
          return <span className="badge bg-danger">unpaid</span>;
        } else {
          return <span className="badge bg-warning">partialy paid</span>;
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1" onClick={() => createPayment(record)}>
                <FaMoneyCheckAlt style={{ marginRight: 8 }} />
                {record.status === "paid" ? "View" : "Create"} Payment
              </Menu.Item>
              <Menu.Item key="2" onClick={() => downloadPDF(record)}>
                <FaFilePdf style={{ marginRight: 8 }} />
                Download PDF
              </Menu.Item>
              <Menu.Item
                key="3"
                onClick={() => {
                  setSelectedQuotation(record);
                  showModal();
                }}
              >
                <FaEye style={{ marginRight: 8 }} />
                View Quotation
              </Menu.Item>
              {record.status === "not-pai" && (
                <Menu.Item
                  key="4"
                  onClick={() => {
                    setSelectedQuotationForEdit(record);
                    showModal();
                  }}
                >
                  <FaEdit style={{ marginRight: 8 }} />
                  Edit Quotation {record.status}
                </Menu.Item>
              )}
              <Menu.Item key="5">
                <Popconfirm
                  title="Delete the task"
                  description="Are you sure to delete this task?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    deleteQuotationMethod({
                      quotationId: record._id,
                      showSuccess,
                      setIsItemDeleted: setIsQuotationCreated,
                    });
                  }}
                >
                  <FaTrash style={{ marginRight: 8 }} />
                  Delete Quotation
                </Popconfirm>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button type="text" icon={<FaEllipsisV />} />
        </Dropdown>
      ),
    },
  ];

  const paymentColumns = [
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (payment_date) => new Date(payment_date).toLocaleDateString(),
    },
    {
      title: "Payment Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Payment Type",
      dataIndex: "payment_method",
      key: "payment_method",
    },
  ];

  const calculateSubtotal = () => {
    return tableItems.reduce((acc, item) => acc + item.price * item.qty, 0);
  };

  const calculateTotalAfterDiscount = (subtotal) => {
    if (discountType === "fixed") {
      return subtotal - discount;
    } else if (discountType === "percentage") {
      return subtotal - subtotal * (discount / 100);
    }
    return subtotal;
  };

  const handleReset = () => {
    setTableItems([]);
    setJobDate("");
    setCustomer("");
    setJobType("");
    setQty("");
    setDiscount(0);
    setDiscountType("fixed");
  };
  const createPayment = (data) => {
    showModal2();
    setCreatePaymentData(data);
  };
  const getBase64FromUrl = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };
  function formatNumber(number) {
    return number.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  const downloadPDF = async (quotationData) => {
    const doc = new jsPDF("p", "mm", "a4");
    const logoUrl = "assets/img/logo/logo.jpeg";
    const dueAmount = calculateDueAmount(
      quotationData.total_after_discount,
      quotationData.payments.reduce((acc, item) => acc + item.amount, 0)
    );
    const pageWidth = doc.internal.pageSize.getWidth();
    const logoBase64 = await getBase64FromUrl(logoUrl);
    doc.addImage(logoBase64, "PNG", 14, 10, 50, 50);

    // Company details x,y
    doc.setFontSize(20);
    doc.text("Quotation", 155, 14);
    doc.setFontSize(16);
    doc.text("The Suit", 155, 20);
    doc.setFontSize(10);
    doc.text("335, Colombo Road", 155, 26);
    doc.text("Negombo, Gampaha 11500", 155, 32);
    doc.text("Sri Lanka", 155, 38);
    doc.text("Phone: 0314355515", 155, 44);
    doc.text("Mobile: 0774055515", 155, 50);

    doc.setDrawColor(182, 182, 181);
    doc.line(0, 65, pageWidth, 65);
    // // Quotation details
    doc.setFont("helvetica", "bold");
    doc.setFontSize(9);
    doc.text(`Quotation Number: ${quotationData.quotation_number}`, 155, 70);
    doc.text(
      `Quotation Date: ${new Date(
        quotationData.createdAt
      ).toLocaleDateString()}`,
      155,
      76
    );
    doc.text(
      `Due Date: ${new Date(quotationData.due_date).toLocaleDateString()}`,
      155,
      82
    );
    doc.text(`Amount Due (LKR): ${dueAmount.toFixed(2)}`, 155, 88);

    // Customer details
    doc.setTextColor(182, 182, 181);
    doc.setFontSize(12);
    doc.text("BILL TO", 14, 70);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(9);
    doc.text(quotationData.customer.customerName, 14, 76);
    doc.text(quotationData.customer.customerPhone, 14, 82);

    // Invoice items table
    const items = quotationData.items.map((item) => [
      item.itemDetails.type,
      item.quantity,
      "Rs " + formatNumber(item.itemDetails.price),
      "Rs " + formatNumber(item.quantity * item.itemDetails.price),
    ]);

    doc.autoTable({
      startY: 100,
      head: [["Items", "Quantity", "Price", "Amount"]],
      body: items,
      theme: "grid",
      styles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      headStyles: { fillColor: [74, 74, 73], textColor: [255, 255, 255] },
      footStyles: { fillColor: [74, 74, 73], textColor: [255, 255, 255] },
      columnStyles: {
        0: { cellWidth: 70 },
        1: { cellWidth: 30 },
        2: { cellWidth: 40 },
        3: { cellWidth: 40 },
      },
    });

    // Total section
    doc.setFont("helvetica", "normal");
    const finalY = doc.previousAutoTable.finalY + 10;
    doc.text("Subtotal: ", 130, finalY);
    doc.text(`Rs ${formatNumber(quotationData.total)}`, 170, finalY);
    doc.setDrawColor(182, 182, 181);
    doc.line(100, finalY + 2, 190, finalY + 2);
    doc.text("Total: ", 130, finalY + 10);
    doc.text(`Rs ${formatNumber(quotationData.total)}`, 170, finalY + 10);
    doc.text("Discount: ", 130, finalY + 20);
    doc.text(
      `${quotationData.discount.amount} (${
        quotationData.discount.type === "fixed" ? "LKR" : "%"
      })`,
      170,
      finalY + 20
    );
    doc.text("Total After Discount: ", 130, finalY + 30);
    doc.text(
      `Rs ${formatNumber(quotationData.total_after_discount)}`,
      170,
      finalY + 30
    );

    // Payment section
    quotationData.payments.forEach((payment, index) => {
      const label =
        quotationData.payments.length >= 2 && index === 0 ? " (Advance)" : "";
      doc.text(
        `Payment${label} on ${new Date(
          payment.payment_date
        ).toLocaleDateString()} using ${
          payment.payment_method
        } : Rs ${formatNumber(payment.amount)}`,
        14,
        finalY + 40 + index * 6
      );
    });

    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(
      "Amount Due: ",
      130,
      finalY + 40 + quotationData.payments.length * 6
    );
    doc.text(
      `Rs ${formatNumber(dueAmount)}`,
      170,
      finalY + 40 + quotationData.payments.length * 6
    );

    doc.setDrawColor(182, 182, 181);
    doc.line(
      0,
      finalY + 50 + quotationData.payments.length * 6,
      pageWidth,
      finalY + 50 + quotationData.payments.length * 6
    );
    // Notes / Terms
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    const notes = [
      "* 50% of the total bill should be paid as advance to commence the production.",
      "* The balance payment should be paid on the second fit-on date.",
      "* Any changes in the appointment dates should be notified a few days prior for rescheduling.",
      "* Calling-in to book the time of visit is much preferred.",
      "* All items are non refundable under any circumstances.",
      "* The Suit will not be accountable for any uncollected orders that exceed 60 days from the mentioned delivery date.",
    ];

    doc.text("Notes / Terms", 14, finalY + 70);
    notes.forEach((note, index) => {
      doc.text(note, 14, finalY + 76 + index * 6);
    });

    // Save the PDF
    doc.save(`quotation_${quotationData.quotation_number}.pdf`);
  };

  const handleSubmit = () => {
    if (!jobDate) {
      alert("Please select a date");
      return;
    }
    if (!customer) {
      alert("Please select a customer");
      return;
    }
    if (tableItems.length === 0) {
      alert("Please add items to the table");
      return;
    }
    const submitData = {
      id: selectedQuotationForEdit ? selectedQuotationForEdit._id : null,
      jobDate,
      customer,
      items: tableItems,
      discount,
      discountType,
      quotationNum,
      subtotal: calculateSubtotal(),
      totalAfterDiscount: calculateTotalAfterDiscount(calculateSubtotal()),
    };
    createQuotation({
      data: submitData,
      setLoading: setLoading3,
      handleCancel,
      setIsQuotationCreated,
      showSuccess,
    });
  };

  const showSuccess = (msg) => {
    return Swal.fire({
      title: "Good job!",
      text: msg,
      icon: "success",
    });
  };

  const handleTableChange = (pagination) => {
    getAllQuotationMethod({
      setQuotationData,
      setLoading: setLoading4,
      params: { page: pagination.current, limit: pagination.pageSize },
      search,
      startDate: selectedRange[0].format("YYYY-MM-DD"),
      endDate: selectedRange[1].format("YYYY-MM-DD"),
    });
  };

  const setData = (data) => {
    setQuotationNum(data.quotation_number);
    setJobDate(data.due_date.split("T")[0]);
    setCustomer(data.customer._id);
    setTableItems(
      data.items.map((item) => ({
        itemId: item.itemDetails._id,
        label: item.itemDetails.type,
        qty: item.quantity,
        price: item.itemDetails.price,
      }))
    );
    setDiscount(data.discount.amount);
    setDiscountType(data.discount.type);
  };

  const calculateDueAmount = (total, paid) => {
    return (
      total -
      paid -
      parseFloat(
        createPaymentData?.payments.reduce(
          (acc, item) => acc + item.amount,
          0
        ) || 0
      )
    );
  };
  const onChange = (dates) => {
    if (dates === null) {
      setSelectedRange([startOfCurrentMonth, endOfCurrentMonth]);
      return;
    }
    setSelectedRange(dates);
  };
  useEffect(() => {
    const initialCalls = async () => {
      const data = await getAllCustomers({ setLoading: setLoading2 });
      const data2 = await getAllJobTypeMethod({
        search: "",
        setLoading: setLoading,
      });
      setJobTypeOptions(data2);
      setCustomers(data.data.data.customers);
    };
    initialCalls();
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      getAllQuotationMethod({
        setQuotationData,
        setLoading: setLoading4,
        params: { page: quotationData.page, limit: quotationData.limit },
        search,
        startDate: selectedRange[0].format("YYYY-MM-DD"),
        endDate: selectedRange[1].format("YYYY-MM-DD"),
      });
    }, 600);

    return () => {
      clearTimeout(handler);
    };
  }, [search, selectedRange]);

  useEffect(() => {
    if (isQuotationCreated) {
      getAllQuotationMethod({
        setQuotationData,
        setLoading: setLoading4,
        params: { page: quotationData.page, limit: quotationData.limit },
        search,
        startDate: selectedRange[0].format("YYYY-MM-DD"),
        endDate: selectedRange[1].format("YYYY-MM-DD"),
      });
    }
  }, [isQuotationCreated]);

  useEffect(() => {
    if (tableItems.length === 0) {
      setDiscount(0);
      setDiscountType("fixed");
    }
  }, [tableItems]);

  useEffect(() => {
    if (selectedQuotation) {
      setData(selectedQuotation);
    }
    if (selectedQuotationForEdit) {
      setData(selectedQuotationForEdit);
    }
  }, [selectedQuotation, selectedQuotationForEdit]);

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Quotation List</h4>
                <h6>Manage Your Quotations</h6>
              </div>
            </div>
            <div className="page-btn">
            {hasJobRightWithAdd && (
                <Link
                  className="btn btn-added"
                  onClick={() => {
                    showModal();
                    getRateQuotationNum();
                  }}
                >
                  <PlusCircle className="me-2" />
                  Add New Quotation
                </Link>
              )}
            
            </div>
          </div>
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <Link className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                  <RangePicker
                    onChange={onChange}
                    value={selectedRange}
                    format="YYYY-MM-DD"
                  />
                  <div className="wordset">
                    <ul>
                      <li>
                        <a
                          href="#"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="pdf"
                        >
                          <img src="assets/img/icons/pdf.svg" alt="pdf" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="excel"
                        >
                          <img src="assets/img/icons/excel.svg" alt="excel" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="print"
                        >
                          <img src="assets/img/icons/printer.svg" alt="print" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="search-set">
                  <a href="#" className="btn btn-filter" id="filter_search">
                    <Sliders className="me-2" />
                    Filter
                  </a>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={quotationColumns}
                  dataSource={quotationData.data}
                  rowKey="_id"
                  pagination={{
                    current: quotationData.page,
                    pageSize: quotationData.limit,
                    total: quotationData.total,
                  }}
                  loading={loading4}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal for view,edit and create invoice */}
      <Modal
        zIndex={200000}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={"75vw"}
        maskClosable={false}
        closable={false}
      >
        <div className="modal-dialog add-centered">
          <div className="modal-content">
            <div className="page-wrapper p-0 m-0">
              <div className="content p-0">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {" "}
                      {selectedQuotation
                        ? "View"
                        : selectedQuotationForEdit
                        ? "Edit"
                        : "Add"}{" "}
                      Quotation
                    </h4>
                    <label>Fill all fields to create an Quotation</label>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div
                      className="row"
                      style={{
                        border: "1px solid black",
                        padding: "10px",
                        margin: "10px 0px",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="input-blocks">
                          <label>Quotation No</label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            value={quotationNum}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="input-blocks">
                          <label>Due Date</label>
                          <input
                            type="date"
                            className="form-control"
                            value={jobDate}
                            onChange={(e) => setJobDate(e.target.value)}
                            disabled={selectedQuotation}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        {loading2 ? (
                          <Spin size="small" />
                        ) : (
                          <div className="input-blocks">
                            <label>Customer</label>
                            <select
                              value={customer}
                              onChange={(e) => setCustomer(e.target.value)}
                              className="form-control"
                              disabled={selectedQuotation}
                            >
                              <option value={""}>Select a Customer</option>
                              {customers &&
                                customers.map((customer, index) => (
                                  <option key={index} value={customer._id}>
                                    {customer.customerName}
                                  </option>
                                ))}
                            </select>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        border: "1px solid black",
                        padding: "10px",
                        margin: "10px 0px",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="col-lg-6 col-sm-6 col-12">
                        {loading ? (
                          <Spin size="small" />
                        ) : (
                          <div className="input-blocks">
                            <label>Item</label>
                            <select
                              value={jobType}
                              onChange={(e) => setJobType(e.target.value)}
                              className="form-control"
                              disabled={selectedQuotation}
                            >
                              <option value={""}>Select a JobType</option>
                              {jobTypeOptions &&
                                jobTypeOptions.map((type, index) => (
                                  <option key={index} value={type.value}>
                                    {type.label}
                                  </option>
                                ))}
                            </select>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-sm-6 col-12">
                        <div className="input-blocks">
                          <label>Qty</label>
                          <input
                            type="number"
                            className="form-control"
                            value={qty}
                            disabled={selectedQuotation}
                            onChange={(e) => {
                              if (e.target.value < 0 || e.target.value == 0) {
                                alert("Qty cannot be negative or zero");
                                return;
                              }
                              setQty(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <Button
                        className="btn btn-primary"
                        disabled={!jobType || !qty}
                        onClick={() => {
                          const isTableItemExist = tableItems.find(
                            (item) => item.itemId === jobType
                          );
                          if (isTableItemExist) {
                            alert("Item already exists in the table");
                            setQty("");
                            setJobType("");
                            return;
                          }
                          setTableItems([
                            ...tableItems,
                            {
                              itemId: jobType,
                              label: jobTypeOptions.find(
                                (type) => type.value === jobType
                              ).label,
                              qty: qty,
                              price: jobTypeOptions.find(
                                (type) => type.value === jobType
                              ).price,
                            },
                          ]);
                          setQty("");
                          setJobType("");
                        }}
                      >
                        Add To Table
                      </Button>
                    </div>
                    <div className="table-responsive">
                      <Table
                        columns={columns}
                        dataSource={tableItems}
                        rowKey="itemId"
                      />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <div className="row">
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Discount Type</label>
                            <select
                              value={discountType}
                              onChange={(e) => {
                                if (
                                  e.target.value == "percentage" &&
                                  discount > 100
                                ) {
                                  alert("Discount cannot be greater than 100%");
                                  return;
                                }
                                setDiscountType(e.target.value);
                              }}
                              className="form-control"
                              disabled={
                                tableItems.length === 0 || selectedQuotation
                              }
                            >
                              <option value={"fixed"}>Fixed</option>
                              <option value={"percentage"}>Percentage</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Discount</label>
                            <Input
                              type="number"
                              className="form-control"
                              value={discount}
                              disabled={
                                tableItems.length === 0 || selectedQuotation
                              }
                              onChange={(e) => {
                                if (
                                  discountType === "percentage" &&
                                  e.target.value > 100
                                ) {
                                  alert("Discount cannot be greater than 100%");
                                  return;
                                } else if (
                                  discountType === "percentage" &&
                                  e.target.value < 0
                                ) {
                                  alert("Discount cannot be negative");
                                  return;
                                } else if (
                                  discountType === "fixed" &&
                                  e.target.value < 0
                                ) {
                                  alert("Discount cannot be negative");
                                  return;
                                } else if (
                                  discountType === "fixed" &&
                                  e.target.value > calculateSubtotal()
                                ) {
                                  alert(
                                    "Discount cannot be greater than Subtotal"
                                  );
                                  return;
                                } else if (e.target.value == "") {
                                  setDiscount(0);
                                } else {
                                  setDiscount(e.target.value);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Subtotal</label>
                            <Input
                              type="text"
                              className="form-control"
                              value={calculateSubtotal()}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Total after Discount</label>
                            <Input
                              type="text"
                              className="form-control"
                              value={calculateTotalAfterDiscount(
                                calculateSubtotal()
                              )}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {!selectedQuotation && (
                      <>
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={() => {
                            handleReset();
                          }}
                        >
                          Reset
                        </button>
                        <button
                          className="btn btn-submit"
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          {loading3 ? <Spin size="small" /> : "Save"}
                        </button>
                      </>
                    )}
                  </div>
                  <button
                    type="button"
                    className="btn btn-cancel"
                    onClick={() => {
                      handleCancel();
                      setSelectedQuotation(null);
                      setSelectedQuotationForEdit(null);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Modal for payment */}
      <Modal
        zIndex={200000}
        open={isModalOpen2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={null}
        width={"50vw"}
        maskClosable={false}
        closable={false}
      >
        <div className="modal-dialog add-centered">
          <div className="modal-content">
            <div className="page-wrapper p-0 m-0">
              <div className="content p-0">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4> Create Payment</h4>
                    <label>Fill all fields to create a payment</label>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div
                      className="row"
                      style={{
                        border: "1px solid black",
                        padding: "10px",
                        margin: "10px 0px",
                        borderRadius: "10px",
                      }}
                    >
                      <Table
                        columns={paymentColumns}
                        dataSource={createPaymentData?.payments}
                        rowKey="_id"
                      />
                    </div>
                    {createPaymentData?.status !== "paid" ? (
                      <div
                        style={{
                          border: "1px solid black",
                          padding: "10px",
                          margin: "10px 0px",
                          borderRadius: "10px",
                        }}
                      >
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Quotation No</label>
                              <input
                                type="text"
                                disabled
                                className="form-control"
                                value={
                                  createPaymentData
                                    ? createPaymentData.quotation_number
                                    : ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Due Date</label>
                              <input
                                type="date"
                                className="form-control"
                                value={
                                  createPaymentData
                                    ? createPaymentData.due_date.split("T")[0]
                                    : ""
                                }
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            {loading2 ? (
                              <Spin size="small" />
                            ) : (
                              <div className="input-blocks">
                                <label>Customer</label>
                                <select
                                  value={
                                    createPaymentData
                                      ? createPaymentData.customer._id
                                      : ""
                                  }
                                  className="form-control"
                                  disabled
                                >
                                  <option value={""}>Select a Customer</option>
                                  {customers &&
                                    customers.map((customer, index) => (
                                      <option key={index} value={customer._id}>
                                        {customer.customerName}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Payment Amount</label>
                              <input
                                type="number"
                                className="form-control"
                                value={payAmount}
                                onChange={(e) => {
                                  if (
                                    e.target.value < 0 ||
                                    e.target.value == 0
                                  ) {
                                    alert(
                                      "Payment Amount cannot be negative or zero"
                                    );
                                    return;
                                  } else if (e.target.value === "") {
                                    setPayAmount(1);
                                  } else if (
                                    createPaymentData.total_after_discount -
                                      parseFloat(
                                        createPaymentData?.payments.reduce(
                                          (acc, item) => acc + item.amount,
                                          0
                                        ) || 0
                                      ) <
                                    e.target.value
                                  ) {
                                    alert(
                                      "Payment Amount cannot be greater than Due Amount"
                                    );
                                    return;
                                  }
                                  setPayAmount(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Payment Type</label>
                              <select
                                value={paymentType}
                                className="form-control"
                                onChange={(e) => setPaymentType(e.target.value)}
                              >
                                <option value={""}>
                                  Select a Payment Type
                                </option>
                                <option value={"cash"}>Cash</option>
                                <option value={"credit"}>Credit</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-sm-4 col-12">
                            <div className="input-blocks">
                              <label>SubTotal Amount</label>
                              <input
                                disabled
                                type="number"
                                className="form-control"
                                value={
                                  createPaymentData
                                    ? createPaymentData.total_after_discount
                                    : 0
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-4 col-12">
                            <div className="input-blocks">
                              <label>Due Amount</label>
                              <input
                                disabled
                                type="number"
                                className="form-control"
                                value={calculateDueAmount(
                                  createPaymentData?.total_after_discount || 0,
                                  payAmount
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-4 col-12">
                            <div className="input-blocks">
                              <label>Paid Amount</label>
                              <input
                                disabled
                                type="number"
                                className="form-control"
                                value={
                                  createPaymentData
                                    ? createPaymentData.payments.reduce(
                                        (acc, item) => acc + item.amount,
                                        0
                                      )
                                    : 0
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {createPaymentData?.status !== "paid" ? (
                    <div>
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        onClick={() => {
                          setPayAmount(1);
                          setPaymentType("");
                        }}
                      >
                        Reset
                      </button>
                      <button
                        className="btn btn-submit"
                        onClick={() => {
                          if (!payAmount) {
                            alert("Please enter payment amount");
                            return;
                          }
                          if (!paymentType) {
                            alert("Please select payment type");
                            return;
                          }
                          const submitData = {
                            quotationId: createPaymentData._id,
                            paymentAmount: payAmount,
                            paymentType,
                            dueAmount: calculateDueAmount(
                              createPaymentData?.total_after_discount || 0,
                              payAmount
                            ),
                          };
                          createPaymentMethod({
                            data: submitData,
                            setLoading: setLoading5,
                            handleCancel: handleCancel2,
                            showSuccess,
                            setIsPaymentCreated: setIsQuotationCreated,
                          });
                        }}
                      >
                        {loading5 ? <Spin size="small" /> : "Save"}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  <button
                    type="button"
                    className="btn btn-cancel"
                    onClick={handleCancel2}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default QuotationList;
