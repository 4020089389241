import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../../src/environment";
import { PlusCircle } from 'react-feather';
const EditRoalaccPermition = () => {
  const { rid } = useParams(); 
  const [userRights, setUserRights] = useState([]);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/accsessroalrights/acc/${rid}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        console.log('user rights data:', data);

        if (Array.isArray(data.data)) {
          setUserRights(data.data);
        } else {
          console.error("Data is not an array");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [rid]);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>User Access Rights </h4>
            <h6>The level of permissions granted to a user account within a system</h6>
          </div>
          <div className="page-btn">
          <Link
                                to="/userrights"
                                className="btn btn-added"
                             
                            >

                                <PlusCircle className="me-2" />
                                Back to User Rights
                            </Link>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Access Rights Name</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {userRights.map((right) => (
                    <tr key={right.id}>
                      <td>{right.right}</td>
                      <td>
                        <input
                          type="checkbox"
                          id={`checkbox-${right.id}`}
                          defaultChecked={right.status === 1}
                          style={{ cursor: "pointer" }}
                        />
                        <label
                          htmlFor={`checkbox-${right.id}`}
                          style={{
                            display: "inline-block",
                            marginLeft: "8px",
                            color: right.status === 1 ? "green" : "red",
                          }}
                        >
                          {right.status === 1 ? "Granted" : "Not Granted"}
                        </label>
                      </td>
                      <td>
                        <Link to={`/editaccsessro/${right.id}`} className="btn btn-edit">
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditRoalaccPermition;
