import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { updateRole } from '../../../service/ApiServices';

const EditRole = ({ RoleId }) => {
    const [newRoleName, setNewRoleName] = useState('');

    const handleSaveChanges = async () => {
        
        try {
            await updateRole(RoleId, { name: newRoleName });
           
        } catch (error) {
            console.error('Error updating role:', error);
           
        }
    };

    const handleRoleNameChange = (e) => {
        setNewRoleName(e.target.value);
    };


    return (
        <div>
            {/* Edit Role */}
            <div className="modal fade" id="edit-units">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Edit Role</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form>
                                        <div className="mb-0">
                                            <label className="form-label">Role Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={newRoleName}
                                                onChange={handleRoleNameChange}
                                            />
                                        </div>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                data-bs-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-submit"
                                                data-bs-dismiss="modal"
                                                onClick={handleSaveChanges}
                                            >
                                                Save Changes
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Edit Role */}
        </div>
    );
};

EditRole.propTypes = {
    RoleId: PropTypes.string.isRequired,
};

export default EditRole;
