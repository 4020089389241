import axios from "axios";
import Swal from "sweetalert2";

const showWarning = (str) => {
  return Swal.fire({
    title: "Error",
    text: str,
    icon: "error",
  });
};
const success = (str) => {
  return Swal.fire({
    title: "Success",
    text: str,
    icon: "success",
  });
};
export const createDeduction = async (obj) => {
  try {
    const response = await axios.post(`/deduction`, obj);
    await fetchDeductioDetails();
    await success(response.data.message);

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message || "Failed to create");
  }
};

export const fetchDeductioDetails = async () => {
  try {
    const response = await axios.get(`/deduction`);
    return response.data;
  } catch (error) {
    console.error("Error fetching deduction details:", error);
    throw error;
  }
};

export const editDeduction = async (id, type) => {
  try {
    const response = await axios.put(`/deduction/${id}`, type);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to edit deduction"
    );
  }
};

export const deleteDeduction = async ({ id }) => {
  try {
    console.log(id);
    await axios
      .delete(`/deduction/${id}`)
      .then(() => {})
      .catch((err) => {
        if (err.response.status === 404) {
          showWarning(err.response.data.message);
          return;
        }
        showWarning("Error occurred while deleting deduction");
      });
  } catch (error) {
    showWarning("Error occurred while deleting deduction");
  }
};

export const deductionBulkUpload = async (file) => {
  try {
    // Initialize FormData and append file
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post("/deduction/bulk-upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    //await success(response.data.message);
    return response.data;
  } catch (error) {
    const errorMsg =
      error.response?.data?.message || "Failed to upload deductions";
    showWarning(errorMsg);
    throw new Error(errorMsg);
  }
};

export const fetchSearchDeduction = async ({name,epf_number,date,deductionType,setLoading2}) => {
  try {setLoading2(true)
      const response = await axios.get(`/deduction/search`, {
          params: {
              name:name,
              epf_number:epf_number,
              date:date,
              deductionType:deductionType

          }, headers: {
              'Cache-Control': 'no-cache'
          }
      });

      //console.log('Payroll Records:', response.data.payrollRecords);
      setLoading2(false) 
      console.log(response,"This is Response")
      return response.data.data.deductions;
      
  } catch (error) {
      
      Swal.fire({
          title: 'Error!',
          text: error.response ? error.response.data.message : 'An error occurred while fetching deductions.',
          icon: 'error',
          confirmButtonText: 'Try Again'
      });
  
   setLoading2(false)
  }
};

