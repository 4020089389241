import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import './style.css';
import Swal from 'sweetalert2';
import { Spin } from 'antd';
import { createAllwanceMethod } from '../../service/ApiServices';
import Select from 'react-select';
const CreateAllowanceCategoryModal = ({ handleCancel, handleAllowanceAdding, selectedAllowance }) => {
    const [name, setName] = useState('');
    const [allowanceType,setAllowanceType]=useState("");
    const [loading, setLoading] = useState(false);
    const [showPayslip, showPayslipIsChecked] = useState(false);

    const handleSave = () => {
        if (!name) {
            showWarning("Name is required!")
            return
        }
        console.log(showPayslip,"SHOW PAYYYY")
        createAllwanceMethod({
            handleAllowanceAdding,
            handleCancel,
            handleReset,
            name,
            showPaySlip:showPayslip,
            allowanceType:allowanceType.value,
            setLoading,
            showSuccess,
            id: selectedAllowance ? selectedAllowance._id : null
        })
    };
    const handleReset = () => {
        setName("");
        setAllowanceType("");
        showPayslipIsChecked(false)
    };
    const showSuccess = () => {
        return Swal.fire({
            title: "Good job!",
            text: "You Successfully Saved Allowance!",
            icon: "success"
        });
    }

    const showWarning = (str) => {
        return Swal.fire({
            title: "Error",
            text: str,
            icon: "error",
        });
    }
    const allowanceOptions = [
       // { value: 'Choose', label: 'Choose' },
        { value: 'Fixed', label: 'Fixed' },
        { value: 'Variable', label: 'Variable' },
    ];

    useEffect(() => {
        if (selectedAllowance) {
            setName(selectedAllowance.name);

            const selectedOption = allowanceOptions.find(option => option.value === selectedAllowance.allowanceType);
            setAllowanceType(selectedOption);
            showPayslipIsChecked(selectedAllowance.showPaySlip)
        }
    }, [selectedAllowance]);

    const handleAllowanceType = (option) => {       
        if (option) {
            setAllowanceType(option);
            console.log(allowanceType)
        } else {
            setAllowanceType(null);
        }   
    };
    const handleShowPayslip = (event) => {
        showPayslipIsChecked(event.target.checked);
        
    };

    return (
        <div className="modal-content">
            <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                    <h4>{selectedAllowance ? "Edit Allowance Category" : "Create Allowances Category"}</h4>
                    <p className="mb-0">Fill in the required fields to create a allowance categories</p>
                </div>
            </div>
            <div className="modal-body custom-modal-body">
                <form>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="input-blocks">
                                <label htmlFor="name">Allowance Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    className="form-control"
                                    aria-label="Job Type Name"
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="input-blocks">
                                <label>Allowance Type</label>
                                <Select
                                    className="select"
                                    options={allowanceOptions}
                                    placeholder="Select an allowance type"
                                    value={allowanceType}
                                    onChange={handleAllowanceType}
                                />
                            </div>
                        </div>
                        <div className="input-blocks m-0">
                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                <span className="status-label">Show in Pay slip </span>
                                <input
                                    type="checkbox"
                                    id="user6"
                                    className="check"
                                    defaultChecked="false"
                                    checked={showPayslip}
                                    onChange={handleShowPayslip}
                                />
                                <label
                                    htmlFor="user6"
                                    className="checktoggle mb-0"
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }} >
                    <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={() => {
                            handleCancel();
                            handleReset();
                        }}
                    >
                        Close
                    </button>
                    <div>
                        <button
                            type="button"
                            className="btn btn-reset me-2"
                            onClick={handleReset}
                        >
                            Reset
                        </button>
                        <button className="btn btn-submit" onClick={handleSave}>
                            {loading ? <Spin size='small' /> : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
CreateAllowanceCategoryModal.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    handleAllowanceAdding: PropTypes.func.isRequired,
    selectedAllowance: PropTypes.object.isRequired
};
export default CreateAllowanceCategoryModal;
