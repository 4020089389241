
import React, { useEffect, useState } from "react"; 
import { Table, Modal, } from "antd";
import { PlusCircle, Sliders, Eye } from "react-feather";  
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CreateLeaveTypeModal from "../../core/modals/hrm/createLeaveAllocation"; 
import EditLeaveTypeModal from "../../core/modals/hrm/editLeaveAllocation"; 
import { getAllLeaveAllocations, deleteLeaveAllocation } from "../../service/leaveAllocationService";
import jsPDF from "jspdf";
import dayjs from "dayjs";
import okilogo from "../../assets/OKI-logo.png"
import ExcelJS from 'exceljs';
const JobType = () => {
  const [data, setData] = useState([]);
  const [isLeaveTypeAdd, setIsLeaveTypeAdd] = useState(false); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State for edit modal
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);  
  const [selectedLeaveBalances, setSelectedLeaveBalances] = useState([]); 
  const [selectedLeaveType, setSelectedLeaveType] = useState(null); // State for selected leave type
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  
  const  fetchData = async () => {
    setLoading(true);
    const fetchdata = await getAllLeaveAllocations({ search: '', setLoading });
    setData(fetchdata);
    setLoading(false);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchData();
      setIsLeaveTypeAdd(false);
    }, 600);

    return () => {
      clearTimeout(handler);
    };
  }, [search, isLeaveTypeAdd]);

  const filteredData = data.filter((item) => {
    const leaveTypeMatch = item.leaveBalances.some(balance => 
      balance.leaveType.name.toLowerCase().includes(search.toLowerCase())
    );
    const employeeFirstNameMatch = item.employee.first_name.toLowerCase().includes(search.toLowerCase());
    const employeeLastNameMatch = item.employee.last_name.toLowerCase().includes(search.toLowerCase());

    return leaveTypeMatch || employeeFirstNameMatch || employeeLastNameMatch;
  });

  const handleDeleteLeaveType = async (id) => {
    try {
      await deleteLeaveAllocation(id);
      fetchData();
    } catch (error) {
      console.error("Error deleting Leave type:", error);
    }
  };

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteLeaveType(id);
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  const handleViewModalOpen = (leaveBalances) => {
    setSelectedLeaveBalances(leaveBalances);
    setIsViewModalOpen(true);
    document.querySelector('.sidebar').style.pointerEvents = 'none';
    document.querySelector('.sidebar').style.opacity = '0.5';
  };

  const handleViewModalClose = () => {
    setIsViewModalOpen(false);
    document.querySelector('.sidebar').style.pointerEvents = 'auto';
    document.querySelector('.sidebar').style.opacity = '1';
  };

  const handleEditModalOpen = (leaveAllocation) => {
    setSelectedLeaveType(leaveAllocation);
    console.log("Editing Leave Allocation:", leaveAllocation);
    setIsEditModalOpen(true); 
  };

  console.log(data,"Allocation data")
    const handlePdfExport = () => {
    const date = dayjs().format('YYYY-MM-DD');
    const img = okilogo;

    // Prepare the data
    const formattedData = data.flatMap(emp => {
        return emp.leaveBalances.map((leave, index) => ({
            name: index === 0 ? `${emp.employee.first_name} ${emp.employee.last_name}` : '',
            number: index === 0 ? emp.employee.employee_id : '',
            fYear: index === 0 ? emp.finacialYear : '',
            leaveType: leave.leaveType.name,
            leaveCount: leave.leaveCount
        }));
    });
    console.log(formattedData)
    const doc = new jsPDF();

    doc.setTextColor(0, 0, 0); 
    doc.setFontSize(16);
    doc.text('OKI International ', 15, 13);

    doc.setFontSize(13);
    doc.setTextColor(138, 132, 125);
    doc.text(`Employees Leave Balances`, 15, 23);
    doc.text(`Date: ${date}`, 15, 30);
    doc.addImage(img, 'PNG', 170, 8, 22, 25);

    // Create the table
    doc.autoTable({
        head: [['Employee Name', 'Employee Number', 'Financial Year', 'Type', 'Leave Count']],
        body: formattedData.map(row => [
            row.name, 
            row.number, 
            row.fYear, 
            row.leaveType, 
            row.leaveCount
        ]),
        startY: 35,
        theme: 'striped',
        headStyles: {
            fillColor: [138, 132, 125],
        }
    });

    // Save the PDF
    doc.save('leave_balances.pdf');
};

//Excel Export

const handleExcelExport = () => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Employees Leave Balances');

  const borderStyle = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
  };

  worksheet.columns = [
      { header: 'Employee Name', key: 'name', width: 20 },
      { header: 'Employee Number', key: 'number', width: 20 },
      { header: 'Financial Year', key: 'fYear', width: 15 },
      { header: 'Leave Type', key: 'type', width: 30 },
      { header: 'Leave Count', key: 'count', width: 15 }
  ];

  const formattedData = data.flatMap(emp => {
      return emp.leaveBalances.map((leave, index) => ({
          name: index === 0 ? `${emp.employee.first_name} ${emp.employee.last_name}` : '',
          number: index === 0 ? emp.employee.employee_id : '',
          fYear: index === 0 ? emp.finacialYear : '',
          type: leave.leaveType.name,
          count: leave.leaveCount
      }));
  });

  formattedData.forEach(row => {
      worksheet.addRow({
          'name': row.name,
          'number': row.number,
          'fYear': row.fYear,
          'type': row.type,
          'count': row.count
      });
  });

  worksheet.eachRow({ includeEmpty: true }, (row) => {
      row.eachCell({ includeEmpty: true }, (cell) => {
          cell.border = borderStyle;
      });
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Employees_Leave_Balances.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
  });
};


  const columns = [
    {
      title: "Financial Year",
      dataIndex: "finacialYear", 
      key: "finacialYear",
    },
    {
      title: "Employee Id",
      dataIndex: ['employee', 'employee_id'], 
      key: 'employee_id',
    },
    {
      title: "Employee Name",
      dataIndex: ['employee'], 
      key: 'employee_name',
      render: (employee) => `${employee.first_name} ${employee.last_name}`
    },
    // {
    //   title:  <div style={{ textAlign: 'center' }}>Leave Count</div>,
    //   dataIndex: 'leaveBalances', 
    //   key: 'leave_count',
    //   render: (leaveBalances) => (
    //     <div >
    //     <Table
    //       dataSource={leaveBalances}
    //       columns={[
    //         {
    //           title: <Tag color="orange" style={{ color: 'black' }}>Leave Type</Tag>,
    //           dataIndex: ['leaveType', 'name'], 
    //           key: 'leaveType_name',
    //           align: 'left',
    //         },
    //         {
    //           title: <Tag color="orange" style={{ color: 'black' }}>Leave Count</Tag>,
    //           dataIndex: 'leaveCount',
    //           key: 'leaveCount',
    //           align: 'left',
    //         },
    //       ]}
    //       pagination={false} 
    //       rowKey="leaveType" 
        
    //     />
    //     </div>
    //   ),
    // },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <td className="action-table-data">
          <Link
            className="me-2 p-2"
            to="#"
            onClick={() => handleEditModalOpen(record)} // Open edit modal
          >
            <i data-feather="edit" className="feather-edit"></i>
          </Link>
          <Link
            className="me-2 p-2"
            to="#"
            onClick={() => handleViewModalOpen(record.leaveBalances)} 
          >
            <Eye className="feather-eye" />
          </Link>
          <Link
            className="confirm-text p-2"
            to="#"
            onClick={() => showConfirmationAlert(record._id)}
          >
            <i data-feather="trash-2" className="feather-trash-2"></i>
          </Link>
        </td>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Leaves Allocation</h4>
              <h6>Manage the Allocation of Leaves</h6>
            </div>
          </div>
          <div className="page-btn">
            <a className="btn btn-added" onClick={() => setIsModalOpen(true)}>
              <PlusCircle className="me-2" />
              Allocate Leaves
            </a>
          </div>
        </div>

        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div className="wordset">
                  <ul>
                    <li>
                      <a onClick={handlePdfExport} data-bs-toggle="tooltip" data-bs-placement="top" title="pdf">
                        <img src="assets/img/icons/pdf.svg" alt="pdf" />
                      </a>
                    </li>
                    <li>
                      <a onClick={handleExcelExport} data-bs-toggle="tooltip" data-bs-placement="top" title="excel">
                        <img src="assets/img/icons/excel.svg" alt="excel" />
                      </a>
                    </li>
                    <li>
                      <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="print">
                        <img src="assets/img/icons/printer.svg" alt="print" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="search-set">
                <a href="#" className="btn btn-filter" id="filter_search">
                  <Sliders className="me-2" />
                  Filter
                </a>
              </div>
            </div>
            <div className="table-responsive">
              <Table
                loading={loading}
                columns={columns}
                dataSource={filteredData}
                rowKey="value"
                pagination={{ pageSize: 10 }}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        zIndex={1050}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        maskClosable={false}
        closable={false}
      >
        <CreateLeaveTypeModal
          handleLeaveTypeAdding={() => {
            setIsLeaveTypeAdd(true);
            setIsModalOpen(false);
          }}
          handleCancel={() => setIsModalOpen(false)}
          selectedLeaveType={null}
          search={search}
        />
      </Modal>
      
      <Modal
        zIndex={1050}
        //title="Edit Leave Allocation"
        open={isEditModalOpen}
        onOk={() => setIsEditModalOpen(false)}
        onCancel={() => setIsEditModalOpen(false)}
        footer={null}
        maskClosable={false}
        closable={false}
      >
        <EditLeaveTypeModal
          handleEditLeaveType={() => {
            setIsLeaveTypeAdd(true);
            setIsEditModalOpen(false);
            //fetchData(); // Refresh data after editing
          }}
          handleCancel={() => setIsEditModalOpen(false)}
          selectedLeaveType={selectedLeaveType}
          search={search}
        />
      </Modal>

      <Modal
        //title="Leave Balances"
        zIndex={1050}
        open={isViewModalOpen}
        onOk={handleViewModalClose}
        onCancel={handleViewModalClose}
        footer={null}
        closable={false}
      >
        {/* <Table
          dataSource={selectedLeaveBalances}
          columns={[
            {
              title: "Leave Type",
              dataIndex: ['leaveType', 'name'], 
              key: 'leaveType_name',
            },
            {
              title: "Leave Count",
              dataIndex: 'leaveCount',
              key: 'leaveCount',
            },
          ]}
          pagination={false} 
          rowKey="leaveType" 
          footer={() => (
            <div style={{ textAlign: 'right', marginTop: '16px' }}>
              <button
                onClick={handleViewModalClose}
                className="btn btn-cancel"
              >
                Close
              </button>
            </div>
          )}  
        /> */}
        <div className="leave-balance-widget">
          <h5 style={{marginBottom:"20px"}}>Leave Balances Overview </h5>
          <ul className="widget-attend">
            {selectedLeaveBalances.map((balance, index) => (
              <li key={index} className="box-attend">
                <div className={`${balance.leaveType.name.toLowerCase().includes("asthma") ? "danger-card" :
                    balance.leaveType.name.toLowerCase().includes("fever ") ? "warming-card" :
                      balance.leaveType.name.toLowerCase().includes("macth") ? "warming-card" :
                        balance.leaveType.name.toLowerCase().includes("funeral ") ? "light-card" :
                          "light-card"
                  }`}>
                  <h4>{balance.leaveCount}</h4>
                  <h6>{balance.leaveType.name}</h6>
                </div>
              </li>
            ))}
          </ul>
          <div style={{ textAlign: 'right', marginTop: '16px' }}>
            <button onClick={handleViewModalClose} className="btn btn-cancel">
              Close
            </button>
          </div>
        </div>

      </Modal>
    </div>
  );
};

export default JobType;
