import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { addCategory } from "../../../service/ApiServices";
import PropTypes from "prop-types";

const AddCategory = ({onCategoryAdded}) => {
  const route = all_routes;

  const [formData, setFormData] = useState({
    category: "",
    categorySlug: ""

  });

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await addCategory(formData);  
        console.log("Customer added successfully:", response.data);
        onCategoryAdded();
      setFormData({
        category: "",
        categorySlug: ""
      });
      navigate(route.addproduct);
    } catch (error) {
      console.error("Error adding customer:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <>
      {/* Add Category */}
      <div className="modal fade" id="add-units-category">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add New Category</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input
                        type="text"
                        className="form-control"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                    />
                  </div>
                  <div className="modal-footer-btn">
                    <Link
                      to="#"
                      className="btn btn-cancel me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <button type="submit" 
                      className="btn btn-submit"
                      data-bs-dismiss="modal">
                      Submit
                    </button>
                    {/* <Link to={route.addproduct} className="btn btn-submit">
                      Submit
                    </Link> */}
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </>
  );
};

AddCategory.propTypes = {
  onCategoryAdded: PropTypes.func.isRequired,
};

export default AddCategory;
