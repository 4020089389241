import React, { useEffect, useState } from "react";

const RoalaccPermitions = () => {
  const [userData, setUseraccsesData] = useState([]);
  const [accsessData, setaccData] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  useEffect(() => {
    const fetchUserRoles = async () => {
      try {
        const response = await fetch(
          "http://localhost:8000/api/useracrights/all",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (Array.isArray(data.data)) {
          setaccData(data.data);
        } else {
          console.error("Data is not an array");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchUserRoles();
  }, []);

  useEffect(() => {
    const fetchUserRoles = async () => {
      try {
        const response = await fetch(
          "http://localhost:8000/api/accsessrights/all",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (Array.isArray(data.data)) {
          setUseraccsesData(data.data);
        } else {
          console.error("Data is not an array");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchUserRoles();
  }, []);
  const handleCheckboxChange = (userId, checked) => {
    const updatedUserData = userData.map((user) => {
      if (user._id === userId) {
        return {
          ...user,
          status: checked ? 1 : 0, 
        };
      }
      return user;
    });
    setUseraccsesData(updatedUserData);
  };


 const handleSubmit = async (e) => {
  e.preventDefault();


  const dataToSend = userData.map((user) => ({
    rid: selectedRoleId,
    right: user.right,
    role: selectedRole,
    status: user.status,
    link: user.link,
    icon: user.icon,
    menu: user.menu,
    menuid: user.menuid,
    submenu: user.submenu,
    submenuid: user.submenuid,
    adds: user.adds,
    viewes: user.viewes,
    updates: user.updates,
    deletes: user.deletes,
    download: user.download,
    upload: user.upload,
    approve: user.approve,
  }));

  try {
    const response = await fetch(`http://localhost:8000/api/accsessroalrights/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend), 
    });

    const result = await response.json();
    if (response.ok) {
      console.log("Permissions saved successfully!");
      alert("Permissions saved successfully!");
    } else {
      console.error("Failed to save permissions:", result.message);
    }
  } catch (error) {
    console.error("Error saving permissions:", error);
  }
};
  const renderPermissionBox = (value) => (
    <span
      style={{
        padding: "5px 10px",
        borderRadius: "5px",
        color: "#fff",
        backgroundColor: value === 1 ? "#28a745" : "#dc3545", // Green for "Yes", Red for "No"
      }}
    >
      {value === 1 ? "Yes" : "No"}
    </span>
  );

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Create New Access Roles</h4>
              <h6>Manage your access roles</h6>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="roleSelect">Select Role</label>
                <select
                      id="roleSelect"
                      className="form-control"
                      name="role"
                      value={selectedRole}
                      onChange={(e) => {
                        const selectedOption = accsessData.find(roale => roale.role === e.target.value);
                        setSelectedRole(e.target.value); // Update the role
                        setSelectedRoleId(selectedOption ? selectedOption.roleid: "");
                           // Update the roleid
                      }}
                    >
                      <option value="">-- Select Role --</option>
                      {accsessData.length > 0 &&
                        accsessData.map((roale) => (
                          <option key={roale._id} value={roale.role}>
                            {roale.role}
                          </option>
                        ))}
                    </select>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Right ID</th>
                      <th>Right Name</th>
                      <th style={{ display: 'none' }}></th>
                      <th style={{ display: 'none' }}>link</th>
                      <th style={{ display: 'none' }}>icon</th>
                      <th style={{ display: 'none' }}>menu</th>
                      <th style={{ display: 'none' }}>menuid</th>
                      <th style={{ display: 'none' }}>submenu</th>
                      <th style={{ display: 'none' }}>submenuid</th>
                      <th>Add</th>
                      <th>View</th>
                      <th>Update</th>
                      <th>Delete</th>
                      <th>Download</th>
                      <th>Upload</th>
                      <th>Approve</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData.length > 0 ? (
                      userData.map((user) => (
                        <tr key={user._id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={user.status === 1}
                              onChange={(e) =>
                                handleCheckboxChange(user._id, e.target.checked)
                              }
                            />
                          </td>
                          <td>{user.rid}</td>
                          <td>{user.right}</td>

                          {/* Inline CSS to hide these columns */}
                          <td style={{ display: 'none' }}>{user.status}</td>
                          <td style={{ display: 'none' }}>{user.link}</td>
                          <td style={{ display: 'none' }}>{user.icon}</td>
                          <td style={{ display: 'none' }}>{user.menu}</td>
                          <td style={{ display: 'none' }}>{user.menuid}</td>
                          <td style={{ display: 'none' }}>{user.submenu}</td>
                          <td style={{ display: 'none' }}>{user.submenuid}</td>
                          <td>{renderPermissionBox(user.adds)}</td>
                          <td>{renderPermissionBox(user.viewes)}</td>
                          <td>{renderPermissionBox(user.updates)}</td>
                          <td>{renderPermissionBox(user.deletes)}</td>
                          <td>{renderPermissionBox(user.download)}</td>
                          <td>{renderPermissionBox(user.upload)}</td>
                          <td>{renderPermissionBox(user.approve)}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="15" className="text-center">
                          No access roles available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <button type="submit" className="btn btn-primary mt-3">
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RoalaccPermitions;
