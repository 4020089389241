import React, { useEffect, useState } from "react";
import { Table, Modal } from "antd";
import { PlusCircle, Sliders } from "react-feather";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  getAllLeaveTypes,
  deleteLeaveType,
} from "../../service/leaveApiServices/leaveTypesApi";
import CreateLeaveTypeModal from "../../core/modals/hrm/addleavetype"; //addleave type export function

const JobType = () => {
  const [data, setData] = useState([]);
  const [isLeaveTypeAdd, setIsLeaveTypeAdd] = useState(false); //line30 //42/23
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLeaveType, setSelectedLeaveType] = useState(null); //168/24 /37/79
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    let fetchedData = await getAllLeaveTypes({ search, setLoading });
    setData(fetchedData);
    setLoading(false);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchData();
      setIsLeaveTypeAdd(false);
      setSelectedLeaveType(null);
    }, 600);

    return () => {
      clearTimeout(handler);
    };
  }, [search, isLeaveTypeAdd]);

  //Search by Name
  const filteredData = data.filter((leaveType) =>
    leaveType.name.toLowerCase().includes(search.toLowerCase())
  );

  const handleDeleteLeaveType = async (id) => {
    try {
      await deleteLeaveType(id);
      console.log("delete successful:", id);
      fetchData();
    } catch (error) {
      console.error("Error deleting Leave type:", error);
    }
  };

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteLeaveType(id);
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setSelectedLeaveType(null);
    setIsModalOpen(false);
  };

  const handleLeaveTypeAdding = () => {
    setIsLeaveTypeAdd(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Leave Quota",
      dataIndex: "quota",
      key: "quota",
    },
    {
      title: "Minimum Leave Count",
      dataIndex: "minimumLeaveCount",
      key: "minimumLeaveCount",
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (text) => {
        const date = new Date(text);
        const localDate = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        );

        const year = localDate.getFullYear();
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const month = monthNames[localDate.getMonth()];
        const day = localDate.getDate();

        return <span>{`${year}-${month}-${day}`}</span>;
      },
    },

    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <td className="action-table-data">
          <Link
            className="me-2 p-2"
            to="#"
            onClick={() => {
              setSelectedLeaveType(record);
              setIsModalOpen(true);
            }}
          >
            <i data-feather="edit" className="feather-edit"></i>
          </Link>
          <Link
            className="confirm-text p-2"
            to="#"
            onClick={() => showConfirmationAlert(record._id)}
          >
            <i data-feather="trash-2" className="feather-trash-2"></i>
          </Link>
        </td>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Leaves</h4>
              <h6>Manage your Leaves</h6>
            </div>
          </div>
          <div className="page-btn">
            <a className="btn btn-added" onClick={() => setIsModalOpen(true)}>
              <PlusCircle className="me-2" />
              Add Leave Type
            </a>
          </div>
        </div>

        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div className="wordset">
                  <ul>
                    {/* <li>
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="pdf"
                      >
                        <img src="assets/img/icons/pdf.svg" alt="pdf" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="excel"
                      >
                        <img src="assets/img/icons/excel.svg" alt="excel" />
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="print"
                      >
                        <img src="assets/img/icons/printer.svg" alt="print" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="search-set">
                <a href="#" className="btn btn-filter" id="filter_search">
                  <Sliders className="me-2" />
                  Filter
                </a>
              </div>
            </div>
            <div className="table-responsive">
              <Table
                loading={loading}
                columns={columns}
                dataSource={filteredData}
                rowKey="value"
                pagination={false}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        zIndex={200000}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        closable={false}
      >
        <CreateLeaveTypeModal
          handleLeaveTypeAdding={handleLeaveTypeAdding}
          handleCancel={handleCancel}
          selectedLeaveType={selectedLeaveType}
        />
      </Modal>
    </div>
  );
};

export default JobType;
