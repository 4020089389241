import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import {
    createJobMethod,
    getAllBodyTypeMethod,
    getAllCustomers, getAllEmployeesMethod,
    getAllJobTypeMethod,
    getJobByJobNoMethod
} from '../../../service/ApiServices';
import { Empty, Modal, Space, Spin, Input, Select } from 'antd';
import CreateJobTypeModal from './createJobType';
import Swal from 'sweetalert2';
import { useImperativeHandle } from 'react';
const { Option } = Select;

const AddJob = forwardRef(({ handleCancel2, handleJobAdded, selectedJob }, ref) => {
    const [loading4, setLoading4] = useState(false)
    const [jobNo, setJobNo] = useState(new Date().getTime());
    const [jobDate, setJobDate] = useState('');
    const [customer, setCustomer] = useState('');
    const [invoiceNo, setInvoiceNo] = useState('');
    const [fitOnDate, setFitOnDate] = useState('');
    const [fitOnDate2, setFitOnDate2] = useState('');
    const [selectedJobTypes, setSelectedJobTypes] = useState([]);
    const [jobTypeOptions, setJobTypeOptions] = useState([]);
    const [deliveryDate, setDeliveryDate] = useState('');
    const [jobType, setJobType] = useState([]);
    const [isJobTypeAdd, setIsJobTypeAdd] = useState(false);
    const [tailor, setTailor] = useState('');
    const [remarks, setRemarks] = useState('');
    const [cutter, setCutter] = useState('');
    const [customers, setCustomers] = useState([]);
    const [jobTypeData, setJobTypeData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [employeeData, setEmployeeData] = useState({
        data: [],
        page: 1,
        limit: 1000,
        total: 0
    });
    const [loading, setLoading] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [search] = useState("")
    const [searchJob, setSearchJob] = useState('');
    const [bodyTypes, setBodyTypes] = useState([]);
    const [selectedBodyType, setSelectedBodyType] = useState('');
    const [selectedBodyTypeOption, setSelectedBodyTypeOption] = useState('');
    useImperativeHandle(ref, () => ({
        handleReset
    }));
    useEffect(() => {
        const initialCalls = async () => {
            const data = await getAllCustomers({ setLoading });
            const data2 = await getAllJobTypeMethod({ setLoading, search: "" });
            const data3 = await getAllBodyTypeMethod({ setLoading, search: "" })
            setBodyTypes(data3);
            setJobTypeOptions(data2);
            setCustomers(data.data.data.customers);
        };
        getAllEmployeesMethod({
            setEmployeeData,
            setLoading: setLoading3,
            params: { page: employeeData.page, limit: employeeData.limit },
            search
        });
        initialCalls();

    }, []);

    useEffect(() => {
        if (isJobTypeAdd) {
            const apiCall = async () => {
                const data2 = await getAllJobTypeMethod();
                setJobTypeOptions(data2);
                setJobType([]);
            };
            apiCall();
        }
    }, [isJobTypeAdd]);

    useEffect(() => {

        if (jobType.length > 0) {
            const selectedJobTypes = jobTypeOptions.filter(option => jobType.map(type => type.label).includes(option.label));
            setSelectedJobTypes(selectedJobTypes);
            setJobTypeData(prevData => prevData.filter((_, index) => jobType[index]));
        } else {
            setSelectedJobTypes([]);
            setJobTypeData([]);
        }
    }, [jobType, jobTypeOptions]);

    useEffect(() => {
        setTimeout(() => {
            if (selectedJob || searchJob) {
                setDataToJob(selectedJob || searchJob);
            }
        })
    }, [selectedJob, searchJob])

    const setDataToJob = (data) => {
        setCustomer(data.customer._id);
        setJobDate(data.jobDate ? data.jobDate.split('T')[0] : new Date().toISOString().split('T')[0]);
        setFitOnDate2(data.finalFitOnDate?.split('T')[0]);
        setJobNo(data.jobNo);
        setFitOnDate(data.invoice?.fit_on_date?.split('T')[0]);
        setDeliveryDate(data.finalFitOnDate?.split('T')[0]);
        setTailor(data.tailor);
        setCutter(data.cutter);
        setJobType(data.jobTypesInfo.map(jobType =>
        ({
            label: jobType.jobType.type,
            value: jobType.jobType._id
        })));
        setJobTypeData(data.jobTypesInfo.map(jobType =>
        ({
            jobType: jobType.jobType.type,
            bodyTypes: jobType?.bodyTypes?.map(item => {
                return item?.values?.map((value) => {
                    return {
                        id: item.id,
                        bodyType: item.name,
                        bodyTypeOption: value
                    }
                })
            }).flat(),
            qty: jobType.qty,
            measurements: jobType.measurements,
            styles: jobType.styles.map(style => {
                return {
                    styleName: style.style.styleName,
                    values: style.style.values,
                    selectedValues: style?.selectedValues || [],
                    style: style.style._id
                }
            })
        })));
        setRemarks(data.remarks)
    }
    useEffect(()=>{
          console.log( "ssss1",searchJob,"ssss2",jobTypeData)
    },[jobTypeData,searchJob])
    const updateJobTypeData = (index, field, value, styleIndex = null, nameOfStyle) => {
        console.log("calling updateJobTypeData", { index, field, value, styleIndex, nameOfStyle });

        setJobTypeData((prev) => {
            const newData = [...prev];
            if (!newData[index]) {
                newData[index] = { qty: '', measurements: '', styles: [], bodyTypes: [] };
            } else if (!newData[index].bodyTypes) {
                newData[index].bodyTypes = [];
            }

            if (field === 'styles' && styleIndex !== null) {
                if (!newData[index].styles[styleIndex]) {
                    newData[index].styles[styleIndex] = { styleName: '', value: [], selectedValues: [] }; // Ensure value and selectedValues are arrays
                }

                // Append new selected values instead of overwriting
                newData[index].styles[styleIndex].selectedValues = [...value]; // Use value directly (multi-select)
                newData[index].styles[styleIndex].styleName = nameOfStyle;

            } else if (field === 'bodyTypes') {
                console.log("bodyType value:", value);
                const bodyTypeExists = newData[index].bodyTypes.some(
                    (bt) => bt.bodyType === value.bodyType && bt.bodyTypeOption === value.bodyTypeOption
                );
                if (!bodyTypeExists) {
                    newData[index].bodyTypes.push(value);
                }
            } else {
                newData[index][field] = value;
            }

            console.log("Updated newData:", newData);
            return newData;
        });
    };

    const handleDeleteBodyType = (jobIndex, bodyTypeIndex) => {
        setJobTypeData((prev) => {
            const newData = [...prev];
            newData[jobIndex].bodyTypes.splice(bodyTypeIndex, 1);
            return newData;
        });
    };

    const handleReset = () => {
        setJobDate('');
        setFitOnDate('');
        setDeliveryDate('');
        setTailor('');
        setCutter('');
        setJobTypeData(prev => prev.map(data => ({ ...data, measurements: '', styles: data.styles.map(style => ({ ...style, value: '' })) })));
        setSearchJob('');
        setInvoiceNo('');
    }

    const showSuccess = () => {
        return Swal.fire({
            title: "Good job!",
            text: "You Successfully Created Job!",
            icon: "success"
        });
    }

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleJobTypeAdding = () => {
        setIsJobTypeAdd(true);
        setTimeout(() => {
            setIsJobTypeAdd(false);
        }, 1000);
    };

    const submitHandler = () => {
        const isAnyFieldEmptyMainForm = [jobNo, jobDate, customer, fitOnDate, deliveryDate, tailor, cutter].some(field => field === '');
        const isAnyFiledEmptyJobTypeSection = jobTypeData.some(data => data.qty === '' || data.measurements === '');
        if (isAnyFieldEmptyMainForm) {
            showWarning('Please fill all the fields in Main Form');
            return;
        }
        if (isAnyFiledEmptyJobTypeSection ||
            jobTypeData.length === 0 ||
            (jobTypeData.length !== selectedJobTypes.length) ||
            jobTypeData.some(item => {
                return item.styles.some(item => item.selectedValues.length === 0)
            })

        ) {
            showWarning('Please fill all the fields in Job Type Section');
            return;
        }


        const updatedJobData = jobTypeOptions.map(option => {
            const found = jobTypeData.find(data => data.jobType === option.label);
            console.log("eeeeeeeeeeeeee", found)
            if (found) {
                const groupedById = found.bodyTypes.reduce((acc, item) => {
                    const existingItem = acc.find(group => group.id === item.id);
                    if (existingItem) {
                        existingItem.values.push(item.bodyTypeOption);
                    } else {
                        acc.push({ id: item.id, name: item.bodyType, values: [item.bodyTypeOption] });
                    }
                    return acc;
                }, []);
                return { ...found, jobType: option.value, bodyTypes: groupedById };
            }
            return null;
        }).filter(data => data !== null);



        const submitData = {
            jobId: searchJob ? searchJob._id : null,
            jobNo,
            jobDate,
            customer,
            fitOnDate,
            deliveryDate,
            tailor,
            cutter,
            remarks,
            jobTypesInfo: updatedJobData,
            //type,
        };
        createJobMethod({
            data: submitData,
            handleReset,
            handleCancel: handleCancel2,
            showSuccess,
            handleJobAdded,
            setLoading: setLoading4
        });
    }

    const findJobByJobno = (jobNo) => {
        if (!jobNo || jobNo.length !== 6) {
            showWarning("Please Enter a valid Job No (6 digits)")
            return;
        }
        getJobByJobNoMethod({
            jobNo,
            setLoading,
            setData: setSearchJob
        })
    }

    const showWarning = (str) => {
        return Swal.fire({
            title: "Error",
            text: str,
            icon: "error",
        });
    }

    return (
        <div className="content">
            <div className="modal-header border-0 custom-modal-header d-flex justify-content-between">
                <div className="page-title">
                    <h4>Create Your Job</h4>
                    <label>Fill all fields to create a Job Order</label>
                </div>
            </div>
            <div className="modal-body custom-modal-body">
                <div style={{
                    display: "flex",
                    //justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px"
                }}>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="input-blocks">
                            <label>Type Invoice Number</label>
                            <input
                                type="text"
                                className="form-control"
                                value={invoiceNo}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value.length <= 6 && /^\d*$/.test(value)) {
                                        setInvoiceNo(value);
                                    } else {
                                        showWarning("Invoice Number should be 6 digits and numeric");
                                    }
                                }}
                                disabled={loading}
                            />
                        </div>
                    </div>
                    <button disabled={loading} className="btn btn-primary" style={{ marginTop: "5px" }} onClick={() => findJobByJobno(invoiceNo)}>Search</button>
                </div>

                {
                    loading ? <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Spin size='large' />
                    </div> : searchJob ?
                        <div>
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-12">
                                    <div className="input-blocks">
                                        <label>Job No</label>
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            value={jobNo}
                                            onChange={(e) => setJobNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-12">
                                    <div className="input-blocks">
                                        <label>Job Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={jobDate}
                                            onChange={(e) => setJobDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-12">
                                    <div className="input-blocks">
                                        <label>Customer</label>
                                        <select
                                            disabled
                                            value={customer}
                                            onChange={(e) => setCustomer(e.target.value)}
                                            className="form-control"
                                        >
                                            <option value={""}>Select a Customer</option>
                                            {customers && customers.map((customer, index) => (
                                                <option key={index} value={customer._id}>{customer.customerName}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-12">
                                    <div className="input-blocks">
                                        <label>Fit On Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={fitOnDate}
                                            onChange={(e) => setFitOnDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-12">
                                    <div className="input-blocks">
                                        <label>Final Fit On Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={fitOnDate2}
                                            onChange={(e) => setFitOnDate2(e.target.value)}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-12">
                                    <div className="input-blocks">
                                        <label>Delivery Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={deliveryDate}
                                            onChange={(e) => setDeliveryDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-12">
                                    <div className="input-blocks">
                                        <label>Cutter</label>

                                        <Select
                                            showSearch
                                            placeholder="Select a cutter"
                                            value={cutter}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            size={"large"}
                                            style={{ width: '100%' }}
                                            loading={loading3}
                                            onChange={(e) => setCutter(e)}
                                            options={employeeData.data.map((val) =>
                                            ({
                                                label: val.first_name + " " + val.last_name,
                                                value: val._id,
                                            })
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-12">
                                    <div className="input-blocks">
                                        <label>Tailor</label>
                                        <Select
                                            showSearch
                                            placeholder="Select a tailor"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            size={"large"}
                                            style={{ width: '100%' }}
                                            value={tailor}
                                            loading={loading3}
                                            onChange={(e) => setTailor(e)}
                                            options={employeeData.data.map((val) =>
                                            ({
                                                label: val.first_name + " " + val.last_name,
                                                value: val._id,
                                            })
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            {selectedJobTypes.length > 0 && selectedJobTypes.map((type, index) => (
                                <div key={index} className="row card p-5" >
                                    <h4>Job Type: {type.label}</h4>
                                    <div className="col-lg-12">
                                        <div className="input-blocks">
                                            <label>Measurements</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                onChange={(e) => {
                                                    updateJobTypeData(index, 'measurements', e.target.value)
                                                    updateJobTypeData(index, 'jobType', type.label)
                                                }}
                                                value={jobTypeData[index]?.measurements || ''}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-sm-12 col-12">
                                        <div className="page-btn">
                                            <label>Qty</label>
                                            <input
                                                type="number"
                                                disabled
                                                className="form-control"
                                                onChange={(e) => updateJobTypeData(index, 'qty', e.target.value)}
                                                value={jobTypeData[index]?.qty || ''}
                                            />
                                        </div>
                                    </div>
                                    <h5 style={{ marginTop: "20px" }}>Styles</h5>
                                    <div className='row'>
                                        {type.styles.map((style, index2) => (
                                            <div className="col-lg-3 col-md-3 col-sm-6" key={index2}>
                                                <div className="input-blocks">
                                                    <label>{jobTypeData[index]?.styles[index2]?.styleName}</label>
                                                    <Select
                                                        mode="multiple"
                                                        style={{ width: '100%' }}
                                                        placeholder="Select values"
                                                        onChange={(value) => updateJobTypeData(index, 'styles', value, index2, jobTypeData[index]?.styles[index2]?.styleName)}

                                                    >
                                                        {jobTypeData[index]?.styles[index2]?.values.map((option, i) => (
                                                            <Option key={i} value={option}>
                                                                {option}
                                                            </Option>
                                                        ))}
                                                    </Select>

                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <h5 style={{ marginTop: "20px" }}>Body Types</h5>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "5px"
                                    }} >
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            <div className="input-blocks">
                                                <select
                                                    className="form-control"
                                                    value={selectedBodyType}
                                                    onChange={(e) => setSelectedBodyType(e.target.value)}
                                                >
                                                    <option value={""}>Select a body Type</option>
                                                    {
                                                        bodyTypes.map((bodyType, index) => (
                                                            <option key={index} value={bodyType._id}>{bodyType.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            <div className="input-blocks">
                                                <select
                                                    className="form-control"
                                                    value={selectedBodyTypeOption}
                                                    onChange={(e) => setSelectedBodyTypeOption(e.target.value)}
                                                >
                                                    <option value={""}>Select a body Type Option</option>
                                                    {
                                                        bodyTypes.find(bodyType => bodyType._id === selectedBodyType)?.types.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            <button className="btn btn-primary"
                                                onClick={() => {
                                                    const bodyType = bodyTypes.find(bt => bt._id === selectedBodyType);
                                                    const bodyTypeOption = selectedBodyTypeOption;
                                                    if (bodyType && bodyTypeOption) {
                                                        const newBodyType = {
                                                            id: bodyType._id,
                                                            bodyType: bodyType.name,
                                                            bodyTypeOption
                                                        };
                                                        console.log("newBodyType before updateJobTypeData:", newBodyType);
                                                        const isBodyTypeExists = jobTypeData[index]?.bodyTypes?.some(
                                                            (bt) => bt.bodyType === newBodyType.bodyType && bt.bodyTypeOption === newBodyType.bodyTypeOption
                                                        );
                                                        if (isBodyTypeExists) {
                                                            showWarning('Body Type already exists');
                                                            return;
                                                        }
                                                        updateJobTypeData(index, 'bodyTypes', newBodyType);
                                                        setSelectedBodyType('');
                                                        setSelectedBodyTypeOption('');
                                                    } else {
                                                        showWarning('Please select a body type and option.');
                                                    }
                                                }}
                                            >Add Body Type</button>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table datanew">
                                            <thead>
                                                <tr>
                                                    <th>Body Type Name</th>
                                                    <th>Body Type Option Name</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            {
                                                jobTypeData[index]?.bodyTypes ? <tbody>
                                                    {
                                                        jobTypeData[index]?.bodyTypes?.map((bodyType, bodyTypeIndex) => (
                                                            <tr key={bodyTypeIndex}>
                                                                <td>{bodyType.bodyType}</td>
                                                                <td>{bodyType.bodyTypeOption}</td>
                                                                <td>
                                                                    <button onClick={() => handleDeleteBodyType(index, bodyTypeIndex)} className="btn btn-danger">Delete</button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody> :
                                                    <tbody>
                                                        <tr style={{
                                                            textAlign: "center"
                                                        }}>
                                                            <td colSpan="3">No Data Found</td>
                                                        </tr>
                                                    </tbody>
                                            }

                                        </table>
                                    </div>

                                </div>

                            ))}
                            <div className="row">

                                <div className="col-lg-12">
                                    <div className="modal-body-table">
                                        <div className="table-responsive">
                                            <table className="table datanew">
                                                <thead>
                                                    <tr>
                                                        <th>Job Type</th>
                                                        <th>Qty</th>
                                                        <th>Measurement</th>
                                                        <th>Styles</th>
                                                        <th>Body Types</th>
                                                    </tr>
                                                </thead>
                                                {
                                                    jobTypeData.length === 0 ? <tbody>
                                                        <tr style={{
                                                            textAlign: "center"
                                                        }}>
                                                            <td colSpan="5">No Data Found</td>
                                                        </tr>
                                                    </tbody> : <tbody>
                                                        {
                                                            jobTypeData.map((data, index) => (
                                                                <tr key={index}>
                                                                    <td>{selectedJobTypes[index]?.label}</td>
                                                                    <td>{data.qty}</td>
                                                                    <td>{data.measurements}</td>
                                                                    <td>
                                                                        {data.styles.map((style, index2) => (
                                                                            <div key={index2}>
                                                                                <span>{style.styleName}:</span>
                                                                                <span>{style.selectedValues.join(',')}</span>
                                                                            </div>
                                                                        ))}
                                                                    </td>
                                                                    <td>
                                                                        {data?.bodyTypes.map((type, index2) => (
                                                                            <div key={index2}>
                                                                                <span>{type.bodyType}:</span>
                                                                                <span>{type.bodyTypeOption}</span>
                                                                            </div>
                                                                        ))}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                }
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 p-3">
                                    <label>Remarks</label>
                                    <Input.TextArea value={remarks} onChange={(e) => setRemarks(e.target.value)} rows={4} />
                                </div>
                            </div>

                        </div> :
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%"
                        }}>
                            <Empty
                                description={
                                    <span>
                                        {
                                            "No Data Found"
                                        }
                                    </span>
                                }
                            />
                        </div>
                }
                <div className="" style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    {
                        searchJob && <div className="">
                            <button
                                type="button"
                                className="btn btn-cancel"
                                onClick={() => {
                                    handleCancel2();
                                    setSearchJob('');
                                    setInvoiceNo('');
                                }}
                            >
                                Close
                            </button>
                        </div>
                    }
                    {
                        searchJob &&
                        <Space>
                            <button
                                type="button"
                                className="btn btn-reset me-2"
                                onClick={handleReset}
                            >
                                Reset
                            </button>
                            <button type='button' className="btn btn-submit ml-0" onClick={() => {
                                submitHandler()
                            }}>
                                {loading4 ? <Spin size='small' /> : "Save"}
                            </button>
                        </Space>
                    }
                </div>
                <Modal
                    zIndex={200000}
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <CreateJobTypeModal handleJobTypeAdding={handleJobTypeAdding} handleCancel={handleCancel} />
                </Modal>
            </div>
        </div>
    );
});
AddJob.displayName = "AddJob";
AddJob.propTypes = {
    handleCancel2: PropTypes.func.isRequired,
    handleJobAdded: PropTypes.func.isRequired,
    selectedJob: PropTypes.object.isRequired,
};


export default AddJob;
