import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../core/img/imagewithbasebath';
import { ChevronUp, PlusCircle, RotateCcw } from 'feather-icons-react/build/IconComponents';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Filter, Sliders, Zap, Edit, Eye, Trash2} from 'react-feather';
import Select from 'react-select';
import { setToogleHeader } from '../../core/redux/action';
// import AddCoupons from '../../core/modals/coupons/addcoupons';
// import EditCoupons from '../../core/modals/coupons/editcoupons';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { DatePicker, Table } from 'antd';
import AddCoupons from '../../core/modals/coupon/couponModal';
import { BASE_URL } from '../../environment';
import { API_BASE_URL } from "../../../src/environment";

const Coupons = () => {

    const dispatch = useDispatch();
    const data = useSelector((state) => state.toggle_header);
    const [selectedCouponId, setSelectedCouponId] = useState(null);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const toggleFilterVisibility = () => {
        setIsFilterVisible((prevVisibility) => !prevVisibility);
    };
    const [coupons, setCoupons] = useState([]);

    const [shouldReloadData, setShouldReloadData] = useState(false);
  

    const storedUserData = localStorage.getItem("user");
    const userData = JSON.parse(storedUserData);
    const id = userData?.data?.uid; //
    const [userRightssub, setUserRightssub] = useState([]);
    useEffect(() => {
      const fetchUserRightssub = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/userright/${id}`);
          if (!response.ok) {
            throw new Error("Failed to fetch user rights");
          }
          const data = await response.json();
          console.log("Fetched User Sub Data:", data.data);
          setUserRightssub(data.data || []); // Ensure it's always an array
        } catch (error) {
          console.error("Error fetching data:", error);
          setUserRightssub([]); // Set to empty array in case of error
        }
      };
    
      fetchUserRightssub(); // Fetch the data on component mount
    }, [id]);
    const hasJobRightWithAdd = userRightssub.some(right => right.right === 'Coupons' && right.adds === 1);
    const hasJobRightWithedit = userRightssub.some(right => right.right === 'Coupons' && right.updates === 1);
    //const hasJobRightWithview = userRightssub.some(right => right.right === 'Coupons' && right.viewes === 1);  
    const hasJobRightWithedel = userRightssub.some(right => right.right === 'Coupons' && right.deletes=== 1); 
    const hasJobRightWithview = userRightssub.some(right => right.right === 'Coupons' && right.viewes === 1);







    useEffect(() => {
      fetchCoupons();
    }, [shouldReloadData]);
  
    const fetchCoupons = async () => {
      try {
        const response = await fetch(`http://${BASE_URL}:8000/api/coupons`);
        if (!response.ok) {
          throw new Error("Failed to fetch coupons");
        }
        const data = await response.json();
        console.log("Fetched data:", data);
        setCoupons(data.data.coupons);
      } catch (error) {
        console.error("Error fetching coupons:", error);
      }
    };

    
  const handleReloadData = () => {
    setShouldReloadData(!shouldReloadData);
  };

    const oldandlatestvalue = [
        { value: 'date', label: 'Sort by Date' },
        { value: 'newest', label: 'Newest' },
        { value: 'oldest', label: 'Oldest' },
    ];
    const price = [
        { value: 'choose', label: 'Choose Type' },
        { value: 'fixed', label: 'Fixed' },
        { value: 'percentage', label: 'Percentage' },
      ];
    const pricelist = [
        { value: 'Choose Name', label: 'Choose Name' },
        { value: 'Coupons 21', label: 'Coupons 21' },
        { value: 'First Offer', label: 'First Offer' },
        { value: 'Offer 40', label: 'Offer 40' },
        { value: 'Subscription', label: 'Subscription' },
      ];
      const columns = [
        {
          render: () => (
            <label className="checkboxs">
              <input type="checkbox" />
              <span className="checkmarks" />
            </label>
          ),
        },
    
        {
          title: "Name",
          dataIndex: "Name",
        },
        {
          title: "Code",
          dataIndex: "Code",
        },
    
        {
          title: "Type",
          dataIndex: "Type",
        },
    
        {
          title: "Discount",
          dataIndex: "Discount",
        },
    
        {
          title: "Limit",
          dataIndex: "Limit",
        },
    
        {
          title: "Used",
          dataIndex: "Used",
        },
        {
            title: "Valid",
            dataIndex: "Valid",
          },
          {
            title: "Status",
            dataIndex: "Status",
          },
          {
            title: "Action",
            dataIndex: "Action",
          },
      ];
    
    const renderTooltip = (props) => (
        <Tooltip id="pdf-tooltip" {...props}>
            Pdf
        </Tooltip>
    );
    const renderExcelTooltip = (props) => (
        <Tooltip id="excel-tooltip" {...props}>
            Excel
        </Tooltip>
    );
    const renderPrinterTooltip = (props) => (
        <Tooltip id="printer-tooltip" {...props}>
            Printer
        </Tooltip>
    );
    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );
    const renderCollapseTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Collapse
        </Tooltip>
    );
    const [selectedDate, setSelectedDate] = useState(new Date());
      const handleDateChange = (date) => {
          setSelectedDate(date);
      };
    const MySwal = withReactContent(Swal);

    const showConfirmationAlert = (couponId) => {
        MySwal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonColor: "#00ff00",
          confirmButtonText: "Yes, delete it!",
          cancelButtonColor: "#ff0000",
          cancelButtonText: "Cancel",
        }).then((result) => {
          if (result.isConfirmed) {
            deleteCoupon(couponId);
            MySwal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              className: "btn btn-success",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          } else {
            MySwal.close();
          }
        });
      };

    
  const deleteCoupon = async (couponId) => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/coupons/${couponId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        handleReloadData();
        console.log("coupon deleted successfully");
      } else {
        console.error("Failed to delete coupon");
      }
    } catch (error) {
      console.error("Error deleting coupon:", error);
    }
  };
  
  const handleLinkClick = async (couponId) => {
    setSelectedCouponId(couponId);
    console.log(couponId)
  };

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredCouponss = coupons.filter(coupon => {
    const searchLower = searchQuery.toLowerCase();
    return (
        coupon.couponName.toLowerCase().includes(searchLower)
    );
});

    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Coupons</h4>
                                <h6>Manage Your Coupons</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                        <li>
                            <OverlayTrigger placement="top" overlay={renderTooltip}>
                                <Link>
                                    <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                                </Link>
                            </OverlayTrigger>
                        </li>
                        <li>
                            <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                                <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                    <ImageWithBasePath src="assets/img/icons/excel.svg" alt="img" />
                                </Link>
                            </OverlayTrigger>
                        </li>
                        <li>
                            <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>

                                <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                    <i data-feather="printer" className="feather-printer" />
                                </Link>
                            </OverlayTrigger>
                        </li>
                        <li>
                            <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>

                                <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                    <RotateCcw />
                                </Link>
                            </OverlayTrigger>
                        </li>
                        <li>
                            <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>

                                <Link
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    id="collapse-header"
                                    className={data ? "active" : ""}
                                    onClick={() => { dispatch(setToogleHeader(!data)) }}
                                >
                                    <ChevronUp />
                                </Link>
                            </OverlayTrigger>
                        </li>
                    </ul>
                        <div className="page-btn">
                        {hasJobRightWithAdd && (
                        <Link
                        to="#"
                        className="btn btn-added"
                        data-bs-toggle="modal"
                        data-bs-target="#add-units"
                            >
                                
                                <PlusCircle className="me-2"/>
                                Add New Coupons
                            </Link>
                            )}
                            
                        </div>
                    </div>
                    {/* /product list */}
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                               <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="search" 
                                            className="form-control form-control-sm formsearch" 
                                            value={searchQuery}
                                            onChange={handleSearchInputChange}
                                        />
                                        <Link to className="btn btn-searchset">
                                            <i data-feather="search" className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="search-path">
                                    <div className="d-flex align-items-center">
                                    <Link className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`} id="filter_search">
                                    <Filter
                                        className="filter-icon"
                                        onClick={toggleFilterVisibility}
                                    />
                                    <span onClick={toggleFilterVisibility}>
                                        <ImageWithBasePath src="assets/img/icons/closes.svg" alt="img" />
                                    </span>
                                </Link>
                                        <div className="layout-hide-box">
                                            <Link to="#" className="me-3 layout-box">
                                                <i data-feather="layout" className="feather-search" />
                                            </Link>
                                            <div className="layout-drop-item card">
                                                <div className="drop-item-head">
                                                    <h5>Want to manage datatable?</h5>
                                                    <p>
                                                        Please drag and drop your column to reorder your table and
                                                        enable see option as you want.
                                                    </p>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                            <span className="status-label">
                                                                <i data-feather="menu" className="feather-menu" />
                                                                Shop
                                                            </span>
                                                            <input
                                                                type="checkbox"
                                                                id="option1"
                                                                className="check"
                                                                defaultChecked=""
                                                            />
                                                            <label htmlFor="option1" className="checktoggle" />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                            <span className="status-label">
                                                                <i data-feather="menu" className="feather-menu" />
                                                                Product
                                                            </span>
                                                            <input
                                                                type="checkbox"
                                                                id="option2"
                                                                className="check"
                                                                defaultChecked=""
                                                            />
                                                            <label htmlFor="option2" className="checktoggle">
                                                                {" "}
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                            <span className="status-label">
                                                                <i data-feather="menu" className="feather-menu" />
                                                                Reference No
                                                            </span>
                                                            <input
                                                                type="checkbox"
                                                                id="option3"
                                                                className="check"
                                                                defaultChecked=""
                                                            />
                                                            <label htmlFor="option3" className="checktoggle">
                                                                {" "}
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                            <span className="status-label">
                                                                <i data-feather="menu" className="feather-menu" />
                                                                Date
                                                            </span>
                                                            <input
                                                                type="checkbox"
                                                                id="option4"
                                                                className="check"
                                                                defaultChecked=""
                                                            />
                                                            <label htmlFor="option4" className="checktoggle">
                                                                {" "}
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                            <span className="status-label">
                                                                <i data-feather="menu" className="feather-menu" />
                                                                Responsible Person
                                                            </span>
                                                            <input
                                                                type="checkbox"
                                                                id="option5"
                                                                className="check"
                                                                defaultChecked=""
                                                            />
                                                            <label htmlFor="option5" className="checktoggle">
                                                                {" "}
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                            <span className="status-label">
                                                                <i data-feather="menu" className="feather-menu" />
                                                                Notes
                                                            </span>
                                                            <input
                                                                type="checkbox"
                                                                id="option6"
                                                                className="check"
                                                                defaultChecked=""
                                                            />
                                                            <label htmlFor="option6" className="checktoggle">
                                                                {" "}
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                            <span className="status-label">
                                                                <i data-feather="menu" className="feather-menu" />
                                                                Quantity
                                                            </span>
                                                            <input
                                                                type="checkbox"
                                                                id="option7"
                                                                className="check"
                                                                defaultChecked=""
                                                            />
                                                            <label htmlFor="option7" className="checktoggle">
                                                                {" "}
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                            <span className="status-label">
                                                                <i data-feather="menu" className="feather-menu" />
                                                                Actions
                                                            </span>
                                                            <input
                                                                type="checkbox"
                                                                id="option8"
                                                                className="check"
                                                                defaultChecked=""
                                                            />
                                                            <label htmlFor="option8" className="checktoggle">
                                                                {" "}
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-sort">
                                <Sliders className="info-img" />
                                <Select
                                    className="select"
                                    options={oldandlatestvalue}
                                    placeholder="Newest"
                                />
                                </div>
                            </div>
                            {/* /Filter */}
                            <div
                            className={`card${isFilterVisible ? " visible" : ""}`}
                            id="filter_inputs"
                            style={{ display: isFilterVisible ? "block" : "none" }}
                        >                                
                        <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <Zap className="info-img"/>
                                                
                                                <Select
                                                className="select"
                                                options={pricelist}
                                                placeholder="Choose Type"
                                              />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                               
                                                <Box className="info-img"/>
                                                <Select
                                                className="select"
                                                options={price}
                                                placeholder="Choose Type"
                                              />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                            <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            type="date"
                                            className="filterdatepicker"
                                            dateFormat="dd-MM-yyyy"
                                            placeholder='20-2-2024'
                                        />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                                            <div className="input-blocks">
                                                <Link className="btn btn-filters ms-auto">
                                                    {" "}
                                                    <i data-feather="search" className="feather-search" />{" "}
                                                    Search{" "}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Filter */}
                            <div className="table-responsive">
                            <Table
                                className="table datanew"
                                columns={columns}
                                dataSource={filteredCouponss.map((coupon) => ({
                                key: coupon._id,
                                Name: coupon.couponName,
                                Code: (
                                    <span className="badge badge-bgdanger">
                                      {coupon.couponCode}
                                    </span>
                                  ),
                                Type: coupon.couponType,
                                Discount: coupon.couponDiscount,
                                Limit: coupon.couponLimit,
                                Used: 0,
                                Valid: coupon.couponEndDate,
                                Status: (
                                    <span className={`badge ${coupon.couponState ? 'badge-linesuccess' : 'badge-linedanger'}`}>
                                      {coupon.couponState ? 'Active' : 'Inactive'}
                                    </span>
                                  ),
                                Action: (
                                    <td className="action-table-data">
                                    <div className="edit-delete-action">
                                        <div className="input-block add-lists"></div>
                                       
                                        {hasJobRightWithview && (
                                             <Link className="me-2 p-2" to="#">
                                             <Eye className="feather-view" />
                                             </Link>
                                        )}

                                        {/* <Link
                                        className="me-2 p-2"
                                        to="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#edit-units"
                                        >
                                        <Edit className="feather-edit" />
                                        </Link> */}
                                        {hasJobRightWithedit && (
                                            <Link
                                            className="me-2 p-2"
                                            to="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#edit-units"
                                            onClick={() => handleLinkClick(coupon._id)}
                                            >
                                            <Edit className="feather-edit" />
                                            </Link>
                                        )}
                                        {hasJobRightWithedel && (
                                              <Link
                                              className="confirm-text p-2"
                                              to="#"
                                              onClick={() => showConfirmationAlert(coupon._id)}
                                              >
                                              <Trash2 className="feather-trash-2" />
                                              </Link>
                                        )}
                                    </div>
                                    </td>
                                ),
                                }))}
                                pagination={false}
                            />
                            </div>
                        </div>
                    </div>
                    {/* /product list */}
                </div>
            </div>
            <AddCoupons onCouponAdded={handleReloadData} couponId={selectedCouponId} />
     </div>
    )
}

export default Coupons
