import Select from "react-select";
import {Spin} from "antd";
import React, {useState} from "react";
import PropTypes from "prop-types";


const CreateAllowance = ({selectedAllowance,handleCancel}) => {

    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
      e.preventDefault();
      setLoading(true);
    }
    return (
        <div className="modal-content">
            <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                    <h4>{selectedAllowance ? "Edit Allowance" : "Create Allowances "}</h4>
                    <p className="mb-0">Fill in the required fields to create a allowance</p>
                </div>
            </div>
            <div className="modal-body custom-modal-body">
                <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="input-blocks">
                                <label htmlFor="selectEmployee">Select Employee</label>
                                <input
                                    type="text"
                                    id="selectEmployee"
                                    className="form-control"
                                    aria-label="Select Employee"

                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="input-blocks">
                                <label>Select Allowance Type</label>
                                <Select
                                    className="select"
                                    // options={allowanceOptions}
                                    placeholder="Select an allowance type"
                                    // value={allowanceType}
                                    // onChange={handleAllowanceType}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="input-blocks">
                                <label>Enter Amount</label>
                                <input
                                    className="form-control"
                                    // options={allowanceOptions}
                                    placeholder="Select an allowance type"
                                    // value={allowanceType}
                                    // onChange={handleAllowanceType}
                                />
                            </div>
                        </div>

                    </div>
                </form>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <button
                        type="button"
                        className="btn btn-cancel"
                        onChange={handleCancel}
                    >
                        Close
                    </button>
                    <div>
                        <button
                            type="button"
                            className="btn btn-reset me-2"
                        >
                            Reset
                        </button>
                        <button className="btn btn-submit" onClick={handleSubmit}>
                            {loading ? <Spin size='small'/> : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

CreateAllowance.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    selectedAllowance: PropTypes.object.isRequired
};

export default CreateAllowance;