import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createCoupon, getCoupon, updateCoupon } from "../../../service/ApiServices";
import { format } from 'date-fns';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { BASE_URL } from "../../../environment";



const AddCoupons = ({ onCouponAdded, couponId }) => {
    const [formData, setFormData] = useState({
        couponName: "",
        couponCode: "",
        couponType: "",
        couponDiscount: 0,
        couponLimit: 0,
        couponStartDate: null,
        couponEndDate: null,
        oncePerCustomer:true,
        product: "",
        couponState: true,
    
      });
    
      const [products, setProducts] = useState([]);

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

      useEffect(() => {
        fetchProduct();
      }, []);
    
      const fetchProduct = async () => {
        try {
          const response = await fetch(`http://${BASE_URL}:8000/api/products`);
          if (!response.ok) {
            throw new Error("Failed to fetch ware Houses");
          }
          const data = await response.json();
          const options = data.data.products.map((product) => ({
            label: product.productName,
            value: product._id,
          }));
          
          setProducts(options);
        } catch (error) {
          console.error("Error fetching product:", error);
        }
      };

      const setSelectedCoupon = async (couponId) => {
        try {
          const response = await getCoupon(couponId);
          if (response.data) {
            const coupon = response.data.data.coupon;
            const productOption = products.find(c => c.value === coupon.product);
            console.log(productOption)
            setFormData({
              ...formData,
              couponName: coupon.couponName,
              couponCode: coupon.couponCode,
              couponType: coupon.couponType,
              couponDiscount: coupon.couponDiscount,
              couponLimit: coupon.couponLimit,
              couponStartDate: new Date(coupon.couponStartDate), // Ensure correct date parsing
              couponEndDate: new Date(coupon.couponEndDate),
              oncePerCustomer: coupon.oncePerCustomer,
              product: productOption,
              couponState: coupon.couponState,
            });  
          } else {
            console.error("Failed to fetch coupon data");
          }
        } catch (error) {
          console.error("Error fetching coupon data:", error);
        }
      };

      useEffect(() => {
        if (couponId) {
          setSelectedCoupon(couponId);
        }
      }, [couponId, products]);
    
      const handleUpdateSubmit = async (e) => {
        e.preventDefault();
        try {
          const formattedDate1 = formData.couponStartDate ? format(formData.couponStartDate, "dd/MM/yyyy") : "";
          const formattedDate2 = formData.couponEndDate ? format(formData.couponEndDate, "dd/MM/yyyy") : "";
    
          const couponData = {
            ...formData,
            couponStartDate: formattedDate1,
            couponEndDate: formattedDate2,
            couponDiscount: parseFloat(formData.couponDiscount), // Ensure it's a number
            couponLimit: parseFloat(formData.couponLimit), // Ensure it's a number
          };

          const updatedFormData = { ...couponData, product: formData.product.value,
            couponType: formData.couponType.value,
          };
          const response = await updateCoupon(couponId, updatedFormData);
    
          // const response = await updateCoupon(couponId, couponData); 
          onCouponAdded();
          console.log("coupon updated successfully:", response);
          setFormData({
            // Clear form data if needed
            couponName: "",
            couponCode: "",
            couponType: "",
            couponDiscount: 0,
            couponLimit: 0,
            couponStartDate: null,
            couponEndDate: null,
            oncePerCustomer:true,
            product: "",
            couponState: true,
          });
        } catch (error) {
          console.error("Error updating coupon:", error);
        }
      };
  
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const formattedDate1 = formData.couponStartDate ? format(formData.couponStartDate, "dd/MM/yyyy") : "";
          const formattedDate2 = formData.couponEndDate ? format(formData.couponEndDate, "dd/MM/yyyy") : "";
    
          const couponData = {
            ...formData,
            couponStartDate: formattedDate1,
            couponEndDate: formattedDate2,
            couponDiscount: parseFloat(formData.couponDiscount), // Ensure it's a number
            couponLimit: parseFloat(formData.couponLimit), // Ensure it's a number
          };
            console.log("-------------------")
            console.log(couponData)
            const updatedFormData = { ...couponData, product: formData.product.value,
              couponType: formData.couponType.value,
            };
            const response = await createCoupon(updatedFormData);
            onCouponAdded();
            console.log("coupon added successfully:", response.data);
          setFormData({
            couponName: "",
            couponCode: "",
            couponType: "",
            couponDiscount: 0,
            couponLimit: 0,
            couponStartDate: null,
            couponEndDate: null,
            oncePerCustomer:true,
            product: "",
            couponState: true,
          });
        } catch (error) {
          console.error("Error adding coupon:", error);
        }
      };

      const price = [
        { value: 'choose', label: 'Choose Type' },
        { value: 'Fixed', label: 'Fixed' },
        { value: 'Percentage', label: 'Percentage' },
      ];
      // const list = [
      //   { value: 'choose', label: 'Choose' },
      //   { value: 'nikeJordan', label: 'Nike Jordan' },
      //   { value: 'amazonEchoDot', label: 'Amazon Echo Dot' },
      // ];
      // const [couponStartDate, setCouponStartDate] = useState(null);
      const handleDateChange = (date) => {
          // setCouponStartDate(date);

          setFormData((prevData) => ({
            ...prevData,
            couponStartDate: date,
        }));
      };
      // const [couponEndDate, setCouponEndDate] = useState(null);
      const handleDateChange1 = (date) => {
          // setCouponEndDate(date);

          setFormData((prevData) => ({
            ...prevData,
            couponEndDate: date,
        }));
    };
    const cancelClick = () => {
      setFormData({
        // Clear form data if needed
        couponName: "",
        couponCode: "",
        couponType: "",
        couponDiscount: 0,
        couponLimit: 0,
        couponStartDate: null,
        couponEndDate: null,
        oncePerCustomer:true,
        products: "",
        couponState: true,
      });
    }
    return (
        <div>
        {/* Add coupons */}
        <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
            <div className="modal-content">
            <div className="page-wrapper-new p-0">
                <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                    <h4>Add Coupons</h4>
                    </div>
                    <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body custom-modal-body">
                    <form onSubmit={handleSubmit} >
                    <div className="row">
                        <div className="col-lg-6">
                        <div className="mb-3">
                            <label className="form-label">Name</label>
                            <input
                            type="text"
                            className="form-control"
                            name="couponName"
                            value={formData.couponName}
                            onChange={handleChange}
                            required
                            />
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="mb-3">
                            <label className="form-label">Code</label>
                            <input
                            type="text"
                            className="form-control"
                            name="couponCode"
                            value={formData.couponCode}
                            onChange={handleChange}
                            required
                            />
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="mb-3">
                            <label className="form-label">Type</label>
                            <Select
                            options={price}
                            value={formData.couponType}
                            placeholder="Choose Type"
                            onChange={(selectedOption) => setFormData((prevData) => ({
                                ...prevData,
                                couponType: selectedOption,
                            }))}
                            required
                            />
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="mb-3">
                            <label className="form-label">Discount</label>
                            <input
                            type="text"
                            className="form-control"
                            name="couponDiscount"
                            value={formData.couponDiscount}
                            onChange={handleChange}
                            required
                            />
                        </div>
                        </div>
                        <div className="col-lg-12">
                        <div className="mb-3">
                            <label className="form-label"> Limit</label>
                            <input
                            type="text"
                            className="form-control"
                            name="couponLimit"
                            value={formData.couponLimit}
                            onChange={handleChange}
                            required
                            />
                            <span className="unlimited-text">0 for Unlimited</span>
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="input-blocks">
                            <label>Start Date</label>
                            <div className="input-groupicon calender-input">
                            <DatePicker
                                name="expenseDate"
                                selected={formData.couponStartDate}
                                onChange={handleDateChange}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Choose Date"
                                className="datetimepicker"
                                required

                            />
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="input-blocks">
                            <label>End Date</label>
                            <div className="input-groupicon calender-input">
                            <DatePicker
                            name="couponDate"
                            selected={formData.couponEndDate}
                            onChange={handleDateChange1}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Choose Date"
                            className="filterdatepicker"
                            type="date"
                            required
                            // placeholder='20-2-2024'
                            />
                            {/* <DatePicker
                            selected={selectedDate1}
                            onChange={handleDateChange1}
                            type="date"
                            className="filterdatepicker"
                            dateFormat="dd-MM-yyyy"
                            placeholder='20-2-2024'
                        /> */}
                            </div>
                        </div>
                        </div>
                        <div className="input-blocks">
                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center mb-2">
                            <span className="status-label">All Products</span>
                            <div className="d-flex align-items-center">
                            <input
                            type="checkbox"
                            id="user4"
                            className="check"
                            name="oncePerCustomer"
                            required
                            checked={formData.oncePerCustomer} // Bind to `expenseState`
                            onChange={(e) => {
                                setFormData((prevData) => ({
                                ...prevData,
                                oncePerCustomer: e.target.checked, // Update state based on checkbox value
                                }));
                            }}
                            />
                            <label
                                htmlFor="user4"
                                className="checktoggle mb-0 me-1"
                            />
                            <span className="customer-toggle">
                                Once Per Customer
                            </span>
                            </div>
                        </div>
                        {/* <Select
                            options={list}
                            placeholder="Choose"
                            onChange={ setFormData((prevData) => ({
                                ...prevData,
                                products: null,
                            }))}
                            /> */}
                            {/* <Select
                            options={list}
                            placeholder="Choose"
                            onChange={(selectedOption) => setFormData((prevData) => ({
                              ...prevData,
                              products: selectedOption.value,
                            }))}
                            // placeholder="Choose"
                          /> */}
                           <Select
                            options={products}
                            placeholder="Choose product"
                            value={formData.product}
                            onChange={(selectedOption) => setFormData((prevData) => ({
                              ...prevData,
                              product: selectedOption,
                            }))}
                            required
                            onInvalid={(e) => e.target.setCustomValidity('Please select product')}
                            onInput={(e) => e.target.setCustomValidity('')}
                            // placeholder="Choose"
                          />
                        {/* <Select
                            className="select"
                            options={list}
                            placeholder="Choose"
                        /> */}
                        </div>
                        <div className="input-blocks m-0">
                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                            <span className="status-label">Status</span>
                            <input
                            type="checkbox"
                            id="user5"
                            className="check"
                            name="couponState"
                            checked={formData.couponState} // Bind to `expenseState`
                            onChange={(e) => {
                                setFormData((prevData) => ({
                                ...prevData,
                                couponState: e.target.checked, // Update state based on checkbox value
                                }));
                            }}
                            required
                            />
                            <label htmlFor="user5" className="checktoggle">
                            {" "}
                            </label>
                        </div>
                        </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        onClick={cancelClick}
                      >
                      Cancel
                      </button>
                      <button type="submit"
                        className="btn btn-submit"
                        data-bs-dismiss="modal">
                        Create Coupon
                      </button>
                    </div>
                  </form>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
{/* /Add Coupons */}
{/* Edit coupons */}
<div className="modal fade" id="edit-units">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Edit Coupons</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                <form onSubmit={handleUpdateSubmit} >
                                    <div className="row">
                                        <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Name</label>
                                            <input
                                            type="text"
                                            className="form-control"
                                            name="couponName"
                                            value={formData.couponName}
                                            onChange={handleChange}
                                            required
                                            />
                                        </div>
                                        </div>
                                        <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Code</label>
                                            <input
                                            type="text"
                                            className="form-control"
                                            name="couponCode"
                                            value={formData.couponCode}
                                            onChange={handleChange}
                                            required
                                            />
                                        </div>
                                        </div>
                                        <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Type</label>
                                            <Select
                                            options={price}
                                            placeholder="Choose Type"
                                            onChange={(selectedOption) => setFormData((prevData) => ({
                                                ...prevData,
                                                couponType: selectedOption.value,
                                            }))}
                                            required
                                            />
                                        </div>
                                        </div>
                                        <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Discount</label>
                                            <input
                                            type="text"
                                            className="form-control"
                                            name="couponDiscount"
                                            value={formData.couponDiscount}
                                            onChange={handleChange}
                                            required
                                            />
                                        </div>
                                        </div>
                                        <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label className="form-label"> Limit</label>
                                            <input
                                            type="text"
                                            className="form-control"
                                            name="couponLimit"
                                            value={formData.couponLimit}
                                            onChange={handleChange}
                                            required
                                            />
                                            <span className="unlimited-text">0 for Unlimited</span>
                                        </div>
                                        </div>
                                        <div className="col-lg-6">
                                        <div className="input-blocks">
                                            <label>Start Date</label>
                                            <div className="input-groupicon calender-input">
                                            <DatePicker
                                            name="couponDate"
                                            selected={formData.couponStartDate}
                                            onChange={handleDateChange}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="Choose Date"
                                            className="datetimepicker"
                                            required
                                            />
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-lg-6">
                                        <div className="input-blocks">
                                            <label>End Date</label>
                                            <div className="input-groupicon calender-input">
                                            <DatePicker
                                            name="couponDate"
                                            selected={formData.couponEndDate}
                                            onChange={handleDateChange1}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="Choose Date"
                                            className="datetimepicker"
                                            required
                                            />
                                            {/* <DatePicker
                                            selected={selectedDate1}
                                            onChange={handleDateChange1}
                                            type="date"
                                            className="filterdatepicker"
                                            dateFormat="dd-MM-yyyy"
                                            placeholder='20-2-2024'
                                        /> */}
                                            </div>
                                        </div>
                                        </div>
                                        <div className="input-blocks">
                                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center mb-2">
                                            <span className="status-label">All Products</span>
                                            <div className="d-flex align-items-center">
                                            <input
                                            type="checkbox"
                                            id="user2"
                                            className="check"
                                            name="oncePerCustomer"
                                            checked={formData.oncePerCustomer} // Bind to `expenseState`
                                            onChange={(e) => {
                                                setFormData((prevData) => ({
                                                ...prevData,
                                                oncePerCustomer: e.target.checked, // Update state based on checkbox value
                                                }));
                                            }}
                                            required
                                            />
                                            <label
                                                htmlFor="user2"
                                                className="checktoggle mb-0 me-1"
                                            />
                                            <span className="customer-toggle">
                                                Once Per Customer
                                            </span>
                                            </div>
                                        </div>
                                        {/* <Select
                                            options={list}
                                            placeholder="Choose"
                                            onChange={ setFormData((prevData) => ({
                                                ...prevData,
                                                products: null,
                                            }))}
                                            /> */}
                                        {/* <Select
                                            className="select"
                                            options={list}
                                            placeholder="Choose"
                                        /> */}
                                        <Select
                                          options={products}
                                          placeholder="Choose product"
                                          value={formData.product}
                                          onChange={(selectedOption) => setFormData((prevData) => ({
                                            ...prevData,
                                            product: selectedOption,
                                          }))}
                                          required
                                          onInvalid={(e) => e.target.setCustomValidity('Please select product')}
                                          onInput={(e) => e.target.setCustomValidity('')}
                                          // placeholder="Choose"
                                        />
                                        </div>
                                        <div className="input-blocks m-0">
                                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                            <span className="status-label">Status</span>
                                            <input
                                            type="checkbox"
                                            id="user3"
                                            className="check"
                                            name="couponState"
                                            checked={formData.couponState} // Bind to `expenseState`
                                            onChange={(e) => {
                                                setFormData((prevData) => ({
                                                ...prevData,
                                                couponState: e.target.checked, // Update state based on checkbox value
                                                }));
                                            }}
                                            required
                                            />
                                            <label htmlFor="user3" className="checktoggle">
                                            {" "}
                                            </label>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer-btn">
                                        <button
                                        type="button"
                                        className="btn btn-cancel me-2"
                                        data-bs-dismiss="modal"
                                        onClick={cancelClick}
                                        >
                                        Cancel
                                        </button>
                                        <button type="submit"
                                            className="btn btn-submit"
                                            data-bs-dismiss="modal">
                                            Create Coupon
                                        </button>
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Edit Coupons */}
    </div>
  );
};
AddCoupons.propTypes = {
    onCouponAdded: PropTypes.func.isRequired,
    couponId: PropTypes.string,
  };
  
  export default AddCoupons
