
import React from "react";
import { HashRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import { base_path } from "./environment.jsx";
import '../src/style/css/feather.css'
import '../src/style/css/line-awesome.min.css'
import "../src/style/scss/main.scss";
import '../src/style/icons/fontawesome/css/fontawesome.min.css'
import '../src/style/icons/fontawesome/css/all.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Provider } from "react-redux";
import {UserProvider} from '../src/context/userContext.js'
import store from "./core/redux/store.jsx";
import AllRoutes from "./Router/router.jsx";
import axios from "axios";
import { QueryClientProvider, QueryClient } from 'react-query'
import 'bootstrap/dist/css/bootstrap.min.css';  // Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js';  // Bootstrap JS (includes Popper.js)

const rootElement = document.getElementById('root');
//axios.defaults.baseURL = 'https://api-thesuit.eyerax.tech/api'; // Replace with your actual base URL
//axios.defaults.baseURL = 'https://api-thesuit.eyerax.tech/api'; // Replace with your actual base URL
//axios.defaults.baseURL = 'http://localhost:8000/api';
 axios.defaults.baseURL = 'http://62.169.29.53:8000/api';
axios.defaults.headers.common['Content-Type'] = 'application/json';
// Replace with your actual base URL
const queryClient = new QueryClient();


// Add interceptor to include user info in request headers
axios.interceptors.request.use(
    async (config) => {
        // Attach session token or user info to headers
        // const userId = await JSON.parse(localStorage.getItem('user')).data._id;// Or fetch from context or cookies
        console.log("USERID",localStorage.getItem('user'))
        if (localStorage.getItem('user') != null) {
            console.log("USERID AFTER LOGIN",localStorage.getItem('user'))
            config.headers['user_id'] = JSON.parse(localStorage.getItem('user')).data._id; // Use a custom header for user session
        }
        return config;
    },
    (error) => {
        console.log("Error",error)
        return Promise.reject(error);
    }
);

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
      <QueryClientProvider client={queryClient} >
        <UserProvider>
        <Provider store={store} >
          <HashRouter basename={base_path}>
            <AllRoutes />
          </HashRouter>
        </Provider>
        </UserProvider>
      </QueryClientProvider>
      
  );
} else {
  console.error("Element with id 'root' not found.");
}
