import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {  Filter, PlusCircle } from 'feather-icons-react/build/IconComponents';
import Select from 'react-select';
import { DatePicker, Modal, Popconfirm, Table, Tooltip as ToolPro } from 'antd';
import AddJob from '../../core/modals/inventory/addjob';

import { deleteJobMethod, getAllJobMethod, jobStatusChangeMethod } from '../../service/ApiServices';
import Swal from 'sweetalert2';
import { useRef } from 'react';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
const { RangePicker } = DatePicker;
import dayjs from 'dayjs';
import { getJobByNo} from "../../service/jobApiServices";
import moment from "moment";
const JobList = () => {
  const startOfCurrentMonth = dayjs().startOf('month');
  const endOfCurrentMonth = dayjs().endOf('month');

  const [selectedRange, setSelectedRange] = useState([
    startOfCurrentMonth,
    endOfCurrentMonth
  ]);

  const [isJobAdded, setIsJobAdded] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isFilterVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isItemDeleted, setIsItemDeleted] = useState(false)
  const [selectedJob, setSelectedJob] = useState("")
  const [loading, setLoading] = useState(false)
  const [jobData, setJobData] = useState({
    data: [],
    page: 1,
    limit: 10,
    total: 0
  });
  const addJobRef = useRef();

  const callHandleReset = () => {
    if (addJobRef.current) {
      addJobRef.current.handleReset();
    }
  };

  useEffect(() => {
    if (isJobAdded || isStatusChanged || isItemDeleted) {
      const fetchJobData = async () => {
        try {
          const response = await getAllJobMethod({
            search: searchQuery,
            params: { page: jobData.page, limit: jobData.limit },
            setLoading,
            startDate: selectedRange[0].format("YYYY-MM-DD"),
            endDate: selectedRange[1].format("YYYY-MM-DD")
          })
          setJobData(response);
        } catch (error) {
          console.error('Error fetching job data:', error);
        }
      };
      fetchJobData();
    }
  }, [isJobAdded, isStatusChanged, isItemDeleted])

  useEffect(() => {
    const handler = setTimeout(async () => {
      const response = await getAllJobMethod({
        search: searchQuery,
        setLoading,
        params: {
          page: jobData.page, limit: jobData.limit,
          startDate: selectedRange[0].format("YYYY-MM-DD"),
          endDate: selectedRange[1].format("YYYY-MM-DD")
        },
        startDate: selectedRange[0].format("YYYY-MM-DD"),
        endDate: selectedRange[1].format("YYYY-MM-DD")
      })
      setJobData(response);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery, selectedRange]);

  const handleJobAdded = () => {
    setIsJobAdded(true);
    setTimeout(() => {
      setIsJobAdded(false);
    }, 3000);
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    //setSelectedJob("")
    setIsModalOpen(false);
  };

  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const showModal3 = async (record) => {
    console.log("Record", record)
    let job = await getJobByNo(record.jobNo);
    console.log("JOB",job)
    setSelectedJob(job)
    setIsModalOpen3(true);
  };

  const handleOk3 = () => {
    setIsModalOpen3(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };



  const oldandlatestvalue = [
    { value: 'date', label: 'Sort by Date' },
    { value: 'newest', label: 'Newest' },
    { value: 'oldest', label: 'Oldest' },
  ];
  const statusOptions = [
    { value: 'choose Status', label: 'Choose Status' },
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
  ];
  const columns = [
    {
      title: "Job No",
      dataIndex: "jobNo",
      sorter: (a, b) => a.jobNo.length - b.jobNo.length,
    },
    {
      title: "Invoice No",
      dataIndex: "jobNo",
      sorter: (a, b) => a.jobNo.length - b.jobNo.length,
    },
    {
      title: "Customer",
      dataIndex: ["customer", "customerName"],
      sorter: (a, b) => a.customer.length - b.customer.length,
    },
    {
      title: "Job Date",
      dataIndex: "jobDate",
      render: (text, record) => {
        return record.jobDate ? new Date(record.jobDate).toLocaleDateString() :
            <span className="badge bg-danger">N/A</span>
      },
      sorter: (a, b) => new Date(a.deliveryDate) - new Date(b.deliveryDate),
    },
    {
      title: "FitOn Date",
      dataIndex: "fitOnDate",
      render: (text, record) => {
        return record.fitOnDate ? new Date(record.fitOnDate).toLocaleDateString() :
            <span className="badge bg-danger">N/A</span>
      },
      sorter: (a, b) => new Date(a.deliveryDate) - new Date(b.deliveryDate),
    },
    {
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      render: (text, record) => {
        return record.deliveryDate ? new Date(record.deliveryDate).toLocaleDateString() :
            <span className="badge bg-danger">N/A</span>
      },
      sorter: (a, b) => new Date(a.deliveryDate) - new Date(b.deliveryDate),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (

        <div style={{
          display: 'flex',
          alignContent: 'start',
          justifyContent: "space-between",
          width: "120px"
        }}>
          <div className={text === 'pending' ? "badge bg-warning" : text ==='in-progress'?"badge bg-info":text === 'completed'?"badge bg-success":text==='cancelled'? "badge bg-danger":""}>
            {text}
          </div>
          <ToolPro title="Edit Status">
            <div className="add-newplus">
              <Link onClick={() => {
                showModal2()
                setSelectedElement(record)
                setSelectedStatus(record.status)
              }}>
                <PlusCircle className="plus-down-add" />
              </Link>
            </div>
          </ToolPro>
        </div>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <Link className="me-2 p-2" to="#"
              hidden
              onClick={() => {
                setSelectedJob(record)
                showModal()
              }}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="me-2 p-2" to="#"
              onClick={() => {

                showModal3(record)
              }}
            >
              <i data-feather="show" className="feather-eye"></i>
            </Link>
            <Link className="me-2 p-2" to="#"
              onClick={() => {
                generatePDF(record)
              }}
            >
              <i data-feather="download" className="feather-download"></i>
            </Link>
            <Popconfirm
              title="Delete the job"
              description="Are you sure to delete this job?"
              onConfirm={() => {

                deleteJobMethod({
                  jobId: record._id,
                  setIsItemDeleted,
                  showSuccessDeleted
                })
              }}
              onCancel={() => {
                console.log("c")
              }}
              okText="Yes"
              cancelText="No"
            >
              <Link  className="confirm-text p-2" to="#"  >
                <i data-feather="trash-2"
                  className="feather-trash-2"

                ></i>
              </Link>
            </Popconfirm>
          </div>
        </td>
      )
    },
  ];

  const showSuccessChangeStatus = () => {
    return Swal.fire({
      title: "Good job!",
      text: "You Successfully Change Status!",
      icon: "success"
    });
  }
  const showSuccessDeleted = () => {
    return Swal.fire({
      title: "Good job!",
      text: "You Successfully Cancelled Job!",
      icon: "success"
    });
  }
  const generatePDF = async (data) => {

    let job = await getJobByNo(data.jobNo);
    const { jobNo, fitOnDate, finalFitOnDate, deliveryDate, tailor, cutter, jobTypesInfo } = job;
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    doc.setFontSize(10);
    doc.text(`Job No: ${jobNo}`, 10, 10);
    doc.text(`Fit On Date: ${new Date(fitOnDate).toLocaleDateString()}`, 10, 30);
    doc.text(`Final Fit On Date: ${new Date(finalFitOnDate).toLocaleDateString()}`, 60, 30);
    doc.text(`Delivery Date: ${new Date(deliveryDate).toLocaleDateString()}`, 120, 30);
    doc.text(`Tailor: ${tailor.first_name + " "+tailor.last_name}`, 10, 40);
    doc.text(`Cutter: ${cutter.first_name + " "+cutter.last_name}`, 60, 40);

    // Draw job type info boxes
    let yPosition = 50;
    doc.setDrawColor(182, 182, 181);
    doc.line(0, 45, pageWidth, 45);

    jobTypesInfo.forEach((jobTypeInfo) => {
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.text(`Job Type : ${jobTypeInfo.jobType.type}`, 10, yPosition);
      doc.text(`Quantity: ${jobTypeInfo.qty}`, 60, yPosition);
      yPosition += 8;
      doc.text(`Measurements: ${jobTypeInfo.measurements}`, 10, yPosition);
      yPosition += 8;


      doc.text("Styles", 10, yPosition);
      doc.setFontSize(10);
      yPosition += 8;

      jobTypeInfo.styles.forEach((style) => {
        doc.setFont('helvetica', 'normal');
        doc.text(`${style.style.styleName}:`, 10, yPosition);
        doc.text(style.selectedValues, 30, yPosition);
        yPosition += 8;
      });


      doc.setTextColor(182, 182, 181);
      doc.text("Body Types", 10, yPosition);
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(9);
      yPosition += 8;

      jobTypeInfo.bodyTypes.forEach((type) => {

        doc.setFont('helvetica', 'normal');
        type.values.forEach((value) => {
          doc.text(`${type.name} : ${value}`, 10, yPosition);
          yPosition += 8;
        })
      });

      doc.line(10, yPosition + 4, pageWidth - 10, yPosition + 4);
      yPosition += 8; // Extra space between different job types
    });


    doc.save(`job-sheet-${jobNo}.pdf`);
  };
  // const generatePDF1 = () => {
  //   // Create a new jsPDF instance
  //   const doc = new jsPDF({
  //     orientation: 'portrait',
  //     unit: 'mm',
  //     format: 'a4'
  //   });
  //
  //   // Define the data for the table
  //   const data = [
  //     ['Row 1', 'Column 1', 'Column 2'],
  //     ['Row 2', 'Data 1', 'Data 2'],
  //     ['Row 3', 'Data 3', 'Data 4'],
  //     // Add more rows as needed
  //   ];
  //
  //   // Create a table on a quarter of the page
  //   doc.autoTable({
  //     body: data,
  //     startY: 10, // Set start position of the table
  //     tableWidth: 105, // Set the width to half of A4 (A4 is 210mm wide, half is 105mm)
  //     theme: 'grid', // Can be 'plain', 'striped', or 'grid'
  //   });
  //
  //   // Save the PDF
  //   doc.save('quarter_a4.pdf');
  // };
  const handleTableChange = async (pagination) => {
    const response = await getAllJobMethod({
      search: searchQuery,
      params: { page: pagination.current, limit: pagination.pageSize },
      setLoading,
      startDate: selectedRange[0].format("YYYY-MM-DD"),
      endDate: selectedRange[1].format("YYYY-MM-DD")
    })
    setJobData(response);
  };
  const onChange = (dates) => {
    if (dates === null) {
      setSelectedRange([startOfCurrentMonth, endOfCurrentMonth])
      return
    }
    setSelectedRange(dates);
  };
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Jobs</h4>
              <h6>Manage your jobs</h6>
            </div>
          </div>

          <div className="page-btn">
            <a

              className="btn btn-added"
              onClick={() => {
                showModal()
                callHandleReset()
              }}
            >
              <PlusCircle className="me-2" />
              Create Job
            </a>
          </div>
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="search"
                    className="form-control"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <Link to="#" className="btn btn-searchset">
                    <i data-feather="search"
                      className="feather-search"
                    />
                  </Link>
                </div>
                <RangePicker onChange={onChange} value={selectedRange} format="YYYY-MM-DD" />

              </div>

            </div>
            {isFilterVisible && (
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        placeholderText="Choose Date"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <Select options={statusOptions} />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <Select options={oldandlatestvalue} />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <a className="btn btn-filters ms-auto">
                        <Filter className="me-2" /> Filter
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Table
              columns={columns}
              dataSource={jobData?.data}
              rowKey="_id"
              pagination={{
                current: jobData?.page,
                pageSize: jobData?.limit,
                total: jobData?.total
              }}
              loading={loading}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Change Status"
        width={"25vw"}
        zIndex={100000}
        open={isModalOpen2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        maskClosable={false}
        footer={null}
      >
        <div className="col-lg-12 col-sm-12 col-12">
          <div className="input-blocks">
            <select
              className="form-select"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value={"pending"}>Pending</option>
              <option value={"in-progress"}>In Progress</option>
              <option value={"completed"}>Completed</option>
              <option value={"delivered"}>Delivered</option>
            </select>
          </div>
        </div>
        <div className="modal-footer-btn d-flex justify-content-start">
          <button
            type="button"
            className="btn btn-cancel me-2"
            onClick={handleCancel2}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-save"
            onClick={() => {
              jobStatusChangeMethod({
                jobId: selectedElement._id,
                jobStatus: selectedStatus,
                setIsStatusChanged,
                handleCancel: handleCancel2,
                showSuccessChangeStatus
              })
            }}
          >
            Save
          </button>
        </div>
      </Modal>
      <Modal
        title=""
        width={"75vw"}
        zIndex={100000}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={() => {
          //setSelectedJob("")
          setIsModalOpen(false)
        }}
        closable={false}
        maskClosable={false}
        footer={null}
      >
        <AddJob
          ref={addJobRef}
          handleCancel2={handleCancel}
          handleJobAdded={handleJobAdded}
          selectedJob={selectedJob}
        />
      </Modal>
      <Modal
          width="50vw"
          zIndex={10000}
          open={isModalOpen3}
          onOk={handleOk3}
          onCancel={() => {
            setSelectedJob("")
            setIsModalOpen3(false)
          }}
          maskClosable={false}
          footer={null}
      >
        <div className="col-xl-12">
          <div className="card shadow-sm border-0">
            <div className="card-header justify-content-between">
              <h3>
                Job No <span className="badge bg-primary">{selectedJob.jobNo} <span
                  className="badge bg-primary">{selectedJob.status}</span></span>

              </h3>
            </div>
            <div className="card-body">
              <dl className="row mb-0">
                <dt className="col-sm-3">Customer</dt>
                <dd className="col-sm-9">
                  {selectedJob?.customer?.customerName}
                </dd>
                <dt className="col-sm-3">Email</dt>
                <dd className="col-sm-9">
                  {selectedJob?.customer?.customerEmail}
                </dd>
                <dt className="col-sm-3">Mobile No</dt>
                <dd className="col-sm-9">
                  {selectedJob?.customer?.customerPhone}
                </dd>
                <dt className="col-sm-3 text-truncate">
                  Address
                </dt>
                <dd className="col-sm-9">
                  {selectedJob?.customer?.customerAddress} , {selectedJob?.customer?.customerCity} , {selectedJob?.customer?.customerCountry}
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="card shadow-sm border-0">
            <div className="card-header justify-content-between">

            </div>
            <div className="card-body">
              <dl className="row mb-0">
                <dt className="col-sm-2">Job Taken on</dt>
                <dd className="col-sm-4">
                  {moment(selectedJob?.jobDate).format("YYYY-MM-DD")}
                </dd>
                <dt className="col-sm-2">First Fit on</dt>
                <dd className="col-sm-4">
                  {moment(selectedJob?.fitOnDate).format("YYYY-MM-DD")}
                </dd>
                <dt className="col-sm-2">Final Fit on</dt>
                <dd className="col-sm-4">
                  {moment(selectedJob?.finalFitOnDate).format("YYYY-MM-DD")}
                </dd>
                <dt className="col-sm-2">
                  Delivery On
                </dt>
                <dd className="col-sm-4">
                  {moment(selectedJob?.deliveryDate).format("YYYY-MM-DD")}
                </dd>
                <dt className="col-sm-2">
                  Tailor
                </dt>
                <dd className="col-sm-4">
                  {selectedJob?.tailor?.first_name + " " + selectedJob?.tailor?.last_name}
                </dd>
                <dt className="col-sm-2">
                  Cutter
                </dt>
                <dd className="col-sm-4">
                  {selectedJob?.cutter?.first_name + " " + selectedJob?.cutter?.last_name}
                </dd>

              </dl>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table text-nowrap table-striped table-hover">
            <thead>
            <tr>
              <th scope="col">Job Type</th>
              <th scope="col">Qty</th>
              <th scope="col">Measurement</th>
              <th scope="col">Body Types</th>
              <th scope="col">Styles</th>
            </tr>
            </thead>
            <tbody>
            {selectedJob?.jobTypesInfo?.map((value,index) =>

                <tr key={index}>
                  <td scope="row">{value.jobType.type}</td>
                  <td>
                    {value.qty}
                  </td>
                  <td>
                    {value.measurements}
                  </td>
                  <td>
                    {value.bodyTypes?.map(body => {
                      return body?.values.map((item) => {
                        return <li key={body.name + " : " + item}>
                          {body.name + " : " + item}
                        </li>
                      })
                    }).flat(2)}
                  </td>
                  <td>
                    {value.styles.map(style => (
                        <li key={style.style._id}>{style.style.styleName}: {style.selectedValues}</li>
                    ))}
                  </td>
                </tr>
            )}


            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default JobList;

