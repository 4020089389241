import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../src/environment";
import { PlusCircle } from 'react-feather';
import { Link } from "react-router-dom";

const EdituserRight = () => {
  const { id } = useParams();
  const [userRight, setUserRight] = useState({
    user: "",
    role_id: "",
    right: "",
    status: 0,
    adds: 0,
    viewes: 0,
    updates: 0,
    deletes: 0,
    download: 0,
    upload: 0,
    approve:0,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  // Fetch user right details by ID
  useEffect(() => {
    const fetchUserRight = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/userright/show/${id}`);
        const data = await response.json();
        if (response.ok) {
          setUserRight({
            user: data.data.user,
            role_id: data.data.role_id,
            right: data.data.right,
            status: data.data.status,
            adds: data.data.adds,
            viewes: data.data.viewes,
            updates: data.data.updates,
            deletes: data.data.deletes,
            download: data.data.download,
            upload: data.data.upload,
            approve: data.data.approve,
          });
        } else {
          throw new Error(data.error || "Failed to fetch user access right");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserRight();
  }, [id]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserRight((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : parseInt(value, 10),
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/userright/update/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userRight),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to update user access right");
      }

      setSuccessMessage("User access right updated successfully!");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Edit User Access Rights</h4>
            <h6>The level of permissions granted to a user account within a system</h6>
          </div>
          <Link to="/userrights" className="btn btn-added">
            <PlusCircle className="me-2" /> Back to User Rights
          </Link>
        </div>
        <div className="card" style={{ margin: "0 auto" }}>
          <div className="card-body">
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p style={{ color: "red" }}>Error: {error}</p>
            ) : (
              <>
                {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
                <form onSubmit={handleSubmit}>
                  {/* Left side inputs */}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group" style={{ margin: "10px 0" }}>
                        <label>User Name</label>
                        <input
                          type="text"
                          name="user"
                          value={userRight.user}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group" style={{ margin: "10px 0" }}>
                        <label>Role ID</label>
                        <input
                          type="text"
                          name="role_id"
                          value={userRight.role_id}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group" style={{ margin: "10px 0" }}>
                        <label>Access Right Name</label>
                        <input
                          type="text"
                          name="right"
                          value={userRight.right}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group" style={{ margin: "10px 0" }}>
                        <label>Status</label>
                        <select
                          name="status"
                          value={userRight.status}
                          onChange={handleInputChange}
                          className="form-control"
                        >
                          <option value={0}>Inactive</option>
                          <option value={1}>Active</option>
                        </select>
                      </div>
                    </div>

                    {/* Right side selects */}
                    <div className="col-md-6">
                      <div className="form-group" style={{ margin: "20px 0", fontSize: "13px", display: "flex", alignItems: "center" }}>
                        <label style={{ fontSize: "13px", marginRight: "10px" }}>Add New</label>
                        <select
                          name="adds"
                          value={userRight.adds}
                          onChange={handleInputChange}
                          className="form-control"
                          style={{ fontSize: "14px", width: "120px", backgroundColor: userRight.adds === 1 ? "#28a745" : "#dc3545", color: "#fff", marginLeft: "26px" }}
                        >
                          <option value="1" style={{ backgroundColor: "#28a745", color: "#fff" }}>Approve</option>
                          <option value="0" style={{ backgroundColor: "#dc3545", color: "#fff" }}>Deny</option>
                        </select>
                      </div>

                      <div className="form-group" style={{ margin: "20px 0", fontSize: "13px", display: "flex", alignItems: "center" }}>
                        <label style={{ fontSize: "13px", marginRight: "10px" }}>View</label>
                        <select
                          name="viewes"
                          value={userRight.viewes}
                          onChange={handleInputChange}
                          className="form-control"
                          style={{ fontSize: "14px", width: "120px", backgroundColor: userRight.viewes === 1 ? "#28a745" : "#dc3545", color: "#fff", marginLeft: "49px" }}
                        >
                          <option value="1" style={{ backgroundColor: "#28a745", color: "#fff" }}>Approve</option>
                          <option value="0" style={{ backgroundColor: "#dc3545", color: "#fff" }}>Deny</option>
                        </select>
                      </div>

                      <div className="form-group" style={{ margin: "20px 0", fontSize: "13px", display: "flex", alignItems: "center" }}>
                        <label style={{ fontSize: "13px", marginRight: "10px" }}>Edit</label>
                        <select
                          name="updates"
                          value={userRight.updates}
                          onChange={handleInputChange}
                          className="form-control"
                          style={{ fontSize: "14px", width: "120px", backgroundColor: userRight.updates === 1 ? "#28a745" : "#dc3545", color: "#fff", marginLeft: "52px" }}
                        >
                          <option value="1" style={{ backgroundColor: "#28a745", color: "#fff" }}>Approve</option>
                          <option value="0" style={{ backgroundColor: "#dc3545", color: "#fff" }}>Deny</option>
                        </select>
                      </div>

                      <div className="form-group" style={{ margin: "20px 0", fontSize: "13px", display: "flex", alignItems: "center" }}>
                        <label style={{ fontSize: "13px", marginRight: "10px" }}>Delete</label>
                        <select
                          name="deletes"
                          value={userRight.deletes}
                          onChange={handleInputChange}
                          className="form-control"
                          style={{ fontSize: "14px", width: "120px", backgroundColor: userRight.deletes === 1 ? "#28a745" : "#dc3545", color: "#fff", marginLeft: "50px" }}
                        >
                          <option value="1" style={{ backgroundColor: "#28a745", color: "#fff" }}>Approve</option>
                          <option value="0" style={{ backgroundColor: "#dc3545", color: "#fff" }}>Deny</option>
                        </select>
                      </div>

                      <div className="form-group" style={{ margin: "20px 0", fontSize: "13px", display: "flex", alignItems: "center" }}>
                        <label style={{ fontSize: "13px", marginRight: "10px" }}>Download</label>
                        <select
                          name="download"
                          value={userRight.download}
                          onChange={handleInputChange}
                          className="form-control"
                          style={{ fontSize: "14px", width: "120px", backgroundColor: userRight.download === 1 ? "#28a745" : "#dc3545", color: "#fff", marginLeft: "32px" }}
                        >
                          <option value="1" style={{ backgroundColor: "#28a745", color: "#fff" }}>Approve</option>
                          <option value="0" style={{ backgroundColor: "#dc3545", color: "#fff" }}>Deny</option>
                        </select>
                      </div>

                      <div className="form-group" style={{ margin: "20px 0", fontSize: "13px", display: "flex", alignItems: "center" }}>
                        <label style={{ fontSize: "13px", marginRight: "10px" }}>Upload</label>
                        <select
                          name="upload"
                          value={userRight.upload}
                          onChange={handleInputChange}
                          className="form-control"
                          style={{ fontSize: "14px", width: "120px", backgroundColor: userRight.upload === 1 ? "#28a745" : "#dc3545", color: "#fff", marginLeft: "40px" }}
                        >
                          <option value="1" style={{ backgroundColor: "#28a745", color: "#fff" }}>Approve</option>
                          <option value="0" style={{ backgroundColor: "#dc3545", color: "#fff" }}>Deny</option>
                        </select>
                      </div>
                      <div className="form-group" style={{ margin: "20px 0", fontSize: "13px", display: "flex", alignItems: "center" }}>
                        <label style={{ fontSize: "13px", marginRight: "10px" }}>Approve</label>
                        <select
                          name="approve"
                          value={userRight.approve}
                          onChange={handleInputChange}
                          className="form-control"
                          style={{ fontSize: "14px", width: "120px", backgroundColor: userRight.upload === 1 ? "#28a745" : "#dc3545", color: "#fff", marginLeft: "40px" }}
                        >
                          <option value="1" style={{ backgroundColor: "#28a745", color: "#fff" }}>Approve</option>
                          <option value="0" style={{ backgroundColor: "#dc3545", color: "#fff" }}>Deny</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Save Changes
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EdituserRight;
