import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../src/environment";
import { PlusCircle } from 'react-feather';
import { Link } from "react-router-dom";

const AddUserRight = () => {
  const { uid } = useParams();
  const [userName, setUserName] = useState("");
  const [userRights, setUserRights] = useState([]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/user/getbyuid/${uid}`);
        const data = await response.json();

        if (response.ok) {
          const fetchedUserName = data.name || "";
          const rid = data.role || "";
          setUserName(fetchedUserName);

          


          const rightsResponse = await fetch(`${API_BASE_URL}/accsessroalrights/acc/${rid}`);
          const rdata = await rightsResponse.json();

        // console.log('right data:',rdata.data );


          if (rightsResponse.ok) {
            setUserRights(rdata.data.map(right => ({
              userid: uid,
              user: fetchedUserName,
              right: right.right,
              status: right.status,


              link: right.link,  
              icon: right.icon,
              menu: right.menu,
              menuid: right.menuid,
              submenu: right.submenu,
              submenuid: right.submenuid,


              adds: right.adds,
              viewes: right.viewes,
              updates: right.updates,
              deletes: right.deletes,
              download: right.download,
              upload: right.upload,
              approve: right.approve,
            })));
          } else {
            console.error(rdata.error || "Failed to fetch user rights");
          }
        } else {
          console.error(data.error || "Failed to fetch user details");
        }
      } catch (error) {
        console.error("Error fetching user details: ", error);
      }
    };

    fetchUserDetails();
  }, [uid]);

  const handleSave = async () => {

  //console.log('right data:',userRights);

    try {
      for (const right of userRights) {
        const userRightToSave = {
          userid: right.userid,
          user: right.user,
          role_id: 1,
          status: right.status,
          right: right.right,

          link: right.link,
          icon: right.icon,
          menu: right.menu,
          menuid: right.menuid,
          submenu: right.submenu,
          submenuid: right.submenuid,

         
      
          adds: right.adds,
          viewes: right.viewes,
          updates: right.updates,
          deletes: right.deletes,
          download: right.download,
          upload: right.upload,
         approve: right.approve,
          
        };


        console.log(userRightToSave);

        const response = await fetch(`${API_BASE_URL}/userright/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userRightToSave),
        });

        const data = await response.json();

        if (!response.ok) {
          console.error("Error response data:", data);
          alert("Error saving user rights: " + data.message);
          return; // Exit if there's an error
        }
      }
      alert("User rights saved successfully!");
    } catch (error) {
      console.error("Error saving data: ", error);
      alert("An error occurred while saving user rights.");
    }
  };



  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Add New User Access Rights For User Name: {userName}</h4>
            <h6>The level of permissions granted to a user account within a system</h6>
          </div>
          <Link to="/userrights" className="btn btn-added">
            <PlusCircle className="me-2" />
            Back to User Rights
          </Link>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>User Access Rights Name</th>
                    <th>Status</th>
                    <th style={{ display: 'none' }}></th>
                    <th style={{ display: 'none' }}></th>
                    <th style={{ display: 'none' }}></th>
                    <th style={{ display: 'none' }}></th>
                    <th style={{ display: 'none' }}></th>
                    <th>Add</th>
                     <th>View</th>
                    <th>Update</th>
                    <th>Delete</th>
                    <th>Download</th>
                    <th>Upload</th>
                    <th>Approve</th>
                  </tr>
                </thead>
                <tbody>
                  {userRights.map((right, index) => (
                    <tr key={index}>
                      <td>{right.right}</td>
                      <td>{right.status ? 'Active' : 'Inactive'}</td>
                      <td style={{ display: 'none' }}>{right.link}</td>
                      <td style={{ display: 'none' }}>{right.icon }</td>
                      <td style={{ display: 'none' }}>{right.menu }</td>
                      <td style={{ display: 'none' }}>{right.menuid }</td>
                      <td style={{ display: 'none' }}>{right.submenuid }</td> 
                      <td>{right.adds ? 'Yes' : 'No'}</td>
                      <td>{right.viewes ? 'Yes' : 'No'}</td>
                      <td>{right.updates ? 'Yes' : 'No'}</td>
                      <td>{right.deletes ? 'Yes' : 'No'}</td>
                      <td>{right.download ? 'Yes' : 'No'}</td>
                      <td>{right.upload ? 'Yes' : 'No'}</td>
                      <td>{right.approve ? 'Yes' : 'No'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <br />
            <button onClick={handleSave} className="btn btn-primary">
              Save Access Rights
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserRight;
