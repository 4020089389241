

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import "./style.css";
import Swal from "sweetalert2";
import { Spin, Table } from "antd";
import { getAllEmployeesWithoutPaginationMethod } from '../../../service/ApiServices';
import { getAllLeaveTypes, getLeaveTypeById } from "../../../service/leaveApiServices/leaveTypesApi";
import { updateLeaveAllocation } from "../../../service/leaveAllocationService";
import Select from 'react-select';

const CreateLeaveTypeModal = ({
  handleCancel,
  selectedLeaveType,
  handleEditLeaveType
}) => {
  const [leaveBalances, setLeaveBalances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  useEffect(() => {
    const fetchEmployees = async () => {
      setLoading(true);
      try {
        const response = await getAllEmployeesWithoutPaginationMethod({ setEmployeeData });
        console.log(response);
      } catch (error) {
        console.error('Error fetching employees:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchEmployees();
  }, []);

  useEffect(() => {
    const fetchLeaveTypes = async () => {
      try {
        const response = await getAllLeaveTypes({ search: '', setLoading });
        const options = response.map(item => ({
          value: item._id,
          label: item.name,
        }));
        setLeaveTypeOptions(options);
      } catch (error) {
        console.error("Error fetching leave types:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchLeaveTypes();
  }, []);

  useEffect(() => {
    if (selectedLeaveType) {
      setSelectedEmployee({
        value: selectedLeaveType.employee._id,
        label: `${selectedLeaveType.employee.first_name} ${selectedLeaveType.employee.last_name}`,
      });

      if (selectedLeaveType.leaveBalances) {
        setLeaveBalances(selectedLeaveType.leaveBalances.map(lb => ({
          leaveType: { _id: lb.leaveType._id, name: lb.leaveType.name },
          leaveCount: lb.leaveCount
        })));
      }
    }
  }, [selectedLeaveType]);

  const showConfirmationAlert = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You can revert this action by clicking the Reset button.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        // Perform the deletion here using the index
        const updatedLeaveBalances = leaveBalances.filter((_, i) => i !== index);
        setLeaveBalances(updatedLeaveBalances);
        Swal.fire('Deleted!');
      }
    });
  };
  const resetForm = () => {
    if (selectedLeaveType) {
      setSelectedEmployee({
        value: selectedLeaveType.employee._id,
        label: `${selectedLeaveType.employee.first_name} ${selectedLeaveType.employee.last_name}`,
      });
  
      if (selectedLeaveType.leaveBalances) {
        setLeaveBalances(selectedLeaveType.leaveBalances.map(lb => ({
          leaveType: { _id: lb.leaveType._id, name: lb.leaveType.name },
          leaveCount: lb.leaveCount
        })));
      }
    }
  };
  
  

  const handleSaveClick = async () => {
    if (!selectedEmployee || leaveBalances.some(item => item.leaveCount === "" || !item.leaveType)) {
      Swal.fire("Error", "Please fill in all fields", "error");
      return;
    }
    // Check for duplicate leaveTypes
  const leaveTypeIds = leaveBalances.map(item => item.leaveType._id);
  const hasDuplicates = leaveTypeIds.length !== new Set(leaveTypeIds).size;

  if (hasDuplicates) {
    Swal.fire("Warning", "There are duplicate leave types. Please remove duplicates before saving.", "warning");
    return;
  }

    try {
      const validatedLeaveBalances = await Promise.all(
        leaveBalances.map(async (item) => {
          const leaveType = await getLeaveTypeById(item.leaveType._id);
          if (item.leaveCount > leaveType.quota) {
            throw new Error(`Leave count for ${leaveType.name} exceeds the quota of ${leaveType.quota}`);
          }
          return {
            leaveType: item.leaveType._id,
            leaveCount: item.leaveCount,
          };
        })
      );
      const updatedData = await updateLeaveAllocation(
        selectedLeaveType._id,            // allocationId
        selectedEmployee.value,           // employee
        validatedLeaveBalances,           // leaveBalances
        new Date().getFullYear().toString(), // financialYear
        setLoading                        // setLoading
      );
      
      if (updatedData) {
        console.log('Updated leave allocation:', updatedData);
      }

      handleEditLeaveType();
      handleClose(); 
    } catch (error) {
      Swal.fire("Error", error.message, "error");
      console.error('Save failed:', error.message);
    }
  };

  const handleClose = () => {
    handleCancel();
    resetForm();
  };
  const handleReset = () => {
    resetForm();
  };
  const handleLeaveCountChange = (index, value) => {
    const updatedLeaveBalances = [...leaveBalances];
    updatedLeaveBalances[index].leaveCount = value;
    setLeaveBalances(updatedLeaveBalances);
  };

  const handleLeaveTypeChange = (index, selectedOption) => {
    const updatedLeaveBalances = [...leaveBalances];
    updatedLeaveBalances[index].leaveType = {
      _id: selectedOption.value,
      name: selectedOption.label,
    };
    setLeaveBalances(updatedLeaveBalances);
  };

  const columns = [
    {
      title: 'Leave Type',
      dataIndex: 'leaveType',
      width:'50%',
      render: (text, record, index) => (
        <Select
          value={{
            value: record.leaveType._id,
            label: record.leaveType.name,
          }}
          options={leaveTypeOptions}
          onChange={(selectedOption) => handleLeaveTypeChange(index, selectedOption)}
          className="select"
        />
      ),
    },
    {
      title: 'Leave Count',
      dataIndex: 'leaveCount',
      width:'35%',
      render: (text, record, index) => (
        <input
          type="number"
          className="form-control"
          value={record.leaveCount}
          onChange={(e) => handleLeaveCountChange(index, e.target.value)}
          style={{ width: '100%' }}
        />
      ),
    },
    {
        title: 'Actions',
        render: (text, record, index) => (
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => showConfirmationAlert(index)} 
            >
              <i data-feather="trash-2" className="feather-trash-2" style={{ color: 'red' }}></i>
            </Link>
          ),
      },
  ];

  return (
    <div className="modal-content">
      <div className="modal-header border-0 custom-modal-header">
        <div className="page-title">
          <h4>Edit Allocate Leaves</h4>
          <p className="mb-0">Fill in the required fields to Edit</p>
        </div>
      </div>
      <div className="modal-body custom-modal-body">
        <form>
          <div className="row mb-3">
            <div className="col-12">
              <div className="input-blocks">
                <label>Employee</label>
                <Select
                  className="select"
                  options={employeeData}
                  placeholder="Select an employee"
                  value={selectedEmployee}
                  onChange={setSelectedEmployee}
                />
              </div>
            </div>
          </div>
        </form>

        <Table
          dataSource={leaveBalances}
          columns={columns}
          pagination={false}
          rowKey="leaveType._id" 
        />

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: '20px' }}>
          <button type="button" className="btn btn-cancel" onClick={handleClose}>
            Close
          </button>
          <div>
          <button
              type="button"
              className="btn btn-reset me-2"
             onClick={handleReset}
            >
              Reset
            </button>
          <button className="btn btn-submit" onClick={handleSaveClick}>
            {loading ? <Spin size="small" /> : "Save"}
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateLeaveTypeModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleEditLeaveType: PropTypes.func.isRequired,
  selectedLeaveType: PropTypes.object,
};

export default CreateLeaveTypeModal;
