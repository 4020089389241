import { PlusCircle } from "feather-icons-react/build/IconComponents";
import React, {useEffect, useState} from "react";
import { createUser } from "../../../service/ApiServices";
import { getRoles, uploadProfilePic } from "../../../service/ApiServices";
import Swal from "sweetalert2";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import PropTypes from "prop-types";
import {Modal} from "antd"; // Import Modal from Bootstrap JS


const AddUsers = ({open,onClose}) => {



  //add profile pic
  const [image, setImage] = useState(null);

  const handleFileChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };

  const handleUploadClick = async () => {
    if (!image) {
      alert('Please select an image');
      return;
    }

    const userId = 'USER_ID'; // Replace with actual user ID
    const result = await uploadProfilePic(userId, image);

    if (result.success) {
      alert('Profile picture updated successfully');
    } else {
      alert(result.error);
    }
  };



  //end profile pic uploading



  //add user work start

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [, setRoleName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [description, setDescription] = useState("");

  useEffect(() => {
    const fetchRoles = async () => {
      const rolesData = await getRoles();
      setRoles(rolesData);
    };
    fetchRoles();
  }, []);
  console.log(roles,"rolessss")
  const handleRoleChange = (e) => {
    const roleId = e.target.value;
    setSelectedRole(roleId);
    const role = roles.find((role) => role._id === roleId);
    // console.log(roleId, "ROLE-ID")
    if (role) {
      setRoleName(role.name);
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();

    let errorMessage = "";

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      errorMessage = "Invalid email format";
    } else if (!password) {
      errorMessage = "Password cannot be empty";
    }

    if (errorMessage) {
      await Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
      });
      return;
    }

    try {
      await createUser({
        name: name,
        phone: phone,
        email: email,
        role: selectedRole,
        password: password,
        confirmPassword: confirmPassword,
       // description: description,
      });
      // Close the modal programmatically
      await Swal.fire({
        title: "Success",
        text: "User created successfully!",
        icon: "success",
      });
      await onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const storedUserData = localStorage.getItem("user");
  const storeData = JSON.parse(storedUserData);

   console.log(storeData, "ROLEE");

  //add user work end

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div>
      {/* Add User */}
      <Modal zIndex={2000} title="Add User" footer={false} open={open}  onCancel={onClose}>
        <form onSubmit={handleCreateUser}>
          <div className="row">
            <div className="col-lg-12">
              <div className="new-employee-field">
                <span>Avatar</span>
                <div className="profile-pic-upload mb-2">
                  <div className="profile-pic">
                              <span>
                                <PlusCircle className="plus-down-add"/>
                                Profile Photo
                              </span>
                  </div>
                  <div className="input-blocks mb-0">
                    <div className="image-upload mb-0">
                      <input type="file"
                          // accept="image/*"
                             onChange={handleFileChange}/>
                      <div className="image-uploads"
                           onClick={handleUploadClick}
                      >
                        <h4>Change Image</h4>
                      </div>
                      {/* <button
                                 className="image-uploads"
                                onClick={handleUploadClick}>Change Image</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>User Name</label>
                <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>Phone</label>
                <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>Email</label>
                <input
                    type="email"
                    className="form-control"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>Role</label>
                <select
                    value={selectedRole}
                    onChange={handleRoleChange}
                    className="form-control"
                >
                  <option value="">Select Role</option>
                  {roles.map((role) => (
                      <option key={role._id} value={role._id}>
                        {role.roleName}
                      </option>

                  ))}
                </select>

              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>Password</label>
                <div className="pass-group">
                  <input
                      type={showPassword ? "text" : "password"}
                      className="pass-input"
                      placeholder="Enter your password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                  />
                  <span
                      className={`fas toggle-password ${showPassword ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={handleTogglePassword}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>Confirm Passworrd</label>
                <div className="pass-group">
                  <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="pass-input"
                      placeholder="Enter your password"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                  />
                  <span
                      className={`fas toggle-password ${showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={handleToggleConfirmPassword}
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-lg-12">
                        <div className="mb-0 input-blocks">
                          <label className="form-label">Descriptions</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          />

                          <p>Maximum 600 Characters</p>
                        </div>
                      </div> */}
          </div>
          <div className="modal-footer-btn">
            <button
                type="button"
                className="btn btn-cancel me-2"
                data-bs-dismiss="modal"
            >
              Cancel
            </button>
            {/* <Link
                      to="/users"
                      type="button"
                      className="btn btn-submit"
                      onClick={handleCreateUser}
                      >
                        Submit
                      </Link> */}
            <button
                type="submit"
                className="btn btn-submit"


            >
              Submit
            </button>
          </div>
        </form>
      </Modal>


      {/* /Add User */}
    </div>
  );
};

// PropTypes validation
AddUsers.propTypes = {
  onClose: PropTypes.func, // Function validation (Optional)
  open: PropTypes.bool
};
export default AddUsers;
