import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AddAccessRight = () => {
  const [formData, setFormData] = useState({
    lable:"",
    right: "",
    rid: "",
    status: "1", 
    link:"",
    icon:"",
    menu:"1",
    menuid:"",
    submenu:"0",
    submenuid:"",
    active:"0",
    adds:"0",
    viewes:"0",
    updates:"0",
    deletes:"0",
    download:"0",
    upload:"0",
    approve:"0",
    pdf:"0",
    excel:"0",
    print:"0",
    email:"0",
    filters:"0",
    searchbar:"0"

  });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleChange1 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange2 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange3 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};   
  const handleChange4 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};   
  const handleChange5 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange6 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange7 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange8 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange9 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange10 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange11 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange12 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange13 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange14 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange15 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange16 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange17 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};


  const handleChange18 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange19 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange20 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange21 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};
  const handleChange22 = (e) => {setFormData({...formData,[e.target.name]: e.target.value, });};

  const handleSubmit = async (e) => {
    e.preventDefault();
 
    try {
      const response = await fetch("http://localhost:8000/api/accsessrights/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
    console.log("accses-data",formData);
      if (response.ok) {
      
        setMessage("Access right created successfully!");

        navigate("/accsesroals"); 
      } else {
     
        const errorData = await response.json();
        setMessage(`Error: ${errorData.message}`);
      }
    } catch (error) {
  
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <h4>Add Access Right</h4>
          <p>Create a new access right</p>
        </div>

        <form onSubmit={handleSubmit}>
        <div className="form-group">
            <label>Right Name</label>
            <input
              type="text"
              name="lable"
              className="form-control"
              value={formData.lable}
              onChange={handleChange17}
             
            />
          </div>
          <div className="form-group">
            <label>Right</label>
            <input
              type="text"
              name="right"
              className="form-control"
              value={formData.right}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Role ID</label>
            <input
              type="number"
              name="rid"
              className="form-control"
              value={formData.rid}
              onChange={handleChange1}
              required
            />
          </div>
          <div className="form-group">
          <div className="row">
                <div className="col-sm-1">
                    <label>Status</label>
                    <select
                    name="status"
                    className="form-control"
                    value={formData.status}
                    onChange={handleChange2}
                    required
                    >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                    </select>
                </div>
                <div className="col-sm-1">
                    <label>Add New</label>
                    <select
                    name="adds"
                    className="form-control"
                    value={formData.adds}
                    onChange={handleChange3}
                    required
                    >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                    </select>
                </div>
                <div className="col-sm-1">
                    <label>View</label>
                    <select
                    name="viewes"
                    className="form-control"
                    value={formData.viewes}
                    onChange={handleChange4}
                    required
                    >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                    </select>
                </div>
                <div className="col-sm-1">
                    <label>Update</label>
                    <select
                    name="updates"
                    className="form-control"
                    value={formData.updates}
                    onChange={handleChange5}
                    required
                    >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                    </select>
                </div>
                <div className="col-sm-1">
                    <label>Deletes</label>
                    <select
                    name="deletes"
                    className="form-control"
                    value={formData.deletes}
                    onChange={handleChange6}
                    required
                    >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                    </select>
                </div>
                <div className="col-sm-1">
                    <label>Download</label>
                    <select
                    name="download"
                    className="form-control"
                    value={formData.download}
                    onChange={handleChange7}
                    required
                    >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                    </select>
                </div>
                <div className="col-sm-1">
                    <label>Upload</label>
                    <select
                    name="upload"
                    className="form-control"
                    value={formData.upload}
                    onChange={handleChange8}
                    required
                    >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                    </select>
                </div>
                <div className="col-sm-1">
                    <label>Approve</label>
                    <select
                    name="approve"
                    className="form-control"
                    value={formData.approve}
                    onChange={handleChange9}
                    required
                    >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                    </select>
                </div>
                <div className="col-sm-1">
                    <label>Pdf</label>
                    <select
                    name="approve"
                    className="form-control"
                    value={formData.Pdf}
                    onChange={handleChange17}
                    
                    >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                    </select>
                </div>
                <div className="col-sm-1">
                    <label>Excel</label>
                    <select
                    name="approve"
                    className="form-control"
                    value={formData.excel}
                    onChange={handleChange18}
                 
                    >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                    </select>
                </div>
                <div className="col-sm-1">
                    <label>Print</label>
                    <select
                    name="approve"
                    className="form-control"
                    value={formData.print}
                    onChange={handleChange19}
                    
                    >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                    </select>
                </div>
                <div className="col-sm-1">
                    <label>Send Email</label>
                    <select
                    name="approve"
                    className="form-control"
                    value={formData.email}
                    onChange={handleChange20}
                   
                    >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                    </select>
                </div>
                <div className="col-sm-1">
                    <label>Filters</label>
                    <select
                    name="approve"
                    className="form-control"
                    value={formData.filters}
                    onChange={handleChange21}
                   
                    >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                    </select>
                </div>
                <div className="col-sm-1">
                    <label>Search Bar</label>
                    <select
                    name="approve"
                    className="form-control"
                    value={formData.searchbar}
                    onChange={handleChange22}
                  
                    >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                    </select>
                </div>
            </div>
            <div className="form-group">
            <div className="row">
                    
                    <div className="col-sm-2">
                        <label>URL Link</label>
                        <input
                        type="text"
                        name="link"
                        className="form-control"
                        value={formData.link}
                        onChange={handleChange10}
                        required
                        />
                    </div>
                    <div className="col-sm-2">
                        <label>Icon</label>
                        <input
                        type="text"
                        name="icon"
                        className="form-control"
                        value={formData.icon}
                        onChange={handleChange11}
                        required
                        />
                    </div>
                    <div className="col-sm-1">
                        <label>Is Manu</label>
                       
                        <input
                        type="text"
                        name="menu"
                        className="form-control"
                        value={formData. menu}
                        onChange={handleChange12}
                        />
                        {/* <select
                        name="menu"
                        className="form-control"
                        value={formData.menu}
                        onChange={handleChange12}
                       //required
                        >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                        </select> */}
                    </div>
                    <div className="col-sm-1">
                        <label> Menuid</label>
                        <input
                        type="text"
                        name="menuid"
                        className="form-control"
                        value={formData. menuid}
                        onChange={handleChange16}
                        />
                    </div>
                    <div className="col-sm-1">
                        <label>Is Submenu</label>
                        {/* <select
                        name="submenu"
                        className="form-control"
                        value={formData.submenu}
                        onChange={handleChange13}
                        >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                        </select> */}
                         <input
                        type="text"
                        name="submenu"
                        className="form-control"
                        value={formData. submenu}
                        onChange={handleChange13}
                        />
                    </div>
                    <div className="col-sm-1">
                        <label>Sub Menuid</label>
                        <input
                        type="text"
                        name="submenuid"
                        className="form-control"
                        value={formData.submenuid}
                        onChange={handleChange14}
                       />
                    </div>
                    <div className="col-sm-1">
                        <label>Is Active</label>
                        <select
                        name="active"
                        className="form-control"
                        value={formData.active}
                        onChange={handleChange15}
                        >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                        </select>
                    </div>
                </div>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            Add Access Right
          </button>
        </form>

        {/* Display any success or error messages */}
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default AddAccessRight;
