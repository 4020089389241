import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/dist';
import Table from '../../core/pagination/datatable';
import Select from 'react-select';
import Sliders from 'feather-icons-react/build/IconComponents/Sliders';
import { ChevronUp, Filter, RotateCcw, StopCircle, Zap } from 'feather-icons-react/build/IconComponents';
import { DatePicker } from 'antd';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ImageWithBasePath from '../../core/img/imagewithbasebath';
import { setToogleHeader } from '../../core/redux/action';
import {getPurchases } from '../../../src/service/purchasesApiServices'; 
import { getStocks } from '../../../src/service/purchasesApiServices';

const PurchaseReport = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.toggle_header);
    const [purchases, setPurchases] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [combinedData, setCombinedData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());


    console.log(purchases,"Purchases")
   
    console.log(stocks,"Stocks")
    console.log(combinedData,"COMBINED DATA PR")

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [purchasesData, stocksData] = await Promise.all([
                    getPurchases(),
                   
                    getStocks(),
                ]);
                setPurchases(purchasesData);
                
                setStocks(stocksData);
                combineData(purchasesData, stocksData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const combineData = (purchases, stocks) => {
     
      console.log(stocks,"function eka atule stock")

      const combined = purchases.map((purchase) => {
        console.log(purchase,"combined eka atula")
        
          
       

        let stockQty = 0;
        stocks?.data?.stock.forEach((stock) => {
            console.log(stock, "Stock $$$$$============");
            if (stock?.product?._id === purchase?.product?._id) {
                stockQty = stock.product.productQuantity;
            }
        });
  
          return {
              productName: purchase?.product?.productName,
              purchasePrice: purchase.purchasePrice,
              qty: purchase?.quantity,
              stockQty: stockQty,
          };
      });
      setCombinedData(combined);
  };
  

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };



    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const toggleFilterVisibility = () => {
        setIsFilterVisible((prevVisibility) => !prevVisibility);
    };

    const renderTooltip = (props) => (
        <Tooltip id="pdf-tooltip" {...props}>
            Pdf
        </Tooltip>
    );
    const renderExcelTooltip = (props) => (
        <Tooltip id="excel-tooltip" {...props}>
            Excel
        </Tooltip>
    );
    const renderPrinterTooltip = (props) => (
        <Tooltip id="printer-tooltip" {...props}>
            Printer
        </Tooltip>
    );
    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );
    const renderCollapseTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Collapse
        </Tooltip>
    );

    const columns = [
        {
            title: "Product Name",
            dataIndex: "productName",
            sorter: (a, b) => a.productName.localeCompare(b.productName),
        },
        {
            title: "Purchase Amount",
            dataIndex: "purchasePrice",
            sorter: (a, b) => a.purchasePrice - b.purchasePrice,
        },
        {
            title: "Purchase QTY",
            dataIndex: "qty",
            sorter: (a, b) => a.qty - b.qty,
        },
        {
            title: "InStock QTY",
            dataIndex: "stockQty",
            sorter: (a, b) => a.stockQty - b.stockQty,
        },
    ];

    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Purchase order report</h4>
                                <h6>Manage your Purchase order report</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                    <Link>
                                        <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <ImageWithBasePath src="assets/img/icons/excel.svg" alt="img" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <i data-feather="printer" className="feather-printer" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                                    <Link
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        id="collapse-header"
                                        className={data ? "active" : ""}
                                        onClick={() => { dispatch(setToogleHeader(!data)) }}
                                    >
                                        <ChevronUp />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                        </ul>
                    </div>
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="form-control form-control-sm formsearch"
                                            value={searchText}
                                            onChange={handleSearchChange}
                                        />
                                        <Link to className="btn btn-searchset">
                                            <i data-feather="search" className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="search-path">
                                    <Link className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`} id="filter_search">
                                        <Filter
                                            className="filter-icon"
                                            onClick={toggleFilterVisibility}
                                        />
                                        <span onClick={toggleFilterVisibility}>
                                            <ImageWithBasePath src="assets/img/icons/closes.svg" alt="img" />
                                        </span>
                                    </Link>
                                </div>
                                <div className="form-sort">
                                    <Sliders className="info-img" />
                                    <Select
                                        className="select"
                                        // options={oldandlatestvalue}
                                        placeholder="Newest"
                                    />
                                </div>
                            </div>

                            <div className={`card${isFilterVisible ? ' visible' : ''}`} id="filter_inputs" style={{ display: isFilterVisible ? 'block' : 'none' }}>
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <Zap className="info-img" />
                                                <Select className="select"  placeholder="Choose Brand" />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <div className="input-groupicon">
                                                    <DatePicker
                                                        selected={selectedDate}
                                                        onChange={handleDateChange}
                                                        type="date"
                                                        className="filterdatepicker"
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholder='Choose Date'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <StopCircle className="info-img" />
                                                <Select className="select" options={status} placeholder="Choose Status" />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                                            <div className="input-blocks">
                                                <Link className="btn btn-filters ms-auto">
                                                    {" "}
                                                    <i data-feather="search" className="feather-search" />{" "}
                                                    Search{" "}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <Table columns={columns} dataSource={combinedData}  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseReport;
