
import React, {useState,useEffect} from 'react'

import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import {Switch, TimePicker} from 'antd';
import moment from 'moment';
import {getShiftById,updateShiftById} from '../../../service/shiftService';
import PropTypes from "prop-types";
import Swal from 'sweetalert2';


dayjs.extend(customParseFormat);

const EditShift = ({onShiftAdded,shiftId}) => {
    const [shiftName, setShiftName] = useState('');
    const [lateGracePeriod, setLateGracePeriod] = useState('');
    const [status, statusIsChecked] = useState(false);
    const [weekdaysDefinition, setWeekdaysDefinition] = useState([
        {
            day: 'Monday',
            checked: false,
            from:'',
            to:''
        },
        {
            day: 'Tuesday',
            checked: false,
            from:'',
            to:''
        },
        {
            day: 'Wednesday',
            checked: false,
            from:'',
            to:''

        },
        {
            day: 'Thursday',
            checked: false,
            from:'',
            to:''
        },
        {
            day: 'Friday',
            checked: false,
            from:'',
            to:''
        },
        {
            day: 'Saturday',
            checked: false,
            from:'',
            to:''
        },
        {
            day: 'Sunday',
            checked: false,
            from:'',
            to:''
        }
    ]);

    const handleStatusCheckboxChange = (event) => {
          const isChecked = event.target.checked; // Get the new checked state
    statusIsChecked(isChecked); // Update the state
    };


    const getShiftData = async () => {
        try {
            const response = await getShiftById(shiftId);
            if(response){
                setShiftName(response.shiftName)
                setLateGracePeriod(response.lateGracePeriod)
                setWeekdaysDefinition(response.weekdaysDefinition)
                statusIsChecked(response.status)
            }
           // console.log(response,"DATAwwww")
           
        } catch (error) {
            console.error("Error fetching designation data:", error);
        }
    }
    useEffect(() => {
        if (shiftId!==null) {
            getShiftData()
      }
    }, [shiftId]);
   
    const handleSubmit = async () => {
       

        const shiftData = {
            shiftName,
            weekdaysDefinition,
            lateGracePeriod,
            status,
        };
        console.log(shiftData)

        try {
            if(shiftName===""||lateGracePeriod===""){
            showWarning("Please fill the required fields")
            }else{
                await updateShiftById(shiftId,shiftData);
                resetForm();
                onShiftAdded()
            }

        } catch (err) {
           console.log(console.error());
           
        }
    };
console.log(shiftId)

    // const print = () => {
    //     console.log('Printing weekdays definition:', weekdaysDefinition);
    // };

    const resetForm = () => {
        console.log(shiftId)
        setShiftName(''); // Reset shift name
        statusIsChecked(false); // Reset status checkbox
        setLateGracePeriod('')
 
        setWeekdaysDefinition([
            {
                day: 'Monday',
                checked: false,
                from:'',
                to:''
            },
            {
                day: 'Tuesday',
                checked: false,
                from:'',
                to:''
            },
            {
                day: 'Wednesday',
                checked: false,
                from:'',
                to:''
    
            },
            {
                day: 'Thursday',
                checked: false,
                from:'',
                to:''
            },
            {
                day: 'Friday',
                checked: false,
                from:'',
                to:''
            },
            {
                day: 'Saturday',
                checked: false,
                from:'',
                to:''
            },
            {
                day: 'Sunday',
                checked: false,
                from:'',
                to:''
            }
        ]);
        const resetWeekdays = weekdaysDefinition.map((weekday) => ({
            ...weekday,
            checked: false,
            from: '',
            to: '',
        }));
        setWeekdaysDefinition(resetWeekdays);
    };
    const showWarning = (str) => {
        return Swal.fire({
            title: "Error",
            text: str,
            icon: "error",
        });
    }
    const handleDayCheckboxChange = (e, day) => {
        const updatedWeekdays = weekdaysDefinition.map(weekday => {
            if (weekday.day === day.day) {
                return { ...weekday, checked: e };  // Update the 'checked' value
            }
            return weekday;
        });
        setWeekdaysDefinition(updatedWeekdays);
    };
    const handleTimeChange = (time, day, type) => {
        const updatedWeekdays = weekdaysDefinition.map(weekday => {
            if (weekday.day === day) {
                return { 
                    ...weekday, 
                    [type]: time ? time.format('HH:mm') : ''  // Set 'from' or 'to' time
                };
            }
            return weekday;
        });
        setWeekdaysDefinition(updatedWeekdays);
    };

    return (
        <div>
            {/* Add Shift */}
            <div className="modal fade" id="edit-units">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Edit Shift</h4>
                                    </div>

                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form>
                                        <ul
                                            className="nav nav-pills modal-table-tab"
                                            id="pills-tab"
                                            role="tablist"
                                        >

                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div
                                                className="tab-pane fade show active"
                                                id="pills-add-shift-info"
                                                role="tabpanel"
                                                aria-labelledby="pills-add-shift-info-tab"
                                            >
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="input-blocks">
                                                            <label>Shift Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={shiftName}
                                                                onChange={(e) => setShiftName(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="input-blocks">
                                                            <label>Late Grace Period (Minutes)</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={lateGracePeriod}
                                                                onChange={(e) => setLateGracePeriod(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="modal-table-item">
                                                            <h4>Weekdays Definition</h4>
                                                            <div className="table-responsive no-pagination">
                                                                <table className="table ">
                                                                    <tbody>
                                                                        {weekdaysDefinition.map((value, index) =>
                                                                            <tr key={index}>
                                                                                <td>

                                                                                    <Switch size="small"
                                                                                        onChange={(e) => handleDayCheckboxChange(e, value)} 
                                                                                        disabled={value.day === 'Sunday'}
                                                                                        checked={value.checked}
                                                                                        />
                                                                                    <span className="status-label ms-2">
                                                                                        {value.day}
                                                                                    </span>


                                                                                </td>
                                                                                <td>
                                                                                    <span>
                                                                                        <label>From</label>
                                                                                        <div className="form-icon">
                                                                                        <TimePicker
                                                                                            popupStyle={{ zIndex: 1070 }}
                                                                                            className="input-group-text"
                                                                                            defaultValue={moment('00:00', 'HH:mm')}
                                                                                            format="HH:mm"
                                                                                            value={value.from ? moment(value.from, 'HH:mm') : null}
                                                                                            onChange={(time) => handleTimeChange(time, value.day, 'from')}
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                boxSizing: "border-box"
                                                                                            }}
                                                                                            disabled={!value.checked}

                                                                                        ></TimePicker>
                                                                                        </div>
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    <label>To</label>
                                                                                    <div className="form-icon">
                                                                                        <TimePicker
                                                                                            popupStyle={{ zIndex: 1070 }}
                                                                                            className="input-group-text"
                                                                                            defaultValue={moment('00:00', 'HH:mm')}
                                                                                            value={value.to ? moment(value.to, 'HH:mm') : null} // Display selected value
                                                                                            format="HH:mm"
                                                                                            onChange={(time) => handleTimeChange(time, value.day, 'to')}
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                boxSizing: "border-box"
                                                                                            }}
                                                                                            disabled={!value.checked}
                                                                                        ></TimePicker>
                                                                                    </div>
                                                                                </td>

                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="mb-0">
                                                                <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                                    <span className="status-label">Status</span>
                                                                    <input
                                                                        type="checkbox"
                                                                        id="user3"
                                                                        className="check"
                                                                        checked={status}
                                                                        onChange={handleStatusCheckboxChange}
                                                                    />
                                                                    <label htmlFor="user3" className="checktoggle">
                                                                        {" "}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",

                                        }} className="mt-4">
                                            <button
                                                type="button"
                                                className="btn btn-cancel"
                                                data-bs-dismiss="modal"
                                                //onClick={print}
                                            >
                                            Close
                                            </button>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-reset me-2"
                                                    onClick={resetForm}
                                                    
                                                >
                                                    Reset
                                                </button>
                                                {/* <button
                                                    type="button"
                                                    className="btn btn-reset me-2"
                                                    onClick={getShiftData}
                                                    
                                                >
                                                    check
                                                </button> */}
                                                
                                                {(!shiftName || !lateGracePeriod) ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-submit"
                                                        onClick={handleSubmit}
                                                    >
                                                        Save
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="btn btn-submit"
                                                        onClick={handleSubmit}
                                                        data-bs-dismiss="modal"
                                                    >
                                                        Save
                                                    </button>
                                                )}

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    )
}
EditShift.propTypes = {
    onShiftAdded: PropTypes.func.isRequired,
    shiftId: PropTypes.string.isRequired,
};

export default EditShift;
