import React, { useState } from 'react';
import { Modal, InputNumber, Spin } from 'antd';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import Select from "react-select";
import { adjustInventory } from '../../service/ApiServices';

const options = [
    { value: "increase", label: "Increase" },
    { value: "decrease", label: "Decrease" }
];

const EditInventoryModal = ({ visible, onClose, inventoryItem, onSave }) => {
    const [newStockQty, setNewStockQty] = useState(inventoryItem?.stock_qty || 0);
    const [selectedOption, setSelectedOption] = useState(options[1]); // Default to "Decrease"
    const [reason, setReason] = useState("")
    const [loading, setLoading] = useState(false)
    const handleSave = () => {
        if (newStockQty === 0 || newStockQty > inventoryItem.quantity) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Stock Quantity',
                text: `The quantity must be between 1 and ${inventoryItem.quantity}.`,
            });
            return;
        } else if ((inventoryItem.stock_qty == inventoryItem.quantity) && selectedOption.value === "increase") {
            Swal.fire({
                icon: 'error',
                title: 'Cannot Increase Stock',
                text: `The stock quantity is already at the maximum limit of ${inventoryItem.quantity}. You cannot increase it further.`,
            });
            return;
        }// product_id, adjustment_qty, adjustment_type, reason, user, inventory_id
        adjustInventory({
            data: { adjustment_qty: newStockQty, adjustment_type: selectedOption.value, reason, inventory_id: inventoryItem._id },
            setLoading,
            onSave: onSave,
            onClose: onClose
        });
    };

    return (
        <Modal
            open={visible}
            title="Edit Inventory Stock"
            onCancel={onClose}
            footer={[
                <button key="back" type="button" onClick={onClose} className="btn btn-cancel me-2">
                    Cancel
                </button>,
                <button type='button' key="submit" onClick={handleSave} className="btn btn-submit">
                    {loading ? <Spin size='small' /> : "Save"}
                </button>
            ]}
            zIndex={10000}
        >
            <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-lg-12 col-md-12 " style={{ marginTop: "34px" }}>
                    <div className="mb-3">
                        <label className="form-label">Quantity</label>
                        <InputNumber
                            min={0}
                            className="mx-2"
                            max={inventoryItem?.quantity}
                            value={newStockQty}
                            onChange={(value) => setNewStockQty(value)}
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <div className="mb-3">
                        <label className="form-label">Adjustment Type</label>
                        <Select
                            name="adjustmentType"
                            value={selectedOption}
                            onChange={setSelectedOption}
                            className="select"
                            options={options}
                            placeholder="Choose"
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <div className="mb-3">
                        <label className="form-label">Reason</label>
                        <textarea
                            rows={5}
                            type="text"
                            className="form-control"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

EditInventoryModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    inventoryItem: PropTypes.shape({
        _id:PropTypes.string,
        stock_qty: PropTypes.number,
        quantity: PropTypes.number,
        product: PropTypes.shape({
            productName: PropTypes.string,
        }),
        grn_details: PropTypes.shape({
            grnNumber: PropTypes.string,
        }),
    }).isRequired,
    onSave: PropTypes.func.isRequired,
};

export default EditInventoryModal;
