import axios from "axios";

//Create Roles
export const createRole = async (req) => {
  try {
    const response = await axios.post(
      `/rolesPermissions`,
      {
        req
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating role", error);
  }
  return createRole;
};
//get Roles

export const getRoles = async () => {
  try {
    const response = await axios.get(`/rolesPermissions`);
    if (!response) {
      console.log("error in fetching data");
    }

    return response.data;
  } catch (error) {
    console.error(error);
  }
  return getRoles;
};

export const getRolePermissionDetailsById = async (roleId) => {
  console.log("Role Id:", roleId); // Debugging log
  try {
    const response = await axios.get(
      `http://localhost:8000/api/role/getrole/${roleId}`
    );
    console.log(response.data, "Role Id");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

//update role
export const updateRole = async (roleId, newRoleName) => {
  try {
    const response = await axios.put(
      `http://localhost:8000/api/rolesPermissions/${roleId}`,
      newRoleName
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message || "Failed to update role");
  }
};

//Delete Roles

export const deleteRole = async (roleId) => {
  try {
    const response = await axios.delete(
      `http://localhost:8000/api/role/delete/${roleId}`
    );
    console.log(response.data, "response deleteRole API");
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message || "Failed to delete role");
  }
};



export const getModules = async () => {

  try {
    const response = await axios.get(
        "/modules"
    );
    console.log(response.data, "id");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};