
import axios from "axios";


export const deleteExpense = async (expenseId) => {
  try {
    const response = await axios.delete(`/expenses/${expenseId}`);
    if (response.status === 200) {
      console.log("Expense deleted successfully");
    } else {
      console.error("Failed to delete expense");
    }
  } catch (error) {
    console.error("Error deleting expense:", error);
  }
};


export const createExpense = async (expenseData) => {
    try {
      const response = await axios.post(`/expenses`, expenseData);
      return response.data; // Return the data received from the response
    } catch (error) {
      console.error("Error creating expense:", error);
      throw error; // Rethrow the error for handling in the calling component
    }
  };


//Update expense By Id
export const updateExpense = async (expenseId, expenseData) => {

    return axios.put(
        `/expenses/${expenseId}`,
        expenseData
    );


};

//get expense by Id
export const getExpense = async (expenseId) => {
    return axios.get(`/expenses/${expenseId}`);


};


export const fetchExpenseCategoriesApi = async () => {
    try {
      const response = await axios.get(`/expenseCategories`);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch Expense Categories: " + error.message);
    }
  };