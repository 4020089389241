import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spin } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import Swal from 'sweetalert2';
import { createDeductionBulk } from '../../service/bulkUploadService';

const BulkUploadModal = ({ 
    onUploadData,
    isVisible,handleCancel }) => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData]=useState(null)
    const handleUpload = async() => {
        if (!file) {
            showWarning("Please select a file");
            return;
        }
      
        try {
                setLoading(true);
                await createDeductionBulk(data);
                onUploadData();
                setLoading(false); 
                document.getElementById('csvId').value = "";
                setFile(null);
                handleCancel();
        }catch{
            console.log("Error in fectching new data")
        }
        
    };

    const handleReset = () => {
        setFile(null);
        document.getElementById('csvId').value = ""; // Reset file input
    }

    const handleBulkUpload = async (event) => {
        try {
            const file = document.getElementById("csvId");
            setFile(file); // Set the selected file in state
            Papa.parse(event.target.files[0], {
                header: true,
                skipEmptyLines: true,
                complete: async (result) => {
                    const data = result.data;
                    console.log(data);
                    //adminAttendanceBulk(data);
                    setData(data)
                }
            });
            
        } catch (error) {
            console.error("Error in file uploading:", error);
        }
    };

    const showWarning = (str) => {
        return Swal.fire({
            title: "Error",
            text: str,
            icon: "error",
        });
    };

    const downloadSampleFile = () => {
        const sampleData = [
            ["record_number", "employee_id", "deduction_type", "amount", "payment_type", "effective_date"],
            ["1", "395", "Staff Loan", "5000", "One Time", "OCT-2024"]
        ];
        const csvString = Papa.unparse(sampleData);
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

        // Create a temporary link to download the CSV
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", "sample.csv"); // Specify file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the temporary link
    };


    return (
        <Modal
            zIndex={200000}
            open={isVisible}
            onCancel={handleCancel}
            footer={null}
            maskClosable={false}
            closable={false}
        >
            <div className="modal-content">
                <div className="page-wrapper-new p-0">
                    <div className="content">
                        <div className="modal-header border-0 custom-modal-header">
                            <div className="page-title">
                                <h4>Bulk Upload</h4>
                                <p className="mb-0">Select a file to upload</p>
                            </div>
                        </div>

                        <div className="modal-body custom-modal-body">
                            <div className='content'>
                                <div className='' style={{ fontStyle: "italic" }}>*Required field labels</div>
                                <p className='mt-1' style={{ textAlign: "justify" }}>
                                   The correct column order is (record_number, employee_id, deduction_type, amount, payment_type, date, installement)
                                </p>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }} className="mt-3">
                                <div className="mb-3 input-blocks" style={{ flex: 1, marginRight: "20px" }}>
                                    <label className="form-label">File Upload</label>
                                    <input
                                        type="file"
                                        className="form-control mt-0"
                                        onChange={handleBulkUpload}
                                        id='csvId'
                                        accept='.csv'
                                    />
                                </div>
                                <div className="mb-3 input-blocks">
                                    <label className="form-label pl-0">Sample File</label>
                                    <button className='btn pe-6 download-btn'
                                            onClick={downloadSampleFile}
                                    >Download <DownloadOutlined /></button>
                                </div>
                            </div>
                            <div className="pt-3" style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <button
                                    type="button"
                                    className="btn btn-cancel"
                                    onClick={handleCancel}
                                >
                                    Close
                                </button>
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-cancel me-2"
                                        onClick={handleReset}
                                        //disabled={loading}
                                    >
                                        {/* {loading ? <Spin size='small' /> : "Reset"} */}
                                        Reset
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-submit ml-0"
                                        onClick={handleUpload}
                                        disabled={loading}
                                    >
                                        {loading ? <Spin size='small' /> : "Upload"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

BulkUploadModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    onUploadData:PropTypes.func.isRequired
};

export default BulkUploadModal;
