import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import React, { useState, useEffect,useRef } from 'react';
import { getHolidayById, editHoliday } from '../../../service/holidayService';
import propTypes from 'prop-types';
import Swal from 'sweetalert2';
import Select from "react-select";
import { getAllHolidays } from '../../../service/holidayService';
import moment from 'moment';

const EditHolidays = ({ onHolidayAdded, holidayId }) => {
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState(true);
  const [duration, setDuration] = useState(0);
  const [academic, setAcademic] = useState(null);
  const selectRef = useRef(null);
  

  // setDuration
 
  useEffect(() => {
    if (holidayId) {
      setSelectedHoliday(holidayId);
    }
  }, [holidayId]);

  const setSelectedHoliday = async (holidayId) => {
    try {
      const response = await getHolidayById(holidayId);
      if (response.data) {
        const holiday = response.data.holiday;
        setName(holiday.name);
        setStartDate(dayjs(holiday.startDate));
        setEndDate(dayjs(holiday.endDate));
        setDuration(holiday.duration);
        setStatus(holiday.status);
        const selectedOption = options.find(option => option.value === holiday.academic);
        setAcademic(selectedOption);  
        
      } else {
        console.error('Failed to fetch holiday data');
      }
    } catch (error) {
      console.error('Error fetching holiday data:', error);
    }
  };
  const setSelectedHolidaydate = async (holidayId) => {
    try {
      const response = await getHolidayById(holidayId);
      if (response.data) {
        const holiday = response.data.holiday;
       
        setStartDate(dayjs(holiday.startDate));
        
        
      }
    } catch (error) {
      console.error('Error fetching holiday data:', error);
    }
  };
  
  const showWarning = (str) =>{
    return Swal.fire({
      title: "Error",
      text: str,
      icon: "error",
    });
  }

  useEffect(() => {
    if (startDate && endDate) {
        if (endDate.isBefore(startDate, 'day')) {
            showWarning('End date cannot be earlier than start date.');
            setEndDate(null);
            setDuration(0);
        } else {
            const diff = endDate.diff(startDate, 'days') + 1;
            setDuration(diff);
            
        }
        if (startDate.month() !== endDate.month() || startDate.year() !== endDate.year()) {
          showWarning("Start date and end date must be in the same month.");
          setEndDate(null);
          setDuration(0);
      }
    }else{
      setDuration(0)
  }
}, [startDate, endDate]);



  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.checked);
  };

  const handleSubmit = async () => {
    if (!name || !startDate || !endDate||(academic===null)) {
      showWarning("Please fill in the required fields.");
      return;
  }
    const holiday = {
      name,
      startDate,
      endDate,
      duration,
      status,
      academic:academic.value
    };


    try {
      console.log('Updated Holiday: ', holiday);
      const response = await editHoliday(holidayId, holiday);
      onHolidayAdded();
      
      if(response.data){
        showSuccess()
      }else{
        showWarning("Error in updating holiday")
      }
      
     
    } catch (error) {
      console.error('Error updating holiday:', error);
    }
  };

  const handleReset = () => {
    
setSelectedHoliday(holidayId)
    //setError('');
  //   if (selectRef.current) {
  //     selectRef.current.clearValue(); // Clear the selected value in the Select component
  // }
};
const handleClose = () => {
    
setSelectedHoliday(holidayId)
};
const options = [
  { value: true, label: 'Academic' },
  { value: false, label: 'Non-Academic' }
];
const handleSelectChange = (option) => {
        
  setAcademic(option);
  console.log(academic)
   
};
const showSuccess = () => {
  return Swal.fire({
    title: "Good job!",
    text: "Successfully Updated!",
    icon: "success",
  });
};
      const disabledEndDate = (current) => {
        if (!startDate) {
            return current && current.isBefore(dayjs(), 'day'); 
        }
        
        return (
          current && (
              current.isBefore(startDate, 'day') || 
              current.month() > startDate.month() && current.year() === startDate.year() || 
              current.year() > startDate.year() 
          ));
    };
    const disabledStartDate = (current) => {
        if (!endDate) {
            return  current && current.isBefore(dayjs(), 'month'); 
        }
        return (
         
          (current.year() < dayjs().year()) || // Disable all dates in previous years
          (current.year() === dayjs().year() && current.month() < dayjs().month()) // Disable all dates in previous months of the current year
      );
    };
  //////////////////////////
  const search = "";
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const fetchHolidays = async (search = '') => {
    await getAllHolidays({
      search,
      setData: setData,

    });
  };

  useEffect(() => {
    const fetchAndFilterHolidays = async () => {
      if (startDate || endDate) {

        fetchHolidays(search);

        let filtered = data;
        filtered = filtered.filter(date => date._id !== holidayId);
        if (startDate) {
          const startMonth = moment(startDate.toDate()).month();
          filtered = filtered.filter(date =>
            moment(date.startDate).month() === startMonth
          );
        }

        if (endDate) {
          const endMonth = moment(endDate.toDate()).month();
          filtered = filtered.filter(date =>
            moment(date.startDate).month() === endMonth
          );
        }

        setFilteredData(filtered);
      }
      else {
        setFilteredData([])
      }
    };

    fetchAndFilterHolidays();

  }, [startDate, endDate]);

  console.log(filteredData,"filter")

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (startDate) {
        const start = moment(startDate.toDate());

        filteredData.forEach(date => {

          if (
            start.isBetween(date.startDate, date.endDate, null, '[]') ||
            start.isSame(date.startDate) ||
            start.isSame(date.endDate)
          ) {
            showWarning("Selected start date already exists in Holiday: " + date.name);
            setSelectedHolidaydate(holidayId)
            setStartDate(null)
            setFilteredData([])
          }
        });
      }

      if (endDate) {
        const end = moment(endDate.toDate());

        filteredData.forEach(date => {

          if (
            end.isBetween(date.startDate, date.endDate, null, '[]') ||
            end.isSame(date.startDate) ||
            end.isSame(date.endDate)
          ) {
            showWarning("Selected end date already exists in Holiday: " + date.name);
            setSelectedHolidaydate(holidayId)
            setEndDate(null)
            setFilteredData([])
          }
        });
      }
    }, 250);

    return () => clearTimeout(timeoutId);
  }, [startDate, endDate, filteredData]);

    
      


  return (
    <div>
      <div className="modal fade" id="edit-department">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Holiday</h4>
                  </div>
                  {/* <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button> */}
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Edit Holiday</label>
                          <input
                            type="text"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className="input-blocks">
                          <label>Select Holiday Type</label>
                          <Select
                            ref={selectRef}
                            value={academic}
                            className='select'
                            options={options}
                            placeholder="Select holiday type"
                            onChange={handleSelectChange}
                          //isClearable
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Start Date</label>
                          <div className="input-groupicon calender-input">
                            <DatePicker
                              onChange={handleStartDateChange}
                              value={startDate}
                              format="DD-MM-YYYY"
                              className="filterdatepicker"
                              placeholder="Select Start Date"
                              getPopupContainer={(trigger) => trigger.parentNode}
                              disabledDate={disabledStartDate}
                              defaultPickerValue={endDate || dayjs()}
                              style={{
                                width: "100%",
                                boxSizing: "border-box"
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>End Date</label>
                          <div className="input-groupicon calender-input">
                            <DatePicker
                              onChange={handleEndDateChange}
                              value={endDate}
                              format="DD-MM-YYYY"
                              className="filterdatepicker"
                              placeholder="Select End Date"
                              disabledDate={disabledEndDate}
                              defaultPickerValue={startDate|| dayjs()}
                              getPopupContainer={(trigger) => trigger.parentNode}
                              style={{
                                width: "100%",
                                boxSizing: "border-box"
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>No of Days</label>
                          <input
                            value={duration}
                            type="number"
                            className="form-control"
                            placeholder="01"
                            disabled
                          />
                        </div>
                      </div> 
                    
                   
{/* error if select befor date as end */}
                      <div className="mb-0">
                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                          <span className="status-label">Status</span>
                          <input
                            type="checkbox"
                            id="user3"
                            className="check"
                            checked={status}
                            onChange={handleStatusChange}
                          />
                          <label htmlFor="user3" className="checktoggle">
                            {" "}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                                        
                        }} className="mt-5" >                   
                    <button
                        type="button"
                        className="btn btn-cancel"
                        data-bs-dismiss="modal"
                        onClick={handleClose}
                     >
                       Close
                    </button>
                      <div>
                        <button
                          type="button"
                          className="btn btn-reset me-2"
                          onClick={handleReset}
                        >
                          Reset
                        </button>
                        {(!name || !startDate || !endDate || (academic === null)) ? (
                          <button
                            type="button"
                            className="btn btn-submit"
                            onClick={handleSubmit}

                          >
                            Save
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-submit"
                            onClick={handleSubmit}
                            data-bs-dismiss="modal"
                          >
                            Save
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EditHolidays.propTypes = {
  onHolidayAdded: propTypes.func.isRequired,
  holidayId: propTypes.string.isRequired,
};

export default EditHolidays;
