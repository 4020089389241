import React, { useEffect, useState } from 'react';
import { Table, InputNumber, Slider, DatePicker, Modal, Spin, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { getAllAdjustmentsById, getAllInventoriesForAdjust } from '../../service/ApiServices';
import { Sliders } from 'react-feather';
import EditInventoryModal from './adjustStock'; // import the modal component
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
//import Swal from 'sweetalert2';

const { RangePicker: DateRangePicker } = DatePicker;

const ManageStockAdjutment = () => {
  const startOfCurrentMonth = dayjs().startOf('month');
  const endOfCurrentMonth = dayjs().endOf('month');
  const [selectedRange, setSelectedRange] = useState([
    startOfCurrentMonth,
    endOfCurrentMonth
  ]);
  const [data, setData] = useState({
    data: [],
    page: 1,
    limit: 10,
    total: 0
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [stockQtyRange, setStockQtyRange] = useState([0, 100]);
  const [showFilters, setShowFilters] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedInventoryItem, setSelectedInventoryItem] = useState(null);
  const [adjustments, setAdjustments] = useState([])
  const fetchData = async (page, limit) => {
    const filters = {
      search,
      price_min: priceRange[0],
      price_max: priceRange[1],
      stock_qty_min: stockQtyRange[0],
      stock_qty_max: stockQtyRange[1],
      expire_date_start: selectedRange[0].format("YYYY-MM-DD"),
      expire_date_end: selectedRange[1].format("YYYY-MM-DD"),
      page: page || data.page,
      limit: limit || data.limit
    };
    console.log(filters);
    await getAllInventoriesForAdjust({
      params: filters,
      setLoading,
      setInventoryData: setData,
    });
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchData();
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [search, priceRange, stockQtyRange, selectedRange]);

  const handleEditClick = (inventoryItem) => {
    setSelectedInventoryItem(inventoryItem);
    setEditModalVisible(true);
  };

  const handleSave = async (res) => {
    console.log(res)
    Swal.fire({
      icon: 'success',
      title: 'Stock Updated',
      text: res.data.message,
    });
    fetchData()
  };

  const columns = [
    {
      title: 'Product Name',
      dataIndex: ['product', 'productName'],
      key: 'product.productName',
    },
    {
      title: "GRN Number",
      dataIndex: ['grn_details', 'grnNumber'],
      key: 'grn_details.grnNumber',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Available Quantity',
      dataIndex: 'stock_qty',
      key: 'stock_qty',
    },
    {
      title: 'Stock Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Expire Date',
      dataIndex: 'expire_date',
      key: 'expire_date',
      render: (expire_date) => (
        <span>{new Date(expire_date).toLocaleDateString()}</span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => (
        <span>{new Date(createdAt).toLocaleDateString()}</span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div className="action-table-data">
          <Link
            onClick={() => handleEditClick(record)}
            className="me-2"
          >
            <i data-feather="edit" className="feather-edit"></i>
          </Link>
          <Link
            onClick={() => {
              setIsModalOpen(true)
              getAllAdjustmentsById({
                id: record._id,
                setData: setAdjustments,
                setLoading: setLoading2
              })
            }}
            className="me-2">
            <i data-feather="show" className="feather-eye"></i>
          </Link>
        </div>
      )
    }
  ];

  const filterStyle = {
    transition: 'max-height 0.5s ease, opacity 0.5s ease',
    maxHeight: showFilters ? '300px' : '0',
    overflow: 'hidden',
    opacity: isAnimating ? (showFilters ? 1 : 0) : 0,
  };

  useEffect(() => {
    if (showFilters) {
      setIsAnimating(true);
    } else {
      const timer = setTimeout(() => setIsAnimating(false), 500);
      return () => clearTimeout(timer);
    }
  }, [showFilters]);

  const onChange = (dates) => {
    if (dates === null) {
      setSelectedRange([startOfCurrentMonth, endOfCurrentMonth])
      return;
    }
    setSelectedRange(dates);
  };

  const handleTableChange = (pagination) => {
    fetchData(pagination.current, pagination.pageSize);
  };

  const columnsForAdjustments = [
    {
      title: 'User',
      dataIndex: ['user', 'name'],
      key: 'user.name',
    },
    {
      title: "Previous Stock",
      dataIndex: 'previous_stock',
      key: 'previous_stock',
      render: (_, info) => {
        return <Tag color='blue'>{info.previous_stock}</Tag>
      }
    },
    {
      title: 'Adjusted Stock',
      dataIndex: 'adjusted_stock',
      key: 'adjusted_stock',
      render: (_, info) => {
        return <Tag color='blue'>{info.adjusted_stock}</Tag>
      }
    },
    {
      title: 'Adjustment Type',
      dataIndex: 'adjustment_type',
      key: 'adjustment_type',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: "createdAt",
      render: (_, info) => {
        return <p>{new Date(info.createdAt).toLocaleDateString()}</p>
      }
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: (_, info) => {
        return <p>{info.reason?info.reason:"-------"}</p>
      }
    }

  ];
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Stocks</h4>
              <h6>Manage your stocks adjustments</h6>
            </div>
          </div>
        </div>

        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Link className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
                {showFilters && (
                  <div style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    gap: "1rem"
                  }}>
                    <div>
                      <div style={{ ...filterStyle, width: '25vw' }}>
                        <h6>Price Range</h6>
                        <div className="d-flex align-items-center">
                          <span>Min:</span>
                          <InputNumber
                            min={0}
                            max={10000}
                            value={priceRange[0]}
                            onChange={(value) => setPriceRange([value, priceRange[1]])}
                            className="mx-2"
                          />
                          <span>Max:</span>
                          <InputNumber
                            min={0}
                            max={10000}
                            value={priceRange[1]}
                            onChange={(value) => setPriceRange([priceRange[0], value])}
                            className="mx-2"
                          />
                        </div>
                        <Slider
                          style={{
                            marginLeft: "10px"
                          }}
                          range
                          min={0}
                          max={10000}
                          value={priceRange}
                          onChange={setPriceRange}
                        />
                      </div>
                      <div style={{ ...filterStyle, width: '25vw' }}>
                        <h6>Stock Quantity</h6>
                        <div className="d-flex align-items-center">
                          <span>Min:</span>
                          <InputNumber
                            min={0}
                            max={1000}
                            value={stockQtyRange[0]}
                            onChange={(value) => setStockQtyRange([value, stockQtyRange[1]])}
                            className="mx-2"
                          />
                          <span>Max:</span>
                          <InputNumber
                            min={0}
                            max={1000}
                            value={stockQtyRange[1]}
                            onChange={(value) => setStockQtyRange([stockQtyRange[0], value])}
                            className="mx-2"
                          />
                        </div>
                        <Slider
                          style={{
                            marginLeft: "10px"
                          }}
                          range
                          min={0}
                          max={1000}
                          value={stockQtyRange}
                          onChange={setStockQtyRange}
                        />
                      </div>
                    </div>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "flex-start"
                    }}>
                      <h6>Expire Date</h6>
                      <DateRangePicker onChange={onChange} value={selectedRange} format="YYYY-MM-DD" />
                    </div>
                  </div>
                )}

              </div>
              <div className="search-set">
                <a onClick={() => setShowFilters(!showFilters)} >
                  <Sliders className="me-2" />
                </a>
              </div>
            </div>
            <div className="table-responsive">
              <Table
                loading={loading}
                columns={columns}
                dataSource={data.data}
                rowKey="value"
                pagination={{
                  current: data.page,
                  pageSize: data.limit,
                  total: data.total
                }}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        zIndex={1200}
        title="Adjustments Summery"
        open={isModalOpen}
        width="75vw"
        onCancel={() => { setIsModalOpen(false) }}
        footer={null}
        maskClosable={false}
        closable={false}
      >
        <div className="table-responsive">
          {
            loading2 ? (
              <div
                style={{
                  width: "100%",
                  height: "20vh", // Make the div full height to center vertically
                  display: "flex",
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                  margin: "0 auto",
                }}
              >
                <Spin size='large' />
              </div>
            ) : (
              <Table
                columns={columnsForAdjustments}
                dataSource={adjustments}
                rowKey="value"
                pagination
              />
            )
          }

        </div>
        <div className="" style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <button
            type="button"
            className="btn btn-cancel"
            onClick={() => {
              setIsModalOpen(false)
            }}
          >
            Close
          </button>
        </div>
      </Modal>
      {selectedInventoryItem && (
        <EditInventoryModal
          visible={editModalVisible}
          onClose={() => setEditModalVisible(false)}
          inventoryItem={selectedInventoryItem}
          onSave={handleSave}
        />
      )}

    </div>
  );
};

export default ManageStockAdjutment;
