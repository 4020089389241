import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from 'react-query';
import { createBrand, editBrand } from '../../service/ApiServices';
import Swal from 'sweetalert2';
import { Spin } from 'antd';

const CreateBrandModal = ({ handleCancel, selectedBrand }) => {
  const [name, setName] = useState('');
  const queryClient = useQueryClient();

  useEffect(() => {
    if (selectedBrand) {
      setName(selectedBrand.name);
    } else {
      setName('');
    }
  }, [selectedBrand]);

  const mutation = useMutation(
    selectedBrand ? (data) => editBrand(selectedBrand._id, data) : createBrand,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('brands');
        Swal.fire({
          title: "Success",
          text: selectedBrand ? "Brand updated successfully!" : "Brand created successfully!",
          icon: "success",
        });
        handleCancel();
        setName('');
      },
      onError: (error) => {
        let errorMessage = "Failed to process request.";
        if (error.response) {
          const { status, data } = error.response;
          if (status === 400 && data.message) {
            errorMessage = data.message;
          } else if (status === 500) {
            errorMessage = "Server error. Please try again later.";
          }
        }
        Swal.fire({
          title: "Error",
          text: errorMessage,
          icon: "error",
        });
      },
    }
  );

  const handleSave = () => {
    if (name.trim() === '') {
      Swal.fire({
        title: "Warning",
        text: "Brand name is required!",
        icon: "warning",
      });
      return;
    }
    mutation.mutate({ name });
  };

  return (
    <div className="modal-content">
      <div className="modal-header border-0 custom-modal-header">
        <div className="page-title">
          <h4>{selectedBrand ? "Edit Brand" : "Create Brand"}</h4>
          <p className="mb-0">Fill in the required fields to {selectedBrand ? "edit" : "create"} a brand</p>
        </div>
      </div>
      <div className="modal-body custom-modal-body">
        <form>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="mb-3">
                <label className="form-label">Brand Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Brand Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </div>
        </form>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <button
            type="button"
            className="btn btn-cancel"
            onClick={() => {
              handleCancel();
              if(!selectedBrand){
                setName('');
              }
              
            }}
          >
            Close
          </button>
          <div>
            <button
              type="button"
              className="btn btn-reset me-2"
              onClick={() => setName('')}
            >
              Reset
            </button>
            <button
              type="button"
              className="btn btn-submit ml-0"
              onClick={handleSave}
              disabled={mutation.isLoading}
            >
              {mutation.isLoading ? <Spin size='small' /> : 'Save'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateBrandModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  selectedBrand: PropTypes.object,
};

export default CreateBrandModal;
