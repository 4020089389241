import React, { useState } from 'react'
import { createVariant } from "../../../service/ApiServices";

const AddVariant = () => {

    //add variant
    const [name, setName] = useState('');
    const [values, setValues] = useState('');
    const [status, setStatus] = useState(true);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await createVariant({
                name:name,
                values:values,
                status:status
            });
            console.log(response.data); 
            
        } catch (error) {
            console.error('Error creating variant:', error);
            
        }
    };



    //add variant
    return (
        <>
            {/* Add Unit */}
            <div className="modal fade" id="add-units">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Create Attributes</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Name</label>
                                            <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                                        </div>
                                        <div className="input-blocks">
                                            <label className="form-label">Variant</label>
                                           
                                            <span className="tag-text">
                                                Enter value separated by comma
                                            </span>
                                            <input type="text" className="form-control" value={values} onChange={(e) => setValues(e.target.value)} />
                                        </div>
                                        <div className="mb-0">
                                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                                <span className="status-label">Status</span>
                                                <input
                                                    type="checkbox"
                                                    id="variant-status"
                                                    className="check"
                                                    checked={status}
                                                    onChange={() => setStatus(!status)}
                                                />
                                                <label htmlFor="user2" className="checktoggle" />
                                            </div>
                                        </div>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                data-bs-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" 
                                            className="btn btn-submit"
                                            data-bs-dismiss="modal"
                                            >
                                                Create Attributes
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Unit */}
        </>

    )
}

export default AddVariant
