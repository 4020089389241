import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../core/breadcrumbs";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Book, User } from "react-feather";
import { GetInvoices } from "../../service/salesReportApiServices";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
import dayjs from "dayjs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const SalesReport = () => {
  const startOfCurrentMonth = dayjs().startOf("month");
  const endOfCurrentMonth = dayjs().endOf("month");

  const [selectedRange, setSelectedRange] = useState([
    startOfCurrentMonth,
    endOfCurrentMonth,
  ]);

  const [salesReports, setSalesReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [searchInvoice, setSearchInvoice] = useState("");
  const [searchCustomer, setSearchCustomer] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetInvoices();
        setSalesReports(response);
        setFilteredReports(response);
      } catch (error) {
        console.error("Error fetching sales reports:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filterReports = () => {
      let filtered = salesReports;

      if (searchInvoice) {
        filtered = filtered.filter((report) =>
          report.invoice_number
            .toLowerCase()
            .includes(searchInvoice.toLowerCase())
        );
      }

      if (searchCustomer) {
        filtered = filtered.filter((report) =>
          report.customer.customerName
            .toLowerCase()
            .includes(searchCustomer.toLowerCase())
        );
      }

      if (searchStatus) {
        filtered = filtered.filter((report) =>
          report.status.toLowerCase().includes(searchStatus.toLowerCase())
        );
      }

      setFilteredReports(filtered);
    };

    filterReports();
  }, [searchInvoice, searchCustomer, searchStatus, salesReports]);
  const onChange = (dates) => {
    if (dates === null) {
      setSelectedRange([startOfCurrentMonth, endOfCurrentMonth]);
      return;
    }
    setSelectedRange(dates);
  };
  //convert to Base64
  /*
  const getBase64FromUrl = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };*/

  const downloadPDF = async () => {
    try {
      console.log("Starting PDF download process...");

      const customWidth = 300; // Example: 210 mm (A4 width)
      const customHeight = 297; // Example: 297 mm (A4 height

      const doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: [customWidth, customHeight],
      });
      const pageWidth = doc.internal.pageSize.getWidth();

      // Header
      doc.setFontSize(20);
      doc.text("The Suit - Sales Report", pageWidth / 2, 20, {
        align: "center",
      });

      // Draw a line
      doc.setDrawColor(150, 150, 181);
      doc.line(0, 30, pageWidth, 30);

      //Body

      // Report data table
      const reports = filteredReports.map((report) => [
        report.invoice_number,
        report.customer.customerName,
        new Date(report.due_date).toLocaleDateString(),
        report.status,
        report.total,
        report.paid_amount,
        report.balance,
      ]);

      doc.autoTable({
        startY: 60,
        endY: 60,
        head: [
          [
            "Invoice Number",
            "Customer Name",
            "Due Date",
            "Status",
            "Total",
            "Paid Amount",
            "Balance",
          ],
        ],
        body: reports,
        theme: "grid",
        styles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
        headStyles: { fillColor: [74, 74, 73], textColor: [255, 255, 255] },
        footStyles: { fillColor: [74, 74, 73], textColor: [255, 255, 255] },
        columnStyles: {
          0: { cellWidth: 25 },
          1: { cellWidth: 40 },
          2: { cellWidth: 40 },
          3: { cellWidth: 40 },
          4: { cellWidth: 40 },
          5: { cellWidth: 40 },
          6: { cellWidth: 40 },
        },
        margin: { bottom: 5 },
      });
      // Calculate total, paid amount, and balance

      // Convert the jsPDF document to a Blob
      const pdfBlob = doc.output("blob");
      // Create a data URL from the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new browser tab using an iframe
      const view = window.open();
      view.document.write(
        `<iframe width="100%" height="100%" src="${pdfUrl}"></iframe>`
      );
      view.document.close();

      console.log("PDF previewed successfully");
    } catch (error) {
      console.error("Error in downloadPDF function:", error);
    }
  };
  const downloadExcel = () => {
    const worksheetData = [
      ["The Suit - Sales Report"], // Title
      [
        "Invoice Number",
        "Customer Name",
        "Due Date",
        "Status",
        "Total",
        "Paid Amount",
        "Balance",
      ], // Headers
      ...filteredReports.map((report) => [
        report.invoice_number,
        report.customer.customerName,
        new Date(report.due_date).toLocaleDateString(),
        report.status,
        report.total,
        report.paid_amount,
        report.balance,
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Report");

    XLSX.writeFile(workbook, "Sales_Report.xlsx");
  };
  return (
    <div className="page-wrapper">
      <div className="content">
        <Breadcrumbs
          maintitle="Sales Report"
          subtitle=" Manage Your Sales Report"
          downloadPDF={downloadPDF}
          downloadExcel={downloadExcel}
        />
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <RangePicker
                onChange={onChange}
                value={selectedRange}
                format="YYYY-MM-DD"
              />

              <div className="search-path">
                <Link
                  to="#"
                  className={`btn btn-filter ${
                    isFilterVisible ? "setclose" : ""
                  }`}
                  id="filter_search"
                  onClick={toggleFilterVisibility}
                >
                  <Filter className="filter-icon" />
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="input-blocks">
                      <Book className="info-img" />
                      <input
                        type="text"
                        placeholder="Search by Invoice Number"
                        className="form-control form-control-sm formsearch"
                        value={searchInvoice}
                        onChange={(e) => setSearchInvoice(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="input-blocks">
                      <User className="info-img" />
                      <input
                        type="text"
                        placeholder="Search by Customer Name"
                        className="form-control form-control-sm formsearch"
                        value={searchCustomer}
                        onChange={(e) => setSearchCustomer(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="input-blocks">
                      <select
                        className="form-control form-control-sm formsearch"
                        value={searchStatus}
                        onChange={(e) => setSearchStatus(e.target.value)}
                      >
                        <option value="">Search by Status</option>
                        <option value="paid">Paid</option>
                        <option value="not-paid">Not Paid</option>
                        <option value="partially-paid">Partially Paid</option>
                        <option value="cancelled">Cancelled</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table datanew">
                <thead>
                  <tr>
                    <th>Invoice Number</th>
                    <th>Customer Name</th>
                    <th>Due Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredReports.map((report) => (
                    <tr key={report._id}>
                      <td>{report.invoice_number}</td>
                      <td>{report.customer.customerName}</td>
                      <td>{new Date(report.due_date).toLocaleDateString()}</td>
                      <td>{report.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesReport;
