import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { addCategory, getCategory, updateCategory } from '../../service/ApiServices';
import { PlusOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { message, Spin, Upload, Image } from 'antd';
import ImgCrop from 'antd-img-crop';
import Select from 'react-select';
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const CreateCategoryModal = ({ handleCancel, selectedCategory }) => {
    const formDataToSend = new FormData();
    const [name, setName] = useState('');
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [selectCategory, setSelectCategory] = useState("");
    const [categories, setCategories] = useState([]);
    const [uploadedPic, setUploadedPic] = useState("")
    const queryClient = useQueryClient();
    const fetchCategory = () => getCategory("");
    const { data: categoryData } = useQuery('categories', fetchCategory, {
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (categoryData && categoryData.data && categoryData.data.data && categoryData.data.data.category) {
            const categoryOptions = [
                {
                    value: '',
                    label: 'Choose',
                },
                ...categoryData.data.data.category
                .filter((category) => !category.parentId)
                .map((category) => ({
                    value: category._id,
                    label: category.title,
                }))
            ];
            setCategories(categoryOptions);
        }
    }, [categoryData]);
    useEffect(() => {
        if (selectedCategory) {
            setName(selectedCategory.title);
            setSelectCategory(selectedCategory.parentId ? selectedCategory.parentId._id : '');
            setUploadedPic(selectedCategory.image);
        } else {
            handleReset();
        }
    }, [selectedCategory]);

    const mutation = useMutation(
        selectedCategory ? (data) => updateCategory(data, selectedCategory._id,) : addCategory,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('categories');
                Swal.fire({
                    title: "Success",
                    text: selectedCategory ? "Category updated successfully!" : "Category created successfully!",
                    icon: "success",
                });
                handleCancel();
                handleReset();
            },
            onError: (error) => {
                let errorMessage = "Failed to process request.";
                if (error.response) {
                    const { status, data } = error.response;
                    if (status === 400 && data.message) {
                        errorMessage = data.message;
                    } else if (status === 500) {
                        errorMessage = "Server error. Please try again later.";
                    }
                }
                Swal.fire({
                    title: "Error",
                    text: errorMessage,
                    icon: "error",
                });
            },
        }
    );

    const handleSave = () => {
        if (name.trim() === '' || (!selectedCategory && fileList.length===0)) {
            Swal.fire({
                title: "Warning",
                text: "Please fill in the required fields",
                icon: "warning",
            });
            return;
        }
        formDataToSend.append('title', name);
        if (fileList.length) {
            formDataToSend.append('file', fileList[0].originFileObj);
        }
        if (selectCategory) {
            formDataToSend.append('parentId', selectCategory);
        }
        mutation.mutate(formDataToSend);
    };
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const handleCustomRequest = async ({ file, onSuccess, onError }) => {
        setUploading(true);
        formDataToSend.append('profilePicture', file);
        try {
            onSuccess(true);
            message.success(`${file.name} uploaded successfully`);
        } catch (error) {
            onError(error);
            message.error(`${file.name} upload failed`);
        } finally {
            setUploading(false);
        }
    };
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );
    const handleReset = () => {
        setName('');
        setFileList([]);
        setSelectCategory('');
    }
    return (
        <div className="modal-content">
            <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                    <h4>{selectedCategory ? "Edit Category" : "Create Category"}</h4>
                    <p className="mb-0">Fill in the required fields to {selectedCategory ? "edit" : "create"} a category</p>
                </div>
            </div>
            <div className="modal-body custom-modal-body">
                <div style={{ marginBottom: "30px", display: "flex", justifyContent: "center" }}>
                    <div style={{ textAlign: "center" }}>
                        {selectedCategory && <label className="form-label" style={{ display: "block", marginBottom: "10px" }}>
                            Uploaded Category Picture
                        </label>}
                        {selectedCategory && (
                            <Image
                                src={selectedCategory.image}
                                alt="Profile Picture"
                                width={120}
                                height={120}
                                style={{
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                    border: "2px solid #ddd",
                                    padding: "5px",
                                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)"
                                }}
                                preview={{
                                    zIndex: 10000,
                                    visible: previewOpen,
                                    src: previewImage,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                }}
                                onClick={() => {
                                    setPreviewImage(uploadedPic);
                                    setPreviewOpen(true);
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className="profile-pic-upload">
                    <div className="input-blocks mb-0">
                        <ImgCrop>
                            <Upload
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                                customRequest={handleCustomRequest}
                                disabled={uploading}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                        </ImgCrop>
                        {previewImage && (
                            <Image
                                wrapperStyle={{ display: "none" }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImage(""),
                                }}
                                src={previewImage}
                            />
                        )}
                        <label className="form-label">Category Picture</label>
                    </div>
                </div>
                <form>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="mb-3">
                                <label className="form-label">Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Category Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="mb-3">
                                <label className="form-label">Partent Category<p style={{
                                    display: "inline",
                                    color: "#C0C0C0"
                                }}>{`(Optional)`}</p><p
                                    style={{
                                        display: "inline",
                                        color: "black",
                                        fontStyle: "italic",
                                        fontSize: "12px",
                                        marginLeft: "10px"
                                    }}
                                >if you want to make sub category</p></label>
                                <Select
                                    name="category"
                                    value={categories.find((category) => category.value === selectCategory) || ''}
                                    className="select"
                                    options={categories}
                                    onChange={(selectedOption) => { setSelectCategory(selectedOption.value) }}
                                    placeholder="Choose"

                                />
                            </div>
                        </div>
                    </div>
                </form>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={() => {
                            handleCancel();
                            if (!selectedCategory) {
                                handleReset();
                            }

                        }}
                    >
                        Close
                    </button>
                    <div>
                        <button
                            type="button"
                            className="btn btn-reset me-2"
                            onClick={() => {
                                handleReset();
                            }}
                        >
                            Reset
                        </button>
                        <button
                            type="button"
                            className="btn btn-submit ml-0"
                            onClick={handleSave}
                            disabled={mutation.isLoading}
                        >
                            {mutation.isLoading ? <Spin size='small' /> : 'Save'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

CreateCategoryModal.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    selectedCategory: PropTypes.object,
};

export default CreateCategoryModal;
