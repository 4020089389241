import {PlusCircle, Sliders} from "react-feather";
import {Link} from "react-router-dom";
import {Modal, Table} from "antd";
import React, {useState} from "react";
import CreateAllowance from "./createAllowance";

const Allowances = () => {

    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [loading] = useState(false);
    const [search, setSearch] = useState(null);
    const [selectedAllowance, setSelectedAllowance] = useState(null);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Allowance Type',
            dataIndex: 'allowanceType',
            key: 'allowanceType',
            render: (allowanceType) => (<span className={`badge ${allowanceType === "Fixed" ? 'badge-linesuccess' : 'badge-linedanger'}`} style={{ width: 80, padding: 6 }}>
                <Link to="#" style={{ cursor: 'default', fontSize: 14 }}>{allowanceType === "Fixed" ? 'Fixed' : 'Variable'}</Link>
            </span>)
        },
        {
            title: 'Show In Pay Slip',
            dataIndex: 'showPaySlip',
            key: 'showPaySlip',
            render: (showPaySlip) => showPaySlip ==true ?
                <span className="badge bg-success">Yes</span> : <span className="badge bg-primary">No</span>
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <td className="action-table-data">
                    <Link
                        className="me-2 p-2"
                        to="#"
                        onClick={() => {
                            setSelectedAllowance(record);
                            setOpenCreateModal(true);
                        }}
                    >
                        <i data-feather="edit" className="feather-edit"></i>
                    </Link>

                </td>
            ),
        },
    ];
    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Allowances</h4>
                            <h6>Manage your allowances</h6>
                        </div>
                    </div>
                    <div className="page-btn">
                        <a className="btn btn-added" onClick={() => setOpenCreateModal(true)}>
                            <PlusCircle className="me-2"/>
                            New Allowance Category
                        </a>
                    </div>
                </div>

                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <Link className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search"/>
                                    </Link>
                                </div>
                                <div className="wordset">
                                    <ul>
                                        <li>
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="pdf">
                                                <img src="assets/img/icons/pdf.svg" alt="pdf"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="excel">
                                                <img src="assets/img/icons/excel.svg" alt="excel"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="print">
                                                <img src="assets/img/icons/printer.svg" alt="print"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="search-set">
                                <a href="#" className="btn btn-filter" id="filter_search">
                                    <Sliders className="me-2"/>Filter
                                </a>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Table loading={loading} columns={columns} dataSource={[]} rowKey="value"
                                   pagination={false}/>
                        </div>
                    </div>
                </div>
            </div>


                <Modal
                    zIndex={200000}
                    open={openCreateModal}
                    onCancel={()=>setOpenCreateModal(false)}
                    footer={null}
                    maskClosable={false}
                    closable={false}
                >
                    <CreateAllowance selectedAllowance={selectedAllowance} handleCancel={()=>setOpenCreateModal(false)}/>
                </Modal>


        </div>

    )
}

export default Allowances;
