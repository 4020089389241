import React, { useEffect, useState } from "react";
import { createLeaveEmployee } from "../../../service/leavesEmployeeApiService";
import { getUserLeaves } from "../../../service//leavesEmployeeApiService";

import Select from "react-select";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import { DatePicker } from "antd";
//const { RangePicker } = DatePicker;

const AddLeaveEmployee = ({ handleCancel, FetchLeaveEmployees }) => {
  const leaveDayOptions = [
    { value: "Choose Status", label: "Choose Status" },
    { value: "Full Day", label: "Full Day", dayValue: 1 },
    { value: "Half Day", label: "Half Day", dayValue: 0.5 },
  ];

//  const [selectedDate, setSelectedDate] = useState([null, null]);
  const [dateRange, setDateRange] = useState([]);

  const [selectedStartDate,setSelectedStartDate] = useState(null);
  const [selectedEndDate,setSelectedEndDate] = useState(null);

  const [selectedLeaveType, setSelectedLeaveType] = useState(null);
  const [reason, setReason] = useState();
  const [leaveDays, setLeaveDays] = useState([]);
  const [leaveType, setLeaveType] = useState([]); //[funeral, sick leave]
  
  // reset form fields after success message
const resetForm = () => {
  setSelectedStartDate(null);
  setSelectedEndDate(null);
  setDateRange([]);
  setSelectedLeaveType(null)
  setReason("");
  setLeaveDays([]);


};
  useEffect(() => {
    fetchLeaveTypes();
    
  }, []);

  const fetchLeaveTypes = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const userId = user.data._id;
      console.log("User:", userId);
      const response = await getUserLeaves(userId);
      const leaveTypeData=response.leaveBalances;
      if (leaveTypeData) {
        const options = leaveTypeData.map((leave) => ({
          value: leave.leaveType._id,
          label: leave.leaveType.name,
          leaveCount:leave.leaveCount
        }));
 //     console.log(options,"This is Optonsns")
        setLeaveType([{ value: "Choose", label: "Choose" }, ...options]);
      }
    } catch (error) {
      console.error("Error fetching leave Types:", error);
    }
  };


  // Handle Date Change
  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
//Handle Dates
const handleStartDateChange = (startDate) => {
  setSelectedStartDate(startDate);

};


//Handle validation
const handleEndDateChange = async(endDate) =>{
  setSelectedEndDate(endDate);
  if (selectedStartDate && endDate){
    const start = selectedStartDate.$d;
    const end = endDate.$d;

    
      const daysArray = [];
      let dayCount = 1;
      let currentDate = new Date(start);
      while (currentDate.getTime() <= end.getTime()) {
        daysArray.push({
          day: `Day ${dayCount}`,
          date: formatDate(currentDate),
          leaveType: null,
        });
        currentDate.setDate(currentDate.getDate() + 1);
        dayCount++;
      }
      setDateRange(daysArray);
      setLeaveDays(daysArray);
    } else {
      setDateRange([]);
      setLeaveDays([]);
      console.log("");
    }
}

const disabledEndDate = (currentDate) => {
  if (!selectedStartDate) {
    return false; // If no start date is selected, no date is disabled
  }

  const endOfCurrentMonth = selectedStartDate.clone().endOf('month');

  // Disable dates before the selected start date and after the current month
  return (
    currentDate < selectedStartDate.startOf('day') || 
    currentDate > endOfCurrentMonth
  );
};
  // Handle Leave Type Change
  const handleLeaveTypeChange = (index, selectedOption) => {
    
    const updatedLeaveDays = [...leaveDays];
    updatedLeaveDays[index].leaveType = selectedOption.label;
    updatedLeaveDays[index].dayValue = selectedOption.dayValue;
    setLeaveDays(updatedLeaveDays);
  };

  const handleLeaveTypeSelection = (selectedOption) => {
    setSelectedLeaveType(selectedOption); 
    console.log("Selected Leave Type:", selectedOption); 
   // console.log("Leave balances:",leaveBalances)

  };

  // Calculate total leave days (sum of Full Day and Half Day values)
  const calculateTotalLeaveDays = () => {
    return leaveDays.reduce((total, day) => total + (day.dayValue || 0), 0); // Default to 0 if dayValue is not set
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const user = JSON.parse(localStorage.getItem("user"));
    

    if (!user) {
      Swal.fire({
        icon: "warning",
        title: "Error",
        text: "User data not found. Please log in again.",
      });
      return;
    }

    const totalLeaveDays = calculateTotalLeaveDays();
    //console.log(totalLeaveDays,"LEAVE TOTAL")
    if (selectedLeaveType && totalLeaveDays > selectedLeaveType.leaveCount) {
    
      Swal.fire({
        icon: "warning",
        title: "Invalid leave Days",
        text: "The total leave count cannot exceed the available leave balance."
      });
      return;
    }
    if (!reason || reason.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Error",
        text: "Reason is required.",
      });
      return;
    }


    const startDateFormatted = formatDate(selectedStartDate.$d);
    const endDateFormatted = selectedEndDate ? formatDate(selectedEndDate.$d) : null;

    // Prepare the leave data
    
    const leaveData = {
      
      leavetype: selectedLeaveType.value,
      leaveTypeName: selectedLeaveType.label,
      leaveDays: leaveDays.map((day) => ({
        date: day.date,
        Day: day.leaveType, // Full Day or Half Day
      })),
      reason: reason,
      startDate: startDateFormatted,
      ...(endDateFormatted && { endDate: endDateFormatted }),
      approval: null,
      userId: user.data._id, // Add userId and name from local storage
      name: user.data.name,
      duration:totalLeaveDays
    };
    
    createLeaveEmployee(leaveData)
      .then((response) => {
        console.log("Response from server:", response);
    
        // Check if the server responded with a success status
        if (response.status === "success") {
            FetchLeaveEmployees(); 
          Swal.fire({
            icon: "success",
            title: "Leave Submitted",
            text: "Leave has been submitted successfully!",
          }).then(() => {
            resetForm();
            handleCancel(); 
          });
        } else {
          // Handle non-success statuses
          Swal.fire({
            icon: "error",
            title: "Error",
            text:"Failed to submit leave:" || response.message,
          });
        }
      })
      .catch((error) => {
        console.error("Failed to submit leave:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      });
    
  };

  return (
    <div>
      {/* Add Leave */}

      <div className="page-wrapper-new p-0">
        <div className="modal-content">
          <div className="modal-header border-0 custom-modal-header">
            <div className="page-title">
              <h4>Apply Leave</h4>
            </div>
          </div>
          <div className="modal-body custom-modal-body">
            <form onSubmit={handleSubmit}>
              <div className="row">

              <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="form-label">Select Leave Type</label>

                    <Select
                      className="select"
                      value={selectedLeaveType}
                      options={leaveType}
                      placeholder="Select Leave Day"
                      onChange={handleLeaveTypeSelection}
                    />
                  </div>
                </div>
                

                <div className="col-lg-12">
                  <div className="input-blocks">
                  <label className="form-label">{selectedLeaveType?.label !== "Short Leave"?"Start Date":"Date"}</label>
                    <div className="input-groupicon calender-input">
                      <DatePicker
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        className="filterdatepicker"
                        dateFormat="dd-MM-yyyy"
                        placeholder={selectedLeaveType?.label !== "Short Leave"?"Start Date":"Date"}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        style={{ width: "100%", height: "40px" }}
                      />
                    </div>
                  </div>
                  {selectedLeaveType?.label !== "Short Leave" && (
                    <div className="input-blocks">
                      <label className="form-label">End Date</label>
                      <div className="input-groupicon calender-input">
                        <DatePicker
                          value={selectedEndDate}
                          onChange={handleEndDateChange}
                          className="filterdatepicker"
                          dateFormat="dd-MM-yyyy"
                          placeholder={"End Date"}
                          disabledDate={disabledEndDate}
                          getPopupContainer={(triggerNode) => triggerNode.parentNode}
                          style={{ width: "100%", height: "40px" }}
                        />
                      </div>
                    </div>
                  )}
                </div>


                {dateRange.map((item, index) => (
                  <div key={index} className="col-lg-12">
                    <div className="apply-leave">
                      <div className="leave-apply">
                        <div className="leave-date">
                          <span>{item.day}</span>
                          <p>{item.date}</p>
                        </div>
                        <div className="leave-time">
                          <div className="input-blocks mb-0">
                            <Select
                              className="select"
                              options={leaveDayOptions}
                              placeholder="Select Leave Day"
                              onChange={(selectedOption) =>
                                handleLeaveTypeChange(index, selectedOption)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

               

                <div className="col-lg-12">
                  <div className="mb-3 summer-description-box mb-0">
                    <label>Reason</label>

                    <input
                      type="text"
                      value={reason}
                      className="form-control"
                      onChange={(event) => setReason(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="modal-footer-btn">
                <button
                  type="button"
                  className="btn btn-cancel me-2"
                  onClick={() => {
                    resetForm(); // Reset the form
                    handleCancel(); // Close the modal
                  }}
                >
                  Cancel
                </button>

                <button className="btn btn-submit">Submit</button>
              </div> */}
              <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <button
            type="button"
            className="btn btn-cancel"
            onClick={() => {
              resetForm(); // Reset the form
              handleCancel(); // Close the modal
            }}
          >
            Close
          </button>
          <div>
            <button
              type="button"
              className="btn btn-reset me-2"
              //onClick={handleReset}
            >
              Reset
            </button>
            <button className="btn btn-submit" 
            //  onClick={() => {
            //   resetForm();       
            // }}
            >
             Save
            </button>
          </div>
        </div>
            </form>
          </div>
        </div>
      </div>

      {/* /Add Leave */}
    </div>
  );
};

AddLeaveEmployee.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  leaveBalances: PropTypes.array.isRequired,
  FetchLeaveEmployees:PropTypes.func.isRequired,
};
export default AddLeaveEmployee;
