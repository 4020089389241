import React, { useEffect, useState } from 'react';
import { Table, Modal, Tag } from 'antd';
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import { getAllJobTypeMethod } from '../../service/ApiServices';
import CreateJobTypeModal from '../../core/modals/inventory/createJobType';
import { getStyleById } from '../../service/styleService';



const JobType = () => {
    const [data, setData] = useState([]);
    const [isJobTypeAdd, setIsJobTypeAdd] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedJobType, setSelectedJobType] = useState(null);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
  

    const fetchData = async () => {
        setLoading(true);
    
        try {
            // Fetch job types
            const jobTypes = await getAllJobTypeMethod({ search, setLoading });
    
            const updatedJobTypes = await Promise.all(
                jobTypes.map(async (jobType) => {
                    try {
                        // Fetch style objects to get their IDs
                        const stylesWithIds = await Promise.all(
                            jobType.styles.map(async (styleId) => {
                                const style = await getStyleById(styleId);
                                return {
                                    value: styleId,
                                    name: style.styleName
                                };
                            })
                        );
                        return {
                            ...jobType,
                            styles: stylesWithIds,
                        };
                    } catch (error) {
                        console.error('Error fetching styles for jobType:', error);
                        return {
                            ...jobType,
                            styles: [],
                        };
                    }
                })
            );
    
            setData(updatedJobTypes);
        } catch (error) {
            console.error('Error fetching job types:', error);
            // Optionally show an error message to the user
        } finally {
            setLoading(false);
        }
    };
    
    
    useEffect(() => {
        const handler = setTimeout(() => {
            fetchData();
            setSelectedJobType(null);
        }, 600);

        return () => {
            clearTimeout(handler);
        };
    }, [search, isJobTypeAdd]);

    useEffect(()=>{
        if(isJobTypeAdd){
            fetchData()
            setIsJobTypeAdd(false);
        }
    },[isJobTypeAdd])

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setSelectedJobType(null);
        setIsModalOpen(false);
    };

    const handleJobTypeAdding = () => {
        setIsJobTypeAdd(true);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Styles',
            dataIndex: 'styles',
            key: 'styles',
            render: (styles) => {
                return (

                        styles.map((style, i) => (
                            <Tag color={"orange"} key={i}>
                                {style.name.toUpperCase()}
                            </Tag>
                        ))

                );
            },
        },
        {
            title: 'Price (Rs.)',
            dataIndex: 'price',
            key: 'price',
            align:'right'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <td className="action-table-data">
                    <Link
                        className="me-2 p-2"
                        to="#"
                        onClick={() => {
                            setSelectedJobType(record);
                            setIsModalOpen(true);
                        }}
                    >
                        <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                </td>
            ),
        },
    ];




    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Services</h4>
                            <h6>Manage all your services from below  </h6>
                        </div>
                    </div>
                    <div className="page-btn">
                        <a className="btn btn-added" onClick={() => setIsModalOpen(true)}>
                            <PlusCircle className="me-2" />
                            Add New Item
                        </a>
                    </div>
                </div>

                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <Link className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </Link>
                                </div>

                            </div>

                        </div>
                        <div className="table-responsive">
                            <Table loading={loading} columns={columns} dataSource={data} rowKey="value" pagination={false} />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                zIndex={200000}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                closable={false}
            >
                <CreateJobTypeModal handleJobTypeAdding={handleJobTypeAdding} handleCancel={handleCancel} selectedJobType={selectedJobType} />
            </Modal>
        </div>
    );
};

export default JobType;
