// import React, { useState } from "react";
// import Scrollbars from "react-custom-scrollbars-2";
// import { Link, useLocation } from "react-router-dom";
// import * as Icon from "react-feather"; // Importing icons for use
// import HorizontalSidebar from "./horizontalSidebar";
// import CollapsedSidebar from "./collapsedSidebar";
// const Sidebar = () => {
//   const location = useLocation();
//   const [subOpen, setSubOpen] = useState("");
//   const storedUserData = localStorage.getItem("user");
//   const userData = JSON.parse(storedUserData);
//   const id = userData?.data?.uid; //
//   //console.log('user id1:', userData)
//   const [userRights, setUserRights] = useState([]);
//   useEffect(() => {
//     const fetchUserRights = async () => {
//       try {
//         const response = await fetch(`http://localhost:8000/api/userright/${id}`);
//         if (!response.ok) {
//           throw new Error("Failed to fetch user rights");
//         }
//         const data = await response.json();
//         console.log("Fetched Data:", data.data);
//         setUserRights(data.data || []); // Ensure it's always an array
//       } catch (error) {
//         console.error("Error fetching data:", error);
//         setUserRights([]); // Set to empty array in case of error
//       }
//     };
//     fetchUserRights(); // Fetch the data on component mount
//   }, [id]);
//   const toggleSidebar = (title) => {
//     setSubOpen(subOpen === title ? "" : title);
//   };
//
//   return (
//     <div>
//       <div className="sidebar" id="sidebar">
//         <Scrollbars>
//           <div className="sidebar-inner slimscroll">
//             <div id="sidebar-menu" className="sidebar-menu">
//             <ul>
//             {Array.isArray(userRights) && userRights.length > 0 ? (
//                   <>
//                     {userRights.map((right, index) => {
//                       if (right.right === "Dashboard" && right.status === 1) {
//                         // Rendering for "Dashboard" when status is 1
//                         return (
//
//                           <li key={index}
//                           className="submenu"
//                           style={{
//                             borderBottom: '1px solid #e8ebed',
//                             padding: '0 0 14px 0',
//                             display: 'block',
//                             marginbottom: '14px',
//
//                           }}
//                           >
//
//                             <Link
//                               to="/"
//                               onClick={() => toggleSidebar("Dashboard")}
//                               className={`${subOpen === "Dashboard" ? "subdrop" : ""} ${
//                                 location.pathname === "/" ? "" : "active"
//                               }`}
//                               style={{ backgroundColor: ' rgba(254, 159, 67, 0.08) '
//
//                               }}
//                             >
//                               <Icon.Grid />
//                               <span>Dashboard</span>
//                               <span
//                                 className={`menu-arrow ${subOpen === "Dashboard" ? "open" : ""}`}
//                               ></span>
//                             </Link>
//                             <ul
//                               style={{
//                                 display: subOpen === "Dashboard" ? "block" : "none",
//                               }}
//                             >
//                               <li>
//                                 <Link
//                                   to="/admin-dashboard"
//                                   className={`${
//                                     location.pathname === "/admin-dashboard" ? "active" : ""
//                                   }`}
//
//                                 >
//                                   Admin Dashboard
//                                 </Link>
//                               </li>
//                             </ul>
//
//                           </li>
//
//
//                         );
//
//                       }
//
//
//                       else if (right.right === "Jobs" && right.status === 1) {
//                         return (
//                           <li
//                           key={index}
//                           style={{
//                             marginBottom: '1px',  // Corrected 'marginbottom' to 'marginBottom'
//                           }}
//                         >
//
//                             <Link
//                               to="/jobs"
//
//                             >
//                               <Icon.Briefcase />
//                               <span>Job</span>
//                             </Link>
//
//                           </li>
//                         );
//                       }  else if (right.right === "Services" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li
//                           key={index}
//                           style={{
//                             marginBottom: '1px',  // Corrected 'marginbottom' to 'marginBottom'
//                           }}
//                         >
//                             <Link
//                               to="/jobs-types"
//                               // onClick={() => toggleSidebar("Services")}
//                               // className={`${subOpen === "Services" ? "subdrop" : ""} ${
//                               //   location.pathname === "/jobs-types" ? "" : ""
//                               // }`}
//                             >
//                              <Icon.Copy/>
//                               <span>Services</span>
//                             </Link>
//                           </li>
//                         );
//                       }
//                        else if (right.right === "Body-Types" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index}
//                           style={{
//
//                             borderBottom: '1px solid #e8ebed',
//                             padding: '0 0 14px 0',
//                             display: 'block',
//                             marginbottom: '14px'
//                           }}
//                           >
//                             <Link
//                               to="/body-types"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.Type/>
//                               <span>Body Types</span>
//                             </Link>
//                           </li>
//                         );
//                       }
//                       else if (right.right === "Inventory" && right.status === 1) {
//                         return (
//                           <li key={index} style={{ borderBottom: '1px solid #e8ebed', padding: '0 0 14px 0', display: 'block', marginBottom: '14px' }}>
//                             <Link
//                               to="/"
//                               onClick={() => toggleSidebar("Inventory")}
//                               className={`${subOpen === "Inventory" ? "subdrop" : ""} ${location.pathname === "/" ? "" : "active"}`}
//                             >
//                                <Icon.Package/>
//                               <span>Inventory</span>
//                               <span className={`menu-arrow ${subOpen === "Inventory" ? "open" : ""}`}></span>
//                             </Link>
//
//                             <ul
//                               style={{
//                                 display: subOpen === "Inventory" ? "block" : "none",
//                                 listStyleType: 'disc', // Adding dots to the list
//                                 paddingLeft: '35px' // Adjusting padding for dot spacing
//                               }}
//                             >
//                               {userRights.some(right => right.right === "product-list" && right.status === 1) && (
//                                 <li>
//                                   <Link
//                                     to="/product-list"
//                                     className={`${location.pathname === "/product-list" ? "active" : ""}`}
//                                   >
//                                     <span>Products</span>
//                                   </Link>
//                                 </li>
//                               )}
//
//                               {userRights.some(right => right.right === "add-product" && right.status === 1) && (
//                                 <li>
//                                   <Link
//                                     to="/add-product"
//                                     className={`${location.pathname === "/add-product" ? "active" : ""}`}
//                                   >
//                                     <span>Create Product</span>
//                                   </Link>
//                                 </li>
//                               )}
//
//                               {userRights.some(right => right.right === "services" && right.status === 1) && (
//                                 <li>
//                                   <Link
//                                     to="/jobs-types"
//                                     className={`${location.pathname === "/services" ? "active" : ""}`}
//                                   >
//                                     <span>Services</span>
//                                   </Link>
//                                 </li>
//                               )}
//
//                               {userRights.some(right => right.right === "grn" && right.status === 1) && (
//                                 <li>
//                                   <Link
//                                     to="/grn"
//                                     className={`${location.pathname === "/grn" ? "active" : ""}`}
//                                   >
//                                     <span>GRN</span>
//                                   </Link>
//                                 </li>
//                               )}
//
//                               {userRights.some(right => right.right === "issuance" && right.status === 1) && (
//                                 <li>
//                                   <Link
//                                     to="/issuance"
//                                     className={`${location.pathname === "/issuance" ? "active" : ""}`}
//                                   >
//                                     <span>Issuance</span>
//                                   </Link>
//                                 </li>
//                               )}
//
//                               {userRights.some(right => right.right === "low-stocks" && right.status === 1) && (
//                                 <li>
//                                   <Link
//                                     to="/low-stocks"
//                                     className={`${location.pathname === "/low-stocks" ? "active" : ""}`}
//                                   >
//                                     <span>Low Stocks</span>
//                                   </Link>
//                                 </li>
//                               )}
//
//                               {userRights.some(right => right.right === "category-list" && right.status === 1) && (
//                                 <li>
//                                   <Link
//                                     to="/category-list"
//                                     className={`${location.pathname === "/category-list" ? "active" : ""}`}
//                                   >
//                                     <span>Category</span>
//                                   </Link>
//                                 </li>
//                               )}
//
//                               {userRights.some(right => right.right === "sub-categories" && right.status === 1) && (
//                                 <li>
//                                   <Link
//                                     to="/sub-categories"
//                                     className={`${location.pathname === "/sub-categories" ? "active" : ""}`}
//                                   >
//                                     <span>Sub Category</span>
//                                   </Link>
//                                 </li>
//                               )}
//
//                               {userRights.some(right => right.right === "brand-list" && right.status === 1) && (
//                                 <li>
//                                   <Link
//                                     to="/brand-list"
//                                     className={`${location.pathname === "/brand-list" ? "active" : ""}`}
//                                   >
//                                     <span>Brands</span>
//                                   </Link>
//                                 </li>
//                               )}
//
//                               {userRights.some(right => right.right === "units" && right.status === 1) && (
//                                 <li>
//                                   <Link
//                                     to="/units"
//                                     className={`${location.pathname === "/units" ? "active" : ""}`}
//                                   >
//                                     <span>Units</span>
//                                   </Link>
//                                 </li>
//                               )}
//                             </ul>
//                           </li>
//                         );
//                       }
//
//
//                       else if (right.right === "Manage-Stock" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//
//                             marginBottom: "0px"
//                           }}
//                           >
//                             <Link
//                               to="/manage-stocks"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//
//
//                               // }`}
//                             >
//                               <Icon.Package/>
//                               <span>Manage Stock</span>
//
//                             </Link>
//
//                           </li>
//                         );
//                       }
//                       else if (right.right === "Stock-Adjustment" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             marginBottom: "0px"
//                           }}
//                           >
//                             <Link
//                               to="/stock-adjustment"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.Clipboard/>
//                               <span>Stock Adjustment</span>
//
//                             </Link>
//
//                           </li>
//                         );
//                       }
//                       else if (right.right === "Stock-Transfer" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             borderBottom: '1px solid #e8ebed',
//                             padding: '0 0 14px 0',
//                             display: 'block',
//                             marginbottom: '14px'
//                           }}>
//                             <Link
//                               to="/stock-transfer"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.Truck/>
//                               <span>Stock Transfer</span>
//
//                             </Link>
//
//                           </li>
//                         );
//                       }
//                       else if (right.right === "Invoices" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             marginBottom: "0px"
//                           }}>
//                             <Link
//                               to="/invoices-list"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.FileText/>
//                               <span>Invoices</span>
//
//                             </Link>
//
//                           </li>
//                         );
//                       } else if (right.right === "Quotation" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             borderBottom: '1px solid #e8ebed',
//                             padding: '0 0 14px 0',
//                             display: 'block',
//                             marginbottom: '14px'
//                           }}>
//                             <Link
//                               to="/quotation-list"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.Save/>
//                               <span>Quotation</span>
//
//                             </Link>
//
//                           </li>
//                         );
//                       }
//                       else if (right.right === "Coupons" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             borderBottom: '1px solid #e8ebed',
//                             padding: '0 0 14px 0',
//                             display: 'block',
//                             marginbottom: '14px'
//                           }}
//                           >
//                             <Link
//                               to="/coupons"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.ShoppingCart/>
//                               <span>Coupons</span>
//
//                             </Link>
//
//                           </li>
//                         );
//                       }
//                       else if (right.right === "Purchases" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             marginBottom: "0px"
//                           }}>
//                             <Link
//                               to="/purchase-list"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.ShoppingBag/>
//                               <span>Purchases</span>
//
//                             </Link>
//
//                           </li>
//                         );
//                       }  else if (right.right === "Purchase-Order" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             marginBottom: "0px"
//                           }}>
//                             <Link
//                               to="/purchase-order-report"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.FileMinus/>
//                               <span>Purchase Order</span>
//
//                             </Link>
//
//                           </li>
//                         );
//                       }  else if (right.right === "Purchase-Return" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                             style={{
//                             borderBottom: '1px solid #e8ebed',
//                             padding: '0 0 14px 0',
//                             display: 'block',
//                             marginbottom: '14px'
//                           }}
//                           >
//                             <Link
//                               to="/purchase-returns"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.RefreshCw/>
//                               <span>Purchase Return</span>
//
//                             </Link>
//
//                           </li>
//                         );
//                        }
//                        if (right.right === "Expenses" && right.status === 1) {
//                         return (
//                           <li key={index} className="submenu" style={{ borderBottom: '1px solid #e8ebed', padding: '0 0 14px 0', display: 'block', marginBottom: '14px' }}>
//                             <Link
//                               to="/"
//                               onClick={() => toggleSidebar("Expenses")}
//                               className={`${subOpen === "Expenses" ? "subdrop" : ""} ${location.pathname === "/" ? "" : "active"}`}
//                             >
//                               <Icon.FileText />
//                               <span>Expenses</span>
//                               <span className={`menu-arrow ${subOpen === "Expenses" ? "open" : ""}`}></span>
//                             </Link>
//
//                             <ul style={{ display: subOpen === "Expenses" ? "block" : "none" }}>
//                               {/* Conditionally render each item based on right.right and right.status */}
//                               {userRights.some(right => right.right === "expense-list" && right.status === 1) && (
//                                 <li>
//                                   <Link
//                                     to="/expense-list"
//                                     className={`${location.pathname === "/expense-list" ? "active" : ""}`}
//                                   >
//                                     Expenses
//                                   </Link>
//                                 </li>
//                               )}
//
//                               {userRights.some(right => right.right === "expense-category" && right.status === 1) && (
//                                 <li>
//                                   <Link
//                                     to="/expense-category"
//                                     className={`${location.pathname === "/expense-category" ? "active" : ""}`}
//                                   >
//                                     Expense Category
//                                   </Link>
//                                 </li>
//                               )}
//                             </ul>
//                           </li>
//                         );
//                       }
//
//                       else if (right.right === "Customers" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             marginBottom: "0px"
//                           }}
//                           >
//                             <Link
//                               to="/customers"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.User/>
//                               <span>Customers</span>
//                             </Link>
//
//                           </li>
//                         );
//                       }
//                       else if (right.right === "Suppliers" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             borderBottom: '1px solid #e8ebed',
//                             padding: '0 0 14px 0',
//                             display: 'block',
//                             marginbottom: '14px'
//                           }}>
//                             <Link
//                               to="/suppliers"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                              <Icon.Users/>
//                               <span>Suppliers</span>
//                             </Link>
//
//                           </li>
//                         );
//                       }
//
//                       else if (right.right === "Employees" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             marginBottom: "0px"
//                           }}>
//                             <Link
//                               to="/employees-grid"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.Users/>
//                               <span>Employees</span>
//                             </Link>
//
//                           </li>
//                         );
//                       }
//                       else if (right.right === "Departments" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu" style={{
//                             marginBottom: "0px"
//                           }}>
//                             <Link
//                               to="/department-grid"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.User/>
//                               <span>Departments</span>
//                             </Link>
//
//                           </li>
//                         );
//                       }
//                       else if (right.right === "Designations" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu" style={{
//                             marginBottom: "0px"
//                           }}>
//                             <Link
//                               to="/designation"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.UserCheck/>
//                               <span>Designations</span>
//                             </Link>
//
//                           </li>
//                         );
//                       }
//                       else if (right.right === "Shifts" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu" style={{
//                             marginBottom: "0px"
//                           }}>
//                             <Link
//                               to="/shift"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.Shuffle/>
//                               <span>Shifts</span>
//                             </Link>
//
//                           </li>
//                         );
//                       }
//
//                       else if (right.right === "Attendance" && right.status === 1) {
//                         return (
//                           <li key={index} className="submenu" style={{ display: 'block', marginBottom: '0px' }}>
//                             <Link
//                               to="/"
//                               onClick={() => toggleSidebar("Attendance")}
//                               className={`${subOpen === "Attendance" ? "subdrop" : ""} ${location.pathname === "/" ? "" : "active"}`}
//                             >
//                               <Icon.Grid />
//                               <span>Attendance</span>
//                               <span className={`menu-arrow ${subOpen === "Attendance" ? "open" : ""}`}></span>
//                             </Link>
//
//                             <ul style={{ display: subOpen === "Attendance" ? "block" : "none" }}>
//                               {/* Conditionally render each item based on right.right and right.status */}
//                               {userRights.some(right => right.right === "attendance-employee" && right.status === 1) && (
//                                 <li style={{ marginBottom: "0px" }}>
//                                   <Link
//                                     to="/attendance-employee"
//                                     className={`${location.pathname === "/attendance-employee" ? "active" : ""}`}
//                                   >
//                                     Employee
//                                   </Link>
//                                 </li>
//                               )}
//
//                               {userRights.some(right => right.right === "attendance-admin" && right.status === 1) && (
//                                 <li style={{ marginBottom: "0px" }}>
//                                   <Link
//                                     to="/attendance-admin"
//                                     className={`${location.pathname === "/attendance-admin" ? "active" : ""}`}
//                                   >
//                                     Admin
//                                   </Link>
//                                 </li>
//                               )}
//                             </ul>
//                           </li>
//                         );
//                       }
//
//                       else if (right.right === "Leaves" && right.status === 1) {
//                         return (
//                           <li key={index} className="submenu" style={{ display: 'block', marginBottom: '0px' }}>
//                             <Link
//                               to="/"
//                               onClick={() => toggleSidebar("Leaves")}
//                               className={`${subOpen === "Leaves" ? "subdrop" : ""} ${location.pathname === "/" ? "" : "active"}`}
//                             >
//                               <Icon.Grid />
//                               <span>Leaves</span>
//                               <span className={`menu-arrow ${subOpen === "Leaves" ? "open" : ""}`}></span>
//                             </Link>
//
//                             <ul style={{ display: subOpen === "Leaves" ? "block" : "none" }}>
//                               {/* Conditionally render each item based on right.right and right.status */}
//                               {userRights.some(right => right.right === "leaves-employee" && right.status === 1) && (
//                                 <li style={{ marginBottom: "0px" }}>
//                                   <Link
//                                     to="/leaves-employee"
//                                     className={`${location.pathname === "/leaves-employee" ? "active" : ""}`}
//                                   >
//                                     Employee Leaves
//                                   </Link>
//                                 </li>
//                               )}
//
//                               {userRights.some(right => right.right === "leaves-admin" && right.status === 1) && (
//                                 <li style={{ marginBottom: "0px" }}>
//                                   <Link
//                                     to="/leaves-admin"
//                                     className={`${location.pathname === "/leaves-admin" ? "active" : ""}`}
//                                   >
//                                     Admin Leaves
//                                   </Link>
//                                 </li>
//                               )}
//
//                               {userRights.some(right => right.right === "leave-types" && right.status === 1) && (
//                                 <li style={{ marginBottom: "0px" }}>
//                                   <Link
//                                     to="/leave-types"
//                                     className={`${location.pathname === "/leave-types" ? "active" : ""}`}
//                                   >
//                                     Leave Types
//                                   </Link>
//                                 </li>
//                               )}
//                             </ul>
//                           </li>
//                         );
//                       }
//
//                       else if (right.right === "Holidays" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             marginBottom: "0px"
//                           }}>
//
//                             <Link
//                               to="/holidays"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.Calendar/>
//                               <span>Holidays</span>
//                             </Link>
//
//                           </li>
//                         );
//                       }
//                       else if (right.right === "payslip" && right.status === 1) {
//                         // Rendering for "Dashboard" when status is 1
//                         return (
//
//                           <li key={index}
//                           className="submenu"
//                           style={{
//                             borderBottom: '1px solid #e8ebed',
//                             padding: '0 0 14px 0',
//                             display: 'block',
//                             marginBottom: '0px'
//                           }}
//                           >
//
//                             <Link
//                               to="/"
//                               onClick={() => toggleSidebar("Payroll")}
//                               className={`${subOpen === "Payroll" ? "subdrop" : ""} ${
//                                 location.pathname === "/" ? "" : "active"
//                               }`}
//                             >
//                               <Icon.DollarSign/>
//                               <span>Payroll</span>
//                               <span
//                                 className={`menu-arrow ${subOpen === "Payroll" ? "open" : ""}`}
//                               ></span>
//                             </Link>
//                             <ul
//                               style={{
//                                 display: subOpen === "Payroll" ? "block" : "none",
//                               }}
//                             >
//                               <li  style={{
//                             marginBottom: "0px"
//                           }}>
//                                 <Link
//                                   to="/payslip"
//                                   className={`${
//                                     location.pathname === "/payslip" ? "active" : ""
//                                   }`}
//                                 >
//                                 Payslip
//                                 </Link>
//                               </li>
//
//
//                             </ul>
//
//                           </li>
//                         );
//                       }
//                       else if (right.right === "Purchase-Report" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             marginBottom: "0px"
//                           }}>
//
//                             <Link
//                               to="/purchase-report"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.PieChart/>
//                               <span>Purchase Report</span>
//                             </Link>
//
//                           </li>
//                         );
//                       }
//                       else if (right.right === "Inventory-Report" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             marginBottom: "0px"
//                           }}>
//
//                             <Link
//                               to="/inventory-report"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                                <Icon.Inbox/>
//                               <span>Inventory Report</span>
//                             </Link>
//
//                           </li>
//                         );
//                       }
//                       else if (right.right === "Invoice-Report" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             marginBottom: "0px"
//                           }}>
//
//                             <Link
//                               to="/invoice-report"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                                <Icon.File/>
//                               <span>Invoice Report</span>
//                             </Link>
//
//                           </li>
//                         );
//                       }
//                       else if (right.right === "Profit-Loss" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             borderBottom: '1px solid #e8ebed',
//                             padding: '0 0 14px 0',
//                             display: 'block',
//                             marginBottom: '0px'
//                           }}>
//
//                             <Link
//                               to="/profit-loss-report"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.TrendingDown/>
//                               <span>Profit & Loss</span>
//                             </Link>
//
//                           </li>
//                         );
//                       }
//                       else if (right.right === "Users" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             marginBottom: "0px"
//                           }}
//                           >
//                             <Link
//                               to="/users"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.UserCheck/>
//                               <span>Users</span>
//
//                             </Link>
//
//                           </li>
//                         );
//                       }
//
//                       else if (right.right === "Roles-Permissions" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             marginBottom: "0px"
//                           }}
//                           >
//                             <Link
//                               to="/roles-permissions"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.UserCheck/>
//                               <span>Roles & Permissions</span>
//
//                             </Link>
//
//                           </li>
//                         );
//                       }
//                       else if (right.right === "user-Rights" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             marginBottom: "0px"
//                           }}
//                           >
//                             <Link
//                               to="/userrights"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                                <Icon.Lock />
//                               <span>user Rights</span>
//                             </Link>
//                           </li>
//                         );
//                       }
//                       else if (right.right === "Login-Out" && right.status === 1) {
//                         // Rendering for "Job" when status is 1
//                         return (
//                           <li key={index} className="submenu"
//                           style={{
//                             borderBottom: '1px solid #e8ebed',
//                             padding: '0 0 14px 0',
//                             display: 'block',
//                             marginBottom: '0px'
//                           }}
//                           >
//                             <Link
//                               to="/signin"
//                               // onClick={() => toggleSidebar("Job")}
//                               // className={`${subOpen === "Job" ? "subdrop" : ""} ${
//                               //   location.pathname === "/" ? "" : "active"
//                               // }`}
//                             >
//                               <Icon.LogOut />
//                               <span>Login-Out</span>
//
//                             </Link>
//
//                           </li>
//                         );
//                       }
//
//                       else if (right.right === "General-Setting" && right.status === 1) {
//                         return (
//                           <li
//                             key={index}
//                             className="submenu"
//                             style={{
//                               display: 'block',
//                               marginBottom: '0px',
//                             }}
//                           >
//                             <Link
//                               to="/"
//                               onClick={() => toggleSidebar("General-Setting")}
//                               className={`${subOpen === "General-Setting" ? "subdrop" : ""} ${
//                                 location.pathname === "/" ? "" : "active"
//                               }`}
//                             >
//                               <Icon.Grid />
//                               <span>General-Setting</span>
//                               <span
//                                 className={`menu-arrow ${subOpen === "General-Setting" ? "open" : ""}`}
//                               ></span>
//                             </Link>
//
//                             <ul
//                               style={{
//                                 display: subOpen === "General-Setting" ? "block" : "none",
//                               }}
//                             >
//                               {/* Rendering based on matching values for right */}
//                               {userRights.some(right => right.right === "Profile" && right.status === 1) && (
//                                 <li style={{ marginBottom: "0px" }}>
//                                   <Link
//                                     to="/general-settings1"
//                                     className={`${
//                                       location.pathname === "/general-settings1" ? "active" : ""
//                                     }`}
//                                   >
//                                     Profile
//                                   </Link>
//                                 </li>
//                               )}
//
//                               {userRights.some(right => right.right === "Security" && right.status === 1) && (
//                                 <li style={{ marginBottom: "0px" }}>
//                                   <Link
//                                     to="/security-settings"
//                                     className={`${
//                                       location.pathname === "/security-settings" ? "active" : ""
//                                     }`}
//                                   >
//                                     Security
//                                   </Link>
//                                 </li>
//                               )}
//
//                               {userRights.some(right => right.right === "Notifications" && right.status === 1) && (
//                                 <li style={{ marginBottom: "0px" }}>
//                                   <Link
//                                     to="/notification"
//                                     className={`${
//                                       location.pathname === "/notification" ? "active" : ""
//                                     }`}
//                                   >
//                                     Notifications
//                                   </Link>
//                                 </li>
//                               )}
//
//                               {userRights.some(right => right.right === "Connected Apps" && right.status === 1) && (
//                                 <li style={{ marginBottom: "0px" }}>
//                                   <Link
//                                     to="/connected-apps"
//                                     className={`${
//                                       location.pathname === "/connected-apps" ? "active" : ""
//                                     }`}
//                                   >
//                                     Connected Apps
//                                   </Link>
//                                 </li>
//                               )}
//                             </ul>
//                           </li>
//                         );
//                       }
//
//
//                       else {
//                         // If the `right` is neither "Dashboard" nor "Job" with status 1, render nothing
//                         return null;
//                       }
//                     })}
//                   </>
//                 ) : (
//                   <span>You do not have permission to access.plese contact admin</span>
//                 )}
//
//               </ul>
//             </div>
//           </div>
//         </Scrollbars>
//       </div>
//       <HorizontalSidebar />
//       <CollapsedSidebar />
//     </div>
//   );
// };
//
// export default Sidebar;
import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
// import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { SidebarData } from "../../core/json/siderbar_data";
import HorizontalSidebar from "./horizontalSidebar";
import CollapsedSidebar from "./collapsedSidebar";

const Sidebar = () => {
  // const SidebarData = useSelector((state) => state.sidebar_data);
  // console.log(sidebarData, "sidebar");

  const Location = useLocation();

  // console.log("Location.pathname", Location.pathname);

  const [subOpen, setSubopen] = useState("");
  const [subsidebar, setSubsidebar] = useState("");

  const toggleSidebar = (title) => {
    if (title == subOpen) {
      setSubopen("");
    } else {
      setSubopen(title);
    }
    console.log("currentPath",currentPath)
  };

  const toggleSubsidebar = (subitem) => {
    if (subitem == subsidebar) {
      setSubsidebar("");
    } else {
      setSubsidebar(subitem);
    }
  };
 // This will give you the current path
  const currentPath = Location.pathname;
  return (

      <div>
        <div className="sidebar" id="sidebar">
          <Scrollbars>
            <div className="sidebar-inner slimscroll">
              <div id="sidebar-menu" className="sidebar-menu">
                <ul>
                  {SidebarData?.map((mainLabel, index) => (
                      <li className="submenu-open" key={index}>
                        <h6 className="submenu-hdr">{mainLabel?.label}</h6>

                        <ul>
                          {mainLabel?.submenuItems?.map((title, i) => {
                            let link_array = [];
                            title?.submenuItems?.map((link) => {
                              link_array?.push(link?.link);
                              if (link?.submenu) {
                                link?.submenuItems?.map((item) => {
                                  link_array?.push(item?.link);
                                });
                              }
                              return link_array;
                            });
                            title.links = link_array;
                            return (
                                <>
                                  <li className="submenu" key={i}>
                                    <Link
                                        to={title?.link}
                                        onClick={() => toggleSidebar(title?.label)}
                                        className={`${
                                            subOpen == title?.label ? "subdrop" : ""
                                        } ${
                                            title?.links?.includes(currentPath) ? "active" : ""
                                        }`}
                                    >
                                      {title?.icon}
                                      <span>{title?.label}</span>
                                      <span className={title?.submenu ? "menu-arrow" : ""}/>
                                    </Link>
                                    <ul
                                        style={{
                                          display: subOpen == title?.label ? "block" : "none",
                                        }}
                                    >
                                      {title?.submenuItems?.map((item, titleIndex) => (
                                          <li className="submenu submenu-two" key={titleIndex}>
                                            <Link
                                                to={item?.link}
                                                className={`${
                                                    item?.submenuItems
                                                        ?.map((link) => link?.link)
                                                        .includes(currentPath) || item?.link == currentPath
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() => {
                                                  toggleSubsidebar(item?.label);
                                                }}
                                            >
                                              {item?.label}
                                            </Link>
                                            {/* Subsubmenu if exists */}
                                            <ul
                                                style={{
                                                  display: subsidebar == item?.label ? "block" : "none",
                                                }}
                                            >
                                              {item?.submenuItems?.map((items, titleIndex) => (
                                                  <li key={titleIndex}>
                                                    <Link
                                                        to={items?.link}
                                                        className={`${
                                                            subsidebar == items?.label
                                                                ? "submenu-two subdrop"
                                                                : "submenu-two"
                                                        } ${
                                                            items?.submenuItems
                                                                ?.map((link) => link.link)
                                                                .includes(currentPath) ||
                                                            items?.link == currentPath
                                                                ? "active"
                                                                : ""
                                                        }`}
                                                    >
                                                      {items?.label}
                                                    </Link>
                                                  </li>
                                              ))}
                                            </ul>
                                          </li>
                                      ))}
                                    </ul>
                                  </li>

                                </>
                            );
                          })}
                        </ul>
                      </li>
                  ))}
                </ul>
              </div>
            </div>
          </Scrollbars>
        </div>
        <HorizontalSidebar/>
        <CollapsedSidebar/>
      </div>
  );
};

export default Sidebar;
