import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../core/img/imagewithbasebath'
import { useLocation } from "react-router-dom";
import { getProduct } from '../../service/ApiServices';
// import { Descriptions } from 'antd';

const ProductDetail = () => {

    const location = useLocation();
    const { productId } = location.state;

    const [product, setProduct] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [subCategoryName, setSubCategoryName] = useState("");
    const [brand, setBrand] = useState("");
    const [unit, setUnit] = useState("");
    const [SKU, setSKU] = useState("");
    const [minimumQuantity, setMinimumQuantity] = useState("");
    const [Quantity, setQuantity] = useState("");
    // const [tax, setTax] = useState("");
    const [discountType, setDiscountType] = useState("");
    const [price, setPrice] = useState("");
    const [status, setStatus] = useState("");
    const [discription, setDiscription] = useState("");
    const [image, setImage] = useState([]);

    const setSelectedProduct = async (productId) => {
        try {
          const response = await getProduct(productId);
    
          
          if (response.data) {
            const product = response.data.data.product;

            setProduct(product.productName);
            setCategoryName(product.category.category);
            setSubCategoryName(product.subCategory.subCategory);
            setBrand(product.brand.name);
            setUnit(product.unit.name);
            setSKU(product.productSKU);
            setMinimumQuantity(product.productQuantityAlert);
            setQuantity(product.productQuantity);
            // setTax(product.);
            setDiscountType(product.productBarcodeSymbology);
            setPrice(product.productItemCode);
            setStatus(product.productWarranties);
            setDiscription(product.productDiscription);
            setImage(product.productImagesUrl);
           
            // setImageFiles(productImages.map(image => image.file).filter(file => file !== null));
    
          } else {
            console.error("Failed to fetch product data");
          }
        } catch (error) {
          console.error("Error fetching product data:", error);
        }
      };
    
      useEffect(() => {
        if (productId) {
          setSelectedProduct(productId);
        }
      }, [productId]);

    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="page-title">
                            <h4>Product Details</h4>
                            <h6>Full details of a product</h6>
                        </div>
                    </div>
                    {/* /add */}
                    <div className="row">
                        <div className="col-lg-8 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="bar-code-view">
                                        <ImageWithBasePath src="assets/img/barcode/barcode1.png" alt="barcode" />
                                        <a className="printimg">
                                            <ImageWithBasePath src="assets/img/icons/printer.svg" alt="print" />
                                        </a>
                                    </div>
                                    <div className="productdetails">
                                        <ul className="product-bar">
                                            <li>
                                                <h4>Product</h4>
                                                <h6>{product}</h6>
                                            </li>
                                            <li>
                                                <h4>Category</h4>
                                                <h6>{categoryName}</h6>
                                            </li>
                                            <li>
                                                <h4>Sub Category</h4>
                                                <h6>{subCategoryName}</h6>
                                            </li>
                                            <li>
                                                <h4>Brand</h4>
                                                <h6>{brand}</h6>
                                            </li>
                                            <li>
                                                <h4>Unit</h4>
                                                <h6>{unit}</h6>
                                            </li>
                                            <li>
                                                <h4>SKU</h4>
                                                <h6>{SKU}</h6>
                                            </li>
                                            <li>
                                                <h4>Minimum Qty</h4>
                                                <h6>{minimumQuantity}</h6>
                                            </li>
                                            <li>
                                                <h4>Quantity</h4>
                                                <h6>{Quantity}</h6>
                                            </li>
                                            <li>
                                                <h4>Tax</h4>
                                                <h6>0.00 %</h6>
                                            </li>
                                            <li>
                                                <h4>Discount Type</h4>
                                                <h6>{discountType}</h6>
                                            </li>
                                            <li>
                                                <h4>Price</h4>
                                                <h6>{price}</h6>
                                            </li>
                                            <li>
                                                <h4>Status</h4>
                                                <h6>{status}</h6>
                                            </li>
                                            <li>
                                                <h4>Description</h4>
                                                <h6>
                                                    {discription}
                                                </h6>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="slider-product-details">
                                        <div className="owl-carousel owl-theme product-slide">
                                            <div className="slider-product">
                                                <img src={image[0]} alt="img" />
                                                <h4>macbookpro.jpg</h4>
                                                <h6>581kb</h6>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /add */}
                </div>
            </div>


        </div>
    )
}

export default ProductDetail
