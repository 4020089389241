import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Edit, Eye, Filter, Sliders, Trash2 } from "react-feather";
import Select from "react-select";
import { Globe, User } from "react-feather";
import ImageWithBasePath from "../../img/imagewithbasebath";
import Breadcrumbs from "../../breadcrumbs";
import { createWareHouse, getWareHouse, updateWareHouse } from "../../../service/ApiServices";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Table } from "antd";
import { BASE_URL } from "../../../environment";

const WareHouses = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const [wareHouses, setWareHouses] = useState([]);
  const [wareHouseID, setWareHouseID] = useState();

  const [shouldReloadData, setShouldReloadData] = useState(false);

  useEffect(() => {
    fetchWareHouses();
  }, [shouldReloadData]);

  const fetchWareHouses = async () => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/wareHouses`);
      if (!response.ok) {
        throw new Error("Failed to fetch warehouses");
      }
      const data = await response.json();
      console.log("Fetched data:", data);
      setWareHouses(data.data.wareHouses);
    } catch (error) {
      console.error("Error fetching warehouses:", error);
    }
  };

  const handleReloadData = () => {
    setShouldReloadData(!shouldReloadData);
  };

  const options = [
    { value: "sortByDate", label: "Sort by Date" },
    { value: "140923", label: "14 09 23" },
    { value: "110923", label: "11 09 23" },
  ];
  const optionsTwo = [
    { label: "Choose Store Name", value: "" },
    { label: "Benjamin", value: "Benjamin" },
    { label: "Ellen", value: "Ellen" },
    { label: "Freda", value: "Freda" },
    { label: "Kaitlin", value: "Kaitlin" },
  ];

  const countries = [
    { label: "Choose Country", value: "" },
    { label: "India", value: "India" },
    { label: "USA", value: "USA" },
  ];

  const options1 = [
    { value: "choose", label: "Choose" },
    { value: "stevenUassets/img/users/user-08.jpg", label: "Steven" },
    { value: "gravelyUassets/img/users/user-09.jpg", label: "Gravely" },
  ];

  const options2 = [
    { value: "choose", label: "Choose" },
    { value: "uk", label: "United Kingdom" },
    { value: "us", label: "United States" },
  ];
  const columns = [
    {
      render: () => (
        <label className="checkboxs">
          <input type="checkbox" />
          <span className="checkmarks" />
        </label>
      ),
    },

    {
      title: "WareHouses Name",
      dataIndex: "WareHousesName",
    },
    {
      title: "Contact Person",
      dataIndex: "ContactPerson",
    },

    {
      title: "Phone",
      dataIndex: "Phone",
    },

    {
      title: "Total Products",
      dataIndex: "TotalProducts",
    },

    {
      title: "Stock",
      dataIndex: "Stock",
    },
    
    {
      title: "Qty",
      dataIndex: "Qty",
      // sorter: (a, b) => a.country.length - b.country.length,
    },

    {
      title: "Created On",
      dataIndex: "CreatedOn",
    },

    {
      title: "Status",
      dataIndex: "Status",
    },

    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (wareHouseId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteWareHouse(wareHouseId);
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  const deleteWareHouse = async (wareHouseId) => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/wareHouses/${wareHouseId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        handleReloadData();
        console.log("warehouse deleted successfully");
      } else {
        console.error("Failed to delete warehouse");
      }
    } catch (error) {
      console.error("Error deleting warehouse:", error);
    }
  };

  const [formData, setFormData] = useState({
    wareHouseName: "",
    wareHouseContactPerson: "",
    wareHousePhone: "",
    wareHouseWorkPhone: "",
    wareHouseEmail: "",
    wareHouseAddress1: "",
    wareHouseAddress2: "",
    wareHouseStatus: true,
    wareHouseCountry: "",
    wareHouseState: "",
    wareHouseCity: "",
    wareHouseZipcode: "",
    wareHouseDate: Date.now,

  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const handleLinkClick = async (wareHouseId) => {
    setSelectedWareHouse(wareHouseId);
    setWareHouseID(wareHouseId);
  };

  const setSelectedWareHouse = async (wareHouseId) => {
    try {
      const response = await getWareHouse(wareHouseId);
      if (response.data) {
        const { wareHouseName, wareHouseContactPerson, wareHousePhone, wareHouseWorkPhone, wareHouseEmail, wareHouseAddress1, 
          wareHouseAddress2, wareHouseStatus, wareHouseCountry, wareHouseState, wareHouseCity, wareHouseZipcode, wareHouseDate } = response.data.data.wareHouse;
        setFormData({
          ...formData,
          wareHouseName,
          wareHouseContactPerson,
          wareHousePhone,
          wareHouseWorkPhone,
          wareHouseEmail,
          wareHouseAddress1,
          wareHouseAddress2,
          wareHouseStatus,
          wareHouseCountry,
          wareHouseState,
          wareHouseCity,
          wareHouseZipcode,
          wareHouseDate,
        });  
      } else {
        console.error("Failed to fetch wareHouse data");
      }
    } catch (error) {
      console.error("Error fetching wareHouse data:", error);
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateWareHouse(wareHouseID, formData); 
      handleReloadData();
      console.log("wareHouse updated successfully:", response);
      setFormData({
        // Clear form data if needed
        wareHouseName: "",
        wareHouseContactPerson: "",
        wareHousePhone: "",
        wareHouseWorkPhone: "",
        wareHouseEmail: "",
        wareHouseAddress1: "",
        wareHouseAddress2: "",
        wareHouseStatus: true,
        wareHouseCountry: "",
        wareHouseState: "",
        wareHouseCity: "",
        wareHouseZipcode: "",
        wareHouseDate: Date.now,
      });
    } catch (error) {
      console.error("Error updating supplier:", error);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
         console.log(formData)
        const response = await createWareHouse(formData);
        handleReloadData();
        console.log("Supplier added successfully:", response.data);
      setFormData({
        wareHouseName: "",
        wareHouseContactPerson: "",
        wareHousePhone: "",
        wareHouseWorkPhone: "",
        wareHouseEmail: "",
        wareHouseAddress1: "",
        wareHouseAddress2: "",
        wareHouseStatus: true,
        wareHouseCountry: "",
        wareHouseState: "",
        wareHouseCity: "",
        wareHouseZipcode: "",
        wareHouseDate: Date.now,
      });
    } catch (error) {
      console.error("Error adding wareHouse:", error);
    }
  };

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredWareHouses = wareHouses.filter(warehouse => {
    const searchLower = searchQuery.toLowerCase();
    return (
      warehouse.wareHouseName.toLowerCase().includes(searchLower)
    );
});

  return (
    <div className="page-wrapper">
      <div className="content">
        <Breadcrumbs
          maintitle="Warehouse"
          subtitle="Manage Your Warehouse"
          addButton="Add New Warehouse"
        />

        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="search" 
                    className="form-control form-control-sm formsearch" 
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              <div className="search-path">
                <Link
                  className={`btn btn-filter ${
                    isFilterVisible ? "setclose" : ""
                  }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div>
              <div className="form-sort stylewidth">
                <Sliders className="info-img" />

                <Select
                  className="select "
                  options={options}
                  placeholder="Sort by Date"
                />
              </div>
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <User className="info-img" />
                      <Select
                        options={optionsTwo}
                        placeholder="Choose Store Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Globe className="info-img" />
                      <Select
                        options={countries}
                        placeholder="Choose Country"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <Link className="btn btn-filters ms-auto">
                        {" "}
                        <i
                          data-feather="search"
                          className="feather-search"
                        />{" "}
                        Search{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
            <Table
                className="table datanew"
                columns={columns}
                dataSource={filteredWareHouses.map((warehouse) => ({
                  key: warehouse._id,
                  WareHousesName: warehouse.wareHouseName,
                  ContactPerson: (
                      <div className="userimgname">
                        <Link to="#" className="product-img">
                          <ImageWithBasePath
                            src= {`${warehouse.wareHouseContactPerson.split('U')[1]}`}
                            alt="product"
                          />
                        </Link>
                        <Link to="#">{warehouse.wareHouseContactPerson.split('U')[0]}</Link>
                      </div>
                  ),
                  Phone: warehouse.wareHousePhone,
                  TotalProducts: 0,
                  Stock: 0,
                  Qty: 0,
                  CreatedOn: warehouse.wareHouseDate,
                  Status: (
                    <span className={`badge ${warehouse.wareHouseStatus ? 'badge-linesuccess' : 'badge-linedanger'}`}>
                      {warehouse.wareHouseStatus ? 'Active' : 'Inactive'}
                    </span>
                  ),
                  action: (
                    <td className="action-table-data">
                      <div className="edit-delete-action">
                        <div className="input-block add-lists"></div>
                        <Link className="me-2 p-2" to="#">
                          <Eye className="feather-view" />
                        </Link>
                        {/* <Link
                          className="me-2 p-2"
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-units"
                        >
                          <Edit className="feather-edit" />
                        </Link> */}
                        <Link
                          className="me-2 p-2"
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-units"
                          onClick={() => handleLinkClick(warehouse._id)}
                          >
                          <Edit className="feather-edit" />
                        </Link>
                        <Link
                          className="confirm-text p-2"
                          to="#"
                          onClick={() => showConfirmationAlert(warehouse._id)}
                        >
                          <Trash2 className="feather-trash-2" />
                        </Link>
                      </div>
                    </td>
                  ),
                }))}
                pagination={false}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>

      <div>
        {/* Add Warehouse */}
        <div className="modal fade" id="add-units">
          <div className="modal-dialog modal-dialog-centered custom-modal-two">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>Add Warehouse</h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body custom-modal-body">
                    <form onSubmit={handleSubmit}>
                      <div className="modal-title-head">
                        <h6>
                          <span>
                            <i data-feather="info" className="feather-edit" />
                          </span>
                          Warehouse Info
                        </h6>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">Name</label>
                            <input type="text"
                            className="form-control"
                            name="wareHouseName"
                            value={formData.wareHouseName}
                            onChange={handleChange}
                            required
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-blocks">
                            <label className="form-label">Contact Person</label>
                            <Select
                            options={options1}
                            placeholder="Choose Person"
                            onChange={(selectedOption) => setFormData((prevData) => ({
                              ...prevData,
                              wareHouseContactPerson: selectedOption.value,
                            }))}
                            required
                          />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 war-add">
                            <label className="mb-2">Phone Number</label>
                            <input name="wareHousePhone"
                            className="form-control"
                            type="text"
                            value={formData.wareHousePhone}
                            onChange={handleChange}
                            required
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">Work Phone</label>
                            <input name="wareHouseWorkPhone"
                            className="form-control"
                            type="text"
                            value={formData.wareHouseWorkPhone}
                            onChange={handleChange}
                            required
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input name="wareHouseEmail"
                            className="form-control"
                            type="text"
                            value={formData.wareHouseEmail}
                            onChange={handleChange}
                            required
                            />
                          </div>
                        </div>
                        <div className="modal-title-head">
                          <h6>
                            <span>
                              <i data-feather="map-pin" />
                            </span>
                            Location
                          </h6>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">Address 1</label>
                            <input name="wareHouseAddress1"
                            className="form-control"
                            type="text"
                            value={formData.wareHouseAddress1}
                            onChange={handleChange}
                            required/>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">Address 2</label>
                            <input name="wareHouseAddress2"
                            className="form-control"
                            type="text"
                            value={formData.wareHouseAddress2}
                            onChange={handleChange}
                            required
                            />
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                            <span className="form-label">Status</span>
                            <input
                              type="checkbox"
                              id="user2"
                              className="check form-control"
                              name="wareHouseState"
                              checked={formData.wareHouseStatus} 
                              onChange={(e) => {
                                setFormData((prevData) => ({
                                  ...prevData,
                                  wareHouseStatus: e.target.checked, 
                                }));
                              }}
                              required
                            />
                            <label htmlFor="user2" className="checktoggle" /> 
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-blocks">
                            <label>Country</label>
                            <Select
                            options={options2}
                            placeholder="Choose country"
                            onChange={(selectedOption) => setFormData((prevData) => ({
                              ...prevData,
                              wareHouseCountry: selectedOption.value,
                            }))}
                            required
                          />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">State</label>
                            <input name="wareHouseState"
                            className="form-control"
                            type="text"
                            value={formData.wareHouseState}
                            onChange={handleChange}
                            required
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-0">
                            <label className="form-label">City</label>
                            <input name="wareHouseCity"
                            className="form-control"
                            type="text"
                            value={formData.wareHouseCity}
                            onChange={handleChange}
                            required
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-0">
                            <label className="form-label">Zipcode</label>
                            <input name="wareHouseZipcode"
                            className="form-control"
                            type="text"
                            value={formData.wareHouseZipcode}
                            onChange={handleChange}
                            required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button type="submit"
                        className="btn btn-submit"
                        data-bs-dismiss="modal">
                        Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Add Warehouse */}
        {/* Edit Warehouse */}
        <div className="modal fade" id="edit-units">
          <div className="modal-dialog modal-dialog-centered custom-modal-two">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>Edit Warehouse</h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body custom-modal-body">
                    <form onSubmit={handleUpdateSubmit} >
                      <div className="modal-title-head">
                        <h6>
                          <span>
                            <i data-feather="info" className="feather-edit" />
                          </span>
                          Warehouse Info
                        </h6>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">Name</label>
                            <input type="text"
                            className="form-control"
                            name="wareHouseName"
                            value={formData.wareHouseName}
                            onChange={handleChange}
                            required
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-blocks">
                            <label>Contact Person</label>
                            <Select
                            options={options1}
                            placeholder="Choose Person"
                            onChange={(selectedOption) => setFormData((prevData) => ({
                              ...prevData,
                              wareHouseContactPerson: selectedOption.value,
                            }))}
                            required
                            // placeholder="Choose"
                          />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 war-edit-phone">
                            <label className="mb-2">Phone Number</label>
                            <input name="wareHousePhone"
                            className="form-control"
                            type="text"
                            value={formData.wareHousePhone}
                            onChange={handleChange}
                            required
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 war-edit-phone">
                            <label className="form-label">Work Phone</label>
                            <input name="wareHouseWorkPhone"
                            className="form-control"
                            type="text"
                            value={formData.wareHouseWorkPhone}
                            onChange={handleChange}
                            required
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input name="wareHouseEmail"
                            className="form-control"
                            type="text"
                            value={formData.wareHouseEmail}
                            onChange={handleChange}
                            required
                            />
                          </div>
                        </div>
                        <div className="modal-title-head">
                          <h6>
                            <span>
                              <i data-feather="map-pin" />
                            </span>
                            Location
                          </h6>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">Address 1</label>
                            <input name="wareHouseAddress1"
                            className="form-control"
                            type="text"
                            value={formData.wareAdderss1}
                            onChange={handleChange}
                            required
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-blocks">
                            <label className="form-label">Address 2</label>
                            <input name="wareHouseAddress2"
                            className="form-control"
                            type="text"
                            value={formData.wareHouseAddress2}
                            onChange={handleChange}
                            required
                            />
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                            <span className="form-label">Status</span>
                            <input
                              type="checkbox"
                              id="user2"
                              className="check form-control"
                              name="wareHouseState"
                              checked={formData.wareHouseStatus} // Bind to `expenseState`
                              onChange={(e) => {
                                setFormData((prevData) => ({
                                  ...prevData,
                                  wareHouseStatus: e.target.checked, // Update state based on checkbox value
                                }));
                              }}
                              required
                            />
                            <label htmlFor="user2" className="checktoggle" /> 
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-blocks">
                            <label>Country</label>
                            <Select className="select" options={options2} required/>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">State</label>
                            <input name="wareHouseState"
                            className="form-control"
                            type="text"
                            value={formData.wareHouseState}
                            onChange={handleChange} />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-0">
                            <label className="form-label">City</label>
                            <input name="wareHouseCity"
                            className="form-control"
                            type="text"
                            value={formData.wareHouseCity}
                            onChange={handleChange} 
                            required
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 mb-0">
                            <label className="form-label">Zipcode</label>
                            <input name="wareHouseZipcode"
                            className="form-control"
                            type="text"
                            value={formData.wareHouseZipcode}
                            onChange={handleChange} 
                            required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button type="submit"
                        className="btn btn-submit"
                        data-bs-dismiss="modal">
                          Save Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WareHouses;
