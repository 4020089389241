import React, {useEffect, useState} from "react";
//import { Link } from 'react-router-dom'
import { createRole } from "../../../../src/service/userRoleApiServices";
import {Link} from "react-router-dom";
import Select from "react-select";
import {getModules} from "../../../service/userRoleApiServices";
import permissionsData from '../../../permission.json';
import Swal from "sweetalert2";
const AddRole = () => {
    const [modules, setModules] = useState([]);
    const [roleName, setRoleName] = useState([]);
    const [functions, setFunctionList] = useState([]);
    const [modulesList, setModulesList] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [filteredModules, setFilteredModules] = useState([]);

    useEffect(() => {
        getModules().then(value => {
            console.log("Response", value)
            setModulesList(value);
            let map = value.map(module => {
                return {
                    label: module.label,
                    value: module._id
                };
            });
            setModules(map);

        })
    }, []);

    {


        /*
        const handleGetRoles = async () => {
          try {
            const rolesData = await getRoles();
            setRoles(rolesData);
          } catch (error) {
            console.error("Error fetching roles:", error);
          }
        };
        console.log(roles, "Updated Roles addrole.jsx");
      */
    }

    // const handlePermissions = (event) => {
    //   const { value, checked } = event.target;
    //   if (checked) {
    //     setPermissions([...permissions, value]);
    //   } else {
    //     setPermissions(permissions.filter((permission) => permission !== value));
    //   }
    // };
    //
    // const handleCreateRole = async () => {
    //   try {
    //     const response = await createRole(roleName, functionName, permissions);
    //     console.log("Role created:", response);
    //     setRoleName("");
    //     setFunctionName("");
    //     setPermissions([]);
    //   } catch (error) {
    //     console.error("Error creating role:", error);
    //   }
    // };
    const customStyles = {
        // Multi-value (selected tag) style
        multiValue: (styles) => {
            return {
                ...styles,
                backgroundColor: '#ff7f00', // Background color of the selected tag
                borderRadius: '12px', // Rounded corners
                color: 'white', // Text color
            };
        },
        // Multi-value label (text inside the tag) style
        multiValueLabel: (styles) => ({
            ...styles,
            color: 'white', // Text color of the selected option
        }),
        // Multi-value remove (the "x" button inside the tag) style
        multiValueRemove: (styles) => ({
            ...styles,
            color: 'white', // Color of the "x" icon
            ':hover': {
                backgroundColor: '#ff7f00', // Darker color on hover
                color: 'white',
            },
        }),
    };
    const handleChangeModule = (val) => {
        setFunctionList([])
        setFilteredModules([])
        console.log("Selected Module", val)
        console.log("modulesList", modulesList)
        val.forEach(a=>{
            let filtered = modulesList.find(value1 => value1._id === a.value);
            console.log("Filtered Module", filtered,filteredModules)
            setFilteredModules(filtered);
            filtered.functions.forEach(value => {
                const function1 = {
                    module:filtered._id,
                    function:value
                }
                setFunctionList(prevModules => [
                    ...prevModules,  // Spread the previous modules array
                    function1       // Add the new object
                ]);
            })

        })


    };
    const handleCheckboxChange = (functionId, permissionType, isChecked,module) => {
        const permission = { function: functionId, permission: permissionType ,moduleId:module};

        if (isChecked) {
            // Add to the array if checked
            setSelectedPermissions((prevState) => [...prevState, permission]);
        } else {
            // Remove from the array if unchecked
            setSelectedPermissions((prevState) =>
                prevState.filter(
                    (item) => item.function !== functionId || item.permission !== permissionType
                )
            );
        }
    };
    const showSuccess = (msg,type,title) => {
        return Swal.fire({
            title: title,
            text: msg,
            icon: type
        });
    }
    console.log("selectedPermissions",selectedPermissions,"Functions",functions)
    const submitRole = () => {

        createRole({
            roleName : roleName,
            permissionsArray : selectedPermissions
        }).then(value => {
           showSuccess(value.msg,"success","success")
        })

    };
    return (
        <div>
            {/* Add Role */}
            <div className="modal fade" id="add-units">
                <div className="modal-dialog modal-lg modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-body custom-modal-body">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-title">Create Role</h5>
                                        </div>
                                        <div className="card-body">
                                            <form action="#">
                                                <div className="mb-3">
                                                    <label className="form-label">Role Name</label>
                                                    <input type="text" className="form-control" onChange={(e)=>setRoleName(e.target.value)}/>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Select Modules</label>
                                                    <Select
                                                        options={modules}
                                                        className="select2"
                                                        isMulti={true}
                                                        styles={customStyles}
                                                        onChange={handleChangeModule}
                                                        // defaultValue={[multiselect[0]]}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped mb-0">
                                                            <thead>
                                                            <tr>
                                                                <th>Function</th>
                                                                {permissionsData.permissions.map((perm) => (
                                                                    <th key={perm.key}>{perm.label}</th>
                                                                ))}


                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {functions?.map((func,index) =>

                                                                <tr key={index}>
                                                                    <td>{func.function.label}</td>
                                                                    {permissionsData.permissions.map((perm) => (
                                                                        <td key={perm.key}>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id={`${perm.key}-checkbox-${func.id}`}
                                                                                aria-label={`${perm.label} ${func.label}`}
                                                                                onChange={(e) =>
                                                                                    handleCheckboxChange(func.function._id, perm.key, e.target.checked,func.module)
                                                                                }
                                                                            />
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            )}


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <div className="text-end">
                                                    <Link to="#" className="btn btn-primary" onClick={submitRole}>
                                                        Submit
                                                    </Link>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Role */}
        </div>
    );
};

export default AddRole;
