import React, { useEffect, useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import PropTypes from "prop-types";
import './style.css';
import Swal from 'sweetalert2';
import { Spin } from 'antd';
import { creteBodyTypeMethod } from '../../../service/ApiServices';
const CreateBodyTypeModal = ({ handleCancel, handleJobTypeAdding, selectedBodyType }) => {
    const [items, setItems] = useState([{ value: '' }]);
    const [bodyTypeName, setBodyTypeName] = useState('');
    const [loading, setLoading] = useState(false);

    const handleInputChange = (index, event) => {
        const newStyles = [...items];
        newStyles[index].value = event.target.value;
        setItems(newStyles);
    };
    const handleAddStyle = () => {
        setItems([...items, { value: '' }]);
    };
    const handleRemoveStyle = (index) => {
        const newStyles = [...items];
        newStyles.splice(index, 1);
        setItems(newStyles);
    };
    const handleSave = () => {
        if (bodyTypeName === '') {
            showWarning("Please enter Job Type Name");
            return;
        }
        if (items.map(type => type.value).filter(type => type !== '').length === 0) {
            showWarning("Please enter at least one type");
            return;
        }
        if (items.filter(type => type.value == 0).length > 0) {
            showWarning("cant be empty item inputs")
            return
        }
        creteBodyTypeMethod({
            name: bodyTypeName,
            types: items.map(type => type.value),
            handleCancel,
            handleReset,
            showSuccess,
            handleJobTypeAdding,
            typeId: selectedBodyType?._id,
            setLoading
        })
    };
    const handleReset = () => {
        setItems([{ value: '' }]);
        setBodyTypeName('');
    };
    const showSuccess = () => {
        return Swal.fire({
            title: "Good job!",
            text: "You Successfully Saved Job Type!",
            icon: "success"
        });
    }
    const showWarning = (str) =>{
        return Swal.fire({
          title: "Error",
          text: str,
          icon: "error",
        });
      }
    useEffect(() => {
        if (selectedBodyType) {
            setBodyTypeName(selectedBodyType.name);
            setItems(selectedBodyType.types.map(style => ({ value: style })));
        }
    }, [selectedBodyType]);
    return (
        <div className="modal-content">
            <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                    <h4>{selectedBodyType ? "Edit Item" : "Create Item"}</h4>
                    <p className="mb-0">Fill in the required fields to create a </p>
                </div>
            </div>
            <div className="modal-body custom-modal-body">
                <form>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="input-blocks">
                                <label htmlFor="bodyTypeName">Type Name</label>
                                <input
                                    type="text"
                                    id="bodyTypeName"
                                    className="form-control"
                                    aria-label="Job Type Name"
                                    value={bodyTypeName}
                                    onChange={(event) => setBodyTypeName(event.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="input-blocks">
                                <label>Styles</label>
                                {items.map((style, index) => (
                                    <div className="d-flex align-items-center mb-2" key={index}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={style.value}
                                            onChange={(event) => handleInputChange(index, event)}
                                            aria-label={`Style ${index + 1}`}
                                        />
                                        <div className="icon-container ms-2">
                                            {index === items.length - 1 ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: '3px'
                                                    }}
                                                >
                                                    <FaPlus
                                                        onClick={handleAddStyle}
                                                        style={{ cursor: 'pointer' }}
                                                        aria-label="Add Style"
                                                    />
                                                    <FaMinus
                                                        onClick={() => handleRemoveStyle(index)}
                                                        style={{ cursor: 'pointer', display: `${index === 0 ? "none" : ""}` }}
                                                        aria-label="Remove Style"
                                                    />
                                                </div>
                                            ) : (
                                                <FaMinus
                                                    onClick={() => handleRemoveStyle(index)}
                                                    style={{ cursor: 'pointer' }}
                                                    aria-label="Remove Style"
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </form>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }} >
                    <div>
                        <button
                            type="button"
                            className="btn btn-reset me-2"
                            onClick={handleReset}
                        >
                            Reset
                        </button>
                        <button className="btn btn-submit" onClick={handleSave}>
                            {loading ? <Spin size='small' /> : "Save"}
                        </button>
                    </div>
                    <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={() => {
                            handleCancel();
                            handleReset();
                        }}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};
CreateBodyTypeModal.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    handleJobTypeAdding: PropTypes.func.isRequired,
    selectedBodyType: PropTypes.object.isRequired
};
export default CreateBodyTypeModal;
