import React, { useState, useEffect } from "react";
//import Select from 'react-select'
import { getWarrantyByID, updatewarranty } from "../../../service/ApiServices";
import PropTypes from "prop-types";

const EditWarrenty = ({ id }) => {
  const periods = [
    { value: "Month", label: "Month" },
    { value: "Year", label: "Year" },
  ];
  const handlePeriodsChange = (selectedOption) => {
    setWarranty({ ...warranty, periods: selectedOption.value });
  };

  const [warranty, setWarranty] = useState({
    name: "",
    description: "",
    duration: "",
    periods: "",
  });

  console.log("UPDATE ID", id);

  console.log("WARRANTY UPDAE", warranty);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getWarrantyByID(id);
        console.log("DATA", response.data.warranty);
        setWarranty(response.data.warranty);
        //setSelectedPeriods(response.data.warranty.periods)
      } catch (err) {
        console.error(err);
      }
    };
    // if(id){  fetchData(); }
    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = updatewarranty(id, warranty);
    setWarranty(response);
  };

  EditWarrenty.propTypes = {
    id: PropTypes.string.isRequired,
    warranty: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      warranty: PropTypes.string.isRequired,
    }).isRequired,
  };

  return (
    <>
      {/* Edit Warranty */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Warrranty</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="mb-3">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        // defaultValue="Piece"
                        name="name"
                        value={warranty.name}
                        // onChange={handleChange}
                        onChange={(e) =>
                          setWarranty({ ...warranty, name: e.target.value })
                        }
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="form-label">Duration</label>
                          <input
                            type="text"
                            className="form-control"
                            // defaultValue={3}
                            name="duration"
                            value={warranty.duration}
                            // onChange={handleChange}
                            onChange={(e) =>
                              setWarranty({
                                ...warranty,
                                duration: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label className="form-label">Periods</label>
                          <select className="form-control">
                            <option key={warranty._id} value={warranty.periods}>
                              {warranty.periods}
                            </option>
                            {periods.map((period) => (
                              <option
                                key={period.value}
                                value={period.value}
                                onChange={handlePeriodsChange}
                              >
                                {period.label}
                              </option>
                            ))}
                            {/* <option value={periods.find(
                              (option) => option.value === warranty.periods
                            )} onChange={handlePeriodsChange}>select Periods</option>  */}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mb-3 input-blocks">
                          <label className="form-label">Description</label>
                          <textarea
                            className="form-control"
                            // defaultValue={
                            //     "Repairs or a replacement for a faulty product within a specified time period after it was purchased."
                            // }
                            name="description"
                            value={warranty.description}
                            // onChange={handleChange}
                            onChange={(e) =>
                              setWarranty({
                                ...warranty,
                                description: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-0">
                      <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span className="status-label">Status</span>
                        <input type="checkbox" id="user3" className="check" />
                        <label htmlFor="user3" className="checktoggle" />
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button className="btn btn-submit" onClick={handleSubmit}>
                        {" "}
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Warranty */}
    </>
  );
};

export default EditWarrenty;
