import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Filter, Sliders } from "react-feather";
import Select from "react-select";
import { Edit, Eye, Globe, Trash2, User } from "react-feather";
// import { useSelector } from "react-redux";
import { Table } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ImageWithBasePath from "../../img/imagewithbasebath";
import Breadcrumbs from "../../breadcrumbs";
import StoreModal from "./storeModal";
import { BASE_URL } from "../../../environment";

const StoreList = () => {
  // const data = useSelector((state) => state.storedata);
  const [selectedStoreId, setSelectedStoreId] = useState(null);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const [stores, setStores] = useState([]);

  const [shouldReloadData, setShouldReloadData] = useState(false);

  useEffect(() => {
    fetchStores();
  }, [shouldReloadData]);

  const fetchStores = async () => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/stores`);
      if (!response.ok) {
        throw new Error("Failed to fetch stores");
      }
      const data = await response.json();
      setStores(data.data.stores);
    } catch (error) {
      console.error("Error fetching stores:", error);
    }
  };

  const handleReloadData = () => {
    setShouldReloadData(!shouldReloadData);
  };

  const options = [
    { value: "sortByDate", label: "Sort by Date" },
    { value: "140923", label: "14 09 23" },
    { value: "110923", label: "11 09 23" },
  ];
  const optionsTwo = [
    { label: "Choose Store Name", value: "" },
    { label: "Benjamin", value: "Benjamin" },
    { label: "Ellen", value: "Ellen" },
    { label: "Freda", value: "Freda" },
    { label: "Kaitlin", value: "Kaitlin" },
  ];

  const countries = [
    { label: "Choose Country", value: "" },
    { label: "India", value: "India" },
    { label: "USA", value: "USA" },
  ];

  const columns = [
    {
      render: () => (
        <label className="checkboxs">
          <input type="checkbox" />
          <span className="checkmarks" />
        </label>
      ),
    },

    {
      title: "Store Name",
      dataIndex: "StoreName",
      sorter: (a, b) => a.StoreName.length - b.StoreName.length,
    },
    {
      title: "Code",
      dataIndex: "Code",
      sorter: (a, b) => a.Code.length - b.Code.length,
    },
    {
      title: "Store",
      dataIndex: "Store",
      sorter: (a, b) => a.Store.length - b.Store.length,
    },

    {
      title: "Email",
      dataIndex: "Email",
      sorter: (a, b) => a.Email.length - b.Email.length,
    },

    {
      title: "Phone",
      dataIndex: "Phone",
      sorter: (a, b) => a.Phone.length - b.Phone.length,
    },

    {
      title: "Country",
      dataIndex: "Country",
      sorter: (a, b) => a.Country.length - b.Country.length,
    },

    {
      title: "Action",
      dataIndex: "action",
      sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (storeId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStore(storeId);
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  const deleteStore = async (storeId) => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/stores/${storeId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        handleReloadData();
        console.log("Store deleted successfully");
      } else {
        console.error("Failed to delete store");
      }
    } catch (error) {
      console.error("Error deleting store:", error);
    }
  };
  
  const handleLinkClick = async (storeId) => {
    setSelectedStoreId(storeId);
  };

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredStores = stores.filter(store => {
    const searchLower = searchQuery.toLowerCase();
    return (
      store.storeName.toLowerCase().includes(searchLower)
    );
});
  
  return (
    <div className="page-wrapper">
      <div className="content">
        <Breadcrumbs
          maintitle="Store List"
          subtitle="Manage Your Store"
          addButton="Add Store"
        />

        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="search" 
                    className="form-control form-control-sm formsearch" 
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              <div className="search-path">
                <Link
                  className={`btn btn-filter ${
                    isFilterVisible ? "setclose" : ""
                  }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div>
              <div className="form-sort stylewidth">
                <Sliders className="info-img" />

                <Select
                  className="select "
                  options={options}
                  placeholder="Sort by Date"
                />
              </div>
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <User className="info-img" />
                      <Select
                        options={optionsTwo}
                        placeholder="Choose Store Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Globe className="info-img" />
                      <Select
                        options={countries}
                        placeholder="Choose Country"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <a className="btn btn-filters ms-auto">
                        {" "}
                        <i
                          data-feather="search"
                          className="feather-search"
                        />{" "}
                        Search{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                className="table datanew"
                columns={columns}
                dataSource={filteredStores.map((store) => ({
                  key: store._id,
                  StoreName: store.storeName,
                  Code: `#${store._id.slice(-3)}`,
                  Store: store.storeName,
                  Email: store.storeEmail,
                  Phone: store.storePhone,
                  Country: store.storeCountry,
                  action: (
                    <td className="action-table-data">
                      <div className="edit-delete-action">
                        <div className="input-block add-lists"></div>
                        <Link className="me-2 p-2" to="#">
                          <Eye className="feather-view" />
                        </Link>
                        <Link
                          className="me-2 p-2"
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-units"
                          onClick={() => handleLinkClick(store._id)}
                          >
                          <Edit className="feather-edit" />
                        </Link>
                        <Link
                          className="confirm-text p-2"
                          to="#"
                          onClick={() => showConfirmationAlert(store._id)}
                        >
                          <Trash2 className="feather-trash-2" />
                        </Link>
                      </div>
                    </td>
                  ),
                }))}
                pagination={false}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      <StoreModal onStoreAdded={handleReloadData} storeId={selectedStoreId} />
    </div>
  );
};

export default StoreList;
