

import Swal from 'sweetalert2';
import axios from "axios";

    const showSuccess = () => {
        return Swal.fire({
            title: "Good job!",
            text: "Successfully Saved!",
            icon: "success",
        });
    };
    const showSuccess2 = () => {
      return Swal.fire({
          title: "Good job!",
          text: "Successfully Updated!",
          icon: "success",
      });
  };
    const showWarning = (str) => {
      return Swal.fire({
          title: "Error",
          text: str,
          icon: "error",
      });
  }
export const createShift = async (shiftData) => {
  try {
    
    const response = await axios.post('/shifts', shiftData);
    if (response.data) {
      showSuccess()
      return response.data
    }

  } catch (error) {
    console.error('Error creating shift:', error);
    showWarning("Error in create shift")
  }
};


export const getAllShiftsNew = async () => {
  try {

    const response = await axios.get(`/shifts`);

    return response.data.data.shifts;

  } catch (error) {
    console.error("Error occurred while fetching shifts:", error.message);
    // Optionally show a warning here if needed
    showWarning("Error occurred while fetching shifts");
  }
};
export const deleteShift = async (shiftId) => {
  try {
      const response = await axios.delete(`/shifts/${shiftId}`);
      if (response.status === 204) {
        console.log("Shift Deleted") 
      }
  } catch (error) {
      console.error('Error deleting shift:', error.response ? error.response.data : error.message);
  }
};


export const getShiftById = async (shiftId) => {
  try {
    const response = await axios.get(`/shifts/${shiftId}`); // Replace with your API endpoint
    if (response.data.status === 'success') {
     // console.log('Shift data:', response.data.data.shift);
      return response.data.data.shift; // Return the shift data
    } else {
      console.error('Failed to fetch shift:', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('Error fetching shift:', error.message);
    return null;
  }
};


export const updateShiftById = async (id, shiftData) => {
  try {
    const response = await axios.put(`/shifts/${id}`, shiftData); // Replace with your actual API endpoint
    if (response.data.status === 'success') {
      showSuccess2()
      return response.data.data.shift; // Return updated shift data
    } else {
      console.error('Failed to update shift:', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('Error updating shift:', error.message);
    return null;
  }
};

////////////////////////////////////////OLD/////////////////////////////////////////////////////////////////////////
export const getAllShifts = async ({search}) => {
    try {
    //   setLoading(true)
      const shiftId = JSON.parse(localStorage.getItem('user')).data._id;
      await axios.get(`/shifts?user=`+shiftId+"&search="+search 
      ).then(() => {
        // setData(res.data.data.shifts)
      }).catch((error)=>{
        console.log(error.message);
        // showWarning("Error occured while fetching shifts")
      })
    } catch (error) {
      console.log(error.message);
    //   showWarning("Error occured while fetching shifts")
    }
  };

  









