import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { Link } from "react-router-dom";
import {Table, Tabs} from "antd";
import { Edit, Eye, Trash2 } from "react-feather";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
//import CustomerModal from "../../core/modals/peoples/customerModal";
import { BASE_URL } from "../../environment";
import {getInvoicesByCustomer} from "../../service/ApiServices";
import { API_BASE_URL } from "../../../src/environment";


// CustomerModal

const CustomerTable = ({ customers,loadCustomer,setSelectedCustomer,changePage,total,page }) => {
    const MySwal = withReactContent(Swal);
    //const [selectedCustomerId, setSelectedCustomerId] = useState(null);

    const [customerData, setCustomerData] = useState(customers);
    const [customerRec, setCustomerRec] = useState(null);
    const [invoices, setInvoices] = useState([]);

    const storedUserData = localStorage.getItem("user");
    const userData = JSON.parse(storedUserData);
    const id = userData?.data?.uid; //
    const [userRightssub, setUserRightssub] = useState([]);
    useEffect(() => {
      const fetchUserRightssub = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/userright/${id}`);
          if (!response.ok) {
            throw new Error("Failed to fetch user rights");
          }
          const data = await response.json();
          console.log("Fetched User Sub Data:", data.data);
          setUserRightssub(data.data || []); // Ensure it's always an array
        } catch (error) {
          console.error("Error fetching data:", error);
          setUserRightssub([]); // Set to empty array in case of error
        }
      };
    
      fetchUserRightssub(); // Fetch the data on component mount
    }, [id]);
    //const hasJobRightWithAdd = userRightssub.some(right => right.right === 'expense-category' && right.adds === 1);
    const hasJobRightWithedit = userRightssub.some(right => right.right === 'Customers' && right.updates === 1);
    const hasJobRightWithview = userRightssub.some(right => right.right === 'Customers' && right.viewes === 1);  
    const hasJobRightWithedel = userRightssub.some(right => right.right === 'Customers' && right.deletes=== 1); 
      

    useEffect(() => {
      setCustomerData(customers);
    }, [customers]);

  const showConfirmationAlert = (customerId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCustomer(customerId);
        MySwal.fire({
        title: "Deleted!",
        text: "Your file has been deleted.",
        className: "btn btn-success",
        confirmButtonText: "OK",
        customClass: {
        confirmButton: "btn btn-success",
        },
      });
      } else {
        MySwal.close();
      }
    });
  };
  const deleteCustomer = async (customerId) => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/customers/${customerId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        setCustomerData(customerData.filter((customer) => customer._id !== customerId));
        console.log("Customer deleted successfully");
          loadCustomer();
      } else {
        console.error("Failed to delete customer");
      }
    } catch (error) {
      console.error("Error deleting customer:", error);
    }
  };

  // const handleLinkClick = async (customerId) => {
  //   setSelectedCustomerId(customerId);
  // };

  const columns = [

    {
      title: "Customer Name",
      dataIndex: "CustomerName",
      sorter: (a, b) => a.CustomerName.length - b.CustomerName.length,
    },
    {
      title: "Code",
      dataIndex: "Code",
      sorter: (a, b) => a.Code.length - b.Code.length,
    },
    {
      title: "Email",
      dataIndex: "Email",
      sorter: (a, b) => a.Email.length - b.Email.length,
    },
    {
      title: "Phone",
      dataIndex: "Phone",
      sorter: (a, b) => a.Phone.length - b.Phone.length,
    },
    {
      title: "Country",
      dataIndex: "Country",
      sorter: (a, b) => a.Country.length - b.Country.length,
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   render: () => (
    //     <td className="action-table-data">
    //       <div className="edit-delete-action">
    //         <Link className="me-2 p-2" to="#">
    //           <Eye className="feather-view" />
    //         </Link>
    //         <Link
    //           className="me-2 p-2"
    //           to={{
    //             pathname: "#", // Replace "#" with the appropriate URL
    //             search: `?customerId=${customer._id}`, // Pass customer ID as a query parameter
    //           }}
    //           data-bs-toggle="modal"
    //           data-bs-target="#edit-units"
    //         >
    //           <Edit className="feather-edit" />
    //         </Link>
    //         <Link
    //           className="confirm-text p-2"
    //           to="#"
    //           onClick={showConfirmationAlert}
    //         >
    //           <Trash2 className="feather-trash-2" />
    //         </Link>
    //       </div>
    //     </td>
    //   ),
    //   sorter: (a, b) => a.createdby.length - b.createdby.length,
    // },
    {
        title: "Action",
        dataIndex: "action",
        sorter: (a, b) => a.createdby.length - b.createdby.length,
      },
  ];
    const invoiceColumns = [
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
        },
        {
            title: 'Customer Name',
            dataIndex: ['customer', 'customerName'],
            key: 'customer.customerName',
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            render: (due_date) => new Date(due_date).toLocaleDateString()
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Total After Discount',
            dataIndex: 'total_after_discount',
            key: 'total_after_discount',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                if (status == "paid") {
                    return <span className="badge bg-success">paid</span>
                } else if (status == "not-paid") {
                    return <span className="badge bg-danger">unpaid</span>
                } else {
                    return <span className="badge bg-warning">partialy paid</span>

                }
            }

        }
    ];

    const items = [
        {
            key: '1',
            label: 'Personal Details',
            children: <dl className="row mb-0">
                <dt className="col-sm-4">Customer Name</dt>
                <dd className="col-sm-8 text-start">
                    {customerRec?.customerName}
                </dd>
                <dt className="col-sm-4">Mobile Number</dt>
                <dd className="col-sm-8">
                    {customerRec?.customerPhone}
                </dd>
                <dt className="col-sm-4">Email</dt>
                <dd className="col-sm-8">
                    {customerRec?.customerEmail}
                </dd>
                <dt className="col-sm-4">
                    Address
                </dt>
                <dd className="col-sm-8">
                    {customerRec?.customerAddress}, {customerRec?.customerCity}, {customerRec?.customerCountry}
                </dd>
            </dl>,
        },
        {
            key: '2',
            label: 'Order History',
            children: <Table columns={invoiceColumns} dataSource={invoices}/>,
        }
    ];
    const customerRecord = async (customer) => {

        console.log("Customer", customer)

        setCustomerRec(customer);
        getInvoicesByCustomer(customer, setInvoices);
    };
    const handleEdit = (customer) => {
        setSelectedCustomer(customer);
    };
    return (
        <>
            <Table
                className="table datanew"
                columns={columns}
                pagination={{
                    current: page,
                    pageSize: 10,
                    total: total,
                    showSizeChanger: true,
                }}
                onChange={changePage}
                rowKey="_id"
                dataSource={customers.map((customer) => ({
                    key: customer._id,
                    CustomerName: customer.customerName,
                    Code: `#${customer._id.slice(-3)}`,
                    Customer: customer.customerName,
                    Email: customer.customerEmail,
                    Phone: customer.customerPhone,
                    Country: customer.customerCountry,
                    action: (
                        <td className="action-table-data">
                            {hasJobRightWithview && (
                              <Link onClick={() => customerRecord(customer)} className="me-2 p-2" to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#bs-example-modal-lg">
                              <Eye className="feather-view"/>
                              </Link>
                            )}
                            {hasJobRightWithedit && (
                                <Link
                                    className="me-2 p-2"
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit-units"
                                    onClick={() => handleEdit(customer)}
                                >
                                    <Edit className="feather-edit"/>
                                </Link>
                            )}
                            
                              {/* <CustomerModal
                  customerId={selectedCustomerId} // selected customerId to CustomerModal
                  onCustomerAdded={onCustomerDeleted} // Pass onCustomerAdded function
                /> */}
                            {hasJobRightWithedel && (
                                 <Link
                                 className="confirm-text p-2"
                                 to="#"
                                 onClick={() => showConfirmationAlert(customer._id)}
                             >
                                 <Trash2 className="feather-trash-2"/>
                             </Link>
                            )}
                            

                      </td>
                  ),
              }))}
          />

          <div
              className="modal fade"
              id="bs-example-modal-lg"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
          >
              <div className="modal-dialog  modal-lg">
                  <div className="modal-content">

                      <div className="modal-body">
                          <div className="card">
                              <div className="card-body">
                                  <Tabs defaultActiveKey="1" items={items} />

                              </div>
                          </div>
                      </div>
                      <div className="modal-footer">
                          <button
                              type="button"
                              className="btn btn-cancel"
                              data-bs-dismiss="modal"
                          >
                              Close
                          </button>
                          <button type="button" className="btn btn-primary">
                              Understood
                          </button>
                      </div>
                  </div>
              </div>
          </div>

      </>

    )
        ;
};

// Define PropTypes for the component
CustomerTable.propTypes = {
    customers: PropTypes.array.isRequired,
    onCustomerDeleted: PropTypes.func.isRequired,
    setSelectedCustomer: PropTypes.func.isRequired,
    loadCustomer: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    total: PropTypes.any.isRequired,
    page: PropTypes.any.isRequired,

};

export default CustomerTable;
