import React, { useEffect, useState } from 'react';
import { Table, Select } from 'antd';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Link } from 'react-router-dom';
import { getAllPayrolls} from '../../service/ApiServices';
import {fetchPayrollByMonthAndYear,fetchPayrollBySearch} from '../../service/payrollservice'
import Swal from 'sweetalert2';
import { Spin } from "antd";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { Dropdown, Menu, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
const { Option } = Select;

function Payslip() {
    const currentYear = dayjs().year();
    const currentMonth = new Date().getMonth();
    //const date=dayjs().format('YYYY-MM-DD');
    const [payrollData, setPayrollData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth+1);
    const [searchMonth, setSearchMonth] = useState(null);
    const [search, setSearch] = useState("")
    const [loading2, setLoading2] = useState(false);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [searchYear, setSearchYear] = useState(null);
    const [center, setCenter] = useState(null)
    const [branch, setBranch] = useState(null)
    const [epf_number, setEpf] = useState("")
    const handleYearChange = (date, dateString) => {
        setSelectedYear(dateString);
    };
    const handleSearchYearChange = (date, dateString) => {
        setSearchYear(dateString);
    };
    const centerOption = [
        { value: "OKI", label: "OKI" },
        { value: "OKIDS", label: "OKIDS" },
        { value: "OKIT", label: "OKIT" }
      ]

      const branchOptions=[
        {value: "kaduwela", label: "Kaduwela"},
        {value: "wattala", label: "Wattala"},
        {value: "negombo", label: "Negombo"},
        {value: "kiribathgoda", label: "Kiribathgoda"},
        {value: "kandana", label: "Kandana"}]
      

    const fetchAllPayrollData = async ( ) => {
        getAllPayrolls({
            month:selectedMonth,
            search:"",
            setLoading2,
            setPayrollData,
            
        })
    };
    const handleFileDownload = (value) => {
        switch (value) {
            case 'okids-payrolls':
                handleOKIDSExcelExport();
                break;
            case 'payrolls':
                handleAllExcelExport();
                break;
            case 'etf-file':
                downloadOKIDSETFFile();
                break;
            case 'epf-file':
                downloadOKIDSEPFFile();
                break;
            default:
                break;
        }
    };
    
    const menu = (
        <Menu onClick={(e) => handleFileDownload(e.key)}>
            <Menu.Item key="okids-payrolls">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label className="btn" style={{ marginRight: '4px' }}>OKIDS Payrolls</label>
                    <img src="assets/img/icons/excel.svg" alt="excel" />
                </div>
            </Menu.Item>
            <Menu.Item key="payrolls">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label className="btn" style={{ marginRight: '4px' }}>Payrolls</label>
                    <img src="assets/img/icons/excel.svg" alt="excel" />
                </div>
            </Menu.Item>
            <Menu.Item key="etf-file">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label className="btn" style={{ marginRight: '4px' }}>ETF File</label>
                    <img src="assets/img/icons/excel.svg" alt="excel" />
                </div>
            </Menu.Item>
            <Menu.Item key="epf-file">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label className="btn" style={{ marginRight: '4px' }}>EPF File</label>
                    <img src="assets/img/icons/excel.svg" alt="excel" />
                </div>
            </Menu.Item>
        </Menu>
    );
    
    const fetchRecordsByMonthAndYear =async ( ) => {
        const records = await fetchPayrollByMonthAndYear({
            setLoading,
            month:selectedMonth,
            year:selectedYear
        });
        if (records) {
            setPayrollData(records);
        } else {
            setPayrollData([]);  // Ensure fallback to an empty array
        }
    }
    

    const getSeachRecord =async ( ) => {
        const records = await fetchPayrollBySearch({
            setLoading,
            month:searchMonth,
            year:searchYear,
            epf_number,
            name:search,
            center,
            branch
        });
        if (records) {
            setPayrollData(records);
        } else {
            setPayrollData([]);  // Ensure fallback to an empty array
        }
    }
   const handleSearch =()=>{
    getSeachRecord();
     console.log(search,"Search",center,"Center",epf_number,"EPFNUMBER")
    }

    const getMonthName = (monthNumber) => {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        return monthNames[monthNumber - 1]; // Subtract 1 because array index starts at 0
    };

    const generatePayslipPDF = (record) => {
        const calculationArray = [
            ['Basic Salary', `Rs. ${record.basic_salary}`],
            ['No Pay Deduction', `Rs. ${record.noPayDeduction}`],
            ['Final Basic Salary', `Rs. ${record.finalBasicSalary}`]
        ]
        if (Array.isArray(record.allowances) && record.allowances.length !== 0) {
            record.allowances.map((item) => {
                calculationArray.push([
                    item.name + " " + new Date().toLocaleDateString(), `Rs. ${item.amount}`
                ])
            })
        }
        calculationArray.push(
            ['Total Allowances', `Rs. ${record.totalAllowanceAmount}`],
            [`Employee Provident Fund (EPF ${record.ept_employee_rate}%)`, `Rs. ${record.calculateEPF}`],
            ['Other Deduction', '']

        )
        if (Array.isArray(record.otherDeductions) && record.otherDeductions.length !== 0) {
            record.otherDeductions.map((item) => {
                if (item.paymentType === "recurring") {
                    calculationArray.push([
                        item.description, `Rs. ${item.installement}`
                    ])
                } else {
                    calculationArray.push([
                        item.description, `Rs. ${item.amount}`
                    ])
                }
            })
        }
        calculationArray.push(
            ['Total of Other Deductions', `Rs. ${record.calculateOtherDeductions}`],
            ['Net Pay', `Rs. ${record.calculateNetPay}`]

        )
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        const lineWidth = 170;
        const startX = (pageWidth - lineWidth) / 2;
        const headerY = 20;

        doc.setFontSize(14);
        const schoolName = "OKI INTERNATIONAL SCHOOL";
        const location = "WATTALA";
        const salaryMonth = `Salary for the month of ${getMonthName(selectedMonth)} 2024`;

        doc.text(schoolName, (pageWidth - doc.getTextWidth(schoolName)) / 2, headerY);
        doc.text(location, (pageWidth - doc.getTextWidth(location)) / 2, headerY + 10);
        doc.setFontSize(12);
        doc.text(salaryMonth, (pageWidth - doc.getTextWidth(salaryMonth)) / 2, headerY + 20);

        doc.setFontSize(10);
        doc.autoTable({
            startY: 50,
            head: "",
            body: [
                ['EPF NUMBER', `${record.epf_number}`],
                ['Branch', `${record.branch || 'N/A'}`],
                ['Name', `${record.name}`],
                ['ID Number', ` ${record.employee_id}`]
            ],
            theme: 'plain',
            columnStyles: {
                0: { cellWidth: pageWidth * 0.25 },
            },
        });
        doc.autoTable({
            startY: 90,
            head: "",
            body: calculationArray,
        });

        const footerY = doc.autoTable.previous.finalY + 20;
        doc.line(startX, footerY, startX + lineWidth, footerY);
        doc.autoTable({
            startY: footerY + 5,
            head: "",
            body: [
                ['12% EPF', `Rs. ${record.calculateEPFCompany}`],
                ['20% EPF', `Rs. ${record.totalEPF}`],
                ['03% ETF', `Rs. ${record.calculateETF}`],
            ],//  [`Employee Trust Fund (ETF ${record.etf}%)`, `Rs. ${record.calculateETF}`],
            theme: 'plain',
            columnStyles: {
                0: { cellWidth: pageWidth * 0.64 },
            },
        });

        doc.save(`Payslip_${record.epf_number}_${record.name}.pdf`);
    };



    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
    };

    useEffect(() => {
        fetchRecordsByMonthAndYear(); 
    }, [selectedMonth, selectedYear]); 
 
    const handleMonthChange = (value) => {
        setSelectedMonth(value);
        setCurrentPage(1);
    };
    const handleSearchMonthChange = (value) => {
        setSearchMonth(value);
        setCurrentPage(1);
    };


    // const filteredData = payrollData.filter((record) => {
    //     const nameMatch=record.name.toLowerCase().includes(search.toLowerCase()) 
    //     const epfMatch= record.epf_number.toLowerCase().includes(search.toLowerCase())
    
    //     return nameMatch||epfMatch
    //   });

    const columns = [
        {
            title: 'EPF Number',
            dataIndex: 'epf_number',
            key: 'epf_number',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Center',
            dataIndex: 'center',
            key: 'center',
        },
        {
            title: 'Branch',
            dataIndex: 'branch',
            key: 'branch',
        },
        {
            title: 'Basic Salary',
            dataIndex: 'basic_salary',
            key: 'basic_salary',
        },
        {
            title: 'Net Pay',
            dataIndex: 'calculateNetPay',
            key: 'calculateNetPay',
            render:(text,record)=>(
                record.finalBasicSalary?record.calculateNetPay:"----------"
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Link className="me-2 p-2" to="#" 
                    onClick={() => {
                        console.log(record)
                        if(!record.finalBasicSalary){
                            Swal.fire({
                                icon:"error",
                                text:`${record.payeTax} didnt have any attendace to calculate payroll! `,
                                title:"Error"
                            })
                            return
                        }
                        generatePayslipPDF(record)
                    
                    }}
                >
                    <i data-feather="show" className="feather-download"></i>
                </Link>
            ),
        },
    ];



    const handleAllExcelExport = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Employees Payrolls Report');
    
        const borderStyle = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
    
        const headerFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D3D3D3' }
        };
    
        worksheet.mergeCells('A1:A2');
        worksheet.mergeCells('B1:B2'); 
        worksheet.mergeCells('C1:C2'); 
        worksheet.mergeCells('D1:D2'); 
        worksheet.mergeCells('E1:E2'); 
        worksheet.mergeCells('F1:F2'); 
        worksheet.mergeCells('G1:G2'); 
        worksheet.mergeCells('K1:K2'); 
    
        worksheet.mergeCells('H1:J1'); 
        worksheet.getCell('H1').value = 'Date';
        worksheet.getCell('H1').alignment = { horizontal: 'center', vertical: 'middle' };  
        worksheet.getCell('H1').font = { bold: true };
    
        const subHeaderRow = worksheet.getRow(2);
        subHeaderRow.getCell(8).value = 'Year';
        subHeaderRow.getCell(9).value = 'Month';
        subHeaderRow.getCell(10).value = 'Date';
    
        const headerRow = worksheet.getRow(1);
        headerRow.getCell('A').fill = headerFill;
        headerRow.getCell('A').value = "Reference No";
        headerRow.getCell('A').alignment = { horizontal: 'center', vertical: 'middle' }; 
        headerRow.getCell('B').fill = headerFill;
        headerRow.getCell('B').value = "Account Name";
        headerRow.getCell('B').alignment = { horizontal: 'center', vertical: 'middle' }; 
        headerRow.getCell('C').fill = headerFill;
        headerRow.getCell('C').value = "Bank Code";
        headerRow.getCell('C').alignment = { horizontal: 'center', vertical: 'middle' }; 
        headerRow.getCell('D').fill = headerFill;
        headerRow.getCell('D').value = "Branch Code";
        headerRow.getCell('D').alignment = { horizontal: 'center', vertical: 'middle' }; 
        headerRow.getCell('E').fill = headerFill;
        headerRow.getCell('E').value = "Credit Account No";
        headerRow.getCell('E').alignment = { horizontal: 'center', vertical: 'middle' }; 
        headerRow.getCell('F').fill = headerFill;
        headerRow.getCell('F').value = "Transaction Code";
        headerRow.getCell('F').alignment = { horizontal: 'center', vertical: 'middle' }; 
        headerRow.getCell('G').fill = headerFill;
        headerRow.getCell('G').value = "Amount (Rs.)";
        headerRow.getCell('G').alignment = { horizontal: 'center', vertical: 'middle' }; 
        headerRow.getCell('H').fill = headerFill; 
        headerRow.getCell('K').fill = headerFill;
        headerRow.getCell('K').value = "Remark";
        headerRow.getCell('K').alignment = { horizontal: 'center', vertical: 'middle' }; 
    
        subHeaderRow.eachCell((cell) => {
            cell.fill = headerFill;
            cell.border = borderStyle;
            cell.font = { bold: true };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
        });
    
        worksheet.columns = [
            { key: 'ref_no', width: 15 },
            { key: 'name', width: 40 },
            { key: 'bank_code', width: 15 },
            { key: 'branch_code', width: 15 },
            { key: 'bank_account', width: 20 },
            { key: 'transaction_code', width: 20 },
            { key: 'total', width: 20 },
            { key: 'year', width: 10 },
            { key: 'month', width: 10 },
            { key: 'date', width: 10 },
            { key: 'remarks', width: 25 }
        ];
    
       // const filteredPayrollData = payrollData.filter(record => record.center !== "OKIDS");
    
        // Start adding data from row 3 (after the headers)
        payrollData.forEach((record, index) => {
            const year = dayjs().year();
            const month = dayjs().month() + 1; 
            const day = dayjs().date();
    
            const row = worksheet.addRow({
                'ref_no': index + 1,
                'name': `${record.name}`,
                'bank_code': record.bank_code,
                'branch_code': record.branch_code,
                'bank_account': record.account_number,
                'transaction_code': '023-Salaries',
                'total': record?.finalBasicSalary ? parseFloat(record.finalBasicSalary).toFixed(2) : '0.00',
                'year': year,
                'month': month,
                'date': day,
                'remarks': `${record.year} ${getMonthName(record.month)} Salary`,
            });
    
            // Align specific cells
            row.getCell('total').alignment = { horizontal: 'right' };
            row.getCell('ref_no').alignment = { horizontal: 'center' };  
            row.getCell('year').alignment = { horizontal: 'center' };
            row.getCell('month').alignment = { horizontal: 'center' };
            row.getCell('date').alignment = { horizontal: 'center' };
        });
    
        worksheet.eachRow({ includeEmpty: true }, (row) => {
            row.eachCell({ includeEmpty: true }, (cell) => {
                if (cell.row >= 1) { 
                    cell.border = borderStyle;
                }
            });
        });
    
    
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `OKI _Payrolls_Report .xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        });
    };
    
    


    const handleOKIDSExcelExport = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Employees Payrolls Report (OKIDS)');
    
        const borderStyle = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
    
        const headerFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D3D3D3' }
        };
    
        
        worksheet.mergeCells('A1:A2');
        worksheet.mergeCells('B1:B2'); 
        worksheet.mergeCells('C1:C2'); 
        worksheet.mergeCells('D1:D2'); 
        worksheet.mergeCells('E1:E2'); 
        worksheet.mergeCells('F1:F2'); 
        
    
        
        const headerRow = worksheet.getRow(1);
        headerRow.values = ['Name', 'Account Number', 'Bank Code', 'Branch Code', 'Total', 'EPF Number'];
    
       
        headerRow.eachCell((cell) => {
            cell.fill = headerFill;
            cell.border = borderStyle;
            cell.font = { bold: true };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };  
        });
    
        
        worksheet.columns = [
            { key: 'name', width: 30 },
            { key: 'bank_account', width: 20 },
            { key: 'bank_code', width: 10 },
            { key: 'branch_code', width: 15 },
            { key: 'total', width: 20 },
            { key: 'epf_number', width: 15 }
        ];
    
        
        const filteredPayrollData = payrollData.filter(record => record.center === "OKIDS");
    
        
        filteredPayrollData.forEach((record, index) => {
            const rowNumber = 3 + index;  
            worksheet.addRow({
                'name': `${record.name}`,
                'bank_account': record.account_number,
                'bank_code': record.bank_code,
                'branch_code': record.branch_code,
                'total': record?.finalBasicSalary ? parseFloat(record.finalBasicSalary).toFixed(2) : '0.00',
                'epf_number': record.epf_number
            });
    
            const row = worksheet.getRow(rowNumber);
            
            row.getCell('total').alignment = { horizontal: 'right' };
            row.getCell('bank_code').alignment = { horizontal: 'center' };
            row.getCell('branch_code').alignment = { horizontal: 'center' };
            row.getCell('epf_number').alignment = { horizontal: 'center' };
        });
    
        
        worksheet.eachRow({ includeEmpty: true }, (row) => {
            row.eachCell({ includeEmpty: true }, (cell) => {
                if (cell.row >= 1) {  
                    cell.border = borderStyle;
                }
            });
        });
    
        
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `OKIDS _Payrolls_Report.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        });
    };
 
    const downloadOKIDSETFFile = () => {

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('OKIDS ETF Report');
        worksheet.views = [
            { state: 'frozen', ySplit: 3 }
        ];
    
        const borderStyle = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
    
        const headerFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D3D3D3' }
        };
    
        // Merging 3 cells for header row
        worksheet.mergeCells('A1:A3');
        worksheet.mergeCells('B1:B3');
        worksheet.mergeCells('C1:C3');
        worksheet.mergeCells('D1:D3');
        worksheet.mergeCells('E1:E3');
        worksheet.mergeCells('F1:F3');
        worksheet.mergeCells('G1:G3');
        worksheet.mergeCells('H1:H3');
    
        // Member number = ETF Number
        const headerRow = worksheet.getRow(1);
        headerRow.values = ['NIC/Passport\nNumber', 'Surname', 'Initials', 'Member\nNumber', 'Total\nContribution', 'Employee\nNumber', 'Contribution\nFrom Period\n(YYYY/MM/DD)', 'Contribution\nTo Period\n(YYYY/MM/DD)'];
    
        headerRow.eachCell((cell) => {
            cell.fill = headerFill;
            cell.border = borderStyle;
            cell.font = { bold: true };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };  
        });
    
        worksheet.columns = [
            { key: 'nic', width: 20 },
            { key: 'surname', width: 20 },
            { key: 'initials', width: 15 },
            { key: 'member_num', width: 15 },
            { key: 'total_contribution', width: 15 },
            { key: 'employee_number', width: 15 },
            { key: 'to_period', width: 15 },
            { key: 'from_period', width: 15 }
        ];
    
        const getInitials = (name) => {
            const nameParts = name.split(' ');
            const initials = nameParts.slice(0, -1)
                .map(part => part.charAt(0).toUpperCase())
                .join(' ');
            return initials;
        }
    
        const getSurname = (name) => {
            const nameParts = name.split(' ');
            return nameParts[nameParts.length - 1];
        }
    
        //const filteredPayrollData = payrollData.filter(record => record.center === "OKIDS");
    
        payrollData.forEach((record, index) => {
            const rowNumber = 3 + index;
            worksheet.addRow({
                'nic': record.nic,
                'surname': getSurname(record.name),
                'initials': getInitials(record.name),
                'member_num': record.epf_number,
                'total_contribution': record.calculateETF,
                'employee_number': 'I 013188',
                'to_period': `${record.year}${record.month}`,
                'from_period': `${record.year}${record.month}`,
            });
    
            const row = worksheet.getRow(rowNumber);
            row.getCell('total_contribution').alignment = { horizontal: 'right' };
            row.getCell('member_num').alignment = { horizontal: 'center' };
            row.getCell('employee_number').alignment = { horizontal: 'center' };
            row.getCell('to_period').alignment = { horizontal: 'center' };
            row.getCell('from_period').alignment = { horizontal: 'center' };  
            row.getCell('total_contribution').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor:{ argb: 'E0F7FA' }  
        };
    
        });

    
        worksheet.eachRow({ includeEmpty: true }, (row) => {
            row.eachCell({ includeEmpty: true }, (cell) => {
                if (cell.row >= 1) {
                    cell.border = borderStyle;
                }
            });
        });
    
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `OKIDS _ETF_Report.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        });
    };

    const downloadOKIDSEPFFile = () => {

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('OKIDS EPF Report');
        worksheet.views = [
            { state: 'frozen', ySplit: 3 }
        ];
    
        const borderStyle = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
    
        const headerFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D3D3D3' }
        };
    
        // Merging 3 cells for header row
        worksheet.mergeCells('A1:A3');
        worksheet.mergeCells('B1:B3');
        worksheet.mergeCells('C1:C3');
        worksheet.mergeCells('D1:D3');
        worksheet.mergeCells('E1:E3');
        worksheet.mergeCells('F1:F3');
        worksheet.mergeCells('G1:G3');
        worksheet.mergeCells('H1:H3');
        worksheet.mergeCells('I1:I3');
        worksheet.mergeCells('J1:J3');
        worksheet.mergeCells('K1:K3');
        worksheet.mergeCells('L1:L3');
        worksheet.mergeCells('M1:M3');
        worksheet.mergeCells('N1:N3');
        worksheet.mergeCells('O1:O3');
    
        // Member number = EPF Number
        const headerRow = worksheet.getRow(1);
        headerRow.values = ['NIC/Passport\nNumber', 'Surname', 'Initials', 'Member\nNumber', 'Total\nContribution', 'Employer\'s\nContribution', 'Member\'s\nContribution', 
            'Total Earnings','Member Status \n E=Extg, N=New, V=Vacated','Zone','Employer\nNumber','Contribution\n Period\n (YYYY/MM)','Data Submission\nNumber','No. of Days\nWorked','Occupation\nClassification\nGrade'];
    
        headerRow.eachCell((cell) => {
            cell.fill = headerFill;
            cell.border = borderStyle;
            cell.font = { bold: true };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };  
        });
    
        worksheet.columns = [
            { key: 'nic', width: 20 },
            { key: 'surname', width: 20 },
            { key: 'initials', width: 15 },
            { key: 'member_num', width: 15 },
            { key: 'total_contribution', width: 15 },
            { key: 'employer_contribution', width: 15 },
            { key: 'member_contribution', width: 15 },
            { key: 'total_earning', width: 15 },
            { key: 'member_status', width: 15 },
            { key: 'zone', width: 15 },
            { key: 'employer_number', width: 15 },
            { key: 'contribution_period', width: 15 },
            { key: 'data_sub_num', width: 15 },
            { key: 'work_days', width: 15 },
            { key: 'occupation_clss_gde', width: 15 }
        ];
    
        const getInitials = (name) => {
            const nameParts = name.split(' ');
            const initials = nameParts.slice(0, -1)
                .map(part => part.charAt(0).toUpperCase())
                .join(' ');
            return initials;
        }
    
        const getSurname = (name) => {
            const nameParts = name.split(' ');
            return nameParts[nameParts.length - 1];
        }
    
       //const filteredPayrollData = payrollData.filter(record => record.center === "OKIDS");
    
       payrollData.forEach((record, index) => {
            const rowNumber = 3 + index;
            worksheet.addRow({
                'nic': record.nic,
                'surname': getSurname(record.name),
                'initials': getInitials(record.name),
                'member_num': record.epf_number,
                'total_contribution': record.totalEPF,
                'employer_contribution': record.calculateEPFCompany,
                'member_contribution':record.calculateEPF,
                'total_earning':record?.finalBasicSalary ? parseFloat(record.finalBasicSalary).toFixed(2) : '0.00',
                'member_status':'E',
                'zone':'I',
                'employer_number':'13188',
                'contribution_period':`${record.month}${record.year}`,
                'data_sub_num':'1',
                'work_days':record.workedDays,
                 'occupation_clss_gde':"23"
            });
    
            const row = worksheet.getRow(rowNumber);
            row.getCell('total_contribution').alignment = { horizontal: 'right' };
            row.getCell('total_earning').alignment = { horizontal: 'right' };
            row.getCell('member_num').alignment = { horizontal: 'center' };
            row.getCell('employer_contribution').alignment = { horizontal: 'right' };
            row.getCell('member_contribution').alignment = { horizontal: 'right' }; 
            row.getCell('total_contribution').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor:{ argb: 'E0F7FA' }  
        };
        row.getCell('employer_contribution').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor:{ argb: 'E0F7FA' }  
        };
        row.getCell('member_contribution').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor:{ argb: 'E0F7FA' }  
        };
        row.getCell('total_earning').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor:{ argb: 'E0F7FA' }  
        };
    
        });

    
        worksheet.eachRow({ includeEmpty: true }, (row) => {
            row.eachCell({ includeEmpty: true }, (cell) => {
                if (cell.row >= 1) {
                    cell.border = borderStyle;
                }
            });
        });
    
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `OKIDS _EPF_Report.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        });
    };
    
    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Payrolls Management</h4>
                            <h6>Manage Employee payrolls</h6>
                        </div>
                    </div>
                    <div className="page-btn">
                        <div className="wordset" style={{ marginLeft: 'auto' }}>

                            <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
                                <Button style={{ width: 200, height: 35 }}>
                                    Download Files <DownOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                        <div className="wordset" style={{ marginLeft: 'auto' }}>

                        </div>
                    </div>


                </div>
                <div className="card table-list-card">
                    <div className="card-body" style={{ maxWidth: "100%", marginRight: "20px" }}>
                        <div className="container mt-3 " style={{ maxWidth: "100%", marginLeft: "20px", marginRight: "40px" }}> {/* Added p-0 and maxWidth */}
                            <div className="row d-flex flex-wrap align-items-start justify-content-between"> {/* Added justify-content-start */}
                                <div className="col-12 col-md-3  text-start">
                                    <label className="form-label">Employee Name</label>
                                    <input
                                        type='text'
                                        value={search}
                                        className="form-control"
                                        onChange={(e) => {
                                            setSearch(e.target.value);
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div className="col-12 col-md-1 mb-3 text-start">
                                    <label className="form-label">EPF No</label>
                                    <input
                                        className="form-control"
                                        type='text'
                                        value={epf_number}
                                        onChange={(e) => setEpf(e.target.value)}
                                        style={{ width: "100%" }}
                                    />
                                </div>

                                <div className="col-12 col-md-2 mb-3 text-start">
                                    <label className="form-label">Center</label>
                                    <Select
                                        value={center}
                                        options={centerOption}
                                        className="select"
                                        placeholder="Select Center"
                                        onChange={setCenter}
                                        allowClear
                                        style={{ width: "100%", height: "38px" }}
                                    />
                                </div>
                                <div className="col-12 col-md-2 mb-3 text-start">
                                    <label className="form-label">branch</label>
                                   
                                        <Select
                                            value={branch}
                                            options={branchOptions}
                                            className="select"
                                            placeholder="Select Branch"
                                            onChange={setBranch}
                                            allowClear
                                            style={{ width: "100%", height: "38px" }}
                                        />
                                    
                                </div>


                                <div className="col-12 col-md-2 mb-3 text-start">
                                    <label className="form-label">Month</label>
                                    <Select
                                        placeholder="Select Month"
                                        value={searchMonth}
                                        style={{ width: "100%" }}
                                        size={"large"}
                                        onChange={handleSearchMonthChange}
                                        allowClear
                                    >
                                        <Option value={1} disabled={1 > currentMonth + 1}>January</Option>
                                        <Option value={2} disabled={2 > currentMonth + 1}>February</Option>
                                        <Option value={3} disabled={3 > currentMonth + 1}>March</Option>
                                        <Option value={4} disabled={4 > currentMonth + 1}>April</Option>
                                        <Option value={5} disabled={5 > currentMonth + 1}>May</Option>
                                        <Option value={6} disabled={6 > currentMonth + 1}>June</Option>
                                        <Option value={7} disabled={7 > currentMonth + 1}>July</Option>
                                        <Option value={8} disabled={8 > currentMonth + 1}>August</Option>
                                        <Option value={9} disabled={9 > currentMonth + 1}>September</Option>
                                        <Option value={10} disabled={10 > currentMonth + 1}>October</Option>
                                        <Option value={11} disabled={11 > currentMonth + 1}>November</Option>
                                        <Option value={12} disabled={12 > currentMonth + 1}>December</Option>
                                    </Select>
                                </div>
                                <div className="col-12 col-md-2 mb-3 text-start">
                                    <label className="form-label">Year</label>
                                   
                                    <DatePicker
                                        picker="year"
                                        onChange={handleSearchYearChange}
                                        //defaultValue={dayjs(`${currentYear}`, 'YYYY')}
                                        placeholder="Select Year"
                                        size="large"
                                        style={{ width: "100%", borderRadius: 8 }}
                                        allowClear
                                    />
                                    
                                </div>


                            </div>
                        </div>

                        <div className="container mt-0" style={{ maxWidth: "100%", marginLeft: "20px", marginRight: "40px" }}>
                            <div className="row d-flex align-items-center justify-content-start">

                                {/* Center Select */}
                                {/* <div className="col-12 col-md-2 mb-3 text-start">
                                    <label className="form-label">Year</label>
                                    <div className="d-flex align-items-center">
                                    <DatePicker
                                        picker="year"
                                        onChange={handleSearchYearChange}
                                        //defaultValue={dayjs(`${currentYear}`, 'YYYY')}
                                        placeholder="Select Year"
                                        size="large"
                                        style={{ width: "100%", borderRadius: 8 }}
                                        allowClear
                                    />
                                    </div>
                                </div> */}
                                

                                {/* Search Button */}
                                {/* <div className="col-12 col-md-2 mb-3 text-start">
                                    <label className="form-label" style={{ visibility: "hidden" }}>Hidden Label</label>
                                    <div className="d-flex align-items-center">
                                        <button
                                            className="btn btn-submit"
                                            onClick={handleSearch}
                                            style={{ height: "38px", width: "55%" }}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div> */}
                                 <div className="col-12 col-md-2 mb-3 text-start">
                                   
                                    <button
                                            className="btn btn-submit"
                                            onClick={handleSearch}
                                            style={{ height: "38px", width: "55%" }}
                                        >
                                            Submit
                                        </button>
                                    
                                </div>

                            </div>
                        </div>



                    </div>
                </div>

                <div className="card table-list-card">
                    <div className="card-body" style={{ maxWidth: "100%", marginRight: "20px" }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "10px",
                                padding: "10px",
                            }}
                        >
                            {/* Left side content */}
                            <div>
                                {/* Optional content on the left */}
                            </div>

                            {/* Right side content */}
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: "10px",
                                }}
                            ><div>
                                    <Select
                                        value={selectedMonth}
                                        style={{ width: "100%" }}
                                        size={"large"}
                                        onChange={handleMonthChange}
                                    >
                                        <Option value={1} disabled={1 > currentMonth + 1}>January</Option>
                                        <Option value={2} disabled={2 > currentMonth + 1}>February</Option>
                                        <Option value={3} disabled={3 > currentMonth + 1}>March</Option>
                                        <Option value={4} disabled={4 > currentMonth + 1}>April</Option>
                                        <Option value={5} disabled={5 > currentMonth + 1}>May</Option>
                                        <Option value={6} disabled={6 > currentMonth + 1}>June</Option>
                                        <Option value={7} disabled={7 > currentMonth + 1}>July</Option>
                                        <Option value={8} disabled={8 > currentMonth + 1}>August</Option>
                                        <Option value={9} disabled={9 > currentMonth + 1}>September</Option>
                                        <Option value={10} disabled={10 > currentMonth + 1}>October</Option>
                                        <Option value={11} disabled={11 > currentMonth + 1}>November</Option>
                                        <Option value={12} disabled={12 > currentMonth + 1}>December</Option>
                                    </Select>
                                </div>
                                <div>
                                    <DatePicker
                                        picker="year"
                                        onChange={handleYearChange}
                                        defaultValue={dayjs(`${currentYear}`, 'YYYY')}
                                        placeholder="Select Year"
                                        size="large"
                                        style={{ width: "100%", borderRadius: 8 }}
                                    />
                                </div>

                                <div>
                                    <button className="btn btn-submit" onClick={fetchAllPayrollData}>
                                        {loading2 ? (
                                            <>
                                                <Spin size="small" />{" "}
                                                <span>Please wait...</span> {/* Text to show while loading */}
                                            </>
                                        ) : (
                                            "Generate Payrolls"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* below       START     ??????????????????????????????????????????????????????????????? */}


                        {/* // BELOW END?????????????????????????????????????????????????????????????????*/}
                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                dataSource={payrollData}
                                rowKey="epf_number"
                                pagination={{
                                    current: currentPage,
                                    //pageSize: pageSize,
                                    // total: totalRecords,
                                }}
                                loading={loading}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default Payslip;
