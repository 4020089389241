import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {  PlusCircle, } from "react-feather";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import AddRole from "../../core/modals/usermanagement/addrole";
import EditRole from "../../core/modals/usermanagement/editrole";
import { getRoles, deleteRole } from "../../service/userRoleApiServices";

const RolesPermissions = () => {
  //roles-permission
  const [roles, setRoles] = useState([]);
  const [updateRole, setUpdateRole] = useState("");
  //console.log(updateRole,"UPDATE ROLE")

  const handleUpdateRole = (record) => {
    setUpdateRole(record);
  };

  //delete user
  const handleDeleteRole = async (roleId) => {
    try {
      await deleteRole(roleId);
      const updatedRoles = roles.filter((role) => role.id !== roleId);
      setRoles(updatedRoles);
      console.log("Role deleted successfully");
    } catch (error) {
      console.error("Error deleting role:", error);
    }
  };
  const storedUserData = localStorage.getItem("user");
  const userData1 = JSON.parse(storedUserData);
  const id = userData1?.data?.uid; //

  useEffect(() => {

  
     // Fetch the data on component mount
  }, [id]);

 // const hasJobRightWithdown = userRightssub.some(right => right.right === 'Shifts' && right.fdownload=== 1); 
  useEffect(() => {
   getRoles().then(value => {
     setRoles(value);
   })
  }, []);




  //roles-permission
  //const route = all_routes;


  const columns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      sorter: (a, b) => a.createdon.length - b.createdon.length,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
                         <Link
                className="me-2 p-2"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#edit-units"
                onClick={() => handleUpdateRole(record.rId)}
              >
                <i data-feather="edit" className="feather-edit"></i>
              </Link>


                 <Link className="me-2 p-2" to={`/permissions/${record.rId}`}>
                 <i
                   data-feather="shield"
                   className="feather feather-shield shield"
                 ></i>
               </Link>


                <Link className="confirm-text p-2" to="#">
                <i
                  data-feather="trash-2"
                  className="feather-trash-2"
                  onClick={() => showConfirmationAlert(record.rId)}
                ></i>
              </Link>

            
          </div>
        </td>
      ),
    },
  ];

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (record) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
        handleDeleteRole(record.id);
      } else {
        MySwal.close();
      }
    });
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Roles &amp; Permission</h4>
                <h6>Manage your roles</h6>
              </div>
            </div>

            <div className="page-btn">

              <a
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-units"
              >
                <PlusCircle className="me-2" />
                Add New Role
              </a>

              
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />

                </div>

              </div>

              <div className="table-responsive">
                <Table columns={columns} dataSource={roles} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <AddRole />
      <EditRole RoleId={updateRole.id} />
    </div>
  );
};

export default RolesPermissions;
