import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./style.css";
import { addLeaveTypes } from "../../../service/leaveApiServices/leaveTypesApi";
import Swal from "sweetalert2";
import { Spin } from "antd";

const CreateLeaveTypeModal = ({
  handleCancel,
  handleLeaveTypeAdding,
  selectedLeaveType,
}) => {
  //parenten ganne 3ma
  const [leaveTypeName, setLeaveTypeName] = useState("");
  const [quotaSize, setQuotaSize] = useState("");
  const [minLeaveCount, setMinLeaveCount] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSave = () => {
    if (leaveTypeName === "") {
      showWarning("Please enter Job Type Name");
      return;
    }
    if (
      quotaSize === "" ||
      parseFloat(quotaSize) === 0 ||
      parseFloat(quotaSize) < 0
    ) {
      showWarning("Please enter valid quota");
      return;
    }
    addLeaveTypes({
      quota: quotaSize,
      name: leaveTypeName,
      minimumLeaveCount: minLeaveCount,
      handleCancel,
      handleReset,
      showSuccess,
      handleLeaveTypeAdding,
      Id: selectedLeaveType?._id,
      setLoading,
    });
  };
  const handleReset = () => {
    setLeaveTypeName("");
    setQuotaSize("");
    setMinLeaveCount("");
  };
  const showSuccess = () => {
    return Swal.fire({
      title: "Good job!",
      text: "Successfully Saved!",
      icon: "success",
    });
  };

  const showWarning = (str) => {
    return Swal.fire({
      title: "Error",
      text: str,
      icon: "error",
    });
  };
  useEffect(() => {
    if (selectedLeaveType) {
      setLeaveTypeName(selectedLeaveType.name);
      setQuotaSize(selectedLeaveType.quota);
      setMinLeaveCount(selectedLeaveType.minimumLeaveCount);
    }
  }, [selectedLeaveType]);
  return (
    <div className="modal-content">
      <div className="modal-header border-0 custom-modal-header">
        <div className="page-title">
          <h4>{selectedLeaveType ? "Edit Leave Type" : "Add Leave Type"}</h4>
          <p className="mb-0">Fill in the required fields to add </p>
        </div>
      </div>
      <div className="modal-body custom-modal-body">
        <form>
          <div className="row mb-3">
            <div className="col-12">
              <div className="input-blocks">
                <label htmlFor="leaveTypeName">Leave Type</label>
                <input
                  type="text"
                  id="leaveTypeName"
                  className="form-control"
                  aria-label="leave Type Name"
                  value={leaveTypeName}
                  onChange={(event) => setLeaveTypeName(event.target.value)}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="input-blocks">
                <label htmlFor="leaveTypeName">Leave Quota</label>
                <input
                  type="number"
                  id="leaveTypeName"
                  className="form-control"
                  aria-label="Job Type Name"
                  value={quotaSize}
                  onChange={(event) => setQuotaSize(event.target.value)}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="input-blocks">
                <label htmlFor="leaveTypeName">Minimum Leave Count</label>
                <input
                  type="number"
                  id="leaveTypeName"
                  className="form-control"
                  value={minLeaveCount}
                  onChange={(event) => setMinLeaveCount(event.target.value)}
                />
              </div>
            </div>
          </div>
        </form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <button
                            type="button"
                            className="btn btn-cancel"
                            onClick={() => {
                                handleCancel();
                                handleReset();
                            }}
                        >
                            Close
                        </button>
                    <div>
            <button
              type="button"
              className="btn btn-reset me-2"
              onClick={handleReset}
            >
              Reset
            </button>
            <button className="btn btn-submit" onClick={handleSave}>
              {loading ? <Spin size="small" /> : "Save"}
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};
CreateLeaveTypeModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleLeaveTypeAdding: PropTypes.func.isRequired,
  selectedLeaveType: PropTypes.object.isRequired,
};
export default CreateLeaveTypeModal;
