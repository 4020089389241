import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { createUnit } from "../../../service/ApiServices";
import PropTypes from "prop-types";

const Addunits = ({onUnitsAdded}) => {
  const route = all_routes;

  const [name, setName] = useState('');
  const shortName = 'no short name';
  const status = true;

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    console.log("----------")
    e.preventDefault();
    try {
      const response = await createUnit({
          name: name,
          shortName: shortName,
          status: status

      });
      navigate(route.addproduct);
      onUnitsAdded();
      console.log(response.data,"RESP+DATA");
  } catch (error) {
      console.error('Error creating unit:', error);
  }
  };

  return (
    <>
      {/* Add Unit */}
      <div className="modal fade" id="add-unit">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Unit</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">Unit</label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {/* <input
                        type="text"
                        className="form-control"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                    /> */}
                  </div>
                  <div className="modal-footer-btn">
                    <Link
                      to="#"
                      className="btn btn-cancel me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <button type="submit" 
                      className="btn btn-submit"
                      data-bs-dismiss="modal">
                      Submit
                    </button>
                  
                  </div>
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Unit */}
    </>
  );
};
Addunits.propTypes = {
  onUnitsAdded: PropTypes.func.isRequired,
};

export default Addunits;
