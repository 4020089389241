import React, {useEffect, useState, useContext} from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import {Link, useNavigate} from "react-router-dom";
import {all_routes} from "../../../Router/all_routes";
import {login} from "../../../service/ApiServices";
import {Button, Toast} from "react-bootstrap";
import {UserContext} from "../../../context/userContext"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

const Signin = () => {
    localStorage.clear();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const {setUser} = useContext(UserContext);

    // const [name, setName] = useState(""); // State to store the user's name
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowToast(false);
        }, 6000);
        return () => clearTimeout(timeoutId);
    }, [showToast]);
    const navigate = useNavigate();
    // const [error, setError] = useState("");

    // hanlde user login
    const handleLogin = async (e) => {
        e.preventDefault();

        let errorMessage = "";

        console.log("Email", email)
        if (email === "") {
            errorMessage = "EPF cannot be empty"
        } else if (password === "") {
            errorMessage = "Password cannot be empty"

        } else if (!password) {
            errorMessage = "Password cannot be empty";
        }

        if (errorMessage !== "") {
            setErrorMsg(errorMessage)
            setShowToast(true);
            return;
        }

        try {
            console.log("Validated")
            const res = await login({
                email: email,
                password: password,
            });


            localStorage.setItem("user", JSON.stringify(res.data));

            const userData = res.data.data;
            const fullName = userData.name.split(' '); 
            const firstName = fullName[0];
            const lastName = fullName.slice(1).join(' ');
            setUser({
                firstName: firstName,
                lastName: lastName
            });


            navigate("/")

        } catch (error) {
            setShowToast(true)
        }
    };
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const route = all_routes;
    return (

        <div className="main-wrapper">
            <div className="account-content">
                <div className="login-wrapper bg-img">
                    <div className="login-content">
                        <form onSubmit={handleLogin}>
                            <div className="login-userset">
                                <div className="login-logo logo-normal">
                                    <ImageWithBasePath src="assets/img/OKI-logo.png" alt="img"/>
                                </div>
                                <Link to={route.dashboard} className="login-logo logo-white">
                                    <ImageWithBasePath src="assets/img/logo-white.png" alt/>
                                </Link>
                                <div className="login-userheading">
                                    <h3>Sign In</h3>
                                    <h4>
                                        Access the The Suit panel using your email and passcode.
                                    </h4>
                                </div>
                                <div className="form-login mb-3">

                                    <label className="form-label">EPF No</label>
                                    <div className="form-addons">
                                        <input
                                            type="text"
                                            className="form- control"
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            required
                                        />
                                        <ImageWithBasePath
                                            src="assets/img/icons/mail.svg"
                                            alt="img"
                                        />
                                    </div>
                                </div>
                                <div className="form-login mb-3">
                                    <label className="form-label">Password</label>
                                    <div className="pass-group">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className="pass-input form-control"
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                            required
                                        />
                                        <span
                                            className="fas toggle-password"
                                            onClick={handleTogglePassword}
                                            title={showPassword ? 'Hide password' : 'Show password'}
                                        >
        <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash}/>
      </span>
                                    </div>
                                </div>
                                <div className="form-login authentication-check">
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <div className="custom-control custom-checkbox">
                                                <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                                                    <input type="checkbox" className="form-control"/>
                                                    <span className="checkmarks"/>
                                                    Remember me
                                                </label>
                                            </div>
                                            <div className="text-end">
                                                <Link className="forgot-link" to={route.forgotPassword}>
                                                    Forgot Password?
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-login">
                                    <button
                                        type="submit"
                                        className="btn btn-login"

                                    >
                                        Sign In
                                    </button>
                                </div>

                                <div className="form-sociallink">
                                    <div
                                        className="my-4 d-flex justify-content-center align-items-center copyright-text">
                                        <p>Copyright © 2024 Eyerax Technologies. All rights reserved</p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="toast-container position-fixed top-0 end-0 p-3">
                <Toast
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    id="solidDangerToast"
                    className="colored-toast bg-danger text-fixed-white"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                >
                    <Toast.Header closeButton className="bg-danger text-fixed-white">
                        <strong className="me-auto">Error</strong>
                        <Button
                            variant="close"
                            onClick={() => setShowToast(false)}
                            aria-label="Close"
                        />
                    </Toast.Header>
                    <Toast.Body>
                        {/* Add your toast content here */}
                        {errorMsg}
                    </Toast.Body>
                </Toast>
            </div>
        </div>


    );
};

export default Signin;
