import axios from "axios";



export const fetchJobs = async () => {
    try {
      const response = await axios.get(` jobs`);
      console.log(response.data,"JOB JOB DATA API")
      return response.data;
    } catch (error) {
      console.error('Error fetching jobs:', error.message);
      throw error;
    }
  };

export const getJobByNo = async (jobNo) => {
  try {
    const response = await axios.get(`/get-job-no/`+jobNo);
    console.log(response.data,"JOB JOB DATA API")
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error.message);
    throw error;
  }
};

  export const fetchJobDetails = async (jobId) => {
    try {
        const response = await axios.get(`jobs/${jobId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching job details:', error);
        throw error;
    }
};

  export const searchJob = async (query) => {
    try {
      const response = await axios.get(` jobs/search`, {
        params: { q: query },
      });
      return response.data;
    } catch (error) {
      console.error('Error searching jobs:', error.message);
      throw error;
    }
  };
  

  export const deleteJob = async (jobId) => {
    try {
      const response = await axios.delete(` jobs/${jobId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting job:', error.message);
      throw error;
    }
  };
  

  export const createJobType = async (jobData) => {
    try {
        const response = await axios.post(` jobs`, jobData);
        return response.data;
    } catch (error) {
        throw new Error('Error creating job');
    }
};
  

  export const updateJob = async (jobId, jobData) => {
    try {
      const response = await axios.put(`jobs/${jobId}`, jobData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating job:', error.message);
      throw error;
    }
  };

