import React, { useEffect, useState } from 'react'
//import { Link } from 'react-router-dom'
//import Select from 'react-select'
import ImageWithBasePath from '../../img/imagewithbasebath';
import { uploadProfilePic, updateUser, getUserById, getRoles } from "../../../service/ApiServices";
import PropTypes from 'prop-types';

const EditUser = ({ userId}) => {

    //console.log(user, 'user EDIT USER')

    const [showPassword, setShowPassword] = useState(false);

    //update user start
    const [user, setUser] = useState(null);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [roleName, setRoleName] = useState("");
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);

    useEffect(() => {
        const fetchRoles = async () => {
            const rolesData = await getRoles();
            setRoles(rolesData);
        };
        fetchRoles();
    }, []);

    const handleRoleChange = (e) => {
        const roleId = e.target.value;
        setSelectedRole(roleId);
        const role = roles.find((role) => role._id === roleId);
        // console.log(roleId, "ROLE-ID")
        if (role) {
          setRoleName(role.name);
        }
      };
      console.log(roleName, "ROLE NAME")
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                // Fetch user details using the user ID
                const userData = await getUserById(userId);
                setUser(userData);
                // Set the user details in the state
                setName(userData.name);
                setPhone(userData.phone);
                setEmail(userData.email);
                setSelectedRole(userData.role);
                setDescription(userData.description);
                setPassword(userData.password);
                setConfirmPassword(userData.password);
                // console.log(userData,"USERDATA edit user")
            } 
            catch (error) {
                console.error('Error fetching user details:', error);
            }
            
        };

        if (userId) {
            fetchUserDetails();
        }
    }, [userId]);



    const handleFileChange = (event) => {
        const selectedImage = event.target.files[0];
        setImage(selectedImage);
    };

    const handleUploadClick = async () => {
        if (!image) {
            alert('Please select an image');
            return;
        }

        const result = await uploadProfilePic(user._id, image);

        if (result.success) {
            alert('Profile picture updated successfully');
        } else {
            alert(result.error);
        }
    };

    const handleUpdateUser = async (e) => {
        e.preventDefault();

        try {
            await updateUser(userId, {
                name,
                phone,
                email,
                role: selectedRole,
                password,
                confirmPassword,
                description,
            });
            alert('User updated successfully');

        } catch (error) {
            console.log(error);
        }
    };


    EditUser.propTypes = {
        userId: PropTypes.string.isRequired,
        userRole: PropTypes.object.isRequired,
        user: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            phone: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            role: PropTypes.string.isRequired,
            userId: PropTypes.string.isRequired,
            password: PropTypes.string.isRequired,
            confirmPassword: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            profilePic: PropTypes.string.isRequired,
            //roles: PropTypes.array.isRequired,
        }).isRequired,
    };



    //update user end

    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
    };
    return (
        <div>
            {/* Edit User */}
            <div className="modal fade" id="edit-units">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Edit User</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form onSubmit={handleUpdateUser}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="new-employee-field">
                                                    <span>Avatar</span>
                                                    <div className="profile-pic-upload edit-pic">
                                                        <div className="profile-pic">
                                                            <span>
                                                                <ImageWithBasePath
                                                                    src="assets/img/users/edit-user.jpg"
                                                                    //src={user.profilePic}
                                                                    className="user-editer"
                                                                    alt="User"
                                                                />
                                                            </span>
                                                            <div className="close-img">
                                                                <i data-feather="x" className="info-img" />
                                                            </div>
                                                        </div>
                                                        <div className="input-blocks mb-0">
                                                            <div className="image-upload mb-0">
                                                                <input type="file" onChange={handleFileChange} />
                                                                <div className="image-uploads" onClick={handleUploadClick}>
                                                                    <h4>Change Image</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>User Name</label>
                                                    <input
                                                        type="text"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Phone</label>
                                                    <input
                                                        type="text"
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Email</label>
                                                    <input
                                                        type="email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Role</label>
                                                  
                                                    <select
                                                        value={selectedRole}
                                                        onChange={handleRoleChange}
                                                        className="form-control"
                                                    >
                                                        <option value="">Role</option>
                                                        {roles.map((role) => (
                                                            <option key={role._id} value={role._id}>
                                                                {role.name}
                                                            </option>

                                                        ))}
                                                    </select>
                                                    {/* {console.log(roles, "selected Role edit user")} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Password</label>
                                                    <div className="pass-group">
                                                        <input
                                                            type="password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                        <span
                                                            className={`fas toggle-password ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                                                            onClick={handleTogglePassword}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-blocks">
                                                    <label>Confirm Passworrd</label>
                                                    <div className="pass-group">
                                                        <input
                                                            type="password"
                                                            value={confirmPassword}
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                        />
                                                        <span
                                                            className={`fas toggle-password ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                                                            onClick={handleToggleConfirmPassword}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-0 input-blocks">
                                                    <label className="form-label">Descriptions</label>
                                                    <textarea
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                    />
                                                    <p>Maximum 600 Characters</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                data-bs-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            {/* <Link to="/users" className="btn btn-submit">
                                                Submit
                                            </Link> */}
                                            <button
                                                type="submit"
                                                className="btn btn-submit"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Edit User */}
        </div>
    )
}

export default EditUser
