import axios from "axios";
import Swal from "sweetalert2";


const showWarning = (str) => {
  return Swal.fire({
    title: "Error",
    text: str,
    icon: "error",
  });
}
    export const creteBodyTypeMethod  = async ({
        styleName,
        values,
        handleCancel,
        handleReset,
        showSuccess,
        handleStyleAdding,
        setLoading,
        styleId
    }) => {
        try {
            const userId = JSON.parse(localStorage.getItem('user')).data._id;
            setLoading(true);
            await axios.post("/styles", { styleName, values, user: userId ,styleId})
                .then((response) => {
                    handleCancel();
                    handleReset();
                    setLoading(false);
                    showSuccess();
                    handleStyleAdding();
                    console.log(response);
                }).catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error.response && error.response.status === 400) {
                        showWarning(error.response.data.error);
                        return;
                    }
                    showWarning("Failed to create style");
                });
        } catch (error) {
            console.log(error);
            showWarning("Failed to create style");
        }
    };
    

export const getAllBodyTypeMethod = async ({search, setLoading}) => {
    try {
        const response = await axios.get("/styles?user="
            + JSON.parse(localStorage.getItem('user')).data._id
            + "&search=" + search
        );
        setLoading(false)
        return response.data
    } catch (error) {
        setLoading(false)
        console.log(error)
        showWarning('Failed to fetch job types');
    }
}

export const getStyleById = async (id) => {
    try {
        const response = await axios.get(`/styles/${id}`);
        return response.data; // style data
    } catch (error) {
        console.error('Error fetching style:', error);
    }
};



