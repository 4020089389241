
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { all_routes } from "../../Router/all_routes";
import DatePicker from "react-datepicker";
import Addunits from "../../core/modals/inventory/addunits";
import AddCategory from "../../core/modals/inventory/addcategory";
import AddBrand from "../../core/modals/addbrand";
import {
  ArrowLeft,
  Calendar,
  ChevronDown,
  ChevronUp,
  Info,
  LifeBuoy,
  List,
  PlusCircle,
  // Trash2,
  X,
} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import { setToogleHeader } from "../../core/redux/action";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import  { getProduct, updateProduct } from "../../service/ApiServices";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../environment";

const EditProduct = () => {
  const navigate = useNavigate();
  const route = all_routes;
  const dispatch = useDispatch();

  const [stores, setStores] = useState([]);
  const [wareHouses, setWareHouses] = useState([]);
  const [shouldReloadCategoryData, setShouldReloadCategoryData] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [shouldReloadBrandData, setShouldReloadBrandData] = useState(false);
  const [brands, setBrands] = useState([]);
  const [shouldReloadUnitData, setShouldReloadUnitData] = useState(false);
  const [units, setUnits] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [preImageFiles, setPreImageFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const { productId } = location.state;
    
  const data = useSelector((state) => state.toggle_header);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [storesResponse, wareHousesResponse, categoriesResponse, subCategoriesResponse, brandsResponse, unitsResponse] = await Promise.all([
        fetch(`http://${BASE_URL}:8000/api/stores`),
        fetch(`http://${BASE_URL}:8000/api/wareHouses`),
        fetch(`http://${BASE_URL}:8000/api/category-list`),
        fetch(`http://${BASE_URL}:8000/api/sub-categories`),
        fetch(`http://${BASE_URL}:8000/api/brand/`),
        fetch(`http://${BASE_URL}:8000/api/unit/`)
      ]);

      if (!storesResponse.ok) throw new Error("Failed to fetch Stores");
      if (!wareHousesResponse.ok) throw new Error("Failed to fetch WareHouses");
      if (!categoriesResponse.ok) throw new Error("Failed to fetch Categories");
      if (!subCategoriesResponse.ok) throw new Error("Failed to fetch SubCategories");
      if (!brandsResponse.ok) throw new Error("Failed to fetch Brands");
      if (!unitsResponse.ok) throw new Error("Failed to fetch Units");

      const [storesData, wareHousesData, categoriesData, subCategoriesData, brandsData, unitsData] = await Promise.all([
        storesResponse.json(),
        wareHousesResponse.json(),
        categoriesResponse.json(),
        subCategoriesResponse.json(),
        brandsResponse.json(),
        unitsResponse.json()
      ]);

      const storesOptions = storesData.data.stores.map(store => ({ label: store.storeName, value: store._id }));
      const wareHousesOptions = wareHousesData.data.wareHouses.map(wareHouse => ({ label: wareHouse.wareHouseName, value: wareHouse._id }));
      const categoriesOptions = categoriesData.data.category.map(category => ({ label: category.category, value: category._id }));
      const subCategoriesOptions = subCategoriesData.data.subcategory.map(subCategory => ({ label: subCategory.subCategory, value: subCategory._id }));
      const brandsOptions = brandsData.map(brand => ({ label: brand.name, value: brand._id }));
      const unitsOptions = unitsData.data.map(unit => ({ label: unit.name, value: unit._id }));

      setStores(storesOptions);
      setWareHouses(wareHousesOptions);
      setCategories(categoriesOptions);
      setSubCategories(subCategoriesOptions);
      setBrands(brandsOptions);
      setUnits(unitsOptions);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  // handle Category
  const handleReloadCategoryData = () => {
    setShouldReloadCategoryData((prev) => !prev);
  };

  useEffect(() => {
    fetchCategorys();
  }, [shouldReloadCategoryData]);

  const fetchCategorys = async () => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/category-list`);
      if (!response.ok) {
        throw new Error("Failed to fetch categorirs");
      }
      const data = await response.json();
      const options = data.data.category.map((category) => ({
        label: category.category,
        value: category._id,
      }));
      
      setCategories(options);
    } catch (error) {
      console.error("Error fetching WareHouse:", error);
    }
  };

  // handle Brand
  const handleReloadBrandData = () => {
    setShouldReloadBrandData((prev) => !prev);
  };

  useEffect(() => {
    fetchBrands();
  }, [shouldReloadBrandData]);

  const fetchBrands = async () => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/brand/`);
      if (!response.ok) {
        throw new Error("Failed to fetch Brands");
      }
      const data = await response.json();
      const options = data.map((brand) => ({
        label: brand.name,
        value: brand._id,
      }));
      
      setBrands(options);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  // handle unit
  const handleReloadUnitData = () => {
    setShouldReloadUnitData((prev) => !prev);
  };

  useEffect(() => {
    fetchUnits();
  }, [shouldReloadUnitData]);

  const fetchUnits = async () => {
    try {
      const response = await fetch(`http://${BASE_URL}:8000/api/unit/`);
      if (!response.ok) {
        throw new Error("Failed to fetch units");
      }
      const data = await response.json();
      const options = data.data.map((unit) => ({
        label: unit.name,
        value: unit._id,
      }));
      
      setUnits(options);
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };

  const barcodesymbol = [
    { value: "choose", label: "Choose" },
    { value: "code34", label: "Code34" },
    { value: "code35", label: "Code35" },
    { value: "code36", label: "Code36" },
  ];


  const [formData, setFormData] = useState({
    store: "",
    wareHouse: "",
    productName: "",
    productSlug: "",
    productSKU: "",
    category: "",
    subCategory: "",
    brand: "",
    unit: "",
    productBarcodeSymbology: "",
    productItemCode: "",
    productDiscription: "",
    productQuantity: 0,
    productQuantityAlert: 0,
    productImagesUrl: [],
    productWarranties: false,
    productManufacturedDate: null,
    productExpiryDate: null,
    productCreater: "",
    productCreaterImg: null,
    // productBatch: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const images = files.map(file => URL.createObjectURL(file));
    setSelectedImages([...selectedImages, ...images]);
    setImageFiles([...imageFiles, ...files]);
  };

  const handleRemoveImage = (index) => {
    const newImages = [...selectedImages];
    const newImageFiles = [...imageFiles];
    const newPreImageFiles = [...preImageFiles];
    newImages.splice(index, 1);
    if(index>preImageFiles.length-1){
      newImageFiles.splice((index-preImageFiles.length-1), 1);
    }
    if(index<=preImageFiles.length-1){
      newPreImageFiles.splice(index, 1);
    }
    setSelectedImages(newImages);
    setImageFiles(newImageFiles);
    setPreImageFiles(newPreImageFiles);
  };

  useEffect(() => {
    if (!isLoading && productId) {
      setSelectedProduct(productId);
    }
  }, [isLoading, productId]);

  const setSelectedProduct = async (productId) => {
    try {
      const response = await getProduct(productId);
      if (response.data) {
        const product = response.data.data.product;
        console.log("working");
        
        const storeOption = stores.find(s => s.value === product.store._id);
        const wareHouseOption = wareHouses.find(w => w.value === product.wareHouse._id);
        const categoriesOption = categories.find(c => c.value === product.category._id);
        const subCategoriesOption = subCategories.find(sc => sc.value === product.subCategory._id);
        const brandsOption = brands.find(b => b.value === product.brand._id);
        const unitsOption = units.find(u => u.value === product.unit._id);
        const barcodesymbolOption = barcodesymbol.find(u => u.value === product.productBarcodeSymbology);

        console.log(subCategoriesOption);

        const cleanImagePaths = product.productImages.map(imagePath => imagePath.replace('/uploads/', ''));
        setPreImageFiles(cleanImagePaths);

        setFormData({
          ...formData,
          store: storeOption,
          wareHouse: wareHouseOption,
          productName: product.productName,
          productSlug: product.productSlug,
          productSKU: product.productSKU,
          category: categoriesOption,
          subCategory: subCategoriesOption,
          brand: brandsOption,
          unit: unitsOption,
          productBarcodeSymbology: barcodesymbolOption,
          productItemCode: product.productItemCode,
          productDiscription: product.productDiscription,
          productQuantity: product.productQuantity,
          productQuantityAlert: product.productQuantityAlert,
          productWarranties: product.productWarranties,
          productManufacturedDate: product.productManufacturedDate ? new Date(product.productManufacturedDate) : null,
          productExpiryDate: product.productExpiryDate ? new Date(product.productExpiryDate) : null,
          productCreater: product.productCreater,
          productCreaterImg: product.productCreaterImg,
        });

        setSelectedImages(product.productImagesUrl);
      } else {
        console.error("Failed to fetch product data");
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };


  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedFormData = { ...formData, store: formData.store.value, productImagesUrl: selectedImages,
        wareHouse: formData.wareHouse.value, category: formData.category.value, subCategory: formData.subCategory.value,
        brand: formData.brand.value, unit: formData.unit.value, productBarcodeSymbology: formData.productBarcodeSymbology.value, 
        preImageFiles: preImageFiles,
       };
      console.log(updatedFormData)

      const formDataToSubmit = new FormData();
      
      for (const key in updatedFormData) {
        formDataToSubmit.append(key, updatedFormData[key]);
      }
      
      imageFiles.forEach((file) => {
        formDataToSubmit.append('productImages', file);
      });
      console.log(formDataToSubmit);
      const response = await updateProduct(productId, formDataToSubmit);  
      // console.log(formDataToSubmit);
      navigate(route.productlist);
      console.log("product updated successfully:", response);
      setFormData({
        store: "",
        wareHouse: "",
        productName: "",
        productSlug: "",
        productSKU: "",
        category: "",
        subCategory: "",
        brand: "",
        unit: "",
        productBarcodeSymbology: "",
        productItemCode: "",
        productDiscription: "",
        productQuantity: 0,
        productQuantityAlert: 0,
        productImages: [],
        productWarranties: false,
        productManufacturedDate: null,
        productExpiryDate: null,
        productCreater: "",
        productCreaterImg: null,
        productBatch: [],
      });
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };


const handleDateChange = (date, name) => {
  setFormData((prevData) => ({
    ...prevData,
    [name]: date,
  }));
};

const handleCheckboxChange = (event) => {
  const { name, checked } = event.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: checked,
  }));
};

const handleCancleClick = () => {
  navigate(route.productlist);
};
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Edit Product</h4>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <div className="page-btn">
                <Link to={route.productlist} className="btn btn-secondary">
                  <ArrowLeft className="me-2" />
                  Back to Product
                </Link>
              </div>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                <Link
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Collapse"
                  id="collapse-header"
                  className={data ? "active" : ""}
                  onClick={() => {
                    dispatch(setToogleHeader(!data));
                  }}
                >
                  <ChevronUp className="feather-chevron-up" />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
        </div>
        {/* /add */}
        <form onSubmit={handleUpdateSubmit}>
          <div className="card">
            <div className="card-body add-product pb-0">
              <div
                className="accordion-card-one accordion"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-controls="collapseOne"
                    >
                      <div className="addproduct-icon">
                        <h5>
                          <Info className="add-info" />

                          <span>Product Information</span>
                        </h5>
                       
                          {/* <div className="chevron-down-add">
                            <ChevronDown />
                          </div> */}
                       
                        <Link to="#" onClick={(e) => e.preventDefault()}>
                          <ChevronDown className="chevron-down-add" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">Store</label>
                            <Select
                            options={stores}
                            value={formData.store}
                            placeholder="Choose store"
                            required
                            onChange={(selectedOption) => setFormData((prevData) => ({
                              ...prevData,
                              store: selectedOption,
                            }))}
                            // placeholder="Choose"
                          />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">Warehouse</label>
                            <Select
                            options={wareHouses}
                            value={formData.wareHouse}
                            placeholder="Choose store"
                            onChange={(selectedOption) => setFormData((prevData) => ({
                              ...prevData,
                              wareHouse: selectedOption,
                            }))}
                            required
                            // placeholder="Choose"
                          />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">Product Name</label>
                            <input
                            type="text"
                            className="form-control"
                            name="productName"
                            value={formData.productName}
                            onChange={handleChange}
                            required
                          />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">Slug</label>
                            <input
                            type="text"
                            className="form-control"
                            name="productSlug"
                            value={formData.productSlug}
                            onChange={handleChange}
                            required
                          />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks add-product list">
                            <label>SKU</label>
                            <input
                            type="text"
                            className="form-control"
                            name="productSKU"
                            value={formData.productSKU}
                            onChange={handleChange}
                            required
                          />
                          </div>
                        </div>
                      </div>
                      <div className="addservice-info">
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <div className="add-newplus">
                                <label className="form-label">Category</label>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add-units-category"
                                >
                                  <PlusCircle className="plus-down-add" />
                                  <span>Add New</span>
                                </Link>
                              </div>
                              <Select
                                options={categories}
                                value={formData.category}
                                placeholder="Choose store"
                                onChange={(selectedOption) => setFormData((prevData) => ({
                                  ...prevData,
                                  category: selectedOption,
                                }))}
                                required
                                // placeholder="Choose"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <label className="form-label">Sub Category</label>
                              <Select
                                options={subCategories}
                                value={formData.subCategory}
                                placeholder="Choose store"
                                onChange={(selectedOption) => setFormData((prevData) => ({
                                  ...prevData,
                                  subCategory: selectedOption,
                                }))}
                                required
                                // placeholder="Choose"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">
                              Barcode Symbology
                            </label>
                            <Select
                                options={barcodesymbol}
                                value={formData.productBarcodeSymbology}
                                placeholder="Choose Type"
                                className="select"
                                onChange={(selectedOption) => setFormData((prevData) => ({
                                    ...prevData,
                                    productBarcodeSymbology: selectedOption,
                                }))}
                                required
                                />
                          </div>
                        </div>
                        </div>
                      </div>
                      <div className="add-product-new">
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <div className="add-newplus">
                                <label className="form-label">Brand</label>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add-units-brand"
                                >
                                  <PlusCircle className="plus-down-add" />
                                  <span>Add New</span>
                                </Link>
                              </div>
                              <Select
                                options={brands}
                                value={formData.brand}
                                placeholder="Choose store"
                                onChange={(selectedOption) => setFormData((prevData) => ({
                                  ...prevData,
                                  brand: selectedOption,
                                }))}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <div className="add-newplus">
                                <label className="form-label">Unit</label>
                                <Link
                                  // to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add-unit"
                                >
                                  <PlusCircle className="plus-down-add" />
                                  <span>Add New</span>
                                </Link>
                              </div>
                              <Select
                                options={units}
                                value={formData.unit}
                                placeholder="Choose store"
                                onChange={(selectedOption) => setFormData((prevData) => ({
                                  ...prevData,
                                  unit: selectedOption,
                                }))}
                                required
                                // placeholder="Choose"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks add-product list">
                            <label>Item Code</label>
                            <input
                            type="text"
                            className="form-control"
                            name="productItemCode"
                            value={formData.productItemCode}
                            onChange={handleChange}
                            required
                          />
                          </div>
                        </div>
                        </div>
                      </div>
                      <div className="row">
                      </div>
                      {/* Editor */}
                      <div className="col-lg-12">
                        <div className="input-blocks summer-description-box transfer mb-3">
                          <label>Description</label>
                          <textarea
                            className="form-control mb-1"
                            name="productDiscription"
                            value={formData.productDiscription}
                            onChange={handleChange}
                            required
                          />
                          <p className="mt-1">Maximum 60 Characters</p>
                        </div>
                      </div>
                      {/* /Editor */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="accordion-card-one accordion"
                id="accordionExample2"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingTwo">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-controls="collapseTwo"
                    >
                      <div className="text-editor add-list">
                        <div className="addproduct-icon list icon">
                          <h5>
                            <LifeBuoy className="add-info" />
                            <span>Pricing &amp; Stocks</span>
                          </h5>
                          <Link to="#" onClick={(e) => e.preventDefault()}>
                            <ChevronDown className="chevron-down-add" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <div className="input-blocks add-products">
                        <label className="d-block">Product Type</label>
                        <div className="single-pill-product">
                          <ul
                            className="nav nav-pills"
                            id="pills-tab1"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <span
                                className="custom_radio me-4 mb-0 active"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                              >
                                <input
                                  type="radio"
                                  className="form-control"
                                  name="payment"
                                />
                                <span className="checkmark" /> Single Product
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks add-product">
                                <label>Quantity Alert</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="productQuantityAlert"
                                  value={formData.productQuantityAlert}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="accordion-card-one accordion"
                            id="accordionExample3"
                          >
                            <div className="accordion-item">
                              <div
                                className="accordion-header"
                                id="headingThree"
                              >
                                <div
                                  className="accordion-button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseThree"
                                  aria-controls="collapseThree"
                                >
                                  <div className="addproduct-icon list">
                                    <h5>
                                      <i
                                        data-feather="image"
                                        className="add-info"
                                      />
                                      <span>Images</span>
                                    </h5>
                                    {/* <div className="chevron-down-add">
                                      <ChevronDown />
                                    </div> */}
                                    <Link to="#" onClick={(e) => e.preventDefault()}>
                                      <ChevronDown className="chevron-down-add" />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="collapseThree"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample3"
                              >
                                <div className="accordion-body">
                                  <div className="text-editor add-list add">
                                    <div className="col-lg-12">
                                    <div className="add-choosen">
                                            <div className="input-blocks">
                                              <div className="image-upload">
                                                <input type="file" onChange={handleImageChange} accept="image/*" />
                                                <div className="image-uploads">
                                                  <PlusCircle className="plus-down-add me-0" />
                                                  <h4>Add Images</h4>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row">
                                            {selectedImages.map((image, index) => (
                                              <div key={index} className="col-6 col-md-4 col-lg-3 mb-3 position-relative phone-img">
                                                <img src={image} alt={`Selected Image ${index + 1}`} className="img-fluid" />
                                                <Link to="#" className="position-absolute top-0 end-0" onClick={(e) => e.preventDefault()}>
                                                  <X
                                                    className="x-square-add remove-product"
                                                    onClick={() => handleRemoveImage(index)}
                                                  />
                                                </Link>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="accordion-card-one accordion"
                id="accordionExample4"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingFour">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-controls="collapseFour"
                    >
                      <div className="text-editor add-list">
                        <div className="addproduct-icon list">
                          <h5>
                            <List className="add-info" />
                            <span>Custom Fields</span>
                          </h5>
                          <Link to="#" onClick={(e) => e.preventDefault()}>
                            <ChevronDown className="chevron-down-add" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample4"
                  >
                    <div className="accordion-body">
                      <div className="text-editor add-list add">
                        <div className="custom-filed">
                        </div>
                        <div className="row">
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks add-product">
                            <label className="checkboxs">
                            <input
                                type="checkbox"
                                name="productWarranties"
                                checked={formData.productWarranties}
                                onChange={handleCheckboxChange}
                              />
                              {/* <input type="checkbox" /> */}
                              <span className="checkmarks" />
                              Warranties
                            </label>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Manufactured Date</label>
                              <div className="input-groupicon calender-input">
                                <Calendar className="info-img" />
                                <DatePicker
                                name="productManufacturedDate"
                                selected={formData.productManufacturedDate}
                                onChange={(date) => handleDateChange(date, 'productManufacturedDate')}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Choose Date"
                                className="datetimepicker"
                                required
                              />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Expiry On</label>
                              <div className="input-groupicon calender-input">
                                <Calendar className="info-img" />
                                <DatePicker
                                  name="productExpiryDate"
                                  selected={formData.productExpiryDate}
                                  onChange={(date) => handleDateChange(date, 'productExpiryDate')}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="Choose Date"
                                  className="datetimepicker"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="btn-addproduct mb-4">
              <button 
              type="button" 
              className="btn btn-cancel me-2"
              onClick={handleCancleClick}
              >
                Cancel
              </button>
              <button type="submit" 
                className="btn btn-submit">
                Save Product
              </button>
              {/* <Link to={route.addproduct} className="btn btn-submit">
                Save Product
              </Link> */}
            </div>
          </div>
        </form>
        {/* /add */}
      </div>
      <Addunits onUnitsAdded={handleReloadUnitData}/>
      <AddCategory onCategoryAdded={handleReloadCategoryData}/>
      <AddBrand onBrandAdded={handleReloadBrandData}/>
    </div>
  );
};


export default EditProduct;

