import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; 

const EditUserRole = () => {
  const { id } = useParams(); 
  const [roleid, setRoleId] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchRoleData = async () => {
      try {
        const response = await fetch(`http://localhost:8000/api/useracrights/update/${id}`, {
          method: "GET",
        });
        const data = await response.json();
        setRoleId(data.roleid);
        setRole(data.role);
      } catch (error) {
        console.error("Error fetching role data:", error);
      }
    };
    fetchRoleData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedRole = { roleid, role };
    try {
      const response = await fetch(`http://localhost:8000/api/useracrights/update/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedRole),
      });

      if (response.ok) {
        navigate("/roles"); 
      } else {
        setError("Failed to update user role");
      }
    } catch (error) {
      console.error("Error updating role:", error);
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Edit User Role</h4>
              <h6>Update the role details</h6>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Role ID</label>
                <input type="text" className="form-control" value={roleid} readOnly />
              </div>
              <div className="form-group">
                <label>Role Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  placeholder="Enter role name"
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Update Role
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserRole;
