import axios from 'axios';
import Swal from 'sweetalert2';
//get all leave allocation
export const getAllLeaveAllocations = async ({ search, setLoading }) => {
  try {
    setLoading(true); 

    const userId = JSON.parse(localStorage.getItem("user")).data._id;
    
    const response = await axios.get(
      `/allocate-leaves?user=${userId}&search=${search}`
    );

    setLoading(false); 
    console.log(response.data); 
    return response.data; 
  } catch (error) {
    setLoading(false); 
    console.error("Error fetching leave allocations:", error);  
    return [];  
  }
};

//delete leave allocation
export const deleteLeaveAllocation = async (id) => {
  try {
    const response = await axios.delete(`/allocate-leaves/${id}`);
    if (!response.ok) {
        const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to delete leave allocation');
          }
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const saveLeaveAllocations = async (leaveAllocations, setLoading) => {
  try {
    setLoading(true);

    const formattedLeaveAllocations = leaveAllocations.map(allocation => ({
      employee: allocation.employee, 
      leaveBalances: allocation.leaveBalances.map(item => ({
        leaveType: item.leaveType, 
        leaveCount: item.leaveCount 
      })),
      finacialYear: allocation.finacialYear
    }));

    const response = await axios.post('/allocate-leaves', formattedLeaveAllocations);
    const { allProcessedAllocations, skippedAllocations } = response.data;
    Swal.fire({
      icon: 'success',
      title: 'Success!',
      text: 'Successfully saved allocations',
    });
    console.log('Skipped allocations:', skippedAllocations);

    if (skippedAllocations.length > 0) {
      const skippedList = skippedAllocations
        .map(item => `Employee: ${item.employeeName.first_name}  ${item.employeeName.last_name}, Leave Type: ${item.leaveTypeName}`)
        .join('<br>');

      Swal.fire({
        icon: 'warning',
        title: 'Skipped Allocations',
        html: `Following leave allocations were skipped due to duplicates:<br>${skippedList}`,
      });
    }

    return allProcessedAllocations; 
  } catch (error) {
    console.error('Error saving allocations:', error.message);
    
    Swal.fire('Error', 'Failed to save leave allocations', 'error');
    throw error; 
  } finally {
    setLoading(false);
  }
};


//update Allocation

export const updateLeaveAllocation = async (allocationId, employee, leaveBalances, financialYear, setLoading) => {
  try {
    setLoading(true);

    const response = await axios.put('/allocate-leaves', {
      allocationId,
      employee,
      leaveBalances,
      financialYear,
    });

    setLoading(false);
    
    // Display success alert
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Leave allocation updated successfully!',
    });

    return response.data; 
  } catch (error) {
    setLoading(false);
    console.error("Error updating leave allocation:", error);

   
    if (error.response && error.response.status === 400) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: error.response.data.message, 
      });
    } else {
      
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.message || 'Failed to update leave allocation',
      });
    }

    return null; 
  }
};


export const filteredTableData = async ({epf_number, empType, departmentName,leaveTypeId}) => {
  try {
    const response = await axios.get('/leaves-table-data', {
        params: {
            leaveTypeId,
            departmentName,
            employee_type:empType,
            epf_number
        }
    });
   
    return response.data;
    
} catch (error) {
    console.error('Error fetching data:', error);
}
};